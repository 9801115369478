import React from 'react';
import { Modal } from '@purposeinplay/core-v2';
import ChallengesBody from './challenges-body';
import useModal, { useCurrentModal } from '@/hooks/modal';

const ChallengesModal = () => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'challenges';

  return (
    <Modal
      title="Challenges"
      isOpen={isOpen}
      setIsOpen={() => close()}
      size="3xl"
      modalStylingOptions={{
        containerStyles: ' mb-14 md:mb-0',
      }}
    >
      <ChallengesBody />
    </Modal>
  );
};

export default ChallengesModal;
