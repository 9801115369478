import { useEffect, useState } from 'react';

const useIsStandalone = () => {
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    const checkStandaloneMode = () => {
      if (typeof window !== 'undefined') {
        setIsStandalone(
          window.matchMedia('(display-mode: standalone)').matches,
        );
      }
    };

    checkStandaloneMode();

    // Add event listener to watch for changes in display mode
    const standaloneQuery = window.matchMedia('(display-mode: standalone)');
    standaloneQuery.addEventListener('change', checkStandaloneMode);

    return () => {
      standaloneQuery.removeEventListener('change', checkStandaloneMode);
    };
  }, []);

  return { isStandalone };
};

export default useIsStandalone;
