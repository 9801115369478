import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';

const useWildChatContent = () => {
  const { t } = useTranslation();
  const homepageContent = t('homepage') as HomepageContentType;
  return homepageContent?.wild_chat;
};

export default useWildChatContent;
