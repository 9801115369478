import Timeleft from '@/components/Timeleft';
import { SessionLimit, UserLimit } from '@/types/user';
import React from 'react';
import InfoStatus from '../info-status';
import dayjs from 'dayjs';
import useCountdown from '@/hooks/use-countdown';
import { Field, FieldGroup, Label } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { SettingsContentType } from '@/types/json-content/settings';

interface UnconfirmedDisabledProps {
  filteredLimit: SessionLimit | UserLimit;
}

const UnconfirmedDisabled = ({ filteredLimit }: UnconfirmedDisabledProps) => {
  const { state, hasNoState } = useCountdown({
    short: false,
    date: filteredLimit?.disable_at,
  });

  const { t } = useTranslation();
  const settingsContent = t('account_settings') as SettingsContentType;
  const content = settingsContent?.sessions;

  return (
    <div className="flex flex-col items-start justify-between md:max-w-lg">
      <InfoStatus
        tagStatus="warning"
        tagLabel={content?.info_status_label?.pending}
        description={`${filteredLimit?.period} minutes`}
        className="mb-2"
      />
      <Field>
        <Label>{content?.countdown_label}</Label>
        <Timeleft
          units={{
            days: state?.days,
            minutes: state?.minutes,
            hours: state?.hours,
            seconds: state?.seconds,
          }}
        />
      </Field>
    </div>
  );
};

export default UnconfirmedDisabled;
