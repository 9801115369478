import Countdown from '@/components/Countdown';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { Avatar, Badge } from '@purposeinplay/core-v2';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  progress: number;
  isOpen: boolean;
  time: any;
}

const PopUpButton = ({ isOpen, setIsOpen, progress, time }: Props) => {
  const [needCustomPosition, setNeedCustomPosition] = useState(false);

  useEffect(() => {
    const updatedNoticeHidden = localStorage.getItem('wild_app_install_hidden');
    const updatedWildCookies = localStorage.getItem('wild_cookies_accepted');

    const shouldHaveCustomPosition =
      !updatedNoticeHidden ||
      !updatedWildCookies ||
      !(updatedNoticeHidden === 'true' && updatedWildCookies === 'true');

    setNeedCustomPosition(shouldHaveCustomPosition);

    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key === 'wild_app_install_hidden' ||
        event.key === 'wild_cookies_accepted'
      ) {
        const updatedNoticeHidden = localStorage.getItem(
          'wild_app_install_hidden',
        );
        const updatedWildCookies = localStorage.getItem(
          'wild_cookies_accepted',
        );

        const shouldHaveCustomPosition =
          !updatedNoticeHidden ||
          !updatedWildCookies ||
          !(updatedNoticeHidden === 'true' && updatedWildCookies === 'true');

        setNeedCustomPosition(shouldHaveCustomPosition);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <button
      onClick={() => setIsOpen(true)}
      className={cn([
        'safe-bottom-padding fixed bottom-20 right-3 z-[99] flex w-auto flex-col items-end focus-within:outline-none hover:outline-none focus:outline-none focus-visible:outline-none md:!right-3 md:bottom-6 md:left-auto md:z-[999] md:items-center lg:bottom-20',
        isOpen && 'hidden',
        needCustomPosition && 'bottom-36',
      ])}
    >
      <div className="w-[6.563rem]">
        <Avatar
          circularProgressProps={{
            animationSpeed: 10,
            incrementSpeed: 1,
            progress,
          }}
          stylingOptions={{
            strokeBgColor: 'text-surface-subdued',
            containerStyles:
              '[&>div]:bg-[linear-gradient(164deg,#8365FF_9.64%,#503A95_88.77%)]',
          }}
          icon={
            <Image
              src={`/assets/deposit-pop-up.svg`}
              loader={DEFAULT_IMAGE_LOADER}
              width={30}
              height={30}
              alt="User Avatar"
            />
          }
          size="lg"
        />
        <Badge
          size="md"
          type="success"
          radius="rounded"
          className="flex w-[rem] justify-center text-center"
        >
          <Countdown date={time} withSvg={false} withoutTextTag />
        </Badge>
      </div>
    </button>
  );
};

export default PopUpButton;
