import React from 'react';
import { Button } from '@purposeinplay/core-v2';

import WildChatButton from '@/modules/wild-chat/components/wild-chat-button';

import useModal from '@/hooks/modal';
import { useAnalytics } from '@/hooks/use-analytics';
import { useIsMounted } from '@purposeinplay/utils';
import { cn } from '@/utils/style';
import useFeatureVisibility from '@/hooks/use-feature-visibility';

const RightNavGuest = () => {
  const { open } = useModal();
  const { track } = useAnalytics();
  const isMounted = useIsMounted();
  const { shouldShow } = useFeatureVisibility('wild-chat');

  return (
    <>
      <div className="ml-auto flex flex-row items-center gap-spacing-sm">
        <Button
          color="tertiary"
          size="md"
          ghost
          style={{
            minWidth: '84px',
          }}
          onClick={() => {
            open('signin');
            track('signin_form_started', {
              category: 'auth',
            });
          }}
        >
          Log In
        </Button>
        <Button
          className="whitespace-nowrap"
          size="md"
          style={{
            minWidth: '84px',
          }}
          onClick={() => {
            open('signup');
            track('signup_form_started', {
              category: 'auth',
              label: window && window.location && window.location.href,
            });
          }}
        >
          Sign Up
        </Button>
        <div
          className={cn([
            isMounted && shouldShow && 'rounded-xl bg-bgr-lighter p-1',
          ])}
        >
          <WildChatButton
            stylingOptions={{
              buttonSize: 'md',
              className: 'h-8 w-8',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RightNavGuest;
