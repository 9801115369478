'use client';

import React, { forwardRef } from 'react';
import { cn } from '@/utils/style';
import { ChatAsideProps } from '../types';

const DesktopChat = forwardRef<HTMLDivElement, ChatAsideProps>(
  ({ isChatOpen, children }, ref) => {
    return (
      <aside
        ref={ref}
        className={cn([
          'transition-element fixed bottom-0 right-0 top-0 z-[999] flex w-[369px] flex-col justify-between bg-bgr-lighter p-2 [box-shadow:-6px_0px_6px_-6px_rgba(0,0,0,0.3)]',
          isChatOpen ? 'translate-x-0' : 'translate-x-full',
        ])}
      >
        {children}
      </aside>
    );
  },
);

DesktopChat.displayName = 'DesktopChat';

export default DesktopChat;
