export const formattedMessage = (message: string) => {
  const regex = /Wild\.io/i;

  if (!regex.test(message)) {
    return message;
  }

  const parts = message.split(/(Wild\.io)/i);
  return (
    <>
      {parts[0]}
      <span className="text-base-primary">{parts[1]}</span>
      {parts[2]}
    </>
  );
};
