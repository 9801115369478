'use client';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useGamesContent } from '@/hooks/use-games-content';

export type SidebarSection =
  | 'notification'
  | 'favorites'
  | 'tournaments'
  | 'search'
  | 'new'
  | 'popular'
  | null;

interface GamePageSidebarContextType {
  activeSection: SidebarSection;
  setActiveSection: (section: SidebarSection) => void;
  isOpen: boolean;
  shouldDisplayBackButton: boolean;
  setShouldDisplayBackButton: (shouldDisplay: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const GamePageSidebarContext = createContext<GamePageSidebarContextType | null>(
  null,
);

export const useGamePageSidebar = () => {
  const context = useContext(GamePageSidebarContext);
  const { gamesContent } = useGamesContent();

  if (!context) {
    throw new Error('useGamePageSidebar should be used in a provider');
  }
  return context;
};

interface GamePageSidebarProviderProps {
  children: React.ReactNode;
}

export const GamePageSidebarProvider: React.FC<
  GamePageSidebarProviderProps
> = ({ children }) => {
  const [activeSection, setActiveSection] = useState<SidebarSection>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldDisplayBackButton, setShouldDisplayBackButton] = useState(false);

  return (
    <GamePageSidebarContext.Provider
      value={{
        activeSection,
        setActiveSection,
        isOpen,
        setIsOpen,
        shouldDisplayBackButton,
        setShouldDisplayBackButton,
      }}
    >
      {children}
    </GamePageSidebarContext.Provider>
  );
};
