import React from 'react';
import { Text } from '@purposeinplay/core-v2';

import dayjs from '@/utils/dayjsconfig';
import { WheelCounterLoader } from '@/components/Loaders';
import WheelCounter from './wheel-counter';
import { useTranslation } from '@/app/i18n/client';
import { WofPageContentType } from '@/types/json-content/wof_landing';

const nextDay = dayjs().utc().add(1, 'day').startOf('day').add(10, 'minutes');

const WheelCountdown = ({
  lootboxes,
  lootboxesLoading,
}: {
  lootboxes: any;
  lootboxesLoading: boolean;
}) => {
  const { t } = useTranslation();
  const wofContent = t('wof_landing') as WofPageContentType;

  if (lootboxesLoading) {
    return <WheelCounterLoader />;
  }

  return (
    <div data-theme="dark" className="!bg-transparent">
      {lootboxes?.length === 0 && (
        <Text
          size="lg"
          className="mb-spacing-lg text-center font-semibold leading-6 text-text-default md:text-xl md:leading-7"
        >
          {wofContent?.wheel_modal?.no_FS_description}
        </Text>
      )}
      <WheelCounter
        text={
          lootboxes?.length > 0
            ? wofContent?.wheel_modal?.expire_in_label
            : wofContent?.wheel_modal?.availabe_in_label
        }
        endTime={lootboxes?.length > 0 ? lootboxes[0].valid_until : nextDay}
      />
    </div>
  );
};

export default WheelCountdown;
