import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { getPrecision } from '@/utils/money';
import { formatNumber } from 'accounting';
import React, { useMemo } from 'react';

interface CurrencyValueProps {
  currency: any;
  exchangeRates: any;
  propPoints: number;
  subunitsToUnit: any;
}

const CurrencyValue: React.FC<CurrencyValueProps> = ({
  currency,
  exchangeRates,
  propPoints,
  subunitsToUnit,
}) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const handleCurrencyValue = useMemo(() => {
    if (propPoints === 0) {
      return formatNumber(
        exchangeRates[currency?.code],
        getPrecision(subunitsToUnit[currency?.code]),
      );
    }

    const points = exchangeRates[currency?.code] * propPoints;

    return formatNumber(points, getPrecision(subunitsToUnit[currency?.code]));
  }, [currency, exchangeRates, propPoints, subunitsToUnit]);

  const fiatValue = useFiatFromCrypto(
    currency?.code,
    Number(handleCurrencyValue),
  );

  if (viewFiat) {
    return <div>{fiatValue}</div>;
  }

  return <div>{handleCurrencyValue}</div>;
};

export default CurrencyValue;
