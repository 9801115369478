import { cn } from '@/utils/style';
import React from 'react';
import ChatBubbleAvatar from './chat-bubble-avatar';
import { ChatMessage } from '@/app/providers/chat';
import { usePlayerApiSettings } from '../hooks/use-get-chat-settings';
import dayjs from '@/utils/dayjsconfig';
import { useAuth } from '@/hooks/auth';
import { Badge } from '@purposeinplay/core-v2';
import useMediaQuery, { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';
import { RAINBOT_CHAT_ID } from '../constants';
import RainbotChatBubbleSimple from './rainbot-chat-bubble-simple';
import useWildChatContent from '../hooks/use-wild-chat-content';

const ChatBubble = ({ message }: { message: ChatMessage }) => {
  const { matchesQuery: isMobileLG } = useMediaQuery(MOBILE_LG_BREAKPOINT);
  const { user } = useAuth();
  const { data: chatPlayerSettings, isLoading: chatSettingsLoading } =
    usePlayerApiSettings();
  const content = useWildChatContent();

  const username =
    chatPlayerSettings?.current_user?.chat_name ||
    user?.currentUser?.nickname ||
    user?.currentUser?.email?.split('@')[0];

  const isCurrentUser =
    chatPlayerSettings?.current_user?.chat_name === message.author.chat_name;

  const isRainbot = message?.author?.id === RAINBOT_CHAT_ID;
  const isAdmin = message?.author?.author_type?.toLowerCase() === 'admin';

  if (isRainbot) {
    return <RainbotChatBubbleSimple message={message} />;
  }

  return (
    <div className={cn(['mb-3 flex flex-row space-x-2'])}>
      <div className="shrink-0">
        <ChatBubbleAvatar
          isCurrentUser={isCurrentUser}
          imageSrc={message?.author?.avatar_url}
          isAdmin={isAdmin}
        />
      </div>
      <div className="flex flex-col space-y-1">
        <div className={cn(['flex flex-row items-center space-x-2'])}>
          <span className="flex items-center space-x-1">
            <span
              className={cn([
                'max-w-[10rem] truncate text-sm font-semibold leading-5 text-text-subdued',
                !isCurrentUser && isMobileLG && 'max-w-[12rem]',
                isCurrentUser && !isMobileLG && 'max-w-[7rem]',
              ])}
            >
              {isAdmin
                ? content?.admin?.name
                : isCurrentUser
                  ? username
                  : message.author.chat_name}
            </span>
            {isCurrentUser && (
              <Badge type="success" ghost radius="rounded">
                You
              </Badge>
            )}
            {isAdmin && (
              <Badge className="bg-slate-500 text-white" ghost radius="rounded">
                {content?.admin?.tag}
              </Badge>
            )}
          </span>
          <span className="text-xs text-text-subdued">
            {dayjs(message.sent_at).format('HH:mm')}
          </span>
        </div>
        <div
          className={cn([
            'rounded-lg !rounded-tl-none bg-bgr-lightest p-2 text-sm text-text-subdued',
          ])}
        >
          {message.payload.content}
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
