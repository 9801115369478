import { useTranslation } from '@/app/i18n/client';
import React from 'react';
import { getContentByState } from '../utils';
import { AgentVerificationContentType } from '@/types/json-content/agent_verification';
import { VerifyAgentForm } from '../types';
import { Button } from '@purposeinplay/core-v2';
import useModal from '@/hooks/modal';

type Props = {
  state: VerifyAgentForm;
  setState: React.Dispatch<React.SetStateAction<VerifyAgentForm>>;
};
const ModalFooterRetry: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();
  const content = t('agent_verification') as AgentVerificationContentType;
  const modalContent = getContentByState(state?.state, content?.modal_states);

  const { close } = useModal();

  return (
    <Button
      color="tertiary"
      size="md"
      onClick={() => close()}
      className="w-full max-w-full"
    >
      {modalContent?.action?.cancel}
    </Button>
  );
};

export default ModalFooterRetry;
