import { useAuth } from '@/hooks/auth';
import React from 'react';
import ReferFriendModal from './new-raf-modal';
import GuestRafModal from './guest-raf-modal';

const RafModalWrapper = () => {
  const { user } = useAuth();
  if (user?.isLoading) return null;
  return user?.isAuthenticated ? <ReferFriendModal /> : <GuestRafModal />;
};

export default RafModalWrapper;
