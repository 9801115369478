import { usePlayerCompPoints } from '@/hooks/comp-points';
import React, { useMemo } from 'react';
import { useGetLotteryContent } from '../hooks/use-lottery-content';
import { ListComponent } from '@purposeinplay/core-v2';
import Image from 'next/image';

export const WildPointIcon = ({
  size = 20,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <Image
      alt="Wild Point Icon"
      src="/assets/wild-coin.svg"
      width={size}
      height={size}
      className={className}
    />
  );
};

const WPValue = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-row items-center justify-end space-x-1">
      <span className="cursor-text font-semibold">{text}</span>
      <WildPointIcon />
    </div>
  );
};

const MyWp = ({
  exchangeRate,
  price,
}: {
  exchangeRate: number | undefined;
  price: number | undefined;
}) => {
  const { data } = usePlayerCompPoints();
  const lotData = useGetLotteryContent();
  const footerData = lotData?.exchange_modal?.footer;

  const pointsRemaining = useMemo(() => data?.chargeable?.points, [data]);

  return (
    <div className="flex flex-wrap gap-1 rounded-lg  md:items-center md:justify-between">
      <ListComponent
        label={footerData?.my_wp}
        value={<WPValue text={`${pointsRemaining ?? '0'}`} />}
        stylingOptions={{
          valueStyles: 'text-icons-warning text-right',
          containerStyles: '!cursor-default hover:bg-surface-subdued',
        }}
      />
      <ListComponent
        label={footerData?.price}
        value={<WPValue text={`${exchangeRate ?? '0'}`} />}
        stylingOptions={{
          valueStyles: 'text-icons-warning text-right',
          containerStyles: '!cursor-default hover:bg-surface-subdued',
        }}
      />
      <ListComponent
        label={footerData?.charge}
        value={<WPValue text={`${price ?? '0'}`} />}
        stylingOptions={{
          valueStyles: 'text-icons-warning text-right',
          containerStyles: '!cursor-default hover:bg-surface-subdued',
        }}
      />
    </div>
  );
};

export default MyWp;
