import useModal, { useCurrentModal } from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import BonusSelectionRenderer from './bonus-selection-renderer';
import WalletContentTitle from '@/components/Wallet/WalletModal/components/wallet-content-title';
import { useAppSelector } from '@/hooks/redux';

const BonusSelectModal = () => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'bonus-selection';

  const walletDynamicContent = useAppSelector(
    (state) => state.bootstrap.bonusSelctionDynamicContent,
  );

  return (
    <Modal
      title={
        walletDynamicContent?.isOpen === 'details' ? (
          <WalletContentTitle
            type="bonus"
            title={walletDynamicContent?.data?.title}
            className="mb-0 md:mb-0 lg:mb-0"
          />
        ) : null
      }
      isOpen={isOpen}
      setIsOpen={() => close()}
      size={walletDynamicContent?.isOpen === 'details' ? '2xl' : '4xl'}
    >
      <BonusSelectionRenderer isInBonusesPage={false} />
    </Modal>
  );
};

export default BonusSelectModal;
