import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '@/hooks/auth';
import { Button, Modal } from '@purposeinplay/core-v2';
import RafTable from './table';
import { RAFCode, useIsRafEligible, useRAFList } from '@/hooks/raf';
import { MainHeading, MainHeadingRestricted } from './heading';
import { AuthImage, GuestImage } from './hero-images';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { cn } from '@/utils/style';
import { DivLoader, TableLoaderV2 } from '../Loaders';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
import { usePlayerStats } from '@/hooks/others';
import { getFiatFromCents } from '@/utils/money';
import RafInfo from './raf-info';
import EmptyState from '../EmptyState';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ReferFriendModal: React.FC = () => {
  const { user } = useAuth();
  const { close, open: openModal } = useModal();
  const isOpen = useCurrentModal() === 'refer_friend';

  const [totalDeposited, setTotalDeposited] = useState<number | null>(null);
  const { data: playerStats, isLoading: playerStatsLoading } = usePlayerStats();
  const { data: rafList, isLoading: rafListLoading } = useRAFList(
    !playerStatsLoading && totalDeposited !== null && totalDeposited >= 50,
  );

  const hasRafAvailable = true;

  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;
  const [loadingDepositSum, setLoadingDepositSum] = useState(false);
  const has50deposit = totalDeposited !== null && totalDeposited >= 50;

  useEffect(() => {
    if (playerStatsLoading) return;
    setLoadingDepositSum(true);
    const fetchDepositedSum = async () => {
      const obj = await getFiatFromCents(playerStats?.deposits_sum);
      let totalSum = 0;
      obj?.map((item: any) => {
        const key = Object.keys(item);
        // @ts-ignore
        const value = item[key];
        totalSum += value;
      });

      setTotalDeposited(totalSum);
      setLoadingDepositSum(false);
    };

    fetchDepositedSum();
  }, [playerStats, playerStatsLoading]);

  const isRafEligible = useIsRafEligible();

  const rafCodes = useMemo(() => {
    if (playerStatsLoading || loadingDepositSum) return null;

    return !has50deposit ? (
      <Button onClick={() => openModal('wallet', { a: 'deposit' })}>
        {content?.action?.deposit}
      </Button>
    ) : hasRafAvailable ? (
      <RafTable
        isLoading={rafListLoading}
        currency={rafList?.currency as string}
        codes={rafList?.referral_codes as RAFCode[]}
      />
    ) : (
      <div className="flex w-full flex-row md:ml-auto md:w-auto">
        <Button
          size="sm"
          color="secondary"
          className="min-w-full md:min-w-max"
          onClick={() => {
            close();
          }}
        >
          {content?.action?.close_btn}
        </Button>
      </div>
    );
  }, [
    playerStatsLoading,
    loadingDepositSum,
    has50deposit,
    content?.action?.deposit,
    content?.action?.close_btn,
    hasRafAvailable,
    rafListLoading,
    rafList?.currency,
    rafList?.referral_codes,
    openModal,
    close,
  ]);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      setIsOpen={() => close()}
      onClose={() => close()}
      footer={rafCodes}
    >
      {playerStatsLoading || loadingDepositSum || user?.isLoading ? (
        <div>
          <DivLoader className="mb-2 w-[50%] lg:w-[50%]" />
          <DivLoader className="mb-2 w-[40%] lg:w-[40%] " />
          <DivLoader className="mb-6 w-[30%] lg:w-[30%]" />

          <TableLoaderV2 headers={3} />
        </div>
      ) : (
        <>
          {user?.isAuthenticated && hasRafAvailable && (
            <div
              className={cn([
                'pointer-events-none absolute inset-0 !-top-3 max-h-[22rem] rounded-3xl md:max-h-[inherit]',
                !user?.isAuthenticated &&
                  !user?.isLoading &&
                  `md:min-h-[28rem]`,
              ])}
            >
              <div className="h-full w-full rounded-3xl">
                <AuthImage />
              </div>
            </div>
          )}
          {has50deposit ? <MainHeading /> : <MainHeadingRestricted />}
          <RafInfo
            has50deposit={has50deposit}
            playerStatsLoading={playerStatsLoading}
            rafList={rafList}
            rafListLoading={rafListLoading}
          />
        </>
      )}
    </Modal>
  );
};

export default ReferFriendModal;
