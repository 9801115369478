'use client';
import React, { useEffect, useState } from 'react';

import { Button, IconButton, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';

import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import CloseSvg from 'public/assets/close-icon.svg';
import Link from 'next/link';

const AppNotice = React.memo(() => {
  const [noticeHidden, setNoticeHidden] = useState(true);

  const handleClick = () => {
    localStorage.setItem('wild_app_install_hidden', JSON.stringify(true));
    setNoticeHidden(true);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: 'wild_app_install_hidden',
        newValue: JSON.stringify(true),
        storageArea: localStorage,
      }),
    );
  };

  useEffect(() => {
    const noticeHidden = localStorage.getItem('wild_app_install_hidden');
    const wildCookies = localStorage.getItem('wild_cookies_accepted');
    setNoticeHidden(!wildCookies ? true : noticeHidden === 'true');

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'wild_cookies_accepted') {
        setNoticeHidden(event.newValue === 'true' ? false : true);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  if (noticeHidden) return null;

  return (
    <>
      <div
        style={{ zIndex: 99 }}
        className="safe-bottom-margin fixed bottom-16 flex w-full justify-center text-sm text-text-default md:bottom-24 md:bottom-8 lg:bottom-8 lg:left-1/2 lg:hidden lg:max-w-lg lg:-translate-x-1/2"
      >
        <div className="flex w-full items-center justify-start gap-spacing-xs bg-bgr-lightest p-3 md:max-w-lg md:rounded-xl">
          <IconButton
            color="tertiary"
            iconOnly
            iconLeft={<CloseSvg className="fill-current" />}
            size="xs"
            ghost
            onClick={handleClick}
          />
          <Image
            alt="logo"
            width={35}
            priority
            height={35}
            fill={false}
            loader={DEFAULT_IMAGE_LOADER}
            src="/assets/logo-symbol-v2.svg"
          />

          <div>
            <Text className="font-semibold leading-4" size="sm">
              Wild.io Application
            </Text>
            <Text size="xs" className="leading-4">
              Unlock Fun with Exclusive Features
            </Text>
          </div>
          <Link
            onClick={handleClick}
            href="/install-app"
            className="ml-auto inline-block"
          >
            <Button
              size="sm"
              className="ml-auto inline-block shrink-0 whitespace-nowrap"
              linkHref="/install-app"
              stroke
              ghost
              color="tertiary"
            >
              Download
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
});

AppNotice.displayName = 'AppNotice';

export default AppNotice;
