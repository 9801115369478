import React, { useState } from 'react';
import { Tabs } from '@purposeinplay/core-v2';
import { useAuth } from '@/hooks/auth';
import { ChallengeTab } from '../types';

const ChallengesHeadingRight = ({
  setActiveTab,
  forPage,
  tabs: challengePageTabs,
}: {
  setActiveTab: (tab: string) => void;
  forPage?: boolean;
  tabs: ChallengeTab[];
}) => {
  const {
    user: { isAuthenticated },
  } = useAuth();
  const [tabs, setTabs] = useState(challengePageTabs);

  const handleTabClick = (e: any, item: any) => {
    const newItems = tabs.map((el) => ({
      ...el,
      active: el.id === item.id ? true : false,
    }));
    setTabs(newItems);
    setActiveTab(item.id);
  };
  return (
    isAuthenticated && (
      <Tabs
        tabStyleOptions={{
          navItemStyles: 'focus-visible:!outline-none focus-visible:!border-0',
        }}
        variant={'segmented-rounded'}
        items={tabs}
        onClick={handleTabClick}
      />
    )
  );
};

export default ChallengesHeadingRight;
