export const HightlightWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div
    style={{
      background: 'linear-gradient(325deg, #00C37A 4.24%, #27D84A 116.92%)',
    }}
    className="absolute -top-6 left-1/2 flex h-10 w-10  -translate-x-1/2 transform items-center justify-center rounded-full ring-8 ring-bgr-lightest group-active:[&>svg]:scale-95"
  >
    {children}
  </div>
);
