import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@/app/i18n/client';

import {
  useAddPlayerLimit,
  usePlayerLimits,
  useRemoveUserLimit,
} from '@/hooks/limits';
import { SettingsContentType } from '@/types/json-content/settings';
import LoadingCoolingOff from './loading-cooling-off';
import UnconfirmedCoolingOff from './unconfirmed-cooling-off';
import ActiveCoolingOff from './active-cooling-off';
import { Alert, Button, Select, Text } from '@purposeinplay/core-v2';

const CoolingOff = () => {
  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const content = contentSettings?.responsible_gambling;

  const [coolOff, setCoolOff] = useState<any>(content?.cooling_of_types[0]);
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: addCoolOff, isPending: addCoolOffLoading } =
    useAddPlayerLimit();
  const { mutateAsync: removeSessionLimit, isPending: removeLimitLoading } =
    useRemoveUserLimit();
  const { data: limits = [], isLoading: limitsLoading } = usePlayerLimits();

  const filteredLimit = useMemo(() => {
    // @ts-ignore
    const limit = limits?.find(
      (limit: any) =>
        limit.type === 'coolingoff' &&
        (limit.status === 'active' || limit.status === 'unconfirmed_disable'),
    );
    return limit;
  }, [limits]);

  useEffect(() => {
    if (filteredLimit?.period) {
      const newCoolOff = content?.cooling_of_types.find(
        (item) => item?.id === filteredLimit.period,
      );
      newCoolOff && setCoolOff(newCoolOff);
    }
  }, [filteredLimit, content?.cooling_of_types]);

  if (limitsLoading) {
    return <LoadingCoolingOff />;
  }

  if (filteredLimit && filteredLimit.status === 'unconfirmed_disable') {
    return <UnconfirmedCoolingOff filteredLimit={filteredLimit} />;
  }

  return (
    <>
      <div>
        {filteredLimit?.status === 'active' ? (
          <ActiveCoolingOff filteredLimit={filteredLimit} className="mb-4" />
        ) : (
          <>
            <Text size="sm" className="mb-4 text-text-subdued">
              {content?.limits?.modal?.cooling_off?.description}
            </Text>
            <Select
              items={content?.cooling_of_types}
              stylingOptions={{ triggerStyles: 'mb-4' }}
              defaultSelectedIndex={content?.cooling_of_types.findIndex(
                (item) => item?.id === coolOff.id,
              )}
              onSelect={(item: any) => setCoolOff(item)}
              disabled={
                filteredLimit && filteredLimit.status === 'unconfirmed_disable'
              }
            />
            <Text size="sm" className="mb-4 text-text-subdued">
              {content?.limits?.modal?.cooling_off?.secondary_description}
            </Text>
          </>
        )}

        {!filteredLimit && (
          <Button
            className="w-full md:w-auto"
            size="sm"
            color="primary"
            onClick={() => setIsOpen(true)}
            disabled={coolOff == null}
          >
            {content?.limits?.modal?.cooling_off?.action?.primary}
          </Button>
        )}
        {filteredLimit && filteredLimit.status !== 'unconfirmed_disable' && (
          <Button
            size="sm"
            color="secondary"
            className="w-full md:w-auto"
            disabled={removeLimitLoading}
            isLoading={removeLimitLoading}
            onClick={() => removeSessionLimit(filteredLimit.id)}
          >
            {content?.limits?.modal?.cooling_off?.action?.secondary}
          </Button>
        )}
      </div>

      <Alert
        title={content?.confirm_limits?.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryButtonOnClick={() =>
          addCoolOff({
            user_limit: {
              period: coolOff?.id,
              type: 'coolingoff',
            },
          })
        }
        primaryButtonLabel={content?.confirm_limits?.primary_btn_label}
        secondaryButtonLabel={content?.confirm_limits?.secondary_btn_label}
        content={`${content?.confirm_limits?.description?.cooling_off} ${coolOff?.component}?`}
        isLoading={addCoolOffLoading}
      />
    </>
  );
};

export default CoolingOff;
