'use client';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import React, { Suspense, useEffect, useRef } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import { useIsMounted } from '@purposeinplay/utils';
import useBodyLock from '@/hooks/use-body-lock';
import useMediaQuery, { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';
import { useIsOnGamePage } from '@/hooks/use-is-on-game-page';
import { SearchModal } from '@/modules/search';
import GamePageSidebar from './game-page-sidebar';
import DefaultSidebar from './default-sidebar';
import { GamePageSidebarProvider } from '../context/game-page-sidebar-context';

const Sidebar = () => {
  const isOpen = useAppSelector((state) => state.bootstrap.isSidemenuOpen);
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const isMounted = useIsMounted();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { matchesQuery: isMobileLg } = useMediaQuery(MOBILE_LG_BREAKPOINT);
  const isOnGamePage = useIsOnGamePage();

  useEffect(() => {
    if (width < 1280) {
      dispatch(setOpenSidemenu(false));
    } else {
      dispatch(setOpenSidemenu(true));
    }
  }, [dispatch, width, isMounted]);

  useBodyLock(
    isOpen,
    {
      reserveScrollBarGap: true,
      allowTouchMove: true,
    },
    sidebarRef.current || undefined,
  );

  return (
    <>
      <Suspense>
        <SearchModal />
      </Suspense>
      <GamePageSidebarProvider>
        {isOnGamePage ? (
          <GamePageSidebar />
        ) : (
          <DefaultSidebar
            sidebarRef={sidebarRef}
            isOpen={isOpen}
            isMounted={isMounted}
          />
        )}
      </GamePageSidebarProvider>
    </>
  );
};

export default Sidebar;
