export const scrollToChatBottom = () => {
  const chatBody = document.getElementById('chat-body-container');

  if (chatBody) {
    chatBody.scrollTo({
      top: chatBody.scrollHeight,
      behavior: 'smooth',
    });
  }
};

export const positionChatAtBottom = () => {
  const chatBody = document.getElementById('chat-body-container');

  if (chatBody) {
    chatBody.scrollTop = chatBody.scrollHeight;
  }
};
