import React, { useMemo } from 'react';
import useSidemenuItems from './use-sidemenu-items';
import { SidemenuItem } from '../types';
import Section from '@/modules/layout/sidebar/components/section';
import { useAuth } from '@/hooks/auth';
import { useGamesContent } from '@/hooks/use-games-content';

const useGamesSidebarItems = () => {
  const { items } = useSidemenuItems({ forGamesPage: true });
  const { gamesContent } = useGamesContent();
  const {
    user: { isAuthenticated },
  } = useAuth();

  const linkItems = useMemo(() => {
    return items?.filter((item) => item?.type === 'link') || [];
  }, [items]);

  const sidemenuItems = useMemo(() => {
    // Get sign in item for unauthenticated users - display separately
    const signinParent = !isAuthenticated
      ? linkItems.find((item) => item.identifier === 'signin')
      : null;

    const signinItem = signinParent?.items?.[0];

    // Make sure icon is a string and add the parent's identifier
    const safeSigninItem = signinItem
      ? {
          ...signinItem,
          icon: signinItem.icon || '',
          identifier: signinParent.identifier,
        }
      : null;

    // First batch items based on authentication status
    let firstBatchItems = [];

    if (isAuthenticated) {
      // For authenticated users: notifications, favorites, tournaments, search
      const notificationsParent = linkItems.find(
        (item) => item.identifier === 'notifications',
      );
      const favoritesParent = linkItems.find(
        (item) => item.identifier === 'favorites',
      );
      const tournamentsParent = linkItems.find(
        (item) => item.identifier === 'tournaments',
      );
      const searchParent = linkItems.find(
        (item) => item.identifier === 'search',
      );

      // Add the parent's identifier to each child item
      const notificationsItem = notificationsParent?.items?.[0]
        ? {
            ...notificationsParent.items[0],
            identifier: notificationsParent.identifier,
          }
        : undefined;
      const favoritesItem = favoritesParent?.items?.[0]
        ? {
            ...favoritesParent.items[0],
            identifier: favoritesParent.identifier,
          }
        : undefined;
      const tournamentsItem = tournamentsParent?.items?.[0]
        ? {
            ...tournamentsParent.items[0],
            identifier: tournamentsParent.identifier,
          }
        : undefined;
      const searchItem = searchParent?.items?.[0]
        ? { ...searchParent.items[0], identifier: searchParent.identifier }
        : undefined;

      // Add items in the required order if they exist
      if (notificationsItem) firstBatchItems.push(notificationsItem);
      if (favoritesItem) firstBatchItems.push(favoritesItem);
      if (tournamentsItem) firstBatchItems.push(tournamentsItem);
      if (searchItem) firstBatchItems.push(searchItem);
    } else {
      // For unauthenticated users: new, popular, search, tournaments
      const newGamesParent = linkItems.find(
        (item) => item.identifier === 'new',
      );
      const popularGamesParent = linkItems.find(
        (item) => item.identifier === 'popular',
      );
      const searchParent = linkItems.find(
        (item) => item.identifier === 'search',
      );
      const tournamentsParent = linkItems.find(
        (item) => item.identifier === 'tournaments',
      );

      // Add the parent's identifier to each child item
      const newGamesItem = newGamesParent?.items?.[0]
        ? { ...newGamesParent.items[0], identifier: newGamesParent.identifier }
        : undefined;
      const popularGamesItem = popularGamesParent?.items?.[0]
        ? {
            ...popularGamesParent.items[0],
            identifier: popularGamesParent.identifier,
          }
        : undefined;
      const searchItem = searchParent?.items?.[0]
        ? { ...searchParent.items[0], identifier: searchParent.identifier }
        : undefined;
      const tournamentsItem = tournamentsParent?.items?.[0]
        ? {
            ...tournamentsParent.items[0],
            identifier: tournamentsParent.identifier,
          }
        : undefined;

      // Add items in the required order if they exist
      if (newGamesItem) firstBatchItems.push(newGamesItem);
      if (popularGamesItem) firstBatchItems.push(popularGamesItem);
      if (searchItem) firstBatchItems.push(searchItem);
      if (tournamentsItem) firstBatchItems.push(tournamentsItem);
      // Sign In is handled separately
    }

    // Make sure all items have a string icon
    firstBatchItems = firstBatchItems.map((item) => ({
      ...item,
      icon: item.icon || '',
    }));

    // Generate the first batch links component
    const firstBatchLinks =
      firstBatchItems.length > 0 ? (
        <Section
          forGamesPage
          items={firstBatchItems as SidemenuItem[]}
          key="menu-section-first-batch"
        />
      ) : null;

    // Second batch items (wallet and chat for authenticated users)
    const secondBatchItems = [];

    if (isAuthenticated) {
      // Find wallet and chat items
      const walletItem = linkItems.find(
        (item) =>
          item.identifier === 'wallet' && item.displayFor === 'AUTHENTICATED',
      );

      if (walletItem) {
        secondBatchItems.push(
          <Section
            forGamesPage
            authOnly={walletItem.displayFor === 'AUTHENTICATED'}
            items={walletItem.items.map((item) => ({
              ...item,
              icon: item.icon || '',
              identifier: walletItem.identifier,
            }))}
            key={`menu-section-narrow-${walletItem.title}`}
          />,
        );
      }

      // Chat item is now directly rendered in GamePageSidebarElements
    }

    return {
      links: [firstBatchLinks, ...secondBatchItems],
      signinItem: safeSigninItem,
    };
  }, [isAuthenticated, linkItems]);

  return { items: sidemenuItems, rawLinks: linkItems };
};

export default useGamesSidebarItems;
