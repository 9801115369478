import MinimalGameCard from '@/components/GameCard/minimal';
import { FALLBACK_SRC_BASE } from '@/components/GameCard/constants';
import MinimalGameCardLoader from './minimal-gamecard-loader';
import { GameListProps, GameType } from '../types';
import React, { Suspense, useMemo, useEffect, useState } from 'react';
import GameCarouselItem from '@/components/Carousels/game-carousel-item';
import CarouselWithGradient from '@/components/Carousels/components/carousel-with-gradient';
import { GamesCarouselLoader } from '@/components/Carousels/games-carousel';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import useDarkMode from '@/hooks/dark';
import {
  useAddToFavorites,
  useRemoveFromFavorites,
  useFavoriteGamesData,
} from '@/hooks/games/favorites';
import { Text } from '@purposeinplay/core-v2';
import GameListEmptyState from './game-list-empty-state';

export const GameList = ({
  games,
  visibleGames,
  isLoading,
  forBottomDrawer = false,
  gameType = 'favorites',
}: GameListProps) => {
  const { darkMode } = useDarkMode();
  const { mutateAsync: addToFavorites } = useAddToFavorites();
  const { mutateAsync: removeFromFavorites } = useRemoveFromFavorites();
  const { favoriteGameIdentifiers } = useFavoriteGamesData();

  const [shouldShowEmptyState, setShouldShowEmptyState] = useState(false);

  useEffect(() => {
    if (!isLoading && (!games || games.length === 0)) {
      const timer = setTimeout(() => {
        setShouldShowEmptyState(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShouldShowEmptyState(false);
    }
  }, [isLoading, games]);

  const slides = useMemo(() => {
    if (!games || games.length === 0) return [];
    return games.slice(0, visibleGames).map((game: any) => {
      return (
        <GameCarouselItem
          isInMiniCarousel
          key={`game-carousel-${game.identifier}`}
          game={game}
          favoriteGames={favoriteGameIdentifiers || []}
          addToFavorites={addToFavorites}
          removeFromFavorites={removeFromFavorites}
          tagText=""
          tagType=""
        />
      );
    });
  }, [
    games,
    visibleGames,
    addToFavorites,
    removeFromFavorites,
    favoriteGameIdentifiers,
  ]);

  if (isLoading) {
    if (forBottomDrawer) {
      return (
        <GamesCarouselLoader
          gameCardProps={{
            className: 'w-[109px] h-[145px]',
          }}
        />
      );
    }

    return (
      <div className="flex flex-col gap-1">
        {Array.from({ length: 5 }).map((item, i) => (
          <MinimalGameCardLoader key={i} />
        ))}
      </div>
    );
  }

  if (shouldShowEmptyState) {
    return <GameListEmptyState type={gameType} />;
  }

  if (!games || games.length === 0) {
    if (forBottomDrawer) {
      return (
        <GamesCarouselLoader
          gameCardProps={{
            className: 'w-[109px] h-[145px]',
          }}
        />
      );
    }

    return (
      <div className="flex flex-col gap-1">
        {Array.from({ length: 5 }).map((item, i) => (
          <MinimalGameCardLoader key={i} />
        ))}
      </div>
    );
  }

  if (forBottomDrawer && slides.length > 0) {
    return (
      <div className="relative">
        <CarouselWithGradient
          options={{
            dragFree: true,
          }}
          withGradient
          gradientFrom={darkMode ? '#1d3040' : '#ebeced'}
          itemsWrapperStyles="relative"
          space={'4'}
          items={slides}
          slideWidth={109}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1">
      {games.slice(0, visibleGames).map((game) => (
        <MinimalGameCard
          // @ts-ignore
          slug={game.slug}
          key={game.identifier}
          title={game.title}
          provider={game.provider}
          imageUrl={`https://cdn.wild.io/thumbnail/${game.identifier}.png`}
          fallBackUrl={`${FALLBACK_SRC_BASE}/thumbnail/${
            game.identifier.split('/')[0]
          }/${game.identifier.split('/')[1]}.webp`}
        />
      ))}
    </div>
  );
};
