import React, { useCallback } from 'react';
import { HightlightWrapper } from './components/highlight-wrapper';
import { BottomNavProps } from '../bottom-navigation/types/index';
import { getBaseIconStyles } from './utils';
import { cn } from '@/utils/style';
import { useAnalytics } from '@/hooks/use-analytics';

const BottomNavItem = React.memo(
  ({ onItemClick, isItemActive, item, ...props }: BottomNavProps) => {
    const { onClick, icon, text, isActive } = item;
    const { track } = useAnalytics();

    const isItemHighlighted = item?.isHighlighted;
    const isItemDisabled = item?.disabled;

    const handleClick = useCallback(() => {
      track('bottom_nav_clicked', {
        category: 'bottom_navigation',
        label: text.toLowerCase(),
        action: 'click',
      });
      !isItemDisabled && onClick();
      onItemClick();
    }, [isItemDisabled, onClick, onItemClick, text, track]);

    return (
      <div className="group">
        <a
          role="button"
          onClick={handleClick}
          className={cn([
            'relative flex max-w-[3.75rem] flex-1 cursor-pointer flex-col items-center justify-center p-1 text-xs font-medium text-text-subdued hover:rounded-xl hover:bg-surface-hovered',
            isItemDisabled && `pointer-events-none`,
            isItemActive && 'rounded-xl bg-surface-hovered',
          ])}
          {...props}
        >
          <span
            className={cn([
              'mb-spacing-2xs h-6 w-6 overflow-visible fill-current [&>svg]:fill-current [&>svg]:text-text-subdued',
              text !== 'Wallet' && `group-active:scale-95`,
              getBaseIconStyles(text),
              isItemDisabled &&
                `[&>svg]:fill-current [&>svg]:text-gray-100 [&>svg]:dark:text-gray-600`,
            ])}
          >
            {isItemHighlighted ? (
              <HightlightWrapper>{icon}</HightlightWrapper>
            ) : (
              icon
            )}
          </span>
          <span
            className={cn([
              'z-10 text-xs font-semibold text-text-default group-hover:text-text-default group-active:scale-95',
              getBaseIconStyles(text),
              isItemDisabled && `text-gray-300 dark:text-gray-600`,
            ])}
          >
            {text}
          </span>
        </a>
      </div>
    );
  },
);

BottomNavItem.displayName = 'BottomNavItem';

export default BottomNavItem;
