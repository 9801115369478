import { useTranslation } from '@/app/i18n/client';
import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { extractTranslations } from '@/utils/content';
import { useMemo } from 'react';

export const useGetPageContent = (path: string) => {
  const { t, i18n } = useTranslation();

  const { data: page } = usePagesQuery(
    { path },
    {
      queryKey: ['pages', path],
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );

  const pageContent = useMemo(() => {
    const currentPage = page?.pages && page?.pages?.data[0]?.attributes;
    return extractTranslations(i18n.language, currentPage);
  }, [page, i18n.language]);

  return pageContent;
};

export default useGetPageContent;
