import { useTranslation } from 'react-i18next';
import { useOtherToastMessages } from './use-other-toast-messages';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@purposeinplay/utils';
import { useState } from 'react';
import { useUpdateSubscriptions } from './others';
import { useLogout } from './auth';
import { getApiBaseUrl, getCurrentHostname } from '@/utils/api-domains';
interface UpdateDetailsError {
  message: string;
  errors?: {
    [key: string]: string[];
  };
  code?: number;
  callStatusCode?: number;
}

interface UpdateDetailsResponse {
  callStatusCode: number;
  [key: string]: any;
}

const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const client = async (
  endpoint: string,
  { token, formData, isV2 = false, ...inputData }: any = {},
  method = 'GET',
  headers = {},
): Promise<UpdateDetailsResponse> => {
  const reqBody = { ...inputData };
  const limitToken = inputData?.limit_token;
  const cashoutToken = inputData?.cashout_token;
  if (limitToken) {
    reqBody.token = limitToken;
    delete reqBody['limit_token'];
  }
  if (cashoutToken) {
    reqBody.token = cashoutToken;
    delete reqBody['cashout_token'];
  }

  const config: any = {
    method: !isEmpty(reqBody) && method === 'GET' ? 'POST' : method,
    body:
      !isEmpty(reqBody) && !formData
        ? JSON.stringify(reqBody)
        : formData
          ? formData
          : undefined,
    headers: {
      'Content-Type':
        !isEmpty(reqBody) && !formData ? 'application/json' : undefined,
      Accept: !formData
        ? isV2
          ? 'application/vnd.s.v2+json'
          : 'application/vnd.s.v1+json'
        : 'multipart/form-data',
      Cookie: token && `_casino_session=${token}`,
      ...headers,
    },
  };

  if (formData) {
    delete config.headers['Content-Type'];
  }
  const hostname = getCurrentHostname();
  const baseUrl = getApiBaseUrl(hostname);
  const url = `${baseUrl}/${endpoint}`;

  const response = await fetch(`${url}/${endpoint}`, {
    credentials: 'include',
    ...config,
  });
  if (response.status === 403 || response.status === 401) {
    return Promise.reject({
      message: 'Please re-authenticate.',
      code: 401,
      callStatusCode: response.status,
    });
  }
  try {
    const data = await response.text();
    const parsedData = data ? JSON.parse(data) : {};
    const result = { ...parsedData, callStatusCode: response.status };

    if (response.ok) {
      return result;
    } else {
      return Promise.reject(result);
    }
  } catch (e) {
    return Promise.reject({
      message: 'Internal system error',
      code: 500,
      callStatusCode: 500,
    });
  }
};

const useUpdateDetailsV2 = () => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync: logoutUser } = useLogout();
  const { mutateAsync } = useUpdateSubscriptions();

  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<UpdateDetailsError | null>(null);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [data, setData] = useState<UpdateDetailsResponse | null>(null);

  const updateDetails = async (data: {
    email: string;
    terms_acceptance: boolean;
    currency: string;
    // receive_promos?: boolean;
  }) => {
    setIsPending(true);
    setIsError(false);
    setError(null);
    setStatus('loading');

    const payload = {
      user: {
        email: data.email,
        terms_acceptance: data.terms_acceptance,
        currency: data.currency,
        // receive_promos: data.receive_promos,
      },
    };

    try {
      const response = await client(
        'auth_providers/update_details',
        payload,
        'POST',
      );

      if (response?.callStatusCode === 200) {
        toast({
          state: 'success',
          title: toastMessages?.updated_info,
        });
        setStatus('success');
        await logoutUser();
        return;
      }

      setData(response);
      setStatus('success');
      // update subscriptions before invalidating the currentUser query
      if (response.callStatusCode !== 200) {
        await mutateAsync({
          receive_promos: true,
        });
      }

      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      setIsPending(false);
      return response;
    } catch (err) {
      setError(err as UpdateDetailsError);
      setIsError(true);
      setStatus('error');
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      setIsPending(false);
      throw err;
    }
  };

  return { updateDetails, isPending, isError, error, status, data };
};

export default useUpdateDetailsV2;
