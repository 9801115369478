import React, { useMemo } from 'react';
import { Tabs } from '@purposeinplay/core-v2';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { TabItemProps } from '../types';
import config from '@/config';
import { useAnalytics } from '@/hooks/use-analytics';

const gameOptions: TabItemProps[] = [
  {
    id: 'home-tab',
    label: 'Home',
    leadingIcon: (
      <Image
        width={18}
        height={18}
        loader={DEFAULT_IMAGE_LOADER}
        src={'/assets/category-icons/home.svg'}
        alt="home tab"
      />
    ),
    tabAs: 'a',
    otherProps: {
      href: `${config.SITE_URL}`,
    },
  },
  {
    id: 'casino-tab',
    label: 'Casino',
    leadingIcon: (
      <Image
        width={18}
        height={18}
        src={'/assets/category-icons/slot-games.svg'}
        alt="casino tab"
      />
    ),
    tabAs: 'a',
    otherProps: {
      href: `${config.SITE_URL}/casino`,
    },
  },
  {
    id: 'sports-tab',
    label: 'Sports',
    leadingIcon: (
      <Image
        width={18}
        height={18}
        src={'/assets/category-icons/sports.svg'}
        alt="casino tab"
      />
    ),
    tabAs: 'a',
    otherProps: {
      href: `${config.SITE_URL}/sports`,
    },
  },
];

const GameOptionLinks = ({ inNavbar }: { inNavbar?: boolean }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { track } = useAnalytics();
  const activeIndex = useMemo(() => {
    return gameOptions?.findIndex(
      (game) => game?.id?.split('-tab')[0] === pathname?.split('/')[1],
    );
  }, [pathname]);

  const handleTrack = (label: string = '') => {
    track('app_switcher_clicked', {
      category: 'navigation',
      label: label,
      action: 'click',
    });
  };

  return (
    <Tabs
      size="md"
      defaultSelectedIndex={
        pathname === '/' ? undefined : activeIndex !== -1 ? activeIndex : 0
      }
      tabStyleOptions={{
        navStyles: !inNavbar ? 'bg-transparent' : '',
        navItemStyles: cn(['first:md:hidden [&:nth-last-child(2)]:md:!ml-0']),
      }}
      items={gameOptions}
      onClick={(e, item) => {
        handleTrack(item?.id);
        if (item?.id === 'sports-tab') {
          router.push(`${config.SITE_URL}/sports`);
          return;
        }
        if (item?.id === 'home-tab') {
          router.push('/');
          return;
        }
        router.push('/casino');
      }}
      variant={inNavbar ? 'segmented' : 'segmented-rounded'}
    />
  );
};

export default GameOptionLinks;
