import React, { useMemo } from 'react';
import useSidemenuItems from '../hooks/use-sidemenu-items';
import Section from './section';
import { SidemenuItem } from '../types';
import AccordionSection from './accordion-section';
import { cn } from '@/utils/style';
import SectionItem from './section-item';
import { useAuth } from '@/hooks/auth';
import { useAppSelector } from '@/hooks/redux';
import { useIsMounted } from '@purposeinplay/utils';
import SidebarAccountList from './account-list';
import { sidebarListWrapperStyles } from '../styles';
import ElementsContainer from './elements-container';

const SidebarList = () => {
  const { user } = useAuth();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();
  const { items } = useSidemenuItems();
  const sidemenuItems = useMemo(() => {
    const links = items
      ?.filter((item) => item?.type === 'link')
      .map((item) => (
        <Section
          authOnly={item?.displayFor === 'AUTHENTICATED'}
          items={item?.items as SidemenuItem[]}
          key={`menu-section-narrow-${item?.title}`}
        />
      ));

    const submenus = items
      ?.filter((item) => item?.type === 'submenu')
      ?.filter((item) => {
        return item?.displayFor === 'AUTHENTICATED' && !user?.isAuthenticated
          ? false
          : true;
      })
      .map((item) => {
        return (
          <AccordionSection
            isAccordion
            title={item?.title}
            // @ts-ignore
            authOnly={item?.displayFor === 'AUTHENTICATED'}
            items={item?.items as SidemenuItem[]}
            isExpanded={Boolean(item?.isExpanded)}
            key={`submenu-section-narrow-${item?.title}`}
          />
        );
      });

    return {
      links,
      submenus,
    };
  }, [items, user?.isAuthenticated]);

  return (
    <ElementsContainer className={cn(!isSidemenuOpen && 'pb-1.5 md:pb-1.5')}>
      <div
        className={cn([
          'scrollbar overflow-y-auto',
          !isSidemenuOpen && 'hide-scroll-wrapper',
          user?.isAuthenticated && 'mb-14 md:mb-0',
        ])}
      >
        <nav className="flex h-full w-full flex-col gap-1">
          {/* <SidebarAccountList /> */}
          <ul
            className={cn([
              sidebarListWrapperStyles,
              !isSidemenuOpen && isMounted && '[&>div]:w-full',
            ])}
          >
            {sidemenuItems?.links?.map((item, index) => item)}
          </ul>
          {sidemenuItems?.submenus &&
            sidemenuItems?.submenus?.length > 0 &&
            sidemenuItems?.submenus?.map((submenu) => (
              <ul
                className={cn([
                  sidebarListWrapperStyles,
                  !isSidemenuOpen && isMounted && '[&>div]:w-full',
                ])}
                key={`submenu-section-narrow-${submenu?.key}`}
              >
                {submenu}
              </ul>
            ))}
          <ul
            className={cn([
              sidebarListWrapperStyles,
              !isSidemenuOpen && isMounted && '[&>div]:w-full',
            ])}
          >
            <li className="flex w-full list-none items-center justify-center [&>div]:w-full">
              <SectionItem
                item={{
                  id: 'sidemenu-live-support',
                  title: 'Live Support',
                  icon: '/assets/sidemenu_live_support_v2.svg',
                  authOnly: false,
                }}
              />
            </li>
          </ul>

          {user?.isAuthenticated && (
            <ul
              className={cn([
                'md:hidden',
                sidebarListWrapperStyles,
                !isSidemenuOpen && isMounted && '[&>div]:w-full',
              ])}
            >
              <li className="w-full list-none">
                <SectionItem
                  item={{
                    id: 'sidemenu-logout',
                    title: 'Logout',
                    icon: 'assets/logout.svg',
                    authOnly: true,
                  }}
                />
              </li>
            </ul>
          )}
        </nav>
      </div>
    </ElementsContainer>
  );
};

export default SidebarList;
