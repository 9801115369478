import { MouseEvent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { usePathname, useRouter } from 'next/navigation';
import { setOpenSearch } from '@/app/reducers/search';
import { useChat } from '@/services/live-chat/use-chat';
import { useAuth } from '@/hooks/auth';
import BackArrow from 'public/assets/back-arrow-long.svg';
import Dots from 'public/assets/dots.svg';
import Chat from 'public/assets/sidemenu_live_support_v2.svg';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import SidebarItemIcon from '../../sidebar/components/item-icon';
import useGamesSidebarItems from '../../sidebar/hooks/use-games-sidebar-items';
import { ExtendedNavigationItem } from '../types/extended-navigation-item';
import React from 'react';
import { useGamesContent } from '@/hooks/use-games-content';

export const useNavigationItems = () => {
  const {
    user: { isAuthenticated },
  } = useAuth();

  const { gamesContent } = useGamesContent();
  const bottomNav = gamesContent.bottom_navigation;
  const navItems = bottomNav.navigation_items;
  const sidebar = gamesContent.game_sidebar;

  const pathname = usePathname();
  const router = useRouter();
  const [signupUrl, setSignupUrl] = React.useState('/');
  const [walletUrl, setWalletUrl] = React.useState('/');
  const dispatch = useDispatch();
  const [_, loadChat] = useChat({ loadWhenIdle: false });
  const { rawLinks } = useGamesSidebarItems();

  React.useEffect(() => {
    if (pathname) {
      setSignupUrl(`${pathname}?m=signup`);
      setWalletUrl(`${pathname}?m=wallet&a=deposit`);
    }
  }, [pathname]);

  const handleSearchClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      dispatch(setOpenSearch(true));
    },
    [dispatch],
  );

  const handleChatClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      loadChat({ open: true });
    },
    [loadChat],
  );

  // Find items by identifier from rawLinks
  const findItemByIdentifier = useCallback(
    (identifier: string) => {
      return rawLinks?.find((link) => link.identifier === identifier);
    },
    [rawLinks],
  );

  // Get first item from items array of a link
  const getFirstItem = useCallback((link: any) => {
    return link?.items?.[0] || null;
  }, []);

  const navigationItems = useMemo((): ExtendedNavigationItem[] => {
    // Find items
    const walletLink = findItemByIdentifier('wallet');
    const walletItem = getFirstItem(walletLink);

    const notificationsLink = findItemByIdentifier('notifications');
    const notificationsItem = getFirstItem(notificationsLink);

    const favoritesLink = findItemByIdentifier('favorites');
    const favoritesItem = getFirstItem(favoritesLink);

    const newGamesLink = findItemByIdentifier('new');
    const newGamesItem = getFirstItem(newGamesLink);

    const popularGamesLink = findItemByIdentifier('popular');
    const popularGamesItem = getFirstItem(popularGamesLink);

    const searchLink = findItemByIdentifier('search');
    const searchItem = getFirstItem(searchLink);

    const signinLink = findItemByIdentifier('signin');
    const signinItem = getFirstItem(signinLink);

    return [
      // Back button - always visible
      {
        href: '/',
        icon: <BackArrow className="h-5 w-5" />,
        showAlways: true,
        label: navItems.back,
      },

      // Authenticated users: Notifications, Favorites, Wallet
      // Unauthenticated users: New Games, Popular Games, Sign-in

      // Notifications - only for authenticated users
      {
        href: notificationsItem?.url || '#',
        icon: notificationsItem?.icon && (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={notificationsItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ),
        show: isAuthenticated,
        section: 'notification',
        label: navItems.notifications,
      },

      // Favorites - only for authenticated users
      {
        href: favoritesItem?.url || '#',
        icon: favoritesItem?.icon && (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={favoritesItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ),
        show: isAuthenticated,
        section: 'favorites',
        label: navItems.favorites,
      },

      // Wallet - only for authenticated users
      {
        href: walletUrl,
        icon: walletItem?.icon && (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={walletItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ),
        show: isAuthenticated,
        label: navItems.wallet,
      },

      // New games - only for unauthenticated users
      {
        href: newGamesItem?.url || '/categories/new',
        icon: newGamesItem?.icon ? (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={newGamesItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ) : (
          <span className="relative flex h-5 w-5 items-center justify-center">
            <Image
              loader={DEFAULT_IMAGE_LOADER}
              alt={sidebar.items.new_games.alt}
              width={20}
              height={20}
              src="/assets/sidemenu_new.svg"
            />
          </span>
        ),
        show: !isAuthenticated,
        section: 'new',
        label: navItems.new,
      },

      // Popular games - only for unauthenticated users
      {
        href: popularGamesItem?.url || '/categories/popular',
        icon: popularGamesItem?.icon ? (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={popularGamesItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ) : (
          <span className="relative flex h-5 w-5 items-center justify-center">
            <Image
              loader={DEFAULT_IMAGE_LOADER}
              alt={sidebar.items.popular_games.alt}
              width={20}
              height={20}
              src="/assets/sidemenu_popular.svg"
            />
          </span>
        ),
        show: !isAuthenticated,
        section: 'popular',
        label: navItems.popular,
      },

      // Signin - only for unauthenticated users
      {
        href: signupUrl,
        icon: signinItem?.icon ? (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={signinItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ) : (
          <span className="relative flex h-5 w-5 items-center justify-center">
            <Image
              loader={DEFAULT_IMAGE_LOADER}
              alt={sidebar.items.signin.alt}
              width={20}
              height={20}
              src="/assets/sidemenu_signin.svg"
            />
          </span>
        ),
        show: !isAuthenticated,
        label: navItems.signin,
      },

      // Search/Explore - always visible
      {
        href: '#',
        icon: searchItem?.icon && (
          <SidebarItemIcon
            iconContainerStyles="h-5 w-5"
            icon={searchItem.icon}
            imageProps={{ className: 'h-5 w-5' }}
          />
        ),
        showAlways: true,
        onClick: handleSearchClick,
        label: navItems.explore,
      },

      // More menu - always visible
      {
        href: '/',
        icon: <Dots className="h-5 w-5" />,
        showAlways: true,
        section: 'more',
        label: navItems.more,
      },
    ];
  }, [
    findItemByIdentifier,
    getFirstItem,
    isAuthenticated,
    walletUrl,
    signupUrl,
    handleSearchClick,
    navItems,
    sidebar.items,
  ]);

  return { navigationItems };
};

export default useNavigationItems;
