export const useGetTextNumber = (
  exchangeToWoF: any,
  exchangeRatesPoints: any,
) => {
  const getNumber = () => {
    const { WildWheelSpins, JungleWheelSpins, VIPWheelSpins } = exchangeToWoF;

    switch (true) {
      case WildWheelSpins:
        return exchangeRatesPoints.wofWheel;
      case JungleWheelSpins:
        return exchangeRatesPoints.jungleWheel;
      case VIPWheelSpins:
        return exchangeRatesPoints.vipWheel;
      default:
        return null;
    }
  };

  const getText = () => {
    const { WildWheelSpins, JungleWheelSpins, VIPWheelSpins } = exchangeToWoF;
    if (WildWheelSpins) {
      return 'Common Wheel Spin';
    }
    if (JungleWheelSpins) {
      return 'Rare Wheel Spin';
    }
    if (VIPWheelSpins) {
      return 'Epic Wheel Spin';
    }
  };

  return {
    number: getNumber(),
    text: getText(),
  };
};
