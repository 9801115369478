import React from 'react';
import { ChallengesAccordionProps } from '../types';
import DisclosureAccordion from '@/components/DisclosureAccordion';
import { cn } from '@/utils/style';

const ChallengesAccordion = ({
  children,
  totalCount,
  loadedCount,
  setLoadedCount,
  cardsToLoad,
  forPage,
  content,
}: ChallengesAccordionProps) => {
  const accordionFooterStyles = cn([
    'from-transparent',
    !forPage &&
      'before:!from-transparent before:!to-transparent bg-transparent before:dark:from-transparent dark:from-transparent',
  ]);

  return (
    <DisclosureAccordion
      disabledButton={totalCount <= loadedCount}
      disableClose={true}
      backgroundGradient={false}
      accordionStyles={`px-0 rounded-none pt-2 bg-transparent`}
      footerStyles={accordionFooterStyles}
      closedHeight={`max-h-full`}
      openHeight={`max-h-full`}
      buttonTextClosed={content?.action?.load ?? 'Load More'}
      buttonTextOpened={content?.action?.unload ?? 'See Less'}
      onClick={() => {
        setLoadedCount(loadedCount + cardsToLoad);
      }}
    >
      {children}
    </DisclosureAccordion>
  );
};

export default ChallengesAccordion;
