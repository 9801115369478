import { useEffect, useRef } from 'react';

/**
 * Hook to lock scrolling on the app-body element
 * @param isLocked Whether the body should be locked
 * @returns void
 */
const useAppBodyLock = (isLocked: boolean) => {
  // Keep track of whether we've applied the lock
  const hasAppliedLock = useRef(false);

  useEffect(() => {
    const appBody = document.getElementById('app-body');
    if (!appBody) return;

    if (isLocked) {
      // Only apply lock if we haven't already
      if (!hasAppliedLock.current) {
        // Save the current scroll position and styling
        const scrollY = window.scrollY;
        const originalStyles = {
          overflow: appBody.style.overflow,
          position: appBody.style.position,
          top: appBody.style.top,
          width: appBody.style.width,
          height: appBody.style.height,
        };

        // Store original styles in a data attribute for later restoration
        appBody.setAttribute(
          'data-original-styles',
          JSON.stringify(originalStyles),
        );

        // Apply scroll lock styles
        appBody.style.overflow = 'hidden';
        appBody.style.position = 'fixed';
        appBody.style.top = `-${scrollY}px`;
        appBody.style.width = '100%';
        appBody.style.height = '100%';

        hasAppliedLock.current = true;
      }
    } else {
      // Only restore if we've applied a lock
      if (hasAppliedLock.current) {
        try {
          const originalStylesStr = appBody.getAttribute(
            'data-original-styles',
          );
          if (originalStylesStr) {
            const originalStyles = JSON.parse(originalStylesStr);

            // Get the scroll position from the top style
            const scrollY = appBody.style.top
              ? parseInt(appBody.style.top.replace('px', ''), 10) * -1
              : 0;

            // Restore original styles
            appBody.style.overflow = originalStyles.overflow || '';
            appBody.style.position = originalStyles.position || '';
            appBody.style.top = originalStyles.top || '';
            appBody.style.width = originalStyles.width || '';
            appBody.style.height = originalStyles.height || '';

            // Restore scroll position
            window.scrollTo(0, scrollY);

            // Clean up data attribute
            appBody.removeAttribute('data-original-styles');
          }
        } catch (error) {
          console.error('Error restoring original styles:', error);
          // Fallback reset
          appBody.style.overflow = '';
          appBody.style.position = '';
          appBody.style.top = '';
          appBody.style.width = '';
          appBody.style.height = '';
        }

        hasAppliedLock.current = false;
      }
    }

    // Cleanup on unmount
    return () => {
      if (hasAppliedLock.current) {
        try {
          const originalStylesStr = appBody.getAttribute(
            'data-original-styles',
          );
          if (originalStylesStr) {
            const originalStyles = JSON.parse(originalStylesStr);

            // Get the scroll position from the top style
            const scrollY = appBody.style.top
              ? parseInt(appBody.style.top.replace('px', ''), 10) * -1
              : 0;

            // Restore original styles
            appBody.style.overflow = originalStyles.overflow || '';
            appBody.style.position = originalStyles.position || '';
            appBody.style.top = originalStyles.top || '';
            appBody.style.width = originalStyles.width || '';
            appBody.style.height = originalStyles.height || '';

            // Restore scroll position
            window.scrollTo(0, scrollY);

            // Clean up data attribute
            appBody.removeAttribute('data-original-styles');
          }
        } catch (error) {
          console.error('Error restoring original styles on unmount:', error);
          // Fallback reset
          appBody.style.overflow = '';
          appBody.style.position = '';
          appBody.style.top = '';
          appBody.style.width = '';
          appBody.style.height = '';
        }

        hasAppliedLock.current = false;
      }
    };
  }, [isLocked]);
};

export default useAppBodyLock;
