import React from 'react';
import Image from 'next/image';
import { cn } from '@/utils/style';

export const FsIcon = ({
  size = 20,
  className,
}: {
  size?: number;
  className?: string;
}) => (
  <Image
    className={cn(['shrink-0', className])}
    src="/assets/bonus-shop/fs-icon-small.svg"
    width={size}
    height={size}
    alt="Fs Tab Icon"
  />
);

export const WofIcon = () => (
  <Image
    className="shrink-0"
    src="/assets/bonus-shop/wof-icon-small.svg"
    width={20}
    height={20}
    alt="Fs Tab Icon"
  />
);

export const LabelWithIcon = ({
  label,
  type = 'fs',
}: {
  label: React.ReactNode;
  type?: 'fs' | 'wof';
}) => (
  <div className="flex flex-row items-center space-x-0.5">
    {type === 'fs' ? <FsIcon /> : <WofIcon />}
    <div className="text-sm">{label}</div>
  </div>
);

const createTabs = (numbers: number[], type: 'wof' | 'fs') =>
  numbers.map((label, index) => ({
    active: index === 0,
    id: label.toString(),
    label: <LabelWithIcon type={type} label={label} />,
  }));

const WOF_NUMBERS = [1, 3, 5, 7, 10];
const FS_NUMBERS = [10, 30, 50, 70, 100];

export const FS_TABS = createTabs(FS_NUMBERS, 'fs');
export const WOF_TABS = createTabs(WOF_NUMBERS, 'wof');
