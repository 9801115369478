import { useGetExchangeRatesFS } from '@/hooks/comp-points-exchange-rates';
import { useGetExchangeRatesPoints } from '@/hooks/use-get-exchange-rates-points';
import { RATES_FS_FIXED } from '../constants';

export const useFSRate = () => {
  const exchangeRatesPoints = useGetExchangeRatesPoints();
  const { data: ratesFS } = useGetExchangeRatesFS();

  const fsRate =
    ratesFS?.find((item: any) => item?.group === 'fs_on_compoints')?.rate
      ?.points ?? RATES_FS_FIXED.points;

  return {
    fsRate,
    exchangeRatesPoints,
  };
};
