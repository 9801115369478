import { useAuth } from '@/hooks/auth';
import React from 'react';
import NotificationsLoading from './components/notifications-loading';
import NotificationHeader from './components/notification-header';
import NoNotifications from './components/no-notifications';
import dayjs from 'dayjs';
import MediaComponent from '@/components/MediaComponent';
import { useAllNotifications } from '@/app/providers/notifications';
import { useWofQuery } from '@/generated';
import { cn } from '@/utils/style';

const Notification = React.memo(
  ({ forSidebar = false }: { forSidebar?: boolean }) => {
    const {
      notifications,
      setUnreadNotifications,
      isLoading: notificationsLoading,
    } = useAllNotifications();
    const { data: wofData, isPending: pendingWof } = useWofQuery();

    const {
      user: { isAuthenticated, isLoading: authLoading, currentUser },
    } = useAuth();

    if (notificationsLoading || authLoading) {
      return <NotificationsLoading />;
    }

    if (
      (!isAuthenticated && !authLoading) ||
      (notifications?.length == 0 && !notificationsLoading)
    ) {
      return (
        <div className="flex flex-col">
          {!forSidebar && <NotificationHeader />}
          <NoNotifications className={cn([forSidebar && '!py-10 lg:py-12'])} />
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        {!forSidebar && <NotificationHeader />}
        <div className="scrollbar">
          <div
            className={cn([
              'scrollbar flex  flex-col overflow-y-scroll',
              !forSidebar && 'max-h-80',
            ])}
          >
            {notifications.map((notif: any, index: number) => {
              let foundWof;
              if (notif?.type === 'lootboxes') {
                foundWof = wofData?.wheelOfFortunes?.data?.find(
                  (wof) =>
                    wof?.attributes?.exchange_group_key === notif?.group_key ||
                    wof?.attributes?.lootbox_group_key === notif?.group_key,
                )?.attributes;
              }
              return (
                <MediaComponent
                  forSidebar={forSidebar}
                  key={`${notif?.type + '-' + index}`}
                  id={`${notif?.type + '-' + notif?.id?.toString()}`}
                  type={notif.type}
                  read={notif.read}
                  notification={notif}
                  wofSlug={foundWof?.slug}
                  description={
                    notif?.created_at
                      ? dayjs(notif?.created_at).fromNow()
                      : undefined
                  }
                  setNotifications={setUnreadNotifications}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  },
);

Notification.displayName = 'Notification';

export default Notification;
