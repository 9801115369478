import {
  ScrollOptions,
  ScrollState,
  TouchMoveEvent,
} from '../types/scroll-lock';

class ScrollLockManager {
  private locks: Set<string> = new Set();
  private initialState: ScrollState | null = null;
  private isIOS: boolean = false;
  private lockableTargets: WeakSet<Element> = new WeakSet();

  constructor() {
    if (typeof window !== 'undefined') {
      this.isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }
  }

  private saveInitialState(): void {
    if (!this.initialState && typeof window !== 'undefined') {
      const body = document.body;
      this.initialState = {
        scrollPosition: window.pageYOffset,
        bodyPosition: body.style.position || null,
        bodyOverflow: body.style.overflow || null,
        bodyWidth: body.style.width || null,
      };
    }
  }

  private setOverflowHidden(options: ScrollOptions = {}): void {
    if (typeof window === 'undefined') return;

    const body = document.body;
    const html = document.documentElement;
    const scrollBarGap = options.reserveScrollBarGap
      ? window.innerWidth - document.documentElement.clientWidth
      : 0;

    if (this.isIOS) {
      const scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.style.width = '100%';
      body.style.height = '100%';
      body.style.overflow = 'hidden';
    } else {
      html.style.overflow = 'hidden';
      body.style.overflow = 'hidden';
      if (scrollBarGap) {
        body.style.paddingRight = `${scrollBarGap}px`;
      }
    }
  }

  private restoreInitialState(): void {
    if (!this.initialState || typeof window === 'undefined') return;

    const body = document.body;
    const html = document.documentElement;
    const scrollPosition = this.initialState.scrollPosition;

    if (this.isIOS) {
      body.style.position = this.initialState.bodyPosition || '';
      body.style.top = '';
      body.style.width = this.initialState.bodyWidth || '';
      body.style.height = '';
      body.style.overflow = this.initialState.bodyOverflow || '';
      window.scrollTo(0, scrollPosition);
    } else {
      html.style.overflow = '';
      body.style.overflow = this.initialState.bodyOverflow || '';
      body.style.paddingRight = '';
    }

    this.initialState = null;
  }

  private handleTouchMove = (event: TouchMoveEvent): void => {
    const target = event.target as Element;

    // Check if the target or any of its parents is a lockable target
    let isLockableTarget = false;
    let element: Element | null = target;

    while (element && !isLockableTarget) {
      if (this.lockableTargets.has(element)) {
        isLockableTarget = true;
      }
      element = element.parentElement;
    }

    if (!isLockableTarget) {
      event.preventDefault();
    }
  };

  public lock(id: string, options: ScrollOptions = {}): void {
    if (this.locks.size === 0) {
      this.saveInitialState();
      this.setOverflowHidden(options);

      if (this.isIOS) {
        document.addEventListener(
          'touchmove',
          this.handleTouchMove as EventListener,
          {
            passive: false,
          },
        );
      }
    }

    this.locks.add(id);
  }

  public unlock(id: string): void {
    this.locks.delete(id);

    if (this.locks.size === 0) {
      this.restoreInitialState();

      if (this.isIOS) {
        document.removeEventListener(
          'touchmove',
          this.handleTouchMove as EventListener,
        );
      }
    }
  }

  public addLockableTarget(element: Element): void {
    this.lockableTargets.add(element);
  }

  public removeLockableTarget(element: Element): void {
    this.lockableTargets.delete(element);
  }

  public clearAllLocks(): void {
    this.locks.clear();
    this.restoreInitialState();
    if (this.isIOS) {
      document.removeEventListener(
        'touchmove',
        this.handleTouchMove as EventListener,
      );
    }
  }
}

export const scrollLockManager = new ScrollLockManager();
