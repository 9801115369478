import { useQuery } from '@tanstack/react-query';

export const fetchApiDataChallenges = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const apiUrlChallenges =
  'https://challenges.wild.io/api/v1/challenges?active=true';

export const useChallenges = () => {
  return useQuery({
    queryKey: ['challenges'],
    queryFn: () => fetchApiDataChallenges(apiUrlChallenges),
  });
};

export const apiUrlRewards = 'https://challenges.wild.io/api/v1/achievements';

export const useRewards = () => {
  return useQuery({
    queryKey: ['rewards'],
    queryFn: () => fetchApiDataChallenges(apiUrlRewards),
  });
};
