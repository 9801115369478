import React from 'react';
import ChallengeCard from './challenge-card';
import { Challenges } from '../types';
import { cn } from '@/utils/style';
import { FilteredGame } from '@/types/games_filters';

const ChallengeList: React.FC<Challenges> = React.memo(
  ({
    items,
    forPage = false,
    isForChallenge = false,
    completeGames,
    ...props
  }) => {
    return (
      <div
        className={cn([
          'grid w-full grid-cols-1  gap-2 md:grid-cols-2',
          forPage &&
            'grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-2 xl:grid-cols-3 ',
        ])}
        {...props}
      >
        {items?.map((challenge, index) => {
          return (
            <ChallengeCard
              isForChallenge={isForChallenge}
              linkSrc={
                completeGames?.find(
                  (game: FilteredGame) =>
                    game?.identifier === challenge?.game_identifier,
                )?.slug || ''
              }
              item={challenge}
              key={`${challenge?.game_identifier}-${index}`}
            />
          );
        })}
      </div>
    );
  },
);

ChallengeList.displayName = 'ChallengeList';

export default ChallengeList;
