'use client';

import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { useIsMounted } from '@purposeinplay/utils';
import React from 'react';

const SidebarOverlay = () => {
  const dispatch = useAppDispatch();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );

  const isMounted = useIsMounted();

  return (
    <div
      onClick={() => {
        dispatch(setOpenSidemenu(!isSidemenuOpen));
      }}
      className={cn([
        'pointer-events-auto fixed inset-0 z-[90] hidden h-full w-full overflow-hidden bg-bgr-lightest xl:hidden',
        isSidemenuOpen && isMounted && 'md:block md:opacity-70',
      ])}
    />
  );
};

export default SidebarOverlay;
