import CountDownButton from './components/countdown-button';

import dayjs from '@/utils/dayjsconfig';
import { useGetLootboxes } from '@/hooks/lootboxes';
import {
  ComponentWheelWheelPrizes,
  Enum_Componentwheelwheelprizes_Type,
  Maybe,
  WheelOfFortune,
} from '@/generated';

export const filterLootboxesByGroupKey = (
  lootboxes: any,
  groupKey: any,
  exchangeGroupKey: any,
  stage = 'issued',
) => {
  return lootboxes
    ?.filter(
      (item: any) =>
        item.stage === stage &&
        (item.group_key === groupKey || item.group_key === exchangeGroupKey),
    )
    .sort(
      (a: any, b: any) =>
        dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
    );
};

export const filterPrizesById = (prizes: any, wofData: any) => {
  return prizes.filter((item: any) => item.id === wofData?.bonuses[0]?.title);
};

export const hasAnyDeposit = (playerData: any, statusIds: any) => {
  return playerData?.statuses?.some((obj: any) => statusIds.includes(obj.id));
};

export const useAvailableInState = (title: string) => {
  const currentDate = dayjs().utc();

  const { data: lootboxes, isLoading: lootboxesLoading } = useGetLootboxes();

  if (lootboxesLoading) {
    return false;
  }

  const allBonuses = lootboxes?.filter((bonus: any) => {
    return bonus?.group_key == title;
  });

  const hasBonus = allBonuses?.find(
    (bonus: any) => currentDate.diff(dayjs.utc(bonus?.created_at), 'day') === 0,
  );

  return hasBonus !== undefined;
};

export const filterPrizes = (
  prizes: WheelOfFortune['prizes'],
  wofData: any,
) => {
  return prizes?.filter(
    (prize) => prize?.identifier === wofData?.bonuses[0]?.title,
  );
};

interface IndicatorAnimationPhases {
  phase1?: number;
  phase2?: number;
  phase3?: number;
  phase4?: number;
  phase5?: number;
}

export const getIconByType = (type: string) => {
  switch (type) {
    case 'gold':
      return '/assets/epic.png';
    case 'silver':
      return '/assets/rare.png';
    case 'bronze':
      return '/assets/common.png';
    case 'weekly-epic':
      return '/assets/weekly-epic.png';
    case 'weekly-rare':
      return '/assets/weekly-rare.png';
    case 'weekly-common':
      return '/assets/weekly-common.png';
    default:
      return '/assets/common.png';
  }
};

export const getColorByType = (type: 'gold' | 'bronze' | 'silver') => {
  switch (type) {
    case 'gold':
      return `text-text-warning`;
    case 'silver':
      return `!text-gray-400 dark:!text-[#F4F5F6]`;
    case 'bronze':
      return `!text-yellow-600`;
    default:
      return `!text-yellow-500`;
  }
};

export const getIconRewardByType = (type: string) => {
  switch (type) {
    case 'freespins':
      return '/assets/fs-reward.svg';
    case 'bonus':
      return '/assets/bonus-reward.svg';
    default:
      return '/assets/bonus-reward.svg';
  }
};

const getLevelRestriction = (level: string, min_level: number | undefined) => {
  if (min_level === undefined) return null;
  if (Number(level) < min_level) {
    return `Unlock at Level ${min_level}`;
  } else return null;
};

export const getButtonLabel = (
  content: any,
  hasLootboxes: boolean,
  level: string,
  nextDay?: any,
  availableIn?: boolean,
  isAuthenticated?: boolean,
  min_level?: number,
) => {
  if (!isAuthenticated) return content?.btn_label;
  const notAvailableLevel = getLevelRestriction(level, min_level);
  if (notAvailableLevel != null) return notAvailableLevel;
  if (hasLootboxes) return content?.btn_label_spin;
  else if (availableIn) {
    return <CountDownButton endTime={nextDay} />;
  } else return content?.btn_label;
};

export const getDisabledButton = (
  level: string,
  min_level: number | undefined,
  hasAvailableIn?: boolean,
  hasLootboxes?: boolean,
) => {
  const notAvailableLevel = getLevelRestriction(level, min_level);
  if (notAvailableLevel != null) return true;
  if (hasAvailableIn && !hasLootboxes) return true;
  return false;
};

export function formatWheelPrize(
  item: Maybe<Maybe<ComponentWheelWheelPrizes>>,
): string {
  // If there's no type, return "0"
  if (!item?.type) {
    return '0';
  }

  // Check for Freespins reward
  if (item.type === Enum_Componentwheelwheelprizes_Type.Freespins) {
    return `${item.fs_amount || 0} FS`;
  }

  // Check for Bonus reward
  if (item.type === Enum_Componentwheelwheelprizes_Type.Bonus) {
    return '$' + (item.bonus_amount || 0);
  }

  // For other types, return a combination of FS and Bonus amounts
  return `${item.fs_amount || 0} FS + $${item.bonus_amount || 0}`;
}

export const getModalWheelStyles = (wheel: string) => {
  switch (wheel) {
    case 'weekly-epic':
      return '!pb-0 pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[linear-gradient(180deg,#370196_0%,#330185_100%,#263747)]';
    case 'weekly-rare':
      return '!pb-0 pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[linear-gradient(180deg,#370196_0%,#330185_100%,#263747)]';
    case 'weekly-common':
      return '!pb-0 pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[linear-gradient(180deg,#370196_0%,#330185_100%,#263747)]';
    case 'rare':
      return '!pb-0 pt-16 overflow-hidden rounded-3xl md:h-auto !bg-[linear-gradient(180deg,#370196_0%,#330185_100%,#263747)]';
    case 'common':
      return 'pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[#261f55]';
    case 'epic':
      return 'pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[#261f55]';
    default:
      return 'pt-10 overflow-hidden rounded-3xl md:h-auto !bg-[#261f55]';
  }
};
