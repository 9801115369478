import useModal, { useCurrentModal } from '@/hooks/modal';
import { Alert, Modal } from '@purposeinplay/core-v2';
import React from 'react';
import RestrictedRegionContent from './components/restricted-region-content';

const RestrictedRegionModal = () => {
  return (
    <Alert
      isOpen={true}
      size="lg"
      setIsOpen={() => null}
      content={<RestrictedRegionContent />}
    />
  );
};

export default RestrictedRegionModal;
