import React from 'react';
import { TextLoader } from '@/components/Loaders';

const LoadingView: React.FC = () => (
  <div className="relative min-h-[200px]">
    {Array.from({ length: 3 }).map((_, i) => (
      <TextLoader
        key={i}
        className="mb-2 aspect-[2/1] h-auto last:mb-0 lg:w-auto"
      />
    ))}
  </div>
);

export default LoadingView;
