import { useState, useRef, useEffect } from 'react';
import { Tournament } from '@/types/tournaments';

export const useTournamentSelection = () => {
  // Track if we should animate (only after user interaction)
  const shouldAnimate = useRef(false);

  // Track selected tournament and animation direction
  const [selectedTournament, setSelectedTournament] =
    useState<Tournament | null>(null);
  const [direction, setDirection] = useState(0);

  // Animation states
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentView, setCurrentView] = useState<'list' | 'selected'>('list');

  // Handle animation timing
  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300); // Match the CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  const selectTournament = (tournament: Tournament) => {
    shouldAnimate.current = true;
    setDirection(1);
    setIsAnimating(true);
    setCurrentView('selected');
    setSelectedTournament(tournament);
  };

  const deselectTournament = () => {
    setDirection(-1);
    setIsAnimating(true);
    setCurrentView('list');
    setSelectedTournament(null);
  };

  // Get container class names based on animation state
  const getContainerClassNames = () => {
    const baseClasses =
      'flex transition-transform duration-300 ease-in-out w-[200%]';

    if (!shouldAnimate.current) {
      return currentView === 'list'
        ? baseClasses
        : `${baseClasses} -translate-x-1/2`;
    }

    if (isAnimating) {
      return direction > 0
        ? `${baseClasses} -translate-x-1/2` // Moving to selected view
        : baseClasses; // Moving to list view
    }

    return currentView === 'list'
      ? baseClasses
      : `${baseClasses} -translate-x-1/2`;
  };

  return {
    selectedTournament,
    currentView,
    selectTournament,
    deselectTournament,
    getContainerClassNames,
  };
};

export default useTournamentSelection;
