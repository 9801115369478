import config from '@/config';
import { useTournamentsQuery } from '@/generated';
import { useAllWildCategories } from '@/hooks/collections';
import { useTournament } from '@/hooks/tournaments/tournaments';
import { useParams } from 'next/navigation';
import { useMemo } from 'react';

const useDynamicTournamentState = () => {
  const { data: tournaments } = useTournamentsQuery();
  const params = useParams();
  const tournamentId = params?.tournament as string;

  const { data } = useTournament(tournamentId);
  const tournament = data?.tournament;

  const existingCategory = config?.ALL_AVAILABLE_COLLECTIONS?.includes(
    tournament?.game_category_identity,
  );

  const gameCategory =
    existingCategory &&
    config?.ALL_AVAILABLE_COLLECTIONS?.find(
      (cat) => cat === tournament?.game_category_identity,
    );

  const { data: collections, isLoading } = useAllWildCategories();

  const gamesCount = useMemo(() => {
    if (!collections) return;
    return collections?.reduce(function (result: any, item: any) {
      result[item?.id] = item?.games_count;
      return result;
    }, {} as any);
  }, [collections]);

  const matchingTournament: any = useMemo(() => {
    return tournaments?.tournaments?.data.find(
      (content: any) =>
        content.attributes.uid === data?.tournament?.frontend_identifier,
    )?.attributes;
  }, [tournaments?.tournaments?.data, data?.tournament?.frontend_identifier]);

  const imageUrl = useMemo(() => {
    return matchingTournament?.thumbnail?.tournament_image?.data[0]?.attributes
      .url;
  }, [matchingTournament?.thumbnail?.tournament_image?.data]);

  return {
    tournament,
    existingCategory,
    gameCategory,
    gamesCount,
    isLoading,
    matchingTournament,
    imageUrl,
  };
};

export default useDynamicTournamentState;
