import { cn } from '@/utils/style';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const SectionSettingsItem = ({ children, className, ...props }: Props) => {
  return (
    <div
      className={cn([
        `rounded-sm bg-surface-subdued px-4 py-[0.875rem] text-white placeholder-gray-600`,
        className && className,
      ])}
      {...props}
    >
      {children}
    </div>
  );
};

export default SectionSettingsItem;
