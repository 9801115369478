import { useEffect, useState } from 'react';

type FliptFlag = {
  key: string;
  enabled: boolean;
  rules: any[];
  rollouts: Array<{
    type: string;
    rank: number;
    segment: {
      value: boolean;
      segmentOperator: string;
      segments: Array<{
        key: string;
        matchType: string;
        constraints: Array<{
          type: string;
          property: string;
          operator: string;
          value: string;
        }>;
      }>;
    };
  }>;
};

type FliptResponse = {
  namespace: {
    key: string;
  };
  flags: FliptFlag[];
};

type FliptContext = Record<string, string>;

export const useFliptBoolean = (
  flagKey: string,
  defaultValue: boolean = false,
  entityId?: string,
  context?: FliptContext,
) => {
  const [enabled, setEnabled] = useState(defaultValue);

  useEffect(() => {
    const evaluateFlag = async () => {
      if (!entityId || entityId === 'pending') {
        return;
      }

      try {
        const response = await fetch(
          'https://flipt.wild.io/internal/v1/evaluation/snapshot/namespace/default',
        );

        const data: FliptResponse = await response.json();

        const flag = data.flags.find((f) => f.key === flagKey);

        if (!flag) {
          setEnabled(defaultValue);
          return;
        }

        // If there are no rollouts, use the flag's enabled status directly
        if (!flag.rollouts || flag.rollouts.length === 0) {
          setEnabled(flag.enabled);
          return;
        }

        // Check rollouts if they exist
        for (const rollout of flag.rollouts) {
          if (rollout.type === 'SEGMENT_ROLLOUT_TYPE') {
            const { segment } = rollout;

            // For OR operator, any segment match is sufficient
            if (segment.segmentOperator === 'OR_SEGMENT_OPERATOR') {
              for (const segmentRule of segment.segments) {
                if (segmentRule.matchType === 'ALL_SEGMENT_MATCH_TYPE') {
                  const matches = segmentRule.constraints.every(
                    (constraint) => {
                      if (!context) return false;

                      const contextValue = context[constraint.property];
                      if (constraint.operator === 'eq') {
                        return contextValue === constraint.value;
                      }
                      return false;
                    },
                  );

                  if (matches) {
                    setEnabled(segment.value);
                    return;
                  }
                }
              }
            }
          }
        }

        // If no rollout rules match, use the flag's enabled status
        setEnabled(flag.enabled);
      } catch (error) {
        console.error('Error evaluating Flipt flag:', error);
        setEnabled(defaultValue);
      }
    };

    evaluateFlag();
  }, [flagKey, entityId, context, defaultValue]);

  return enabled;
};
