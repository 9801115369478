import React from 'react';
import ActivityCard from './activity-card';
import { Challenge, RewardResult } from '../types';

interface ChallengeCardProps {
  item?: Challenge | RewardResult | undefined;
  key: string | number;
  linkSrc: string;
  isForChallenge?: boolean;
}

const ChallengeCard = React.memo(
  ({ item, linkSrc, isForChallenge = false }: ChallengeCardProps) => {
    const multiplier =
      isForChallenge && item
        ? item.multiplier_custom_data.target_multiplier
        : item?.multiplier_custom_data?.target_multiplier;

    const winAmount = isForChallenge
      ? (item as Challenge)?.reward?.cash_bonus?.amount
      : (item as RewardResult)?.cash_bonus?.amount;

    return (
      <ActivityCard
        isForChallenge={isForChallenge}
        linkSrc={linkSrc}
        item={item}
        multiplier={multiplier}
        winAmount={winAmount}
      />
    );
  },
);

ChallengeCard.displayName = 'ChallengeCard';

export default ChallengeCard;
