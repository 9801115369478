import React from 'react';
import AdaptiveImage from '../AdaptiveImage';

export const GuestImage = () => {
  return (
    <AdaptiveImage
      sources={{
        desktop: {
          light: '/assets/raf/raf-guest-light-1.png',
          dark: '/assets/raf/raf-guest-1.png',
        },
        mobile: {
          light: '/assets/raf/mobile-raf-guest.png',
          dark: '/assets/raf/mobile-raf-guest.png',
        },
      }}
      alt="RAF Desktop"
      fill
      className="mt-14 h-full w-full scale-125 transform-gpu rounded-t-3xl object-cover object-center sm:mt-0 sm:-translate-y-[35%] sm:translate-x-[20%] sm:object-contain sm:object-center dark:sm:-translate-y-[15%] dark:sm:translate-x-[20%]"
    />
  );
};

export const AuthImage = () => {
  return (
    <AdaptiveImage
      alt="RAF Desktop"
      fill
      className="rounded-t-3xl object-cover object-right md:object-contain md:object-right-top "
      sources={{
        desktop: {
          light: `/assets/raf/raf.png`,
          dark: `/assets/raf/raf.png`,
        },
        mobile: {
          light: `/assets/raf/mobile-raf.png`,
          dark: `/assets/raf/mobile-raf.png`,
        },
      }}
    />
  );
};
