import { Button } from '@purposeinplay/core-v2';
import useWildChatContent from '../hooks/use-wild-chat-content';

export const FooterButtons = ({
  isLoading,
  isDisabled,
  onClose,
}: {
  isDisabled: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) => {
  const createNicknameContent = useWildChatContent().create_nickname_modal;
  return (
    <Button
      className="w-full"
      isLoading={isLoading}
      form="nickname-form"
      type="submit"
      disabled={isDisabled || isLoading}
    >
      {createNicknameContent?.buttons?.create}
    </Button>
  );
};
