import { Tournament } from '../types';

export function calculatePrizeSums(tournament: Tournament) {
  const { prizes, money_prize_pool_cents, currency } = tournament;

  const prizeSums = prizes.map((prize) => {
    const prizeSum =
      (prize.money_budget_percent / 100) * money_prize_pool_cents;
    return {
      award_place: prize.award_place,
      money_budget_percent: prize.money_budget_percent,
      prizeSum,
      currency,
    };
  });

  return prizeSums;
}
