import React from 'react';
import MoreItem from './more-item';

interface MoreListViewProps {
  items: Array<{ id: string; text: string }>;
  onShowJackpotDetail: () => void;
  onItemAction?: (id: string) => void;
}

const MoreListView: React.FC<MoreListViewProps> = ({
  items,
  onShowJackpotDetail,
  onItemAction,
}) => {
  const handleItemClick = (id: string) => {
    if (onItemAction && (id === 'chat' || id === 'support')) {
      onItemAction(id);
    }
  };

  return (
    <div className="p-1">
      <div className="flex flex-col">
        {items.map((item) => (
          <MoreItem
            key={item.id}
            label={item.text}
            id={item.id}
            onShowJackpotDetail={onShowJackpotDetail}
            onItemClick={
              item.id === 'chat' || item.id === 'support'
                ? () => handleItemClick(item.id)
                : undefined
            }
          />
        ))}
      </div>
    </div>
  );
};

export default MoreListView;
