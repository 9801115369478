import ChatBody from './chat-body';
import ChatBodyContainer from './chat-body-container';
import ChatFooter from './chat-footer';
import ChatHeader from './chat-header';

export const ChatElements = () => (
  <>
    <ChatHeader />
    <ChatBodyContainer>
      <ChatBody />
    </ChatBodyContainer>
    <ChatFooter />
  </>
);
