import React from 'react';
import useModal, { useCurrentModal } from '@/hooks/modal';
import LayoutModal from './layout-modal';
import ForgotPasswordForm from '@/components/Auth/forgot-pass-form';
import { useAnalytics } from '@/hooks/use-analytics';

const ForgotPasswordModal = () => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'forgot-pass';
  const { track } = useAnalytics();
  return (
    <LayoutModal isOpen={isOpen} setIsOpen={() => openModal('forgot-pass')}>
      <ForgotPasswordForm
        closeModal={() => close()}
        openLoginModal={() => {
          openModal('signin');
          track('forgot_password_form_started', {
            category: 'auth',
          });
        }}
      />
    </LayoutModal>
  );
};

export default ForgotPasswordModal;
