import { getPageIconByCategory } from '@/utils/helpers';
import { Heading } from '@purposeinplay/core-v2';
import React from 'react';

const ChallengesHeadingLeft = ({ title }: { title: string }) => {
  return (
    <div className="flex items-center">
      <div className="mr-3 rounded-md bg-transparent">
        {getPageIconByCategory('live-casino', 24, 24)}
      </div>
      <Heading
        as="h2"
        className="min-w-max text-lg font-semibold leading-6 text-text-default xl:text-2xl"
      >
        {title ?? 'Challenges'}
      </Heading>
    </div>
  );
};

export default ChallengesHeadingLeft;
