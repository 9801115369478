import { useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import React from 'react';

const GameViewWrapper = ({ children }: { children: React.ReactNode }) => {
  const bootstrap = useAppSelector((state) => state.bootstrap);
  const { isMinimized, gameViewOpen } = bootstrap;
  const gameView = bootstrap.gameViews?.[0]; // Get the first game view

  return (
    <div
      id={`game-view-0`}
      className={cn([
        'game-view pointer-events-none absolute left-0 top-0 -mt-14  hidden w-full pt-3 lg:-mt-16 lg:pt-8',
        gameView && `block`,
        isMinimized && `fixed left-7 top-3/4 z-[100] h-44 w-72`,
        !isMinimized && 'absolute inset-0',
      ])}
    >
      {children}
    </div>
  );
};

export default GameViewWrapper;
