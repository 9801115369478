import { useAuth } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import { Button, Text } from '@purposeinplay/core-v2';
import React from 'react';
import EmptyNotificationsSVG from 'public/assets/no-notifications.svg';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useAnalytics } from '@/hooks/use-analytics';
import EmptyState from '@/components/EmptyState';
import { cn } from '@/utils/style';

const NoNotifications = ({ className }: { className?: string }) => {
  const { open } = useModal();
  const { user } = useAuth();
  const { t } = useTranslation();
  const content = t('notifications') as NotificationsContentType;

  const { track } = useAnalytics();

  return (
    <div
      className={cn([
        'flex h-full w-full flex-col items-center p-7',
        className,
      ])}
    >
      <EmptyState
        title={content?.dropdown?.empty?.title}
        description={content?.dropdown?.empty?.description}
        iconName="bell"
        className="border-transparent p-0"
      />
      {!user?.isAuthenticated && (
        <div className="flex items-center">
          <Button
            size="md"
            color="tertiary"
            className="mr-2"
            style={{
              minWidth: '84px',
            }}
            onClick={() => {
              open('signin');
              track('signin_form_started', {
                category: 'auth',
              });
            }}
          >
            Log In
          </Button>
          <Button
            size="md"
            className="mr-2"
            style={{
              minWidth: '84px',
            }}
            onClick={() => {
              open('signup');
              track('signup_form_started', {
                category: 'auth',
                label: window && window.location && window.location.href,
              });
            }}
          >
            Sign Up
          </Button>
        </div>
      )}
    </div>
  );
};

export default NoNotifications;
