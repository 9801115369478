import { ModalStates as ModalStatesContent } from '@/types/json-content/agent_verification';
import { VerifyAgentState } from './types';

export const getImageByState = (state: VerifyAgentState) => {
  switch (state) {
    case 'success':
      return '/assets/agent-verification/success.png';
    case 'invalid':
      return '/assets/agent-verification/invalid.png';
    default:
      return '/assets/agent-verification/default.png';
  }
};

export const getContentByState = (
  state: VerifyAgentState,
  content: ModalStatesContent,
) => {
  switch (state) {
    case 'success':
      return content?.success;
    case 'invalid':
      return content?.error;
    default:
      return content?.default;
  }
};
