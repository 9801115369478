'use client';

import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { IconButton } from '@purposeinplay/core-v2';
import ToggleSVG from 'public/assets/toggle.svg';
import React from 'react';

const ToggleButton = ({ ...props }) => {
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const dispatch = useAppDispatch();

  return (
    <IconButton
      onClick={() => {
        dispatch(setOpenSidemenu(!isSidemenuOpen));
      }}
      className="group hidden p-0 md:flex"
      ghost
      size="sm"
      iconOnly
      iconStyles="!w-7 !h-7"
      iconLeft={
        <ToggleSVG className={cn(['fill-current text-text-default'])} />
      }
      {...props}
    />
  );
};

export default ToggleButton;
