import {
  TournamentTopPlayer,
  PlayerNameCellProps,
} from '@/modules/tournaments/types';

export const PlayerNameCell = ({
  player,
  isYou,
  foundPlayerText,
}: PlayerNameCellProps) => (
  <span className="flex flex-row items-center space-x-2">
    <span className="flex flex-col">
      <span>{player?.nickname || 'Player'}</span>
    </span>
    {isYou && (
      <span className="rounded-lg bg-indigo-600/10 px-1.5 py-0.5 text-sm uppercase leading-4 text-indigo-600">
        {foundPlayerText}
      </span>
    )}
  </span>
);
