import { useMemo } from 'react';
import { useGetExchangeRatesPoints } from '@/hooks/use-get-exchange-rates-points';
import { useGetExchangeRatesFS } from '@/hooks/comp-points-exchange-rates';
import { RATES_FS_FIXED } from '../constants';
import { useFSRate } from './use-fs-rate';

export const usePointsToUpdate = (formData: any) => {
  const { fsRate, exchangeRatesPoints } = useFSRate();

  const getPointsToUpdateWith = useMemo(() => {
    return (value: number) => {
      if (formData.exchangeToWoF.WildWheelSpins) {
        return Number(value) * exchangeRatesPoints.wofWheel;
      }
      if (formData.exchangeToWoF.JungleWheelSpins) {
        return Number(value) * exchangeRatesPoints.jungleWheel;
      }
      if (formData.exchangeToWoF.VIPWheelSpins) {
        return Number(value) * exchangeRatesPoints.vipWheel;
      }
      if (formData.exchangeToFs) {
        return Number(value) * fsRate;
      }
      return 0;
    };
  }, [
    exchangeRatesPoints.jungleWheel,
    exchangeRatesPoints.vipWheel,
    exchangeRatesPoints.wofWheel,
    fsRate,
    formData.exchangeToWoF.JungleWheelSpins,
    formData.exchangeToWoF.VIPWheelSpins,
    formData.exchangeToWoF.WildWheelSpins,
    formData.exchangeToFs,
  ]);

  return { getPointsToUpdateWith, fsRate };
};
