import { GameSectionProps } from '../types';
import { useGameSection } from '../hooks/use-game-section';
import dynamic from 'next/dynamic';
import { GameList } from './game-list';
import { LoadMoreButton } from './load-more-button';

// Dynamically import components to prevent context errors
const GameSectionHeaderForSidebar = dynamic(
  () => import('./game-section-header-sidebar'),
  { ssr: false },
);

const GameSectionHeaderForBottomDrawer = dynamic(
  () => import('./game-section-header-drawer'),
  { ssr: false },
);

// Fallback header for loading states
const GameSectionHeaderFallback = dynamic(
  () => import('./game-section-header-fallback'),
  { ssr: false },
);

const GameSection = ({
  gameCat,
  forBottomDrawer = false,
  hasTitle = true,
}: GameSectionProps) => {
  const {
    games,
    visibleGames,
    isLoading,
    shouldShowLoadMore,
    handleLoadMore,
    totalGames,
    sectionTitle,
    isLoadingMore,
  } = useGameSection(gameCat, forBottomDrawer);

  return (
    <div className="flex flex-col gap-3 pb-2">
      {hasTitle &&
        (typeof window === 'undefined' ? (
          <GameSectionHeaderFallback
            totalGames={totalGames}
            title={sectionTitle}
            gameCat={gameCat}
          />
        ) : forBottomDrawer ? (
          <GameSectionHeaderForBottomDrawer
            forBottomDrawer
            totalGames={totalGames}
            title={sectionTitle}
            gameCat={gameCat}
          />
        ) : (
          <GameSectionHeaderForSidebar
            forBottomDrawer={false}
            totalGames={totalGames}
            title={sectionTitle}
            gameCat={gameCat}
          />
        ))}
      <GameList
        forBottomDrawer={forBottomDrawer}
        games={games}
        visibleGames={visibleGames}
        isLoading={isLoading}
        gameType={gameCat}
      />
      {!forBottomDrawer && (
        <LoadMoreButton
          onClick={handleLoadMore}
          isVisible={shouldShowLoadMore}
          isLoading={isLoadingMore}
          disabled={isLoadingMore}
        />
      )}
    </div>
  );
};

export default GameSection;
