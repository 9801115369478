'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import { IconButton } from '@purposeinplay/core-v2';

import { useTranslation } from '@/app/i18n/client';
import { useLogout } from '@/hooks/auth';
import { GeneralContentType } from '@/types/json-content/general';
import { cn } from '@/utils/style';
import { useAnalytics } from '@/hooks/use-analytics';
import LogoutSVG from 'public/assets/logout.svg';

const LogoutButton = ({
  className,
  closeDropdown,
}: {
  forAvatar?: boolean;
  className?: string;
  closeDropdown?: () => void;
}) => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const avatarContent = general?.navigation?.right_nav_auth?.avatar_dropdown;
  const router = useRouter();

  const { mutateAsync: logoutUser } = useLogout();

  const { track } = useAnalytics();

  const handleLogout = async () => {
    await Promise.all([
      track('signout_succeeded', {
        category: 'auth',
      }),
      logoutUser(),
      closeDropdown,
    ]);
    router.replace('/');
  };

  return (
    <div className={cn(['flex w-full items-center justify-center', className])}>
      <IconButton
        onClick={handleLogout}
        color="tertiary"
        size="sm"
        ghost
        className="max-w-max"
        iconLeft={<LogoutSVG />}
      >
        {avatarContent?.log_out}
      </IconButton>
    </div>
  );
};

export default LogoutButton;
