import React from 'react';
import { GamePageBottomDrawerProvider } from '../context/game-page-bottom-drawer-context';
import GamePageBottomNav from './game-page-bottom-nav';

export const GamePageBottomNavWrapper = () => {
  return (
    <GamePageBottomDrawerProvider>
      <GamePageBottomNav />
    </GamePageBottomDrawerProvider>
  );
};

export default GamePageBottomNavWrapper;
