import { useWindowSize } from 'usehooks-ts';
import { useMemo } from 'react';
import PercentageBar from '@/components/PercentageBar';
import { ProgressBar } from '../types';

export const useCardCountToLoad = ({ forModal = false } = {}) => {
  const { width } = useWindowSize();
  const cardCount = useMemo(() => {
    switch (true) {
      case width <= 640:
        return 4;
      case width <= 768:
        return 8;
      case width <= 1024:
        return 10;
      default:
        return forModal ? 6 : 21;
    }
  }, [forModal, width]);

  return cardCount;
};

export const calculatePercentageAndFooterContent = (
  loadedCount: number,
  totalCount: number,
  percentagebarContent: ProgressBar,
) => {
  const percentage = Math.min((loadedCount / totalCount) * 100, 100);
  const seenCount = totalCount <= loadedCount ? totalCount : loadedCount;

  return (
    totalCount > 0 && (
      <PercentageBar
        percentagebarContent={percentagebarContent}
        percentage={percentage}
        seenCount={seenCount}
        totalCount={totalCount}
      />
    )
  );
};
