import { IconButton, Text } from '@purposeinplay/core-v2';
import { Heading } from '@purposeinplay/core-v2';
import React from 'react';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';
import BackArrow from 'public/assets/back-arrow-long.svg';
import CloseSVG from 'public/assets/close.svg';
import { cn } from '@/utils/style';
import { useGamesContent } from '@/hooks/use-games-content';
import Link from 'next/link';
import AdaptiveImage from '@/components/AdaptiveImage';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const ContainerBodyLayout = ({
  title = '',
  children,
  onBack,
}: {
  title?: string;
  children: React.ReactNode;
  onBack?: () => void;
}) => {
  const { setIsOpen, shouldDisplayBackButton } = useGamePageSidebar();
  const { gamesContent } = useGamesContent();
  const navigation = gamesContent.game_sidebar.navigation;

  return (
    <div className="h-full">
      <div className="flex items-center justify-between">
        <div className="mb-3 flex items-center justify-between">
          <Link className="flex h-12 w-12 items-center justify-center" href="/">
            <AdaptiveImage
              alt="logo"
              width={26}
              priority
              height={33}
              fill={false}
              loader={DEFAULT_IMAGE_LOADER}
              sources={{
                desktop: {
                  dark: '/assets/logo-symbol-v2.svg',
                  light: '/assets/logo-symbol-v2.svg',
                },
              }}
            />
          </Link>
          {title && (
            <Text
              className={cn([
                'text-lg font-semibold',
                shouldDisplayBackButton && 'cursor-pointer',
              ])}
            >
              {shouldDisplayBackButton ? (
                <span
                  onClick={onBack}
                  className="flex flex-row items-center gap-1"
                >
                  <BackArrow className="h-5 w-5" />
                  <span>{navigation.back}</span>
                </span>
              ) : (
                title
              )}
            </Text>
          )}
        </div>
      </div>
      <div className="scrollbar  h-full pb-14 ">{children}</div>
    </div>
  );
};

export default ContainerBodyLayout;
