'use client';
import React, { useRef, useEffect } from 'react';
import EmptyChat from './empty-chat';
import ChatBubble from './chat-bubble';
import { useChat } from '@/app/providers/chat';
import { ChatBubbleLoader } from '@/components/Loaders';
import NewMessagesButton from './new-messages-button';
import { useChatScroll } from '../hooks/use-chat-scroll';
import { ChatTestButton } from './chat-test-button';
import { useTestChat } from '../hooks/use-test-chat';
import { useAppSelector } from '@/hooks/redux';

const ChatBody = () => {
  // UNCOMMENT FOR TESTING PURPOSES
  // const { messages, isLoading, addNewRandomMessage } = useTestChat();
  const { messages, isLoading } = useChat();
  const isChatOpen = useAppSelector((state) => state.bootstrap.isChatOpen);
  const prevIsChatOpenRef = useRef(isChatOpen);
  const { newMessagesCount, handleGoToBottom } = useChatScroll(messages);

  useEffect(() => {
    prevIsChatOpenRef.current = isChatOpen;
  }, [isChatOpen]);

  if (isLoading) {
    return Array.from({ length: 20 }).map((_, i) => (
      <ChatBubbleLoader key={i} />
    ));
  }

  if (messages.length === 0) {
    return <EmptyChat />;
  }

  const hasJustOpened = isChatOpen && !prevIsChatOpenRef.current;

  return (
    <div className="relative w-full [overflow-wrap:anywhere]">
      {/* UNCOMMENT FOR TESTING PURPOSES */}
      {/* <ChatTestButton className="fixed" onClick={() => addNewRandomMessage()} /> */}
      {/* Do not display on first render with 0 new messages */}
      {newMessagesCount > 0 && !hasJustOpened && (
        <NewMessagesButton
          onClick={handleGoToBottom}
          messageCount={newMessagesCount}
        />
      )}
      {messages?.map((message) => (
        <ChatBubble key={message.id} message={message} />
      ))}
    </div>
  );
};

export default ChatBody;
