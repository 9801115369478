import Timeleft from '@/components/Timeleft';
import useCountdown from '@/hooks/use-countdown';
import React from 'react';

const PopUpCountdown = ({ time }: { time: any }) => {
  const { state } = useCountdown({
    short: false,
    date: time as string,
    enabled: time !== null,
  });
  return (
    <Timeleft
      stylingOptions={{
        unit: 'bg-bgr-lightest text-text-default',
      }}
      extraSmall
      small
      invert={true}
      units={{
        days: state?.days,
        hours: state?.hours,
        minutes: state?.minutes,
        seconds: state?.seconds,
      }}
    />
  );
};

export default PopUpCountdown;
