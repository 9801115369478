import { useTranslation } from '@/app/i18n/client';
import { useCurrencies } from '@/hooks/currencies';
import { useFiatFromCents } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { getIcon } from '@/modules/feed/utils';
import { Currency } from '@/types/currency';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useMemo } from 'react';

const useNotificationDetails = (
  notification: any,
  type:
    | 'deposit'
    | 'withdraw'
    | 'level'
    | 'freeSpins'
    | 'bonus'
    | 'lootboxes'
    | 'jackpot'
    | 'empty',
) => {
  const { viewFiat } = useAppSelector((state) => state.bootstrap);

  const fiatAmount = useFiatFromCents(
    notification?.currency,
    notification?.amount_cents,
  );

  const icon = useMemo(() => getIcon(type), [type]);
  const { t } = useTranslation();
  const notifContent = t('notifications') as NotificationsContentType;
  const content = notifContent.list_notification_messages;

  const titleProps = useMemo(() => {
    return {
      type,
      message: notification?.freespins_total
        ? notification?.freespins_total
        : viewFiat
          ? fiatAmount
          : notification.amount_cents,
      currency: notification?.currency,
      info: notification?.name,
      viewFiat,
      content,
    };
  }, [
    content,
    fiatAmount,
    notification.amount_cents,
    notification?.currency,
    notification?.freespins_total,
    notification?.name,
    type,
    viewFiat,
  ]);

  return {
    titleProps,
    icon,
  };
};

export default useNotificationDetails;
