import React from 'react';
import Wheel from './wheel.svg';
import Indicator from './indicator.svg';

const WheelIcon = () => {
  return (
    <div className="relative h-6 w-6">
      <Wheel className="animate-infinite-rotate absolute inset-0" />
      <Indicator className="absolute -top-0.5 left-0 right-0 mx-auto" />
    </div>
  );
};

export default WheelIcon;
