import { useTranslation } from '@/app/i18n/client';
import { SettingsContentType } from '@/types/json-content/settings';
import { Alert } from '@purposeinplay/core-v2';
import React from 'react';

interface Props {
  addSessionLimit: any;
  addLimitLoading: boolean;
  isOpen: boolean;
  setIsOpen: any;
  minutes: string;
}

const ConfirmationSession = ({
  addSessionLimit,
  addLimitLoading,
  isOpen,
  setIsOpen,
  minutes,
}: Props) => {
  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const content = contentSettings?.responsible_gambling?.confirm_limits;
  return (
    <Alert
      title={content?.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      primaryButtonOnClick={async () => {
        try {
          await addSessionLimit({
            user_limit: {
              period: Number(minutes),
              type: 'session',
            },
          });
        } catch (e) {
          setIsOpen(false);
          throw e;
        }
      }}
      content={`${content?.description?.sessions} ${minutes} ${content?.description?.sessions_2}?`}
      primaryButtonLabel={content?.primary_btn_label}
      secondaryButtonLabel={content?.secondary_btn_label}
      isLoading={addLimitLoading}
    />
  );
};

export default ConfirmationSession;
