import React from 'react';
import FeedActions from './feed-actions';
import { Heading } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';

const NotificationHeader = ({ loading = false }) => {
  const { t } = useTranslation();
  const content = t('notifications') as NotificationsContentType;

  return (
    <div className="-mt-3 flex items-center justify-between border-b border-border-general-subdued p-3">
      <Heading className="text-base font-medium" as="h2">
        {content?.dropdown?.title}
      </Heading>
      <FeedActions loading={loading} />
    </div>
  );
};

export default NotificationHeader;
