import { Text } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { WheelContentType } from '@/types/json-content/wheel';
import { WofButtonProps } from '../types';
import { WofControls } from './wof-controls';

const WofButton = ({
  lootboxesFiltered,
  hasLootbox,
  isWheelAnimating,
  setMakeCall,
  hasAvailableIn,
  lootboxesLoading,
}: WofButtonProps) => {
  const { t } = useTranslation();
  const wheelContent = t('wheel') as WheelContentType;
  return (
    <div className="mb-spacing-lg mt-20">
      {lootboxesFiltered?.length > 0 ? (
        <WofControls
          lootboxesLoading={lootboxesLoading}
          hasAvailableIn={hasAvailableIn}
          lootboxesFiltered={lootboxesFiltered}
          hasLootbox={hasLootbox}
          isWheelAnimating={isWheelAnimating}
          setMakeCall={setMakeCall}
        />
      ) : (
        <Text
          size="xl"
          className="text-center font-semibold leading-7 text-text-default"
        >
          {wheelContent?.action?.not_today}
        </Text>
      )}
    </div>
  );
};

export default WofButton;
