import { Tournament } from '../types';
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from '@/utils/dayjsconfig';
import { useQueryClient } from '@tanstack/react-query';

export const useTournamentStatus = (tournament: Tournament) => {
  const [now, setNow] = useState(dayjs());
  const queryClient = useQueryClient();

  const tournamentEnded = useMemo(() => {
    const endAt = dayjs(tournament?.end_at);
    return endAt.diff(now) < 0;
  }, [tournament?.end_at, now]);

  const inTheFuture = useMemo(() => {
    const endAt = dayjs(tournament?.start_at);
    return now.diff(endAt) < 0;
  }, [tournament?.start_at, now]);

  const prevTournamentEnded = useRef(tournamentEnded);
  const prevInTheFuture = useRef(inTheFuture);

  // Update "now" every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  // This will trigger a refetch of the tournaments list so that
  // the user not sees the negative countdown
  useEffect(() => {
    // Check if the state has changed from the previous to the current
    if (
      (tournamentEnded !== prevTournamentEnded.current ||
        inTheFuture !== prevInTheFuture.current) &&
      (tournamentEnded || inTheFuture)
    ) {
      queryClient.invalidateQueries({ queryKey: ['tournamentsAPI'] });
    }

    prevTournamentEnded.current = tournamentEnded;
    prevInTheFuture.current = inTheFuture;
  }, [tournamentEnded, inTheFuture, queryClient]);

  return {
    tournamentEnded,
    inTheFuture,
  };
};
