import useDarkMode from '@/hooks/dark';
import Image from 'next/image';
import React from 'react';

const AwardIcon = ({ svgName, alt }: { svgName?: string; alt?: string }) => {
  const { darkMode } = useDarkMode();
  const src = `/assets/awards/${svgName}${!darkMode ? '-light' : ''}.svg`;
  return (
    <Image
      className="shrink-0"
      width={100}
      height={40}
      src={src}
      alt={alt || 'Award Icon'}
    />
  );
};

export default AwardIcon;
