import { useTranslation } from '@/app/i18n/client';
import { InputLoader, TextLoader } from '@/components/Loaders';
import { useAuth } from '@/hooks/auth';
import { useCountries } from '@/hooks/others';
import { SettingsContentType } from '@/types/json-content/settings';
import {
  Button,
  Combobox,
  DatePicker,
  DatePickerV2,
  ErrorMessage,
  Field,
  Input,
  Label,
  Modal,
} from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import { useMemo, useState } from 'react';
import { formatDate } from '../utils';
import { useAppDispatch } from '@/hooks/redux';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { GeneralContentType } from '@/types/json-content/general';
import { setOpenTriggerModal } from '@/app/reducers/bootstrap';
import { useUpdateProfile } from '@/hooks/profile';
import { handleError } from '@/utils/helpers';
import { UseMutationResult } from '@tanstack/react-query';
import dayjs from 'dayjs';

const FillProfileModal: React.FC<{
  closeModal?: () => void;
}> = (): JSX.Element => {
  const { close } = useModal();

  const {
    mutateAsync: updateProfile,
    isPending: updateProfileLoading,
    error,
    isError,
  } = useUpdateProfile() as UseMutationResult<any, any, any, unknown>;

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    country: '',
    date_of_birth: '',
  });
  const {
    user: { currentUser = {}, refetch, isFetching, isLoading: isUserLoading },
  }: any = useAuth();

  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const currentModal = useCurrentModal();

  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const fillProfileContent = contentSettings?.fill_profile_modal?.form;
  const general = t('general') as GeneralContentType;
  const pageTriggerContent = general?.page_triggers;

  const { data: countries = [], isLoading: countriesLoading }: any =
    useCountries();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    const target = e.target;
    setFormData({ ...formData, [type]: target.value });
  };

  const formattedCountries = useMemo(() => {
    return (
      countries &&
      countries.map((country: any) => ({
        id: country?.code,
        value: country?.name,
        name: country?.name,
      }))
    );
  }, [countries]);

  const defaultCountry = useMemo(
    () =>
      formattedCountries?.find(
        (country: { id: string; component: any }) =>
          country.id === currentUser?.country,
      ),
    [formattedCountries, currentUser?.country],
  );

  const selectedIndex = useMemo(() => {
    return currentUser?.country != null && currentUser?.country != ''
      ? formattedCountries?.findIndex(
          (curr: { id: string; name: string }) =>
            curr.id === currentUser?.country,
        )
      : null;
  }, [currentUser?.country, formattedCountries]);

  const countriesSelect = useMemo(() => {
    return countriesLoading ||
      formattedCountries.length == 0 ||
      isFetching ||
      isUserLoading ? (
      <InputLoader className="w-full" />
    ) : (
      <Combobox
        label={fillProfileContent?.country?.label}
        placeholder={fillProfileContent?.country?.placeholder}
        selectedIndex={selectedIndex}
        items={formattedCountries}
        disabled={defaultCountry ? true : false}
        onChange={(item: any) =>
          setFormData((formData: any) => ({
            ...formData,
            country: item.id,
          }))
        }
      />
    );
  }, [
    countriesLoading,
    formattedCountries,
    isFetching,
    isUserLoading,
    fillProfileContent?.country?.label,
    fillProfileContent?.country?.placeholder,
    selectedIndex,
    defaultCountry,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formData?.first_name === '' || formData?.last_name === '') {
      toast({
        state: 'error',
        title: contentSettings?.fill_profile_modal?.toasts?.no_fill,
      });
      return;
    }
    updateProfile({
      // @ts-ignore
      context: 'edition',
      player: {
        ...formData,
      },
    }).then((res) => {
      toast({
        state: 'success',
        title: contentSettings?.fill_profile_modal?.toasts?.success,
      });
      refetch();
      close();
      dispatch(setOpenTriggerModal(undefined));
    });
  };

  const handleCloseModal = () => {
    close();
    dispatch(setOpenTriggerModal(undefined));
  };

  const isFetchingOrLoading = isFetching || isUserLoading || countriesLoading;

  return (
    <Modal
      modalStylingOptions={{
        containerStyles: 'overflow-visible',
      }}
      setIsOpen={handleCloseModal}
      isOpen={currentModal === 'fill-profile'}
      title={pageTriggerContent?.modal?.fill_profile}
    >
      {/* TO DO put everything under form when kit is updated */}
      {/* Issue right now is that the buttons from datepicker submit form */}
      <form className="mb-4 flex flex-col space-y-4" onSubmit={handleSubmit}>
        <Input
          variant="static"
          id="first-name"
          type="text"
          label={fillProfileContent?.first_name?.label}
          value={formData.first_name}
          onChange={(e) => handleChange(e, 'first_name')}
          placeholder={fillProfileContent?.first_name?.placeholder}
          required
        />

        <Input
          variant="static"
          id="last-name"
          type="text"
          label={fillProfileContent?.last_name?.label}
          value={formData.last_name}
          onChange={(e) => handleChange(e, 'last_name')}
          placeholder={fillProfileContent?.last_name?.placeholder}
          required
        />
        <div>{countriesSelect}</div>
        {isFetchingOrLoading ? (
          <InputLoader />
        ) : (
          <DatePickerV2
            label={fillProfileContent?.date_picker?.dob}
            onChange={(date) => {
              const formattedDate =
                date instanceof Date ? dayjs(date).format('YYYY-MM-DD') : date;
              setFormData({
                ...formData,
                date_of_birth: formattedDate,
              });
            }}
            defaultSelected={
              currentUser?.date_of_birth
                ? new Date(currentUser?.date_of_birth)
                : undefined
            }
            error={isError && handleError(error?.errors, 'date_of_birth')}
            errorMessage={
              isError && handleError(error?.errors, 'date_of_birth')
            }
            disabled={currentUser?.date_of_birth}
            id="date-picker"
          />
        )}

        <Button
          onClick={handleSubmit}
          size="md"
          type="submit"
          isLoading={updateProfileLoading}
          disabled={updateProfileLoading}
        >
          {fillProfileContent?.action?.btn_label}
        </Button>
      </form>
    </Modal>
  );
};

export default FillProfileModal;
