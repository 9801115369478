import { QueryClient } from '@tanstack/react-query';
import client from './api';
import { TournamentsDocument } from '@/generated';
import prefetchContent from './content-api';

export function removeLastDashAndSaveSuffix(inputString: string) {
  const lastDashIndex = inputString.lastIndexOf('-');
  if (lastDashIndex !== -1) {
    const removedString = inputString.substring(0, lastDashIndex);
    const suffix = inputString.substring(lastDashIndex + 1);
    return { removedString, suffix };
  } else {
    return { removedString: inputString, suffix: '' };
  }
}

export async function fetchTournaments() {
  const tournaments = await client('tournaments');
  const recents = await client('tournaments/recent');
  return [...tournaments, ...recents];
}

export async function fetchTournamentsCMS(queryClient: QueryClient) {
  return prefetchContent('tournaments', TournamentsDocument, queryClient);
}

export async function fetchTournamentDetails(params: { tournament: string }) {
  const queryClient = new QueryClient();
  const { removedString, suffix } = removeLastDashAndSaveSuffix(
    params.tournament,
  );
  const allTournaments = await fetchTournaments();
  const foundTournament = allTournaments.find(
    (tournament) => tournament?.id === Number(suffix),
  );
  const tournamentsCMS = await fetchTournamentsCMS(queryClient);
  const tournamentCMS = tournamentsCMS?.tournaments?.data?.find(
    (tournament: any) => tournament.attributes.uid === removedString,
  );
  return { tournamentCMS, removedString, suffix, foundTournament };
}

export async function fetchTournamentIdDetails(params: { tournament: string }) {
  const { removedString, suffix } = removeLastDashAndSaveSuffix(
    params.tournament,
  );
  const fetchedTournament = await client(`tournaments/${suffix}`);

  return { removedString, suffix, tournament: fetchedTournament };
}
