import useModal, { useCurrentModal } from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import React from 'react';
import WalletSettings from './WalletSettings';
import { useGetWalletSettingsContent } from '../WalletModal/hooks/use-get-wallet-content';

const WalletSettingsModal = () => {
  const { close } = useModal();
  const isOpen = useCurrentModal() === 'wallet-settings';

  const walletContent = useGetWalletSettingsContent();

  return (
    <Modal
      title={walletContent?.title}
      isOpen={isOpen}
      setIsOpen={() => close()}
      size="2xl"
    >
      <WalletSettings />
    </Modal>
  );
};

export default WalletSettingsModal;
