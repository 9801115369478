import useModal, { useCurrentModal } from '@/hooks/modal';
import { Button, Modal } from '@purposeinplay/core-v2';
import React from 'react';
import AvatarModalBody from './components/avatar-modal-body';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { useUpdateAvatar } from '@/hooks/avatar';
import { useAppSelector } from '@/hooks/redux';

const AvatarModal = () => {
  const { close } = useModal();
  const isOpen = useCurrentModal() === 'avatar';
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const avatarModalContent =
    general?.navigation?.right_nav_auth?.avatar_dropdown?.avatar_modal;

  const { mutateAsync: updateAvatar, isPending } = useUpdateAvatar();

  const selectedAvatar = useAppSelector(
    (state) => state.bootstrap.selectedAvatar,
  );

  return (
    <Modal
      title={avatarModalContent?.title}
      isOpen={isOpen}
      size="lg"
      setIsOpen={() => close()}
      footer={
        <Button
          className="w-full"
          isLoading={isPending}
          disabled={isPending}
          onClick={() => {
            updateAvatar({
              avatar: selectedAvatar,
            });
            close();
          }}
        >
          {avatarModalContent?.btn_text}
        </Button>
      }
    >
      <AvatarModalBody />
    </Modal>
  );
};

export default AvatarModal;
