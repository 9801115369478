import { useAppSelector } from '@/hooks/redux';
import { usePathname } from 'next/navigation';
import React from 'react';
import { CLOSED_PATHS } from '../constants';

const useShouldHideIFrame = () => {
  const pathname = usePathname();
  const bootstrap = useAppSelector((state) => state.bootstrap);
  const { isMinimized, gameViewOpen } = bootstrap;
  const gameView = bootstrap.gameViews?.[0];

  const shouldHide =
    !isMinimized &&
    (CLOSED_PATHS.includes(pathname) || !pathname.includes('/games/'));

  return { shouldHide, isMinimized };
};

export default useShouldHideIFrame;
