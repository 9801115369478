import React from 'react';
import { useGetWalletSettingsContent } from '../../WalletModal/hooks/use-get-wallet-content';
import { Button, Heading, Text } from '@purposeinplay/core-v2';
import CurrencyRadios from './currency-radios';
import WalletSettingsSwitches from './wallet-settings-switches';
import useModal from '@/hooks/modal';

const WalletSettings = () => {
  const walletContent = useGetWalletSettingsContent();
  const { close } = useModal();
  return (
    <div className="flex flex-col space-y-7">
      <div>
        <Heading
          as="h1"
          className="mb-1 text-sm font-semibold text-text-subdued"
        >
          {walletContent?.header}
        </Heading>
        {/* Uncomment for wallet settings description */}
        {/* <Text className="text-sm font-normal text-text-subdued">
          {walletContent?.description}
        </Text>{' '} */}
      </div>
      <CurrencyRadios />
      <WalletSettingsSwitches />
    </div>
  );
};

export default WalletSettings;
