import AvatarCard from '@/modules/layout/navbar/components/avatar-card';
import { useAuth } from '@/hooks/auth';
import { cn } from '@/utils/style';
import React from 'react';
import ActionLinks from './action-links';
import { useLoyalty } from '@/hooks/loyalty';
import { TextLoader } from '@/components/Loaders';
import { useAppSelector } from '@/hooks/redux';
import { ActionLink } from '../types';
import useSidemenuItems from '../hooks/use-sidemenu-items';
import NarrowProgress from './narrow-progress';
import { useIsMounted } from '@purposeinplay/utils';

const FeaturedSidebarList: React.FC = () => {
  const { user } = useAuth();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();
  const { isLoading } = useLoyalty();
  const { items } = useSidemenuItems();

  const featuredList = items?.find((item) => item?.type === 'featured_section')
    ?.items as unknown as ActionLink[];

  return (
    <div
      className={cn([
        'px-1.5 py-2 xl:px-0 xl:py-0',
        !isSidemenuOpen && isMounted && 'md:!pb-0 xl:!px-1.5 xl:!py-2 xl:!pb-0',
        isSidemenuOpen && isMounted && 'px-0 py-0',
      ])}
    >
      <div
        className={cn([
          'rounded-lg p-1 md:mb-3 md:bg-bgr-light xl:rounded-none xl:p-3 xl:px-3',
          !isSidemenuOpen && isMounted && 'rounded-lg !p-1 xl:rounded-lg',
          isSidemenuOpen && isMounted && 'rounded-none p-3 !pb-0 md:!pb-3',
        ])}
      >
        {/* {user?.isAuthenticated && (
          <>
            <div className="mb-3 list-none md:-mx-3 md:hidden md:px-3">
              <AvatarCard />
            </div>

            {isLoading ? (
              <TextLoader className="mb-2.5 h-7 lg:w-full" />
            ) : (
              <NarrowProgress />
            )}
          </>
        )} */}

        <ActionLinks links={featuredList} />
      </div>
    </div>
  );
};

export default FeaturedSidebarList;
