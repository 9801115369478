import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import TournamentCardButtons from './tournament-card-buttons';
import { Tournament } from '@/types/tournaments';
import { Icon } from './helpers';
import { TournamentsContentType } from '@/types/json-content/tournaments';
import { useTranslation } from '@/app/i18n/client';
import useCardButtonsState from '../hooks/use-card-buttons-state';
import { cn } from '@/utils/style';

const EnrollBanner = ({
  tournament,
  isForSidebar = false,
}: {
  isForSidebar?: boolean;
  tournament: Tournament;
}) => {
  const { t } = useTranslation();
  const tournamentContent = t('tournaments') as TournamentsContentType;
  const { isAlreadyEnrolled } = useCardButtonsState(tournament);

  const bannerText = isAlreadyEnrolled
    ? tournamentContent?.enroll_banner?.enrolled
    : tournamentContent?.enroll_banner?.enroll;

  return (
    <div
      className={cn([
        'mb-4 flex flex-col gap-4 rounded-lg bg-bgr-modal p-4 sm:flex-row sm:items-center sm:justify-between md:mb-7',
        isForSidebar &&
          'mb-0  sm:flex-col sm:items-center sm:justify-between md:mb-0',
      ])}
    >
      <div className="flex flex-row items-center space-x-2">
        <Icon className="shrink-0" name={'enrolled'} size={16} />
        <Text size="sm" className="leading-5 text-icons-default">
          {bannerText}
        </Text>
      </div>
      <div
        className={cn([
          'flex w-full items-center justify-between sm:w-auto',
          isForSidebar && 'sm:w-full',
        ])}
      >
        <TournamentCardButtons
          isForSidebar={isForSidebar}
          forDynamicRoute
          tournament={tournament}
        />
      </div>
    </div>
  );
};

export default EnrollBanner;
