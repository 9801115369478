'use client';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { Button, Modal } from '@purposeinplay/core-v2';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import { getContainerStyles } from './utils';
import { useTranslation } from '@/app/i18n/client';
import { WheelContentType } from '@/types/json-content/wheel';
import Link from 'next/link';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import './styles.css';

const PrizeModal = () => {
  const { open, close } = useModal();
  const isOpen = useCurrentModal() === 'prize';

  const searchParams = useSearchParams();

  const { t } = useTranslation();
  const wheelContent = t('wheel') as WheelContentType;

  const prize = searchParams.get('p');
  const type = searchParams.get('t') || 'common';

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      setIsOpen={() => close()}
      modalStylingOptions={{
        containerStyles: getContainerStyles(type) + 'pt-12',
        closeBtnContainerStyles:
          '[&>span>svg]:fill-current [&>span>svg]:text-white [&>span>svg]:hover:text-gray-300',
      }}
    >
      <div className="relative z-20 flex flex-col items-center">
        <Image
          alt="Coin Prize Image"
          width={512}
          height={183}
          src="/assets/wheel/prize-modal-coins-2.png"
          className="absolute inset-0 z-10 object-fill"
          loader={DEFAULT_IMAGE_LOADER}
        />
        <Image
          alt="Prize Modal Image Svg"
          width={72}
          height={72}
          src="/assets/wheel/prize-modal-icon.svg"
          className="mb-2"
          loader={DEFAULT_IMAGE_LOADER}
        />
        <span className="mb-4 text-lg leading-7 text-text-default">
          {wheelContent?.prize_modal?.message}
        </span>
        <span className="mb-7 rounded-xl border border-dashed border-base-warning px-4 py-4 text-2xl font-bold leading-8 text-text-warning">
          {`${(prize as string)?.replace(/(WoF|VIP|Jungle)\s?Wheel/g, '')}`}
        </span>
        <Link href="/bonuses">
          <Button>{wheelContent?.prize_modal?.action?.btn_label}</Button>
        </Link>
      </div>
    </Modal>
  );
};

export default PrizeModal;
