import { useTranslation } from '@/app/i18n/client';
import client from '@/utils/api';
import { GENERAL_ERROR, INVALIDATE_GLOBAL_QUERIES } from '@/utils/constants';
import { Snackbar } from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

export const useExchangeCompPoints = () => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['exchangeCompPoints'],
    mutationFn: (data: any) => {
      const {
        currency,
        points,
        exchangeToFS,
        exchangeToWoF,
        exchangeToCrypto,
      } = data;
      try {
        const getGroup = () => {
          if (exchangeToCrypto) return 'default';
          if (exchangeToFS) return 'fs_on_compoints';
          if (exchangeToWoF.WildWheelSpins) return 'wof_regular_exchange';
          if (exchangeToWoF.JungleWheelSpins) return 'wof_jungle_exchange';
          if (exchangeToWoF.VIPWheelSpins) return 'wof_vip_exchange';
        };
        const getURL = () => {
          const exchangeToLootboxes =
            exchangeToWoF.WildWheelSpins ||
            exchangeToWoF.JungleWheelSpins ||
            exchangeToWoF.VIPWheelSpins;
          if (exchangeToCrypto) return 'comp_points/exchange/money';
          if (exchangeToFS) return 'comp_points/exchange/freespins';
          if (exchangeToLootboxes) return 'comp_points/exchange/lootboxes';
        };
        const group = getGroup();
        const url = getURL();

        if (group === undefined || url === undefined) {
          throw new Error('Group or URL is undefined.');
        }

        const payload = {
          exchange: {
            points: Number(points),
            currency: currency,
            group: group,
          },
        };

        return client(url, payload, 'POST');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.points_exchanged,
      });
      INVALIDATE_GLOBAL_QUERIES.map((query) => {
        return queryClient.invalidateQueries({ queryKey: [query] });
      });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};
export const useExchangeCompPointsToFS = () => {
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['exchangeCompPointsToFs'],
    mutationFn: (data: any) => {
      const { currency, points, group } = data;
      try {
        const url = 'comp_points/exchange/freespins';

        if (group === undefined || url === undefined) {
          throw new Error('Group or URL is undefined.');
        }

        const payload = {
          exchange: {
            points: Number(points),
            currency: currency,
            group: group,
          },
        };

        return client(url, payload, 'POST');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.points_exchanged,
      });
      INVALIDATE_GLOBAL_QUERIES.map((query) => {
        return queryClient.invalidateQueries({ queryKey: [query] });
      });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};
