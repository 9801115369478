import { useAppSelector } from '@/hooks/redux';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { useIsMounted } from '@purposeinplay/utils';
import Image from 'next/image';
import React from 'react';

type Props = {
  icon: string;
  imageProps?: any;
  forGamesPage?: boolean;
  iconContainerStyles?: string;
};

const ItemIcon: React.FC<Props> = ({
  iconContainerStyles,
  icon,
  imageProps = {},
  forGamesPage,
}) => {
  const isMounted = useIsMounted();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );

  return (
    <span
      className={cn([
        'relative mx-auto flex h-6 min-w-6 items-center justify-center',
        !forGamesPage && 'xl:mx-0',
        !isSidemenuOpen && isMounted && !forGamesPage && 'xl:mx-auto',
        isSidemenuOpen && isMounted && !forGamesPage && 'mx-0',
        iconContainerStyles,
      ])}
    >
      <Image
        // TO DO REMOVE THIS
        loader={DEFAULT_IMAGE_LOADER}
        alt={`Sidebar icon`}
        loading="eager"
        className="object-contain"
        fill
        src={icon}
        {...imageProps}
      />
    </span>
  );
};

export default ItemIcon;
