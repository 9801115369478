import React from 'react';
import AvatarOptions from './avatar-options';

const AvatarModalBody = () => {
  return (
    <div className="mx-auto flex flex-col items-center">
      <AvatarOptions />
    </div>
  );
};

export default AvatarModalBody;
