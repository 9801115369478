import React, { useEffect, useState } from 'react';
import MoreItem from './more-item';
import { MoreTabProvider } from '../context/more-tab-context';
import { useTranslation } from '@/app/i18n/client';
import JackpotDetailView from './jackpot-detail-view';
import useSeoGameByTitle from '@/modules/games/hooks/use-seo-game-by-title';
import { cn } from '@/utils/style';
import MoreListView from './more-list-view';
import { useDispatch } from 'react-redux';
import { setOpenChat } from '@/app/reducers/bootstrap';
import { useChat } from '@/services/live-chat/use-chat';
import { useGamePageBottomDrawer } from '../context/game-page-bottom-drawer-context';
import { useAuth } from '@/hooks/auth';

const MORE = [
  {
    id: 'favorites',
    text: 'Add to Favorites',
  },
  {
    id: 'mode',
    text: 'Game Mode',
  },
  {
    id: 'jackpot',
    text: 'Jackpot',
  },
  {
    id: 'chat',
    text: 'Community Chat',
  },
  {
    id: 'support',
    text: 'Support',
  },
];

const MoreContainer = () => {
  const { t } = useTranslation();
  const { game } = useSeoGameByTitle();
  const dispatch = useDispatch();
  const [_, loadChat] = useChat({ loadWhenIdle: false });
  const { setIsOpen } = useGamePageBottomDrawer();
  const {
    user: { isAuthenticated },
  } = useAuth();
  const [showJackpotDetail, setShowJackpotDetail] = useState(false);

  // Handle chat and support clicks
  const handleItemAction = (id: string) => {
    if (id === 'chat') {
      setIsOpen(false);
      dispatch(setOpenChat(true));
    } else if (id === 'support') {
      setIsOpen(false);
      loadChat({ open: true });
    }
  };

  // Handle jackpot detail view
  const showDetail = () => {
    setShowJackpotDetail(true);
    window.dispatchEvent(new CustomEvent('showJackpotDetail'));
  };

  const hideDetail = () => {
    setShowJackpotDetail(false);
  };

  // Listen for custom events from the AnimatedDrawer
  useEffect(() => {
    const handleHideJackpotDetail = () => {
      hideDetail();
    };

    window.addEventListener('hideJackpotDetail', handleHideJackpotDetail);

    return () => {
      window.removeEventListener('hideJackpotDetail', handleHideJackpotDetail);
    };
  }, []);

  return (
    <MoreTabProvider>
      <div className="relative overflow-hidden">
        {showJackpotDetail ? (
          <JackpotDetailView onBack={hideDetail} />
        ) : (
          <MoreListView
            items={MORE.filter((item) => {
              // Only show favorites if user is authenticated
              if (item.id === 'favorites' && !isAuthenticated) {
                return false;
              }

              // Only show jackpot if the game has a jackpot
              if (
                item.id === 'jackpot' &&
                !game?.categories?.some((category: string) =>
                  category.toLowerCase().includes('jackpot'),
                )
              ) {
                return false;
              }
              return true;
            })}
            onShowJackpotDetail={showDetail}
            onItemAction={handleItemAction}
          />
        )}
      </div>
    </MoreTabProvider>
  );
};

export default MoreContainer;
