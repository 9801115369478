import { useTournamentsQuery } from '@/generated';
import { useAuth } from '@/hooks/auth';
import {
  useRecentTournaments,
  useTournaments,
} from '@/hooks/tournaments/tournaments';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { useMemo } from 'react';
import dayjs from '@/utils/dayjsconfig';

const useTournamentsState = () => {
  const { user } = useAuth();

  const { data: tournaments = [], isFetching: areTournamentsFetching } =
    useTournaments();

  const playerGroupIds = user?.currentUser?.statuses?.map(
    (group: { id: string }) => group.id,
  );

  const { data: tournamentsCMS } = useTournamentsQuery(undefined, {
    staleTime: DEFAULT_CONTENT_STALE_TIME,
  });

  const { data: recentTournaments, isLoading: recentTournamentsLoading } =
    useRecentTournaments();

  const now = dayjs();

  const tournamentsList = Array.from(tournaments);

  const tournamentsListIds = Array.from(tournaments)?.map(
    (tournament) => tournament?.frontend_identifier,
  );

  const visibleTournamentsIds = useMemo(() => {
    return tournamentsCMS?.tournaments?.data
      .filter(
        (content) =>
          content?.attributes?.uid &&
          tournamentsListIds?.includes(content?.attributes?.uid),
      )
      ?.filter((matchingTournament) => {
        const matchingTournamentIds =
          matchingTournament?.attributes?.group_ids?.map(
            (group) => group?.group_id,
          );
        if (matchingTournament?.attributes?.group_ids?.length === 0) {
          return matchingTournament;
        } else {
          return playerGroupIds?.some((group: string) =>
            matchingTournamentIds?.includes(group),
          );
        }
      })
      ?.map((tournament) => tournament?.attributes?.uid);
  }, [playerGroupIds, tournamentsCMS?.tournaments?.data, tournamentsListIds]);

  const filteredTournaments = useMemo(() => {
    return tournamentsList?.filter((tournament) =>
      visibleTournamentsIds?.includes(tournament?.frontend_identifier),
    );
  }, [tournamentsList, visibleTournamentsIds]);

  const upcomingTournaments = filteredTournaments.filter(
    (tournament) =>
      dayjs(tournament.start_at).isAfter(now) &&
      dayjs(tournament.end_at).isAfter(now),
  );

  const activeTournaments = filteredTournaments.filter(
    (tournament) =>
      dayjs(tournament.start_at).isBefore(now) &&
      dayjs(tournament.end_at).isAfter(now),
  );

  const expiredTournaments = useMemo(() => {
    return (
      recentTournaments &&
      recentTournaments
        .filter(
          (tournament) =>
            tournament.finished_at &&
            dayjs(tournament.finished_at).isBefore(dayjs()),
        )
        .sort((a, b) => dayjs(b.finished_at).diff(dayjs(a.finished_at)))
        .slice(0, 9)
    );
  }, [recentTournaments]);

  return {
    areTournamentsFetching,
    expiredTournaments: expiredTournaments || [],
    upcomingTournaments: upcomingTournaments || [],
    activeTournaments: activeTournaments || [],
    tournamentsCMS,
    recentTournamentsLoading,
  };
};

export default useTournamentsState;
