export const EXCHANGE_MAPPING = {
  'Free Spins': {
    exchangeToFS: true,
    exchangeToCrypto: false,
    exchangeToWoF: {
      WildWheelSpins: false,
      JungleWheelSpins: false,
      VIPWheelSpins: false,
    },
  },
  Crypto: {
    exchangeToFS: false,
    exchangeToCrypto: true,
    exchangeToWoF: {
      WildWheelSpins: false,
      JungleWheelSpins: false,
      VIPWheelSpins: false,
    },
  },
  'WoF Spins': {
    exchangeToFS: false,
    exchangeToCrypto: false,
    exchangeToWoF: {
      WildWheelSpins: true,
      JungleWheelSpins: false,
      VIPWheelSpins: false,
    },
  },
};

export const WILD_POINT_TABS = [
  {
    id: 'wof',
    label: 'WoF Spins',
    active: true,
  },
  {
    id: 'fs',
    label: 'Free Spins',
    active: false,
  },
  {
    id: 'crypto',
    label: 'Crypto',
    active: false,
  },
];

export const SELECT_ITEMS = [
  {
    id: 'wof',
    component: <div>Common Wheel Spins</div>,
  },
  {
    id: 'jungle',
    component: <div>Rare Wheel Spins</div>,
  },
  {
    id: 'vip',
    component: <div>Epic Wheel Spins</div>,
  },
];

export const RATES_FS_FIXED = {
  freespins_count: 1,
  points: 100,
};
