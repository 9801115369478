'use client';

'use client';

// React/Next.js imports
import React, { useMemo } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

// Third-party libraries
import { Tooltip } from '@purposeinplay/core-v2';
import { useIsMounted } from '@purposeinplay/utils';

import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import AdaptiveImage from '@/components/AdaptiveImage';
import { TextLoader } from '@/components/Loaders';
import { sidebarBaseItemStyles } from '@/modules/layout/sidebar/styles';
import ItemIcon from '@/modules/layout/sidebar/components/item-icon';
import ElementsContainer from '@/modules/layout/sidebar/components/elements-container';
import useIsCurrentMap from '@/modules/wild-map/hooks/use-is-current-map';

const FeaturedSidebarBanner: React.FC = () => {
  const { currentMap, activeMapDetails } = useIsCurrentMap();
  const { title } = activeMapDetails || {};

  const dispatch = useAppDispatch();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();

  const pathname = usePathname();
  const isActive = pathname?.includes(currentMap?.attributes?.path ?? '');
  const itemProps = useMemo(
    () => ({
      className: cn([
        sidebarBaseItemStyles,
        isActive && 'bg-surface-hovered',
        !isSidemenuOpen &&
          isMounted &&
          'xl:flex xl:justify-center xl:w-full xl:gap-0 xl:items-center xl:p-0',
        isSidemenuOpen && isMounted && 'justify-start flex gap-2 p-spacing-xs',
      ]),
    }),
    [isActive, isSidemenuOpen, isMounted],
  );
  const { matchesQuery: isMobile, isLoading } =
    useMediaQuery(MOBILE_BREAKPOINT);

  const hasValidImageSources = !!(
    activeMapDetails?.mapBannerDesktop || activeMapDetails?.mapBannerMobile
  );

  const triggerContent = useMemo(() => {
    return isSidemenuOpen ? (
      <Link
        href={currentMap?.attributes?.path ?? ''}
        onClick={() => isMobile && dispatch(setOpenSidemenu(false))}
      >
        <div className="relative h-11 w-full rounded-md lg:h-12">
          {hasValidImageSources ? (
            <AdaptiveImage
              alt="Banner Img"
              className="rounded-md object-cover object-center"
              sources={{
                desktop: {
                  light: activeMapDetails?.mapBannerDesktop,
                  dark: activeMapDetails?.mapBannerDesktop as string,
                },
                mobile: {
                  light: activeMapDetails?.mapBannerMobile,
                  dark: activeMapDetails?.mapBannerMobile as string,
                },
              }}
              fill
              loader={DEFAULT_IMAGE_LOADER}
            />
          ) : (
            <TextLoader className="h-11 w-full rounded-md lg:h-12 lg:w-full" />
          )}
        </div>
      </Link>
    ) : (
      <div
        className={cn([
          'mx-auto flex w-full flex-col items-start space-y-1 rounded-lg bg-bgr-lightest p-1 [&>div]:w-full',
        ])}
      >
        <Link
          onClick={() => isMobile && dispatch(setOpenSidemenu(false))}
          href={currentMap?.attributes?.path ?? ''}
          {...itemProps}
        >
          <ItemIcon
            icon={activeMapDetails?.icon ?? ''}
            imageProps={{
              loader: DEFAULT_IMAGE_LOADER,
            }}
          />
        </Link>
      </div>
    );
  }, [
    isSidemenuOpen,
    currentMap?.attributes?.path,
    hasValidImageSources,
    activeMapDetails?.mapBannerDesktop,
    activeMapDetails?.mapBannerMobile,
    activeMapDetails?.icon,
    itemProps,
    isMobile,
    dispatch,
  ]);

  return (
    <ElementsContainer className={cn(['!pb-0 md:!pb-padding-xl'])}>
      {!isSidemenuOpen && isMounted && !isLoading ? (
        <Tooltip
          styleOptions={{
            popperStyles: '!z-[99999] p-2 text-xs',
            arrowStyles: '!z-[99999]',
          }}
          type="tooltip"
          displayOn="hover"
          trigger={triggerContent}
          placement="right"
        >
          {title}
        </Tooltip>
      ) : (
        triggerContent
      )}
    </ElementsContainer>
  );
};

export default FeaturedSidebarBanner;
