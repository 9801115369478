'use client';
import React, { useRef } from 'react';
import { cn } from '@/utils/style';
import GamePageSidebarElements from './game-page-sidebar-elements';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';
import AnimatedContainerBody from './animated-container-body';
import { useOnClickOutside } from '@purposeinplay/utils';

const GamePageSidebar = () => {
  const { isOpen, setIsOpen } = useGamePageSidebar();
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => setIsOpen(false));

  return (
    <>
      <aside
        ref={containerRef}
        className={cn([
          'fixed bottom-0 left-0 top-0 z-[999] hidden h-screen w-[384px] flex-row gap-x-1.5 gap-y-2 bg-bgr-lighter px-1.5 py-2 md:flex',
          'transform transition-transform duration-300 ease-in-out',
          isOpen ? 'translate-x-0' : '-translate-x-[324px]',
        ])}
      >
        <div className="relative flex-1 overflow-hidden">
          <AnimatedContainerBody />
        </div>
        <div className="relative w-12 flex-shrink-0">
          <GamePageSidebarElements />
        </div>
      </aside>
    </>
  );
};

export default GamePageSidebar;
