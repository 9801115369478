'use client';
import { TournamentsContentType } from '@/types/json-content/tournaments';
import { cn } from '@/utils/style';
import { Text } from '@purposeinplay/core-v2';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LeaderboardTitle = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const tournamentContent = useMemo(() => {
    if (t && t('tournaments')) {
      return t('tournaments') as TournamentsContentType;
    }
    return {
      leaderboard_title: 'Leaderboard',
    };
  }, [t]);

  return (
    <Text size="xl" className={cn(['mb-4 font-semibold lg:mb-7', className])}>
      {tournamentContent?.leaderboard_title}
    </Text>
  );
};

export default LeaderboardTitle;
