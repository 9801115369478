import React, { memo, useEffect } from 'react';
import { useGamePageBottomDrawer } from '../context/game-page-bottom-drawer-context';
import DrawerContentLayout from './drawer-content-layout';
import Notification from '@/modules/feed/index.tsx';
import { useGamesContent } from '@/hooks/use-games-content';
import GameContainer from '@/modules/layout/sidebar/components/game-container';
import MoreContainer from './more-container';
import GameSection from '@/modules/layout/sidebar/components/game-section';
import GameGridDisplay from '@/modules/layout/sidebar/components/game-grid-display';
import { GameSectionType } from '@/modules/layout/sidebar/types';

const DrawerContentRenderer = memo(() => {
  const {
    activeSection,
    selectedGameCat,
    selectedGameTitle,
    setSelectedGameCat,
    setSelectedGameTitle,
  } = useGamePageBottomDrawer();
  const { gamesContent } = useGamesContent();
  const sections = gamesContent.game_sidebar.sections;

  // Debug log when activeSection changes
  useEffect(() => {
    if (activeSection === 'favorites') {
      console.log('Drawer opened with:', {
        activeSection,
        selectedGameCat,
        selectedGameTitle,
      });
    }

    // Cleanup context when drawer is closed or section changes away from favorites
    if (activeSection !== 'favorites') {
      if (selectedGameCat || selectedGameTitle) {
        console.log('Cleaning up selected game context');
        setSelectedGameCat(null);
        setSelectedGameTitle(null);
      }
    }
  }, [
    activeSection,
    selectedGameCat,
    selectedGameTitle,
    setSelectedGameCat,
    setSelectedGameTitle,
  ]);

  if (activeSection === 'notification') {
    return (
      <DrawerContentLayout>
        <Notification forSidebar />
      </DrawerContentLayout>
    );
  }

  if (activeSection === 'favorites') {
    // If we have a selectedGameCat and selectedGameTitle in the context, render a GameGridDisplay with that gameCat
    if (selectedGameCat && selectedGameTitle) {
      // For debugging, log the title we're about to display
      console.log('Using selected title:', selectedGameTitle);

      return (
        <DrawerContentLayout>
          <div className="flex flex-col gap-7">
            <GameGridDisplay forBottomDrawer gameCat={selectedGameCat} />
          </div>
        </DrawerContentLayout>
      );
    }

    // Default favorites view if no selectedGameCat
    return (
      <DrawerContentLayout>
        <GameContainer forBottomDrawer />
      </DrawerContentLayout>
    );
  }

  if (activeSection === 'new') {
    return (
      <DrawerContentLayout>
        <div className="flex flex-col gap-7">
          <GameGridDisplay forBottomDrawer gameCat="fresh-releases" />
        </div>
      </DrawerContentLayout>
    );
  }

  if (activeSection === 'popular') {
    return (
      <DrawerContentLayout>
        <div className="flex flex-col gap-7">
          <GameGridDisplay forBottomDrawer gameCat="top-games" />
        </div>
      </DrawerContentLayout>
    );
  }

  if (activeSection === 'more') {
    return (
      <DrawerContentLayout>
        <MoreContainer />
      </DrawerContentLayout>
    );
  }

  return <DrawerContentLayout>{activeSection}</DrawerContentLayout>;
});

DrawerContentRenderer.displayName = 'DrawerContentRenderer';

export default DrawerContentRenderer;
