import React from 'react';
import { useGetWalletSettingsContent } from '../../WalletModal/hooks/use-get-wallet-content';
import { Switch, Text } from '@purposeinplay/core-v2';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setHideZeroBalances, setViewFiat } from '@/app/reducers/bootstrap';
import { SettingsModal } from '@/types/json-content/wallet';
import { cn } from '@/utils/style';
import { useAnalytics } from '@/hooks/use-analytics';

const ZeroBalanceLabel = ({
  walletContent,
}: {
  walletContent: SettingsModal;
}) => (
  <div className="ml-2">
    <span className="mb-1 text-sm font-semibold text-text-default">
      {walletContent?.toggles?.zero_balances?.title}
    </span>
    <Text className="text-text-subdued/70 text-sm font-normal">
      {walletContent?.toggles?.zero_balances?.description}
    </Text>{' '}
  </div>
);

const ViewFiatLabel = ({ walletContent }: { walletContent: SettingsModal }) => (
  <div className="ml-2">
    <span className="mb-1 text-sm font-semibold text-text-default">
      {walletContent?.toggles?.view_fiat?.title}
    </span>
    <Text className="text-text-subdued/70 text-sm font-normal">
      {walletContent?.toggles?.view_fiat?.description}
    </Text>{' '}
  </div>
);

const WalletSettingsSwitches = () => {
  const walletContent = useGetWalletSettingsContent();

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const hideZeroBalances = useAppSelector(
    (state) => state.bootstrap.hideZeroBalances,
  );

  const { track } = useAnalytics();

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn([
        'flex flex-col space-y-4 [&>div>div>button]:bg-gray-200 [&>div>div>button]:dark:bg-gray-700',
        hideZeroBalances &&
          '[&>div:nth-of-type(1)>div>button]:bg-base-primary [&>div:nth-of-type(1)>div>button]:dark:bg-base-primary',
        viewFiat &&
          '[&>div:nth-of-type(2)>div>button]:bg-base-primary [&>div:nth-of-type(2)>div>button]:dark:bg-base-primary',
      ])}
    >
      <Switch
        checked={hideZeroBalances}
        onChange={(value) => dispatch(setHideZeroBalances(value))}
        size="large"
        labelRight={<ZeroBalanceLabel walletContent={walletContent} />}
      />

      <Switch
        checked={viewFiat}
        onChange={(value) => {
          dispatch(setViewFiat(value));
          localStorage.setItem('fiat', value?.toString());
          track('fiat_currency_toggled', {
            category: 'user',
            label: value,
          });
        }}
        size="large"
        labelRight={<ViewFiatLabel walletContent={walletContent} />}
      />
    </div>
  );
};

export default WalletSettingsSwitches;
