'use client';

import React, { Suspense } from 'react';
import BottomNav, { GamePageBottomNavWrapper } from '.';
import { DivLoader } from '@/components/Loaders';
import { useIsOnGamePage } from '@/hooks/use-is-on-game-page';

const MobileNav = () => {
  const isOnGamePage = useIsOnGamePage();
  return (
    <Suspense
      fallback={
        <div className="fixed inset-x-0 bottom-0 z-10">
          <DivLoader className="flex h-[60px] justify-between rounded-none rounded-t-2xl bg-bgr-lightest px-2 py-2.5 shadow" />
        </div>
      }
    >
      {isOnGamePage ? <GamePageBottomNavWrapper /> : <BottomNav />}
    </Suspense>
  );
};

export default MobileNav;
