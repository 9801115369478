import { useAppSelector } from '@/hooks/redux';
import { formatStringTitle } from '@/utils/helpers';
import { cn } from '@/utils/style';
import { useIsMounted } from '@purposeinplay/utils';
import React from 'react';

type Props = {
  isHighlighted?: boolean;
  title: string | React.ReactNode | undefined;
};

const SidebarItemTitle: React.FC<Props> = ({ title, isHighlighted }) => {
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();

  if (!title) return null;

  return (
    <span
      className={cn([
        'absolute hidden max-w-44 truncate whitespace-nowrap text-sm md:absolute md:hidden xl:relative xl:inline-block',
        !isSidemenuOpen && isMounted && 'md:!absolute md:!hidden',
        isSidemenuOpen &&
          isMounted &&
          'relative inline-block md:relative md:inline-block',
      ])}
    >
      {isHighlighted && typeof title === 'string'
        ? formatStringTitle(title)
        : title}
    </span>
  );
};

export default SidebarItemTitle;
