import { useCallback } from 'react';
import { useAuth } from '@/hooks/auth';
import { useAnalytics } from '@/hooks/use-analytics';
import useModal from '@/hooks/modal';

interface LinkHandlerProps {
  link: { opensModal: string };
  closeSidemenuIfMobile: () => void;
}

const useLinkHandler = ({ link, closeSidemenuIfMobile }: LinkHandlerProps) => {
  const { track } = useAnalytics();
  const { open } = useModal();
  const { user } = useAuth();

  const handleClick = useCallback(() => {
    const trackEvent = (eventAction: string, eventLabel: string) => {
      track(eventAction, {
        category: 'left-side-menu',
        label: eventLabel,
      });
    };

    switch (link.opensModal) {
      case 'bonuses':
        trackEvent('bonus_shop_viewed', 'bonus-shop');
        closeSidemenuIfMobile();
        break;
      case 'wheel':
        trackEvent('wheel_viewed', 'wild-wheel');
        closeSidemenuIfMobile();

        break;
      case 'refer_friend':
        trackEvent('refer_a_friend_viewed', 'refer-a-friend');
        closeSidemenuIfMobile();
        open('refer_friend');
        break;
      case 'telegram':
        trackEvent('telegram_landing_page_viewed', 'telegram');
        closeSidemenuIfMobile();
        break;
      case 'pwa':
        trackEvent('install_app_landing_page_viewed', 'install-app');
        closeSidemenuIfMobile();
        break;
      // TO DO REPLACE THIS WHEN UPDATING CMS (add new field for lottery)
      // This is actually a lottery link
      case 'wild_points':
        trackEvent('wild_lottery_viewed', 'wild-lottery');
        closeSidemenuIfMobile();
        break;
      default:
        if (user?.isAuthenticated) {
          open(link.opensModal);
        } else {
          open('signup');
        }
    }
  }, [
    link.opensModal,
    track,
    closeSidemenuIfMobile,
    open,
    user?.isAuthenticated,
  ]);

  return handleClick;
};

export default useLinkHandler;
