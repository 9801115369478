import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from '@purposeinplay/core-v2';
import AvailableWildPoints from './available-wild-points';
import { usePlayerCompPoints } from '@/hooks/comp-points';
import ExchangeInputs from './exchange-inputs';
import { useAccounts } from '@/hooks/accounts';
import { useExchangeCompPoints } from '@/hooks/use-exchange-comp-points';
import { EXCHANGE_MAPPING, WILD_POINT_TABS } from '../constants';
import ExchangeCurrencyDetails from './exchange-currency-details';
import ExchangeButton from './exchange-button';
import { handleErrorWildPoints } from '../utils';
import WofDropdown from './wof-dropdown';
import { useFSRate } from '../hooks/use-fs-rate';

const WildPointsBody = () => {
  const { exchangeRatesPoints, fsRate } = useFSRate();
  const [tabs, setTabs] = useState(WILD_POINT_TABS);
  const [tabPoints, setTabPoints] = useState(1);
  const { account: currentAccount } = useAccounts();
  const [formData, setFormData] = useState({
    // Sets points on first render
    points: tabs[0].active ? exchangeRatesPoints.wofWheel : 0,
    exchangeToFS: false,
    currency: currentAccount?.currency || 'BTC',
    exchangeToCrypto: false,
    exchangeToWoF: {
      WildWheelSpins: true,
      JungleWheelSpins: false,
      VIPWheelSpins: false,
    },
  });
  const {
    mutateAsync: handleExchange,
    error,
    isPending,
  } = useExchangeCompPoints();

  const localError = useMemo(() => {
    if (error) {
      return handleErrorWildPoints(error);
    }
  }, [error]);

  const handleTabClick = (e: any, item: any) => {
    const newItems = tabs.map((el) => ({
      ...el,
      active: el.id === item.id ? true : false,
    }));
    setTabs(newItems);
    if (item.id === 'wof') {
      setFormData({
        ...formData,
        ...EXCHANGE_MAPPING['WoF Spins'],
        points: exchangeRatesPoints.wofWheel,
      });
    } else if (item.id === 'fs') {
      setFormData({
        ...formData,
        ...EXCHANGE_MAPPING['Free Spins'],
        points: fsRate * 10,
      });
    } else if (item.id === 'crypto') {
      setFormData({ ...formData, ...EXCHANGE_MAPPING['Crypto'], points: 0 });
    }
  };
  const { data } = usePlayerCompPoints();

  const pointsRemaining = useMemo(() => data?.chargeable?.points, [data]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleExchange({
      points: formData.points,
      currency: formData.currency,
      exchangeToFS: formData.exchangeToFS,
      exchangeToWoF: formData.exchangeToWoF,
      exchangeToCrypto: formData.exchangeToCrypto,
    });
  };

  return (
    <div className="flex flex-col">
      <Tabs
        className="mb-3 md:mb-7"
        variant="segmented-rounded"
        tabStyleOptions={{
          navItemStyles: 'whitespace-nowrap',
        }}
        onClick={handleTabClick}
        items={tabs}
      />
      <form onSubmit={handleSubmit}>
        <div className="mb-1 flex h-8 w-full items-center justify-between">
          <AvailableWildPoints pointsRemaining={pointsRemaining} />
          {tabs.find((item) => item.active)?.id === 'wof' && (
            <div>
              <WofDropdown
                tabPoints={tabPoints}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          )}
        </div>
        <ExchangeInputs
          setTabPoints={setTabPoints}
          error={localError}
          selectedAction={tabs.find((item) => item.active)}
          formData={formData}
          setFormData={setFormData}
          pointsRemaining={pointsRemaining}
        />
        <ExchangeCurrencyDetails formData={formData} />

        <ExchangeButton
          exchangeLoading={isPending}
          pointsRemaining={pointsRemaining}
          exchangeToFS={formData.exchangeToFS}
          points={formData.points}
          exchangeToWoF={formData.exchangeToWoF}
        />
      </form>
    </div>
  );
};

export default WildPointsBody;
