'use client';
import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { useAppSelector } from '@/hooks/redux';
import useMediaQuery, { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';
import { useIsMounted } from 'usehooks-ts';
import { ChatElements } from './components/chat-elements';
import MobileChat from './components/mobile-chat';
import DesktopChat from './components/desktop-chat';
import useFeatureVisibility from '@/hooks/use-feature-visibility';
import useBodyLock from '@/hooks/use-body-lock';
import { positionChatAtBottom } from './utils';

const WildChat: React.FC = React.memo(() => {
  const isChatOpen = useAppSelector((state) => state.bootstrap.isChatOpen);
  const { shouldShow, isLoading: isFeatureLoading } =
    useFeatureVisibility('wild-chat');
  const isMounted = useIsMounted();
  const prevIsChatOpenRef = useRef(false);
  const chatRef = useRef<HTMLDivElement>(null);

  // Position chat at bottom when opening
  useLayoutEffect(() => {
    if (isChatOpen && !prevIsChatOpenRef.current) {
      positionChatAtBottom();
    }
    prevIsChatOpenRef.current = isChatOpen;
  }, [isChatOpen]);

  const { matchesQuery: isMobileLG } = useMediaQuery(MOBILE_LG_BREAKPOINT);

  useBodyLock(
    isChatOpen,
    {
      reserveScrollBarGap: true,
      allowTouchMove: true,
    },
    chatRef.current || undefined,
  );

  if (!isMounted() || !shouldShow || isFeatureLoading) {
    return null;
  }

  if (isMobileLG) {
    return (
      <MobileChat isChatOpen={isChatOpen} ref={chatRef}>
        <ChatElements />
      </MobileChat>
    );
  }

  return (
    <DesktopChat isChatOpen={isChatOpen} ref={chatRef}>
      <ChatElements />
    </DesktopChat>
  );
});

WildChat.displayName = 'WildChat';

export default WildChat;
