import { useGetExchangeRatesMoney } from '@/hooks/comp-points-exchange-rates';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { useMemo } from 'react';

const useExchangeRatesCash = () => {
  const { data: rates } = useGetExchangeRatesMoney();

  const { formatCurrency } = useCurrencyFormatter();

  const exchangeRates = useMemo(() => {
    if (!rates || !rates[0]?.rates) {
      return {};
    }

    return rates[0].rates.reduce((result: any, rate: any) => {
      result[rate.currency] = Number(
        formatCurrency(rate.amount_cents, rate.currency, { style: 'decimal' }),
      );
      return result;
    }, {});
  }, [formatCurrency, rates]);

  return exchangeRates;
};

export default useExchangeRatesCash;
