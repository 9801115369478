import { TextLoader } from '@/components/Loaders';
import { cn } from '@/utils/style';
import { usePathname } from 'next/navigation';
import React from 'react';

const CardButtonsLoader = ({ isForSidebar }: { isForSidebar?: boolean }) => {
  const pathname = usePathname();
  const isForDynamicPage = pathname.includes('/tournaments/');
  return (
    <div className="flex w-full flex-row items-center space-x-2">
      <TextLoader
        className={cn([
          'h-10 w-full lg:w-full',
          (isForDynamicPage || isForSidebar) && 'h-8',
        ])}
      />
      <TextLoader
        className={cn([
          'h-10 w-full lg:w-full',
          (isForDynamicPage || isForSidebar) && 'h-8',
        ])}
      />
    </div>
  );
};

export default CardButtonsLoader;
