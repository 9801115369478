import React from 'react';
import Image from 'next/image';

const RibbonImage = ({ src }: { src: string }) => {
  if (!src) {
    return null;
  }

  return (
    <div className="absolute -bottom-20 h-36 w-full">
      <div className="flex h-20 w-full items-start justify-center">
        <Image
          src={src}
          width={380}
          height={140}
          alt="ribbon"
          className="relative z-10 mx-auto -translate-x-1 transform-gpu md:h-[8.75rem]"
          style={{
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
  );
};

export default RibbonImage;
