import React, { Suspense, MouseEvent } from 'react';
import GameBottomNavItem from './game-bottom-nav-item';
import { AnimatedDrawer } from './animated-drawer';
import { SearchModal } from '@/modules/search';
import { useAuth } from '@/hooks/auth';
import {
  useGamePageBottomDrawer,
  BottomDrawerSection,
} from '../context/game-page-bottom-drawer-context';
import { useRouter } from 'next/navigation';
import { ExtendedNavigationItem } from '../types/extended-navigation-item';
import { TextLoader } from '@/components/Loaders';
import { useNotificationStatus } from '../hooks/use-notification-status';
import NotificationIndicator from './notification-indicator';
import useNavigationItems from '../hooks/use-navigation-items';
import { useGamesContent } from '@/hooks/use-games-content';

const GamePageBottomNav: React.FC = () => {
  const {
    user: { isAuthenticated, isFetching, isLoading },
  } = useAuth();

  const { gamesContent } = useGamesContent();
  const { hasUnreadNotifications } = useNotificationStatus();
  const router = useRouter();
  const { navigationItems } = useNavigationItems();

  const { activeSection, isOpen, setActiveSection, setIsOpen } =
    useGamePageBottomDrawer();

  const handleSectionClick = (section: BottomDrawerSection) => {
    if (isOpen && section === activeSection) {
      setIsOpen(false);
      setActiveSection(null);
    } else {
      setActiveSection(section);
      setIsOpen(true);
    }
  };

  const handleItemClick = (
    e: MouseEvent<HTMLAnchorElement>,
    item: ExtendedNavigationItem,
  ) => {
    e.preventDefault();

    if (item.section) {
      handleSectionClick(item.section);
      return;
    }

    if (item.onClick) {
      item.onClick(e);
      return;
    }

    if (item.href && item.href !== '#') {
      if (isAuthenticated && item.href.includes('?m=wallet')) {
        router.push(item.href);
      } else {
        router.push(item.href);
      }
    }
  };

  if (isLoading || isFetching) {
    return (
      <div
        style={{ zIndex: 999 }}
        className="safe-bottom-padding fixed inset-x-0 bottom-0 flex h-12 w-full justify-between bg-bgr-lightest p-1 shadow md:hidden"
      >
        {Array.from({ length: 7 }).map((_, index) => (
          <TextLoader key={index} className="h-10 w-10" />
        ))}
      </div>
    );
  }

  return (
    <>
      <Suspense>
        <SearchModal />
      </Suspense>
      <AnimatedDrawer />
      <div
        style={{ zIndex: 999 }}
        className="safe-bottom-padding fixed inset-x-0 bottom-0 flex h-12 w-full items-center justify-between rounded-t-xl bg-bgr-lightest p-1 shadow md:hidden"
      >
        {navigationItems.map(
          (item, index) =>
            (item.showAlways || item.show) && (
              <GameBottomNavItem
                key={`${item.href}-${index}`}
                href={item.section ? '#' : item.href}
                icon={
                  item.section === 'notification' && hasUnreadNotifications ? (
                    <NotificationIndicator icon={item.icon} />
                  ) : (
                    item.icon
                  )
                }
                onClick={(e) => handleItemClick(e, item)}
                isActive={activeSection === item.section && isOpen}
                label={item.label}
              />
            ),
        )}
      </div>
    </>
  );
};

export default GamePageBottomNav;
