import React from 'react';
import ActionBtn from '@/components/ActionButton';
import AdaptiveImage from '@/components/AdaptiveImage';
import { useAppDispatch } from '@/hooks/redux';
import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import ElementsContainer from './elements-container';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const MobileHeader = () => {
  const dispatch = useAppDispatch();
  return (
    <ElementsContainer className="!pb-0 md:hidden">
      <div className="flex justify-between">
        <AdaptiveImage
          alt="logo"
          width={110}
          height={35}
          fill={false}
          sources={{
            desktop: {
              light: `/assets/logo-v2.svg`,
              dark: `/assets/logo-dark-v2.svg`,
            },
          }}
          loader={DEFAULT_IMAGE_LOADER}
        />

        <ActionBtn
          className="relative right-0 top-0 lg:top-0"
          color="tertiary"
          onClick={() => dispatch(setOpenSidemenu(false))}
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          }
        />
      </div>
    </ElementsContainer>
  );
};

export default MobileHeader;
