import React from 'react';
import { useAppSelector } from '@/hooks/redux';
import { useAuth } from '@/hooks/auth';
import { cn } from '@/utils/style';
import { Text } from '@purposeinplay/core-v2';
import useModal from '@/hooks/modal';
import useNotificationDetails from './hooks/use-notification-details';
import { useAllNotifications } from '@/app/providers/notifications';
import dynamic from 'next/dynamic';
import Chevron from '../Chevron/chevron';
import { getSnackbarIconStyle } from './utils';
import { useWofQuery } from '@/generated';
import { Title } from '@/modules/feed/utils.tsx';

const NotificationModal = dynamic(
  () =>
    import('../NotificationModal/index.tsx').then((module) => module.default),
  {
    ssr: false,
  },
);
interface Props {
  key?: string;
  id: string;
  description?: string;
  type:
    | 'deposit'
    | 'withdraw'
    | 'level'
    | 'freeSpins'
    | 'bonus'
    | 'lootboxes'
    | 'jackpot'
    | 'empty';
  read: boolean;
  notification: any;
  wofSlug?: string | null | undefined;
  setNotifications?: any;
  forSidebar?: boolean;
}

const MediaComponent: React.FC<Props> = React.memo(
  ({
    forSidebar,
    key,
    id,
    type,
    read,
    description,
    notification,
    setNotifications,
    wofSlug,
  }) => {
    const { viewFiat } = useAppSelector((state) => state.bootstrap);
    const { user } = useAuth();
    const { notificationDetailsId, setNotificationsDetailsId } =
      useAllNotifications();
    const { titleProps, icon } = useNotificationDetails(notification, type);
    const { open } = useModal();

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      if (type === 'lootboxes') {
        open(`${wofSlug}-wheel`);

        setNotifications((prev: any) => {
          const prevNotif = prev[user?.currentUser?.id as any] || [];
          return {
            ...prev,
            [user?.currentUser?.id as any]: prevNotif.filter(
              (el: any) => el !== id.toString(),
            ),
          };
        });
        return;
      }
      if (type === 'empty') return;
      setNotificationsDetailsId(id);
    };

    return (
      <>
        <div
          role="button"
          onClick={(e) => handleClick(e)}
          className={cn([
            'group flex shrink-0 flex-row items-center p-padding-xl last:border-transparent hover:bg-surface-hovered',
            read && 'opacity-80 transition-all hover:opacity-100',
            forSidebar && 'overflow-hidden rounded-[4px] !p-padding-lg',
            !forSidebar &&
              'overflow-hidden border-b border-border-general-subdued',
          ])}
        >
          <div
            className={cn([
              'relative mr-spacing-xs flex h-8 w-8 items-center justify-center rounded-radius-md p-padding-sm',
              getSnackbarIconStyle(type === 'level' ? 'info' : 'success'),
            ])}
          >
            {icon}
          </div>
          <div className="max-w-[80%] cursor-pointer">
            <Text
              size="sm"
              className={cn([
                'mb-1 text-sm leading-4',
                read && `text-text-subdued`,
              ])}
            >
              <Title {...titleProps} />
            </Text>
            <Text size="xs" className="leading-none text-text-subdued">
              {description}
            </Text>
          </div>
          {!forSidebar && (
            <Chevron
              className={cn([
                'ml-auto -rotate-90 justify-self-end fill-current text-text-subdued group-hover:text-text-default',
              ])}
            />
          )}
        </div>

        <NotificationModal
          openModal={notificationDetailsId === id}
          setOpenModal={() => {
            setNotificationsDetailsId('');
          }}
          setNotifications={setNotifications}
          read={read}
          id={id}
          viewFiat={viewFiat}
          notification={notification}
          type={type}
        />
      </>
    );
  },
);

MediaComponent.displayName = 'NotificationMediaComponent';

export default MediaComponent;
