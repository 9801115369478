import React from 'react';
import GameSection from './game-section';

const GameContainer = ({
  forBottomDrawer = false,
}: {
  forBottomDrawer?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-7">
      <GameSection forBottomDrawer={forBottomDrawer} gameCat="recents" />
      <GameSection forBottomDrawer={forBottomDrawer} gameCat="favorites" />
      <GameSection forBottomDrawer={forBottomDrawer} gameCat="suggested" />
    </div>
  );
};

export default GameContainer;
