import React, { Suspense } from 'react';
import ActionLinkTrigger from './action-link-trigger';
import { Tooltip } from '@purposeinplay/core-v2';
import { DivLoader } from '@/components/Loaders';
import { ActionLink } from '../types';
import { useAppSelector } from '@/hooks/redux';
import { useIsMounted } from '@purposeinplay/utils';

const SidebarActionLink = ({ link }: { link: ActionLink }) => {
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();
  const triggerContent = () => {
    return (
      <Suspense fallback={<DivLoader className="h-10 w-full lg:w-full" />}>
        <ActionLinkTrigger link={link} />
      </Suspense>
    );
  };

  return !isSidemenuOpen && isMounted ? (
    <Tooltip
      styleOptions={{
        popperStyles: '!z-[99999] p-2 text-xs',
        arrowStyles: '!z-[99999]',
      }}
      type="tooltip"
      displayOn="hover"
      trigger={triggerContent()}
      placement="right"
    >
      {link?.title}
    </Tooltip>
  ) : (
    triggerContent()
  );
};

export default SidebarActionLink;
