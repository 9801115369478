'use client';
import {
  useAuth,
  useConfirmAuthProvider,
  useUnlockAccount,
  useUpdateDetails,
} from '@/hooks/auth';
import {
  useActivateCouponBonus,
  useSetPreviewBonusCoupon,
} from '@/hooks/bonuses';
import { useAddPlayerToGroups } from '@/hooks/groups';
import useModal from '@/hooks/modal';
import { useConfirmCashout } from '@/hooks/payments';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import config from '@/config';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { setOpenTriggerModal, setViewFiat } from '@/app/reducers/bootstrap';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@purposeinplay/utils';
import { useUpdateProfile } from '@/hooks/profile';
import UserConfirmationModal from './components/user-confirmation-modal';
import ChangePasswordModal from '../Modals/auth/change-pass-modal';
import FillProfileModal from './components/fill-profile-modal';
import { Button, Modal } from '@purposeinplay/core-v2';
import DepositForm from '../DepositForm';
import { processMarkdown } from '@/utils/helpers';
import ProfileLimits from '../Settings/components/limits/components/profile-limits';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { useRefCode } from '@/hooks/raf';
import UpdateInfoContent from './components/update-info-content';
import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { CUPONS_NO_DEPOSIT } from '@/utils/constants';
import { useUpdateSubscriptions } from '@/hooks/others';

const paramsToExtract = [
  'reset_password_token',
  'confirmation_token',
  'stag',
  'atg',
  'adc',
  'adcb',
  'confirm_limit',
  'ref_code',
  'unlock_token',
];

const PageTriggers = () => {
  const openModal = useAppSelector((state) => state.bootstrap.openTriggerModal);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { closeOnly, close } = useModal();

  const searchParams = useSearchParams();

  const isRedirected = Boolean(searchParams.get('isRedirected'));

  const {
    user: { currentUser, isAuthenticated },
  }: any = useAuth();

  const extractParams = useCallback(
    (keys: any) => {
      return keys.reduce((acc: any, key: any) => {
        const value = searchParams.get(key);
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});
    },
    [searchParams],
  );

  const {
    reset_password_token,
    confirmation_token,
    stag,
    atg,
    adc,
    adcb,
    confirm_limit,
    unlock_token,
    ref_code,
  } = extractParams(paramsToExtract);

  const router = useRouter();
  const { refetch: fetchRefCode } = useRefCode(ref_code);

  const { toast } = useToast();

  const [awaitingDeposit, setAwaitingDeposit] = useState<string | null>(null);
  const { mutateAsync: updateDetails, isPending: isLoading } =
    useUpdateDetails();

  const { mutateAsync: addPlayerToGroups, isPending: addToGroupPending } =
    useAddPlayerToGroups();
  const { mutateAsync: unlockAccount, isPending: unlockAccPending } =
    useUnlockAccount();
  const { mutateAsync: setPreviewBonusCoupon, isPending: previewBonusPending } =
    useSetPreviewBonusCoupon();
  const { mutateAsync: confirmCashout, isPending: confirmCashoutPending } =
    useConfirmCashout();
  const { mutateAsync: updateProfile, isPending: updateProfilePending } =
    useUpdateProfile();
  const { mutateAsync: confirmAuthProvider } = useConfirmAuthProvider();

  const handleConfirmAuthProvider = useCallback(async () => {
    try {
      await confirmAuthProvider(confirmation_token);
    } catch (e) {
      console.log(e);
    }
  }, [confirmAuthProvider, confirmation_token]);

  const { mutateAsync: activatCuponBonus, isPending: couponBonusPending } =
    useActivateCouponBonus();

  const { mutateAsync } = useUpdateSubscriptions();

  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const pageTriggerContent = general?.page_triggers;
  const { data: updatedTerms, isLoading: pagesLoading } = usePagesQuery(
    { path: '?m=updated_terms' },
    {
      queryKey: ['pages', '?m=updated_terms'],
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );
  const updatedTermsContent =
    updatedTerms?.pages?.data[0]?.attributes?.content?.find(
      (content) => content?.__typename === 'ComponentContentMarkdown',
    );
  const updatedTermsAction =
    updatedTerms?.pages?.data[0]?.attributes?.content?.find(
      (content) => content?.__typename === 'ComponentActionsAction',
    );

  const pathname = usePathname();

  useEffect(() => {
    const viewFiat = localStorage.getItem('fiat') || 'true';
    dispatch(setViewFiat(JSON.parse(viewFiat)));
  }, [dispatch]);

  useEffect(() => {
    if (pathname.includes('/accounts/confirm_cashout_address/')) {
      const splitParams = pathname.split('/accounts/confirm_cashout_address/');

      if (splitParams.length > 1) {
        const paymentId = Number(splitParams[1].split('/')[0]);
        const token = splitParams[1].split('/')[1].split('?')[0];

        if (paymentId && token) {
          confirmCashout({
            payment_id: paymentId,
            token,
          });
        }
      }
    }
  }, [pathname, confirmCashout]);

  useEffect(() => {
    dispatch(setOpenTriggerModal(undefined));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (!currentUser?.auth_fields_missed?.length) return;

    const { auth_fields_missed } = currentUser;
    const needsTerms = auth_fields_missed?.includes('terms_acceptance');
    const needsEmail = auth_fields_missed?.includes('email');

    if (needsTerms && needsEmail) {
      dispatch(setOpenTriggerModal('updated_info'));
      return;
    }

    if (needsTerms) {
      dispatch(setOpenTriggerModal('updated_terms'));
    }
  }, [currentUser, dispatch]);

  const hasConfirmedAuthProvider = useRef(false);

  useEffect(() => {
    if (isRedirected && !hasConfirmedAuthProvider.current) {
      handleConfirmAuthProvider();
      hasConfirmedAuthProvider.current = true;
    }
  }, [isRedirected, handleConfirmAuthProvider]);

  useEffect(() => {
    if (
      addToGroupPending ||
      unlockAccPending ||
      previewBonusPending ||
      confirmCashoutPending ||
      updateProfilePending
    ) {
      return;
    }
    if (reset_password_token) {
      dispatch(setOpenTriggerModal('changePasswordModal'));
    }

    const awaitingDeposit = JSON.parse(
      localStorage.getItem('awaitingDeposit') as string,
    );

    if (awaitingDeposit && currentUser?.email) {
      setAwaitingDeposit(awaitingDeposit);
      if (awaitingDeposit.bonus !== 'no-bonus') {
        dispatch(setOpenTriggerModal('depositModal'));
      }
    }

    if (confirmation_token && !isRedirected) {
      dispatch(setOpenTriggerModal('userConfirmationModal'));
    }

    if (ref_code) {
      fetchRefCode();
    }

    const fetchData = async () => {
      try {
        if (stag) {
          const response = await fetch(
            `${config.baseUrl}/api/info/locales?stag=${stag}`,
            {
              credentials: 'include',
              headers: {
                Accept: 'application/vnd.s.v1+json',
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.ok) {
            router.push(pathname);
            console.log('Stag handled successfully');
          }
        }
      } catch (error) {
        console.log('ERROR', error);
        router.push('/');
      }
    };

    fetchData();

    if (atg && isAuthenticated) {
      addPlayerToGroups([atg])
        .then(async () => {
          closeOnly(['atg']);
          await queryClient.invalidateQueries({
            queryKey: ['depositPreviewBonuses'],
          });

          toast({
            state: 'success',
            title: pageTriggerContent?.toast?.group,
          });
        })
        .catch(() => {
          closeOnly(['atg']);
          toast({
            state: 'error',
            title: pageTriggerContent?.toast?.no_group,
          });
        });
    }

    if (adc && isAuthenticated && currentUser?.deposit_bonus_code === adc) {
      closeOnly(['adc']);
      return;
    }
    if (adc && isAuthenticated && currentUser?.deposit_bonus_code !== adc) {
      if (couponBonusPending) return;
      setPreviewBonusCoupon(adc)
        .then(() => {
          closeOnly(['adc']);
          queryClient.invalidateQueries({ queryKey: ['bonusesPreview'] });
        })
        .catch(() => {
          closeOnly(['adc']);
          toast({
            state: 'error',
            title: pageTriggerContent?.toast?.no_promo,
          });
        });
    }

    if (adcb && isAuthenticated && currentUser?.deposit_bonus_code === adcb) {
      closeOnly(['adcb']);
      return;
    }
    if (adcb && isAuthenticated && currentUser?.deposit_bonus_code !== adcb) {
      if (couponBonusPending) return;

      activatCuponBonus(adcb).then(() => {
        closeOnly(['adcb']);
        queryClient.invalidateQueries({ queryKey: ['bonusesPreview'] });
      });
    }

    if (unlock_token) {
      unlockAccount(unlock_token)
        .then(() => {
          closeOnly(['unlock_token']);

          toast({
            state: 'success',
            title: pageTriggerContent?.toast?.unlock,
          });
        })
        .catch(() => {
          closeOnly(['unlock_token']);
          toast({
            state: 'error',
            title: pageTriggerContent?.toast?.no_unlock,
          });
        });
    }

    if (confirm_limit && isAuthenticated) {
      dispatch(setOpenTriggerModal('profile_limits'));
    }
  }, [
    activatCuponBonus,
    couponBonusPending,
    router,
    addPlayerToGroups,
    setPreviewBonusCoupon,
    atg,
    adc,
    fetchRefCode,
    ref_code,
    reset_password_token,
    confirmation_token,
    currentUser,
    queryClient,
    stag,
    unlock_token,
    unlockAccount,
    confirm_limit,
    isAuthenticated,
    dispatch,
    toast,
    pathname,
    closeOnly,
    pageTriggerContent,
    addToGroupPending,
    unlockAccPending,
    previewBonusPending,
    confirmCashoutPending,
    updateProfilePending,
    isRedirected,
    confirmAuthProvider,
    adcb,
  ]);

  const getModal = useMemo(() => {
    switch (openModal) {
      case 'userConfirmationModal': {
        return (
          <UserConfirmationModal
            confirmation_token={confirmation_token}
            closeModal={() => {
              router.push('/');
            }}
          />
        );
      }
      case 'changePasswordModal': {
        return (
          <ChangePasswordModal
            closeModal={() => {
              router.push('/');
            }}
            reset_password_token={reset_password_token}
          />
        );
      }
      case 'fill-profile': {
        return <FillProfileModal />;
      }
      case 'depositModal': {
        return (
          <Modal
            modalStylingOptions={{
              containerStyles: 'overflow-visible',
            }}
            setIsOpen={() => dispatch(setOpenTriggerModal(undefined))}
            isOpen={openModal === 'depositModal'}
            title={pageTriggerContent?.modal?.deposit}
          >
            <DepositForm
              deposit={awaitingDeposit as any}
              closeModal={() => dispatch(setOpenTriggerModal(undefined))}
            />
          </Modal>
        );
      }
      case 'updated_terms': {
        return (
          <Modal
            hasCloseButton={false}
            title={updatedTerms?.pages?.data[0]?.attributes?.title}
            isOpen={openModal === 'updated_terms'}
            setIsOpen={() => {}}
            footer={
              <div className="flex w-full flex-row justify-start">
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  color={updatedTermsAction?.button_variant || 'primary'}
                  onClick={async () => {
                    try {
                      await updateDetails({
                        email: currentUser?.email,
                        currency: currentUser?.currency,
                        terms_acceptance: true,
                        // receive_promos: true,
                      });
                      await mutateAsync({
                        receive_promos: true,
                      });
                      dispatch(setOpenTriggerModal(undefined));
                    } catch (e) {
                      throw e;
                    }
                  }}
                >
                  {updatedTermsAction?.label}
                </Button>
              </div>
            }
          >
            <div className="markdown-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: processMarkdown(
                    updatedTermsContent?.Markdown as string,
                  ),
                }}
              />
            </div>
          </Modal>
        );
      }
      case 'updated_info': {
        return (
          <Modal
            hasCloseButton={false}
            title={pageTriggerContent?.modal?.update_info?.title}
            isOpen={openModal === 'updated_info'}
            setIsOpen={() => {}}
          >
            <UpdateInfoContent />
          </Modal>
        );
      }
      case 'profile_limits': {
        return (
          <Modal
            modalStylingOptions={{
              containerStyles: 'overflow-visible',
            }}
            setIsOpen={() => dispatch(setOpenTriggerModal(undefined))}
            isOpen={openModal === 'profile_limits'}
            title={pageTriggerContent?.modal?.profile_limits}
          >
            <ProfileLimits />
          </Modal>
        );
      }
    }
  }, [
    awaitingDeposit,
    confirmation_token,
    currentUser?.currency,
    currentUser?.email,
    dispatch,
    isLoading,
    mutateAsync,
    openModal,
    pageTriggerContent?.modal?.deposit,
    pageTriggerContent?.modal?.profile_limits,
    pageTriggerContent?.modal?.update_info?.title,
    reset_password_token,
    router,
    updateDetails,
    updatedTerms?.pages?.data,
    updatedTermsAction?.button_variant,
    updatedTermsAction?.label,
    updatedTermsContent?.Markdown,
  ]);

  return getModal;
};

export default PageTriggers;
