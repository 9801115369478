import React from 'react';
import SelectBonusList from './select-bonus-list';
import { useTranslation } from '@/app/i18n/client';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { Badge, Text } from '@purposeinplay/core-v2';
import useBonusTime from '@/hooks/use-bonus-time';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import Countdown from '@/components/Countdown';

const BonusSelectBody = ({
  isInBonusesPage,
}: {
  isInBonusesPage?: boolean;
}) => {
  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;

  const { time } = useBonusTime();

  return (
    <div className="flex flex-col">
      <div className="mb-spacing-lg flex flex-col items-center">
        <h1 className="mb-spacing-2xs text-2xl font-bold text-base-primary">
          {content?.bonus_selection_modal?.title}
        </h1>
        <h3 className="mb-spacing-sm max-w-sm text-center text-base text-text-default">
          {content?.bonus_selection_modal?.description}
        </h3>
        <div className="flex flex-row items-center">
          <Image
            alt="Bonus Prize"
            height={16}
            width={16}
            src="/assets/lightning-limit.svg"
            loader={DEFAULT_IMAGE_LOADER}
          />
          <Text size="xs" className="mr-spacing-xs font-medium">
            {content?.bonus_selection_modal?.countdown_label}
          </Text>
          <Badge
            radius="rounded"
            size="sm"
            type="warning"
            className="mr-spacing-sm"
          >
            <Countdown date={time} withoutTextTag withSvg={false} />
          </Badge>
        </div>
      </div>
      <SelectBonusList isInBonusesPage={isInBonusesPage} />
    </div>
  );
};

export default BonusSelectBody;
