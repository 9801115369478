import useModal, { useCurrentModal } from '@/hooks/modal';
import { Modal } from '@purposeinplay/core-v2';
import React from 'react';
import WildPointsBody from './components/wild-points-body';
import { useTranslation } from '@/app/i18n/client';
import { WildPointsContentType } from '@/types/json-content/wild-points';

const WildPointsModal = () => {
  const { close } = useModal();
  const isOpen = useCurrentModal() === 'wild-points';

  const { t } = useTranslation();
  const wildPoints = t('wild_points') as WildPointsContentType;

  return (
    <Modal
      title={wildPoints?.modal?.title}
      size="xl"
      isOpen={isOpen}
      setIsOpen={() => close()}
      modalStylingOptions={{
        containerStyles: 'overflow-visible',
      }}
    >
      <WildPointsBody />
    </Modal>
  );
};

export default WildPointsModal;
