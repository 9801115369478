export const getContainerStyles = (type: string) => {
  const stylesLookup = {
    common: `prize-modal-common `,
    rare: `prize-modal-rare `,
    epic: `prize-modal-epic `,
  };

  if (type === 'common') {
    return stylesLookup.common;
  } else if (type === 'rare') {
    return stylesLookup.rare;
  }
  return stylesLookup.epic;
};
