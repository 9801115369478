import React from 'react';
import { Button, Modal } from '@purposeinplay/core-v2';
import { GuestMainHeading } from './heading';
import Steps from './steps';
import { GuestImage } from './hero-images';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';
// @ts-ignore

const GuestRafModal: React.FC = () => {
  const { close, open: openModal } = useModal();
  const isOpen = useCurrentModal() === 'refer_friend';

  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      setIsOpen={() => close()}
      onClose={() => close()}
      footer={
        <div className="ml-auto flex w-fit flex-row gap-spacing-xs">
          <Button
            size="md"
            color="tertiary"
            onClick={() => {
              close();
            }}
          >
            {content?.action?.close_btn}
          </Button>
          <Button size="md" onClick={() => openModal('signup')}>
            {content?.action?.create_account}
          </Button>
        </div>
      }
    >
      <div className="pointer-events-none absolute inset-0 rounded-3xl ">
        <div className="h-full w-full rounded-3xl">
          <GuestImage />
        </div>
      </div>
      <GuestMainHeading />

      <div className="w-full">
        <Steps />
      </div>
    </Modal>
  );
};

export default GuestRafModal;
