import React, { MouseEvent, useState } from 'react';
import { cn } from '@/utils/style';

const GameBottomNavItem = ({
  icon,
  handleClick,
  onClick,
  href,
  isActive,
  label,
}: {
  icon: React.ReactNode;
  handleClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  href?: string;
  isActive?: boolean;
  label?: string;
}) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const handleItemClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    } else if (handleClick) {
      handleClick(e);
    }
  };

  return (
    <a
      href={href}
      onClick={handleItemClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      className={cn([
        'wild-transition flex h-10 w-10 flex-col items-center justify-center gap-1',
        label === 'Notifications' || label === 'Favorites' ? 'w-[56px]' : '',
      ])}
    >
      <div
        className={cn([
          'flex h-5 w-5 items-center justify-center',
          isPressed ? 'scale-[0.8]' : 'scale-100',
        ])}
      >
        {icon}
      </div>
      {label && (
        <span className="whitespace-nowrap text-xs leading-none text-text-default">
          {label}
        </span>
      )}
    </a>
  );
};

export default GameBottomNavItem;
