import { cn } from '@/utils/style';
import React from 'react';
import useShouldHideIFrame from '@/modules/games/hooks/use-should-hide-iframe';

const GameViewLayout = ({ children }: { children: React.ReactNode }) => {
  const { shouldHide, isMinimized } = useShouldHideIFrame();

  // Empty divs simulate GameFrame header and footer from the game page

  return (
    <div
      id={`gameframe-wrapper-0`}
      className={cn([
        'pointer-events-auto relative z-[70] mx-auto flex aspect-[16/9] max-h-game flex-col  overflow-hidden  p-4',
        shouldHide && 'hidden',
        isMinimized && 'rounded-b-lg',
      ])}
    >
      <div className="h-[35px]" />
      <div
        className={cn([
          'relative my-3 flex-1',
          isMinimized && 'absolute inset-0 my-0',
          !isMinimized && 'overflow-hidden rounded-lg',
        ])}
      >
        {children}
      </div>
      <div className="h-9" />
    </div>
  );
};

export default GameViewLayout;
