import { IconButton, Text } from '@purposeinplay/core-v2';
import React, { useCallback } from 'react';
import ExpandSVG from 'public/assets/expand.svg';
import { useRouter } from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
  setGameView,
  setGameViewAt,
  setGameViewOpen,
  setIsMinimized,
} from '@/app/reducers/bootstrap';
import { useScreenLayout } from '../../context/screen-layout-context';

const MinimizedHeader = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const bootstrap = useAppSelector((state) => state.bootstrap);
  const { isMinimized } = bootstrap;
  const gameView = bootstrap.gameViews?.[0];
  const { clearGameForIndex } = useScreenLayout();

  const gameViewIndex = 0;

  const handleClearGame = useCallback(() => {
    clearGameForIndex(gameViewIndex);

    dispatch(setGameViewAt({ index: gameViewIndex, gameView: null }));

    dispatch(setIsMinimized(false));
    dispatch(setGameViewOpen(false));
  }, [clearGameForIndex, dispatch, gameViewIndex]);

  if (!isMinimized) {
    return null;
  }

  return (
    <div className="pointer-events-auto relative left-0 right-0 z-10 flex items-center rounded-t-md bg-bgr-subtile p-1.5 px-2">
      <Text className="truncate" size="sm" as="span">
        {gameView?.title}
      </Text>
      <div className="ml-auto flex flex-row items-center">
        <IconButton
          color="tertiary"
          ghost
          className="relative h-8 w-8 p-0"
          iconLeft={<ExpandSVG className="text-text-default" />}
          iconOnly
          onClick={() => {
            dispatch(setGameViewOpen(false));
            router.push(`/games/${gameView?.slug}`);
          }}
        />
        <IconButton
          color="tertiary"
          ghost
          iconOnly
          iconLeft={
            <svg
              className="text-text-default"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          }
          className="relative h-8 w-8 p-0"
          onClick={handleClearGame}
        />
      </div>
    </div>
  );
};

export default MinimizedHeader;
