import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { cn } from '@/utils/style';
import { sidebarBaseItemStyles, sidebarListWrapperStyles } from '../styles';
import SidebarItemIcon from './item-icon';
import { useGamesContent } from '@/hooks/use-games-content';
import { useOnClickOutside } from '@purposeinplay/utils';
import {
  useScreenLayout,
  ScreenLayout,
} from '@/modules/games/context/screen-layout-context';
import { TooltipPopover } from '@purposeinplay/core-v2';

const ScreenSelect = () => {
  const { gamesContent } = useGamesContent();
  const displayOptions = gamesContent.game_sidebar.display_options;
  const accessibility = gamesContent.game_sidebar.accessibility;
  const { layout, setLayout, isFullScreen, setIsFullScreen } =
    useScreenLayout();
  const [displayOn, setDisplayOn] = useState<'hover' | 'click'>('hover');

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('/assets/games/screen.svg');
  const [animatingButtons, setAnimatingButtons] = useState<boolean[]>([]);
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => {
    setIsOpen(false);
    setDisplayOn('hover');
  });

  const itemProps = cn([sidebarBaseItemStyles, 'relative !w-full']);

  const handleToggleFullscreen = useCallback(() => {
    import('screenfull')
      .then((Screenfull) => {
        let screenfull = Screenfull.default;
        if (screenfull.isEnabled) {
          // For multi-game layouts, target the grid container
          // For single game, use the specific game wrapper
          const isSingle = layout === 'single';
          const targetElement = document.getElementById(
            isSingle ? `gameframe-wrapper-0` : 'game-grid-container',
          );
          if (targetElement) {
            // If already in fullscreen, exit fullscreen
            if (screenfull.isFullscreen) {
              screenfull.exit();
              setIsFullScreen(false);
            } else {
              // Enter fullscreen
              screenfull.request(targetElement);
              setIsFullScreen(true);

              // Apply fullscreen-specific styling
              if (layout === 'quad') {
                // For quad layout, ensure container has proper height and overflow handling
                targetElement.classList.add('fullscreen-quad-layout');
                targetElement.style.height = '100vh';
                targetElement.style.maxHeight = '100vh';
                targetElement.style.overflowY = 'auto';
              }
            }
          } else {
            console.warn(displayOptions.screen_warnings.grid_container_missing);
          }
        }
      })
      .catch((error) => console.error('Failed to load screenfull:', error));
  }, [
    layout,
    displayOptions.screen_warnings.grid_container_missing,
    setIsFullScreen,
  ]);

  const optionButtons = useMemo(
    () => [
      {
        icon: '/assets/games/expand.svg',
        title: displayOptions.fullscreen,
        layout: layout,
        action: () => handleToggleFullscreen(),
      },
      {
        icon: '/assets/games/screen.svg',
        title: displayOptions.single_screen,
        layout: 'single' as ScreenLayout,
        action: () => setLayout('single'),
      },
      {
        icon: '/assets/games/2-screens.svg',
        title: displayOptions.two_screens,
        layout: 'double' as ScreenLayout,
        action: () => setLayout('double'),
      },
      {
        icon: '/assets/games/4-screens.svg',
        title: displayOptions.four_screens,
        layout: 'quad' as ScreenLayout,
        action: () => setLayout('quad'),
      },
    ],
    [
      displayOptions.fullscreen,
      displayOptions.single_screen,
      displayOptions.two_screens,
      displayOptions.four_screens,
      layout,
      handleToggleFullscreen,
      setLayout,
    ],
  );

  // Initialize animation states for each button
  useEffect(() => {
    if (isOpen) {
      // Stagger the animations
      optionButtons.forEach((_, index) => {
        setTimeout(() => {
          setAnimatingButtons((prev) => {
            const newState = [...prev];
            newState[index] = true;
            return newState;
          });
        }, index * 100);
      });
    } else {
      setAnimatingButtons([]);
    }
  }, [isOpen, optionButtons]);

  // Set initial icon based on layout
  useEffect(() => {
    const option = optionButtons.find((opt) => opt.layout === layout);
    if (option) {
      setSelectedIcon(option.icon);
    }
  }, [layout, optionButtons]);

  // Listen for fullscreen changes (e.g., when user presses ESC to exit fullscreen)
  useEffect(() => {
    const handleFullscreenChange = () => {
      import('screenfull').then(({ default: screenfull }) => {
        if (screenfull.isEnabled && !screenfull.isFullscreen) {
          // User exited fullscreen with ESC key
          console.log('Fullscreen exited via ESC key');
          setIsFullScreen(false);

          // Clean up fullscreen-specific styling
          const targetElement = document.getElementById('game-grid-container');
          if (targetElement) {
            targetElement.classList.remove('fullscreen-quad-layout');
            targetElement.style.height = '';
            targetElement.style.maxHeight = '';
            targetElement.style.overflowY = '';
          }
        }
      });
    };

    // Add event listener for fullscreen changes
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      // Clean up event listeners
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange,
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange,
      );
      document.removeEventListener(
        'MSFullscreenChange',
        handleFullscreenChange,
      );
    };
  }, []);

  const handleOptionClick = (
    icon: string,
    newLayout: ScreenLayout,
    action: () => void,
  ) => {
    if (icon) {
      setSelectedIcon(icon);
    }
    if (action) {
      action();
    }
    setLayout(newLayout);
    setIsOpen(false);
    setTimeout(() => {
      setDisplayOn('hover');
    }, 100);
  };

  const getButtonClassName = (index: number, isSelected: boolean) => {
    return cn([
      sidebarListWrapperStyles,
      'z-[9999] flex h-12 w-12 items-center justify-center overflow-hidden rounded-lg transition-all duration-200',
      'transform opacity-0 translate-x-[-20px]',
      'xl:flex xl:items-center xl:justify-center [&>span]:xl:w-full [&>span]:xl:h-full',
      animatingButtons[index] && 'opacity-100 translate-x-0',
      !isSelected && 'bg-bgr-modal',
    ]);
  };

  return (
    <TooltipPopover
      key={displayOn}
      styleOptions={{
        popperStyles: 'z-[99999] p-2 text-xs',
        arrowStyles: '!z-[99999]',
        triggerStyles: 'w-full',
      }}
      trigger={
        <li
          ref={containerRef}
          className={cn([
            'flex w-full list-none items-center justify-center',
            sidebarListWrapperStyles,
          ])}
        >
          <div className="relative flex w-full items-center justify-center">
            <button
              className={itemProps}
              onClick={() => {
                setIsOpen(!isOpen);
                setDisplayOn('click');
              }}
              aria-expanded={isOpen}
              aria-label={accessibility.display_options_label}
            >
              <SidebarItemIcon forGamesPage icon={selectedIcon} />
            </button>

            {isOpen && (
              <div className="absolute -top-1 left-[50px] z-[9999] flex gap-1">
                {optionButtons.map((button, i) => {
                  const isSelected = layout === button.layout;
                  return (
                    <button
                      key={button.title}
                      onClick={() => {
                        handleOptionClick(
                          button.icon,
                          button.layout,
                          button.action,
                        );
                      }}
                      className={getButtonClassName(i, isSelected)}
                      aria-label={button.title}
                      style={{
                        transitionDelay: `${i * 0.1}s`,
                      }}
                    >
                      <span className={itemProps}>
                        <SidebarItemIcon
                          forGamesPage
                          icon={button.icon}
                          imageProps={{
                            className: [
                              'object-contain',
                              !isSelected && 'opacity-30',
                            ]
                              .filter(Boolean)
                              .join(' '),
                          }}
                        />
                        <span className="sr-only">{button.title}</span>
                      </span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </li>
      }
      placement="right"
      displayOn={displayOn}
    >
      {displayOptions.title}
    </TooltipPopover>
  );
};

export default ScreenSelect;
