import React, { useEffect, useState, useRef } from 'react';
import { useGamePageBottomDrawer } from '../context/game-page-bottom-drawer-context';
import { cn } from '@/utils/style';
import DrawerContentRenderer from './drawer-content-renderer';
import { useGamesContent } from '@/hooks/use-games-content';
import { Text } from '@purposeinplay/core-v2';
import useAppBodyLock from '@/hooks/use-app-body-lock';
import BackArrow from 'public/assets/back-arrow.svg';

interface AnimatedDrawerProps {
  className?: string;
}

export const AnimatedDrawer: React.FC<AnimatedDrawerProps> = ({
  className,
}) => {
  const {
    isOpen,
    setIsOpen,
    activeSection,
    selectedGameTitle,
    selectedGameCat,
    setSelectedGameCat,
    setSelectedGameTitle,
  } = useGamePageBottomDrawer();
  const { gamesContent } = useGamesContent();
  const drawer = gamesContent.bottom_navigation.drawer;
  const sections = gamesContent.game_sidebar.sections;
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showJackpotDetail, setShowJackpotDetail] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const drawerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useAppBodyLock(isOpen);

  useEffect(() => {
    if (!isOpen) {
      setShowJackpotDetail(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleCloseDrawer = () => {
      setIsOpen(false);
    };

    document.addEventListener('closeBottomDrawer', handleCloseDrawer);

    return () => {
      document.removeEventListener('closeBottomDrawer', handleCloseDrawer);
    };
  }, [setIsOpen]);

  useEffect(() => {
    const handleShowJackpotDetail = () => {
      setShowJackpotDetail(true);
    };

    const handleHideJackpotDetail = () => {
      setShowJackpotDetail(false);
    };

    window.addEventListener('showJackpotDetail', handleShowJackpotDetail);
    window.addEventListener('hideJackpotDetail', handleHideJackpotDetail);

    return () => {
      window.removeEventListener('showJackpotDetail', handleShowJackpotDetail);
      window.removeEventListener('hideJackpotDetail', handleHideJackpotDetail);
    };
  }, []);

  const getTitle = () => {
    if (!activeSection) return drawer.default_title;

    if (activeSection === 'more') {
      if (showJackpotDetail) return drawer.jackpot;
      return drawer.more;
    }

    if (activeSection === 'new') {
      return drawer.new_games;
    }

    if (activeSection === 'popular') {
      return drawer.popular_games;
    }

    if (activeSection === 'favorites' && selectedGameTitle) {
      return selectedGameTitle;
    }

    // Use sections from the game_sidebar if available
    if (
      activeSection &&
      sections &&
      sections[activeSection as keyof typeof sections]
    ) {
      return sections[activeSection as keyof typeof sections];
    }

    return drawer.default_title;
  };

  // Handle different back button actions depending on context
  const handleBackClick = () => {
    // If we're viewing jackpot details
    if (activeSection === 'more' && showJackpotDetail) {
      window.dispatchEvent(new CustomEvent('hideJackpotDetail'));
      setShowJackpotDetail(false);
    }
    // If we're viewing a selected game category
    else if (
      activeSection === 'favorites' &&
      selectedGameCat &&
      selectedGameTitle
    ) {
      // Reset game category and title
      setSelectedGameCat(null);
      setSelectedGameTitle(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setOffsetY(0);
      setTimeout(() => setIsAnimating(true), 10);
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;

    const currentY = e.touches[0].clientY;
    const diff = currentY - startY;

    if (diff > 0) {
      setOffsetY(diff);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);

    if (offsetY > 100) {
      setIsOpen(false);
    } else {
      setOffsetY(0);
    }
  };

  const handleNotchClick = () => {
    setIsOpen(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        className={cn(['fixed inset-0 z-40'])}
        onClick={handleOverlayClick}
      />

      <div
        ref={drawerRef}
        className={cn([
          className || '',
          'pointer-events-auto fixed inset-x-0 bottom-0 z-[998] origin-bottom overflow-hidden rounded-t-2xl bg-bgr-modal p-3 shadow-lg',
          'transition-transform duration-300 ease-in-out',
          isAnimating ? 'translate-y-0' : 'translate-y-full',
        ])}
        style={{
          transform: isDragging ? `translateY(${offsetY}px)` : undefined,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div onClick={handleNotchClick}>
          <div className="absolute left-1/2 top-3 h-1.5 w-14 -translate-x-1/2 cursor-pointer rounded-full bg-bgr-light" />

          <Text
            as="span"
            className="block pt-3 text-center text-base font-semibold"
          >
            {/* Show back button for jackpot detail or selected game category */}
            {(activeSection === 'more' && showJackpotDetail) ||
            (activeSection === 'favorites' &&
              selectedGameCat &&
              selectedGameTitle) ? (
              <div className="flex items-center justify-center">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBackClick();
                  }}
                  className="absolute left-3 flex items-center text-sm font-normal"
                >
                  <BackArrow className="mr-1 h-3 w-3 fill-current text-text-default" />
                </button>
                {getTitle()}
              </div>
            ) : (
              getTitle()
            )}
          </Text>
        </div>

        <div ref={contentRef} className="mt-4 h-full pb-12">
          <DrawerContentRenderer />
        </div>
      </div>
    </>
  );
};
