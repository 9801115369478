import { setOpenChat } from '@/app/reducers/bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import React from 'react';

const useChatState = () => {
  const dispatch = useAppDispatch();
  const isChatOpen = useAppSelector((state) => state.bootstrap.isChatOpen);
  const toggleChat = () => {
    dispatch(setOpenChat(!isChatOpen));
  };

  const closeChat = () => {
    dispatch(setOpenChat(false));
  };

  const openChat = () => {
    dispatch(setOpenChat(true));
  };

  return { toggleChat, closeChat, openChat };
};

export default useChatState;
