import React from 'react';

export function splitArray(array: any[]) {
  if (array.length < 6) {
    return { firstFive: array, rest: [] };
  }
  return { firstFive: array.slice(0, 5), rest: array.slice(5) };
}

export const getHref = (opensModal: string) => {
  switch (opensModal) {
    case 'bonuses':
      return '/bonus-shop';
    case 'wheel':
      return '/wof';
    case 'refer_friend':
      return '?m=refer_friend';
    case 'telegram':
      return '/telegram';
    case 'pwa':
      return '/jackpot';
    case 'wild_points':
      return '/lottery';
    case 'advent':
      return '/advent-calendar';
    default:
      return '/';
  }
};
