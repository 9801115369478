import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import EmojiPicker, { Theme } from 'emoji-picker-react';
import { IconButton } from '@purposeinplay/core-v2';
import EmojiSVG from 'public/assets/emoji.svg';
import { useOnClickOutside } from '@purposeinplay/utils';
import useDarkMode from '@/hooks/dark';
import '../styles/chat.css';
import { cn } from '@/utils/style';

const EmojiPickerComponent = ({
  setInputValue,
}: {
  setInputValue: Dispatch<SetStateAction<string>>;
}) => {
  // State to track the picker visibility and selected emoji
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  // Function to handle emoji selection
  const onEmojiClick = (emojiData: any, e: any) => {
    e.preventDefault();
    setInputValue((prevValue) => prevValue + emojiData.emoji);
    setSelectedEmoji(emojiData.emoji);
    setIsPickerOpen(false); // Close picker after selecting
  };

  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsPickerOpen(false));

  const { darkMode } = useDarkMode();
  const emojiTheme = darkMode ? Theme.DARK : Theme.LIGHT;

  return (
    <div
      ref={containerRef}
      className="absolute right-2 top-2 flex items-center justify-center"
    >
      <div className="relative">
        <IconButton
          onClick={() => setIsPickerOpen(!isPickerOpen)}
          iconOnly
          color="tertiary"
          size="xs"
          iconStyles="flex items-center justify-center !rounded-full h-4 w-4"
          iconLeft={
            <EmojiSVG className="h-4 w-4 fill-current text-text-default" />
          }
        />
        {isPickerOpen && (
          <div
            className={cn([
              'absolute -left-[17rem] bottom-8 z-50 mb-2 [&>aside>div:nth-of-type(2)>ul>li>h2]:bg-bgr-modal [&>aside>div:nth-of-type(2)>ul>li>h2]:text-sm [&>aside>div:nth-of-type(2)>ul>li>h2]:text-text-default [&>aside>div:nth-of-type(3)]:hidden [&>aside>div>div]:bg-bgr-modal [&>aside]:border-none [&>aside]:bg-bgr-modal',
            ])} /* Adjust position above */
          >
            <EmojiPicker theme={emojiTheme} onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmojiPickerComponent;
