'use client';

import React, { forwardRef } from 'react';
import { cn } from '@/utils/style';
import { ChatAsideProps } from '../types';

const MobileChat = forwardRef<HTMLDivElement, ChatAsideProps>(
  ({ isChatOpen, children }, ref) => {
    return (
      <aside
        ref={ref}
        className={cn([
          'transition-element fixed bottom-0 right-0 top-0 z-[999] flex w-full flex-col justify-between bg-bgr-lighter p-2',
          isChatOpen ? 'translate-x-0' : '-translate-x-full',
        ])}
      >
        {children}
      </aside>
    );
  },
);

MobileChat.displayName = 'MobileChat';

export default MobileChat;
