import { useTranslation } from '@/app/i18n/client';
import { GamesContentType } from '@/types/json-content/games';
import { GameContentProps } from '@/modules/category-page/types';

export const useGamesContent = () => {
  const { t } = useTranslation();
  const gamesContent = t('games') as GamesContentType;

  return { gamesContent };
};
