'use client';

import React, { Suspense } from 'react';
import SigninModal from './auth/signin-modal';
import { useCurrentModal } from '@/hooks/modal';
import SignupModal from './auth/signup-modal';
import ForgotPasswordModal from './auth/forgot-pass-modal';
import ChallengesModal from '@/modules/challenges/components/challenges-modal';
import WheelModal from '@/modules/wheel-of-fortune/components/wheel-modal';
import PrizeModal from '../PrizeModal';
import WildPointsModal from '../WildPointsModal';
import LimitsModal from '../Settings/components/limits/components/limits-modal';
import BonusSelectModal from '../Bonuses/components/bonus-select-modal';
import WalletModal from '../Wallet/WalletModal';
import WalletSettingsModal from '../Wallet/WalletSettingsModal';
import PromoCode from '../Bonuses/components/promo-code';
import RestrictedRegionModal from '../RestrictedRegionModal';
import AvatarModal from '../AvatarModal';
import FillProfileModal from '../PageTriggers/components/fill-profile-modal';
import RafModalWrapper from '../ReferFriend/raf-modal-wrapper';
import LotteryTermsModal from '../PageTriggers/components/lottery-terms';
import BonusDetailsPreviewModal from '../Bonuses/components/bonus-details-modal-preview';
import VerifyAgentModal from '@/modules/verify-agent';

const handleModal = (modal: string | null) => {
  if (modal?.includes('wheel')) {
    return <WheelModal />;
  }
  switch (modal) {
    case 'signin':
      return <SigninModal />;
    case 'signup':
      return <SignupModal />;
    case 'forgot-pass':
      return <ForgotPasswordModal />;
    case 'challenges':
      return <ChallengesModal />;
    case 'prize':
      return <PrizeModal />;
    case 'wild-points':
      return <WildPointsModal />;
    case 'confirm_limit':
      return <LimitsModal />;
    case 'bonus-selection':
      return <BonusSelectModal />;
    case 'details':
      return <BonusDetailsPreviewModal />;
    case 'wallet':
      return <WalletModal />;
    case 'wallet-settings':
      return <WalletSettingsModal />;
    case 'promo-code':
      return <PromoCode forModal />;
    case 'restricted-region':
      return <RestrictedRegionModal />;
    case 'refer_friend':
      return <RafModalWrapper />;
    case 'avatar':
      return <AvatarModal />;
    case 'fill-profile':
      return <FillProfileModal />;
    case 'lottery-terms':
      return <LotteryTermsModal />;
    case 'verify-agent':
      return <VerifyAgentModal />;
    default:
      return null;
  }
};

const Modals = () => {
  const currentModal = useCurrentModal();
  return <Suspense>{handleModal(currentModal)}</Suspense>;
};

export default Modals;
