import { Input } from '@purposeinplay/core-v2';
import EmojiPickerComponent from './emoji-picker';
import React, { useMemo, useState } from 'react';
import NicknameModal from './nickname-modal';
import useWildChatContent from '../hooks/use-wild-chat-content';
import { useAuth } from '@/hooks/auth';
import SubmitButton from './submit-button';
import { useChat } from '@/app/providers/chat';
import { usePlayerApiSettings } from '../hooks/use-get-chat-settings';
import useModal from '@/hooks/modal';
import { scrollToChatBottom } from '../utils';
import useChatState from '../hooks/use-chat-state';
import UnlockChatModal from './unlock-chat-modal';

const ChatFooter = () => {
  const chatContent = useWildChatContent();
  const [open, setOpen] = useState(false);
  const [isUnlockOpen, setIsUnlockOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { closeChat } = useChatState();
  const { addMessage, isLoading } = useChat();
  const { data: chatPlayerSettings, isPending: isChatSettingsLoading } =
    usePlayerApiSettings();
  const { user } = useAuth();
  const { open: openModal } = useModal();

  const hasNickname = useMemo(() => {
    return (
      !isChatSettingsLoading &&
      chatPlayerSettings?.current_user?.chat_name &&
      chatPlayerSettings?.current_user?.chat_name !== ''
    );
  }, [chatPlayerSettings?.current_user?.chat_name, isChatSettingsLoading]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const hasAccessToChat = useMemo(() => {
    return user?.currentUser?.statuses?.find(
      (status: { id: string }) => status?.id === 'chatyes1d:feature',
    );
  }, [user?.currentUser?.statuses]);

  const handleSubmit = (e: any) => {
    if (!hasAccessToChat) {
      setIsUnlockOpen(true);
      return;
    }
    if (hasNickname) {
      return addMessage(inputValue).then(() => {
        setInputValue('');
        // Smooth scroll to bottom
        scrollToChatBottom();
      });
    }
    setOpen(true);
  };

  const handleButtonPress = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    if ('key' in e && e?.key !== 'Enter') return;

    // Needed because enter triggers emoji picker for some reason
    e.preventDefault();

    if (user?.isAuthenticated) {
      handleSubmit(e);
      return;
    }

    openModal('signup');
    closeChat();
  };

  // Needed because emoji's are counted as 2 characters
  const visualLength = useMemo(() => {
    return Array.from(inputValue).length;
  }, [inputValue]);

  return (
    <div className="flex flex-row space-x-spacing-xs">
      <div className="relative flex-1">
        <Input
          onKeyDown={handleButtonPress}
          maxLength={255}
          value={inputValue}
          onChange={handleChange}
          className="!pr-10"
          variant="static"
          placeholder={chatContent?.input?.placeholder}
        />
        <EmojiPickerComponent setInputValue={setInputValue} />
      </div>
      <SubmitButton
        onClick={handleButtonPress}
        isDisabled={visualLength === 0}
        isLoading={isLoading}
      />
      <NicknameModal open={open} setOpen={setOpen} />
      <UnlockChatModal isOpen={isUnlockOpen} setIsOpen={setIsUnlockOpen} />
    </div>
  );
};

export default ChatFooter;
