import { IconButton } from '@purposeinplay/core-v2';
import React from 'react';
import ExpandSVG from 'public/assets/expand.svg';
import { cn } from '@/utils/style';

const ActionBtn = ({
  svg,
  onClick,
  className,
  color,
  ghost,
  ...props
}: {
  svg?: JSX.Element;
  className?: string;
  ghost?: boolean;
  color?: any;
  onClick?: (e: any) => void;
}) => {
  return (
    <IconButton
      color="tertiary"
      className={cn(['absolute right-9 top-2 lg:top-2.5', className])}
      iconLeft={svg ? svg : <ExpandSVG />}
      iconOnly
      ghost
      size="sm"
      onClick={onClick && onClick}
      {...props}
    />
  );
};

export default ActionBtn;
