'use client';
import React from 'react';
import Image from 'next/image';
import { Heading, IconButton } from '@purposeinplay/core-v2';
import AroundSVG from 'public/assets/around.svg';
import SupportSvg from 'public/assets/support.svg';
import Link from 'next/link';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { formattedMessage } from '../utils';
import { useSupportChatToggle } from '@/hooks/use-support-chat-toggle';

const RestrictedRegionContent = () => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const restrictedRegionContent = general?.restricted_page;

  const { handleClick } = useSupportChatToggle();

  return (
    <div className="flex flex-col space-y-7">
      <div className="flex justify-center">
        <Image
          src="/assets/restricted/restricted-img.png"
          alt="Ethereum"
          width={237}
          height={128}
        />
      </div>
      <div className="flex flex-col space-y-3">
        <Heading
          as="h1"
          className="text-2xl font-bold leading-8 lg:text-center"
        >
          {/* Highlights wild.io text */}
          {formattedMessage(restrictedRegionContent?.sorry)}
        </Heading>
        <Heading
          as="h2"
          className="text-sm font-normal leading-6 lg:text-center"
        >
          {restrictedRegionContent?.using_vpn}
        </Heading>
      </div>
      <div className="flex items-center justify-center">
        <Link href="mailto:support@wild.io">
          <IconButton
            size="sm"
            color="tertiary"
            className="mr-2 h-8 w-8"
            iconLeft={<AroundSVG className="stroke-current stroke-1" />}
            iconOnly
          />
        </Link>
        <IconButton
          size="sm"
          color="tertiary"
          iconOnly
          className="h-8 w-8"
          iconLeft={<SupportSvg tw="fill-current" />}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default RestrictedRegionContent;
