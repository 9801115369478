'use client';
import { useChallenges, useRewards } from '@/hooks/challenges';
import React, { useMemo, useState } from 'react';
import { Challenge, ChallengesContentProps, RewardResult } from '../types';
import {
  CARD_COUNT,
  CARD_COUNT_MODAL,
  REWARD_PLACEHOLDERS,
} from '../constants';
import { calculatePercentageAndFooterContent } from '../utils';
import { useAuth } from '@/hooks/auth';
import CategoryHeading from '@/components/CategoryHeading';
import ChallengesHeadingLeft from './challenges-heading-left';
import ChallengesHeadingRight from './challenges-heading-right';
import ChallengesAccordion from './challenges-accordion';
import ChallengeList from './challenge-list';
import { ChallengesNoResults } from './challenges-no-results';
import { cn } from '@/utils/style';
import { useTranslation } from 'react-i18next';
import { useGamesByIds } from '@/hooks/games/filters';
import { ChallengeListLoader } from '@/components/Loaders';
import useGetPageContent from '@/hooks/use-get-page-content';
import { useDynamicContent } from '@/utils/content-helpers';

const ChallengesBody = ({ forPage }: { forPage?: boolean }) => {
  const [activeTab, setActiveTab] = useState('active');
  const { data: challenges, isLoading: areChallengesLoading } = useChallenges();

  const pageContent = useGetPageContent('/challenges');

  const dynamicContent = useDynamicContent({
    attributes: pageContent,
  });

  const { data: rewards, isLoading: areRewardsLoading } = useRewards();

  const { user } = useAuth();

  const { t } = useTranslation();
  const challengesContent = t('challenges') as ChallengesContentProps;

  const challengeIdentifiers = useMemo(() => {
    return challenges?.challenges?.map(
      (challenge: Challenge) => challenge.game_identifier,
    );
  }, [challenges]);

  const rewardsIdentifiers = useMemo(() => {
    const identifiers = rewards?.achievements?.map(
      (reward: RewardResult) => reward.game_identifier,
    );
    const placeholderIdentifiers = REWARD_PLACEHOLDERS.map(
      (reward) => reward.game_identifier,
    );

    if (identifiers) {
      return Array.from(new Set([...identifiers, ...placeholderIdentifiers]));
    }

    return Array.from(new Set(placeholderIdentifiers));
  }, [rewards]);

  const identifiersToUse = useMemo(() => {
    switch (activeTab) {
      case 'active':
        return challengeIdentifiers;
      case 'myClaims':
      case 'allClaims':
        return rewardsIdentifiers;
      default:
        return [];
    }
  }, [activeTab, challengeIdentifiers, rewardsIdentifiers]);

  const { data: completeGames, isLoading: areGamesLoading } = useGamesByIds(
    {
      game_ids: identifiersToUse,
    },
    {
      enabled: identifiersToUse?.length > 0,
    },
  );

  const isDataLoading = useMemo(() => {
    return areChallengesLoading || areRewardsLoading || areGamesLoading;
  }, [areChallengesLoading, areRewardsLoading, areGamesLoading]);

  const [loadedCount, setLoadedCount] = useState(
    forPage ? CARD_COUNT : CARD_COUNT_MODAL,
  );

  const id = user?.currentUser?.id;

  const myClaims = rewards?.achievements?.filter((reward: RewardResult) => {
    return reward.user_id === id?.toString();
  });

  const allClaims = rewards?.achievements;

  const hasNoChallenges =
    challenges?.challenges?.length === 0 || !challenges?.challenges;
  const hasNoRewards = myClaims?.length === 0 || !myClaims;

  const gameIds = useMemo(() => {
    return completeGames?.map((game: any) => game?.identifier);
  }, [completeGames]);

  const challengesUpdated = useMemo(() => {
    return challenges?.challenges?.filter((challenge: Challenge) =>
      gameIds?.includes(challenge?.game_identifier),
    );
  }, [challenges?.challenges, gameIds]);

  const allClaimsUpdated = useMemo(() => {
    let claims;
    if (allClaims) {
      claims = [...REWARD_PLACEHOLDERS, ...allClaims];
    } else {
      claims = [...REWARD_PLACEHOLDERS];
    }
    return claims?.filter((claim: RewardResult) => {
      return gameIds?.includes(claim?.game_identifier);
    });
  }, [allClaims, gameIds]);

  const myClaimsUpdated = useMemo(() => {
    return myClaims?.filter((claim: RewardResult) => {
      return gameIds?.includes(claim?.game_identifier);
    });
  }, [myClaims, gameIds]);

  const totalCount = useMemo(() => {
    switch (activeTab) {
      case 'active':
        return challengesUpdated?.length || 0;
      case 'myClaims':
        return myClaimsUpdated?.length || 0;
      case 'allClaims':
        return allClaimsUpdated?.length || 0;
      default:
        return 0;
    }
  }, [
    activeTab,
    allClaimsUpdated?.length,
    challengesUpdated?.length,
    myClaimsUpdated?.length,
  ]);

  const getContent = useMemo(() => {
    if (isDataLoading) {
      return <ChallengeListLoader forPage={forPage} />;
    }

    const noChallenges = hasNoChallenges;
    const noRewards = !areRewardsLoading && hasNoRewards;
    const loadedClaims = myClaimsUpdated?.slice(0, loadedCount);
    const loadedChallenges = challengesUpdated.slice(0, loadedCount);

    switch (activeTab) {
      case 'active':
        return noChallenges ? (
          <ChallengesNoResults
            description={challengesContent?.no_results?.active_challenges}
          />
        ) : (
          <ChallengeList
            isForChallenge
            completeGames={completeGames}
            forPage={forPage}
            items={loadedChallenges}
          />
        );
      case 'myClaims':
        return noRewards ? (
          <ChallengesNoResults
            description={challengesContent?.no_results?.my_claims}
          />
        ) : (
          <ChallengeList
            completeGames={completeGames}
            forPage={forPage}
            items={loadedClaims}
          />
        );
      case 'allClaims':
        return allClaimsUpdated?.length === 0 ? (
          <ChallengesNoResults
            description={challengesContent?.no_results?.all_claims}
          />
        ) : (
          <ChallengeList
            completeGames={completeGames}
            forPage={forPage}
            items={allClaimsUpdated.slice(0, loadedCount)}
          />
        );
      default:
        return null;
    }
  }, [
    isDataLoading,
    hasNoChallenges,
    areRewardsLoading,
    hasNoRewards,
    myClaims,
    loadedCount,
    challenges?.challenges,
    activeTab,
    challengesContent?.no_results,
    completeGames,
    forPage,
    allClaimsUpdated,
  ]);

  return (
    <div>
      <CategoryHeading
        className={cn([
          'mb-2 flex flex-col items-start justify-between space-y-3 md:flex-row  md:items-center md:space-y-0',
          !forPage && 'block w-full',
        ])}
      >
        {forPage ? (
          <ChallengesHeadingLeft title={challengesContent?.page_title} />
        ) : null}
        <ChallengesHeadingRight
          tabs={challengesContent?.tabs}
          forPage={forPage}
          setActiveTab={setActiveTab}
        />
      </CategoryHeading>
      <ChallengesAccordion
        forPage={forPage}
        totalCount={totalCount}
        loadedCount={loadedCount}
        setLoadedCount={setLoadedCount}
        cardsToLoad={CARD_COUNT}
        content={challengesContent?.container_footer}
      >
        {getContent}
        {calculatePercentageAndFooterContent(
          loadedCount,
          totalCount,
          challengesContent?.container_footer?.progress_bar,
        )}
      </ChallengesAccordion>
      {forPage && dynamicContent && dynamicContent?.length > 0 && (
        <div className="flex-start relative inline-flex w-full flex-col justify-start gap-4">
          {dynamicContent?.map((content: any) => content)}
        </div>
      )}
    </div>
  );
};

export default ChallengesBody;
