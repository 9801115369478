import React from 'react';
import { Tournament } from '@/types/tournaments';
import MiniTournamentCard from './mini-tournament-card';
import EnrollBanner from '@/modules/tournaments/components/enroll-banner';
import LeaderboardTitle from '@/modules/tournaments/components/leaderboard-title';
import TournamentTable from '@/modules/tournaments/components/tournament-table';

interface TournamentDetailViewProps {
  tournament: Tournament | null;
}

const TournamentDetailView: React.FC<TournamentDetailViewProps> = ({
  tournament,
}) => (
  <div className="w-1/2 flex-shrink-0 overflow-auto">
    {tournament && (
      <div className="flex flex-col gap-7">
        <MiniTournamentCard tournament={tournament} />
        <EnrollBanner isForSidebar tournament={tournament} />
        <div>
          <LeaderboardTitle className="mb-2.5 text-xl font-semibold lg:mb-2.5" />
          <TournamentTable isForSidebar tournament={tournament} />
        </div>
      </div>
    )}
  </div>
);

export default TournamentDetailView;
