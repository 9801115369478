import React from 'react';
import { Tournament } from '@/types/tournaments';
import MiniTournamentCard from './mini-tournament-card';
import { Button } from '@purposeinplay/core-v2';
import Link from 'next/link';
import { useGamesContent } from '@/hooks/use-games-content';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';

interface TournamentListViewProps {
  tournaments: Tournament[];
  onSelectTournament: (tournament: Tournament) => void;
}

const TournamentListView: React.FC<TournamentListViewProps> = ({
  tournaments,
  onSelectTournament,
}) => {
  const { gamesContent } = useGamesContent();
  const content = gamesContent;
  const { setIsOpen: setSidebarIsOpen } = useGamePageSidebar();

  const handleViewAllClick = () => {
    setSidebarIsOpen(false);
  };

  return (
    <div className="w-1/2 flex-shrink-0">
      <div className="flex flex-col gap-2">
        {tournaments.map((tournament) => (
          <div
            key={tournament?.id}
            onClick={() => onSelectTournament(tournament)}
            className="cursor-pointer"
          >
            <MiniTournamentCard tournament={tournament} />
          </div>
        ))}
        <Link href={'/tournaments'} onClick={handleViewAllClick}>
          <Button linkHref="/tournaments" className="w-full" color="tertiary">
            {content?.view_tournaments}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TournamentListView;
