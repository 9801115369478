import { Button, Modal, Text } from '@purposeinplay/core-v2';
import React, { Dispatch, SetStateAction } from 'react';
import useWildChatContent from '../hooks/use-wild-chat-content';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import Link from 'next/link';

const UnlockChatModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const unlockChatContent = useWildChatContent().unlock_chat_modal;

  const BUTTON_LINK = '/?m=wallet&a=deposit';

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="mb-0 flex flex-col items-center justify-center space-y-7">
        <Text size="lg" className="font-semibold">
          {unlockChatContent.title}
        </Text>
        <Image
          loader={DEFAULT_IMAGE_LOADER}
          src="assets/send.svg"
          width={52}
          height={52}
          alt="Send Image"
        />
        <Text className="text-center text-text-subdued">
          {unlockChatContent.description}
        </Text>
        <Link className="w-full" href={BUTTON_LINK} passHref>
          <Button linkHref={BUTTON_LINK} className="w-full">
            {unlockChatContent.button_cta}
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

export default UnlockChatModal;
