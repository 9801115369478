import { useTranslation } from '@/app/i18n/client';
import { WildPointIcon } from '@/components/Lottery/components/my-wp';
import { WildPointsContentType } from '@/types/json-content/wild-points';
import React from 'react';

const AvailableWildPoints = ({
  pointsRemaining,
}: {
  pointsRemaining: number | string;
}) => {
  const { t } = useTranslation();
  const wildPointsContent = t('wild_points') as WildPointsContentType;
  return (
    <div className="flex shrink-0 flex-row items-center space-x-1">
      <span className="whitespace-nowrap text-xs font-medium leading-none text-text-default">
        {wildPointsContent?.modal?.header?.left}:
      </span>
      <div className="flex flex-row items-center space-x-1 text-xs font-medium text-green-500">
        <span> {pointsRemaining || 0}</span>
        <WildPointIcon size={16} />
      </div>
    </div>
  );
};

export default AvailableWildPoints;
