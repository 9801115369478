'use client';

import { useAppSelector } from '@/hooks/redux';
import useIsOnGamePage from '@/hooks/use-is-on-game-page';
import { cn } from '@/utils/style';
import React from 'react';

const LayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isOnGamePage = useIsOnGamePage();

  return (
    <div
      className={cn([
        'relative mb-7 transition-all duration-200 ease-in-out md:pl-[4.5rem] lg:mb-12',
        isSidemenuOpen && 'xl:pl-[16rem]',
        !isSidemenuOpen && 'md:pl-[3.75rem]',
        isOnGamePage && 'md:pl-[4.5rem] xl:pl-[4.5rem]',
      ])}
    >
      {children}
    </div>
  );
};

export default LayoutWrapper;
