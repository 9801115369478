import { IconButton } from '@purposeinplay/core-v2';
import ArrowDownSVG from 'public/assets/double-arrow-down.svg';
import React from 'react';

const NewMessagesButton = ({
  onClick,
  messageCount,
}: {
  onClick: () => void;
  messageCount: number;
}) => {
  return (
    <IconButton
      iconRight={<ArrowDownSVG />}
      className="fixed bottom-14 left-1/2 z-50 -translate-x-1/2 transition-all duration-500 ease-in-out"
      size="sm"
      onClick={onClick}
    >{`${messageCount} New Messages`}</IconButton>
  );
};

export default NewMessagesButton;
