import {
  Tournament,
  TournamentPrize,
  PrizeDisplayProps,
} from '@/modules/tournaments/types';
import { WildPointIcon } from '@/components/Lottery/components/my-wp';
import CryptoValue from '@/components/CryptoValue';
import { calculatePrizeSums } from '../utils/calculate-prize-sums';
import { formatCompoints } from '../utils/format-comp-points';
import { mapValueToPrize } from '../utils';

export const PrizeDisplay = ({
  isTournamentCompPoint,
  isDynamic,
  sortedPrizes,
  index,
  tournament,
  currency,
}: PrizeDisplayProps) => {
  if (isTournamentCompPoint) {
    return (
      <span className="flex items-center justify-end space-x-1">
        <span>
          {formatCompoints(sortedPrizes[index]?.chargeable_comp_points)}
        </span>
        <WildPointIcon />
      </span>
    );
  }

  if (isDynamic) {
    return (
      <CryptoValue
        forPrize
        value={calculatePrizeSums(tournament)?.[index]?.prizeSum}
        currency={tournament?.currency}
      />
    );
  }

  return mapValueToPrize(sortedPrizes, index, tournament, currency);
};
