import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import Image from 'next/image';

export const Separator = () => (
  <span className="h-5 w-[1px] translate-y-1/2 bg-border-general-default content-['']"></span>
);

export const Icon = ({
  name,
  size = 16,
  className,
}: {
  name: string;
  size?: number;
  className?: string;
}) => {
  return (
    <Image
      className={className}
      loader={DEFAULT_IMAGE_LOADER}
      alt={`Icon ${name}`}
      src={`/assets/tournaments/${name}.svg`}
      width={size ?? 16}
      height={size ?? 16}
    />
  );
};
