import React, { useState, useEffect, useMemo } from 'react';
import { GameSectionType } from '../types';
import { useGameSection } from '../hooks/use-game-section';
import { useGamesWithFilters } from '@/hooks/games/use-games-with-filters';
import { useIsMobile } from '@/modules/home/context/is-mobile';
import { LoadMoreButton } from './load-more-button';
import GridGameItem from './grid-game-item';

interface GameGridDisplayProps {
  gameCat: GameSectionType;
  forBottomDrawer?: boolean;
}

// Define game type based on what's expected from the GameCarouselItem
interface Game {
  identifier: string;
  id: string;
  title: string;
  provider: string;
  payout?: string;
  slug: string;
  [key: string]: any; // Allow other properties as GameCarouselItem expects
}

const GAMES_PER_PAGE = 9;
const LARGE_PAGE_SIZE = 100;

const GameGridDisplay: React.FC<GameGridDisplayProps> = ({
  gameCat,
  forBottomDrawer = false,
}) => {
  const isUserAgentMobile = useIsMobile();
  const isNoLimitCategory =
    gameCat === 'fresh-releases' || gameCat === 'top-games';

  // For 'fresh-releases' and 'top-games', fetch more games upfront
  const {
    data: gamesData,
    isLoading: isGamesDataLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGamesWithFilters({
    device: isUserAgentMobile ? 'mobile' : 'desktop',
    without_territorial_restrictions: true,
    page: 1,
    page_size: isNoLimitCategory ? LARGE_PAGE_SIZE : GAMES_PER_PAGE,
    filter: {
      categories: {
        identifiers: [gameCat],
      },
    },
  });

  // Always call the hook, but we may not use its results
  const {
    games: regularGames,
    isLoading: isRegularLoading,
    handleLoadMore: regularHandleLoadMore,
    isLoadingMore: isRegularLoadingMore,
    shouldShowLoadMore: regularShouldShowLoadMore,
  } = useGameSection(gameCat);

  // Combine data from both sources
  const games = useMemo(() => {
    if (isNoLimitCategory && gamesData?.pages) {
      return gamesData.pages.flatMap((page) => page.data || []);
    }
    return regularGames;
  }, [isNoLimitCategory, gamesData, regularGames]);

  const isLoading = isNoLimitCategory ? isGamesDataLoading : isRegularLoading;

  // Both drawer and regular view start with 9 games (GAMES_PER_PAGE)
  const [visibleCount, setVisibleCount] = useState(GAMES_PER_PAGE);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);

  // Show games in sets of 9
  const visibleGames = ((games as Game[]) || []).slice(0, visibleCount);

  const onLoadMore = () => {
    setIsLoadMoreClicked(true);
    setVisibleCount((prev) => prev + GAMES_PER_PAGE);
    if (!isNoLimitCategory) {
      regularHandleLoadMore();
    } else if (hasNextPage) {
      fetchNextPage();
    }

    // Reset loading state after a short delay for better UX
    setTimeout(() => {
      setIsLoadMoreClicked(false);
    }, 1000);
  };

  // Reset visible count when game category changes
  useEffect(() => {
    setVisibleCount(GAMES_PER_PAGE);
  }, [gameCat]);

  // Determine if we should show the load more button
  const shouldShowLoadMore = isNoLimitCategory
    ? games.length > visibleCount || hasNextPage
    : regularShouldShowLoadMore;

  const isLoadingMore = isNoLimitCategory
    ? isFetchingNextPage || isLoadMoreClicked
    : isRegularLoadingMore || isLoadMoreClicked;

  // Adjust grid columns for bottom drawer
  const gridClasses = forBottomDrawer
    ? 'grid grid-cols-3 gap-2'
    : 'grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4';

  // Adjust button style for bottom drawer
  const buttonClasses = forBottomDrawer
    ? 'w-full rounded-lg text-sm py-1.5'
    : 'w-full rounded-lg';

  if (isLoading) {
    return (
      <div className={gridClasses}>
        {Array.from({ length: GAMES_PER_PAGE }).map((_, index) => (
          <div
            key={`skeleton-${index}`}
            className="animate-pulse aspect-[3/4] rounded-2xl bg-gray-200 dark:bg-gray-700"
          />
        ))}
      </div>
    );
  }

  if (!games || games.length === 0) {
    return (
      <div className="flex h-44 w-full items-center justify-center rounded-2xl bg-gray-100 dark:bg-gray-800">
        <p className="text-center text-sm text-gray-500 dark:text-gray-400">
          No games available
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      <div className={gridClasses}>
        {visibleGames.map((game: Game) => (
          <div key={`game-grid-${game.identifier}`} className="aspect-[3/4]">
            <GridGameItem game={game} forBottomDrawer={forBottomDrawer} />
          </div>
        ))}
      </div>

      <LoadMoreButton
        onClick={onLoadMore}
        isVisible={shouldShowLoadMore}
        isLoading={isLoadingMore}
        disabled={isLoadingMore}
      />
    </div>
  );
};

export default GameGridDisplay;
