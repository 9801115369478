import React, { memo, useMemo } from 'react';
import { FormDataPropsUpdated } from '../types';
import { getPrecision, subunitsToUnit } from '@/utils/money';
import { formatNumber } from 'accounting';
import { getIconByCurrency } from '@/utils/helpers';
import { useGetExchangeRatesFS } from '@/hooks/comp-points-exchange-rates';
import useExchangeRatesCash from '../hooks/use-exchange-rates-cash';
import { useGetExchangeRatesPoints } from '@/hooks/use-get-exchange-rates-points';
import { RATES_FS_FIXED } from '../constants';
import { useGetTextNumber } from '../hooks/use-get-text-number';
import { WildPointIcon } from '@/components/Lottery/components/my-wp';

const ExchangeDetail = ({
  leftContent,
  rightContent,
}: {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) => (
  <div className="mb-7 mt-3 flex items-center space-x-1 text-sm text-text-default">
    <span className="flex flex-row items-center space-x-1">{leftContent}</span>
    <span>=</span>
    <span className="flex flex-row items-center space-x-1">{rightContent}</span>
  </div>
);

const ExchangeCurrencyDetails = memo(
  ({ formData }: { formData: FormDataPropsUpdated['formData'] }) => {
    const { exchangeToFS, exchangeToWoF, exchangeToCrypto, currency } =
      formData;
    const exchangeRatesPoints = useGetExchangeRatesPoints();
    const { data: ratesFS } = useGetExchangeRatesFS();
    const exchangeRates = useExchangeRatesCash();
    const { number, text } = useGetTextNumber(
      exchangeToWoF,
      exchangeRatesPoints,
    );

    const isThereRatesFS = ratesFS && ratesFS.length > 0;
    const isWildPointExchange = !exchangeToFS && !exchangeToCrypto;

    const renderExchangeDetails = useMemo(() => {
      if (exchangeToCrypto) {
        const exchangeRate = exchangeRates[currency];
        const precision = getPrecision(subunitsToUnit[currency]);

        return (
          <ExchangeDetail
            leftContent={
              <>
                <span>1</span>
                <WildPointIcon size={16} />
              </>
            }
            rightContent={
              <>
                <span>{formatNumber(exchangeRate, precision)}</span>
                <span>{getIconByCurrency(currency?.toLowerCase())}</span>
              </>
            }
          />
        );
      }

      if (exchangeToFS) {
        const points = isThereRatesFS
          ? ratesFS[0]?.rate?.points
          : RATES_FS_FIXED.points;
        const freeSpins = isThereRatesFS
          ? ratesFS[0]?.rate?.freespins_count
          : RATES_FS_FIXED.freespins_count;

        return (
          <ExchangeDetail
            leftContent={
              <>
                <span>{points?.toLocaleString()}</span>
                <WildPointIcon size={16} />
              </>
            }
            rightContent={<span>{`${freeSpins} Free Spin`}</span>}
          />
        );
      }

      if (isWildPointExchange && exchangeRatesPoints) {
        return (
          <ExchangeDetail
            leftContent={
              <>
                <span>{number?.toLocaleString()}</span>
                <WildPointIcon size={16} />
              </>
            }
            rightContent={
              <>
                <span>1</span>
                <span>{text}</span>
              </>
            }
          />
        );
      }
    }, [
      currency,
      exchangeRates,
      exchangeRatesPoints,
      exchangeToCrypto,
      exchangeToFS,
      isThereRatesFS,
      isWildPointExchange,
      number,
      ratesFS,
      text,
    ]);

    return renderExchangeDetails;
  },
);

ExchangeCurrencyDetails.displayName = 'ExchangeCurrencyDetails';

export default ExchangeCurrencyDetails;
