import React from 'react';
import { VerifyAgentForm } from '../types';
import ModalFooterVerify from './modal-footer-verify';
import ModalFooterRetry from './modal-footer-retry';

type Props = {
  state: VerifyAgentForm;
  setState: React.Dispatch<React.SetStateAction<VerifyAgentForm>>;
};

const VerifyAgentFooter: React.FC<Props> = React.memo(({ state, setState }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-spacing-xs">
      {state?.state === 'default' ? (
        <ModalFooterVerify state={state} setState={setState} />
      ) : (
        <ModalFooterRetry state={state} setState={setState} />
      )}
    </div>
  );
});

VerifyAgentFooter.displayName = 'VerifyAgentFooter';

export default VerifyAgentFooter;
