import { TextLoader } from '@/components/Loaders';
import React from 'react';

const LoadingCoolingOff = () => {
  return (
    <div>
      <TextLoader className="mb-4 h-6 w-3/5 md:w-4/5" />
      <TextLoader className="mb-4" />
      <TextLoader className="mb-4 h-6 w-3/5 md:w-4/5" />
      <TextLoader className="h-9 w-full md:w-full" />
    </div>
  );
};

export default LoadingCoolingOff;
