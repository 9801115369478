import { setSelectedAvatar } from '@/app/reducers/bootstrap';
import { useAvatar } from '@/hooks/avatar';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import Image from 'next/image';
import React, { useMemo } from 'react';

const AvatarOption = ({ id, image }: { id: string; image: string }) => {
  const { data } = useAvatar();

  const savedAvatar = useAppSelector((state) => state.bootstrap.savedAvatar);
  const selectedAvatar = useAppSelector(
    (state) => state.bootstrap.selectedAvatar,
  );

  const dispatch = useAppDispatch();

  const isSelected = useMemo(() => {
    if (!savedAvatar && !selectedAvatar) {
      return data?.avatar === id;
    }
    if (savedAvatar && !selectedAvatar) {
      return savedAvatar === id;
    }
    return selectedAvatar === id;
  }, [data, selectedAvatar, savedAvatar, id]);

  const handleClick = () => {
    dispatch(setSelectedAvatar(id));
  };

  return (
    <div
      className={cn([
        'relative h-16 w-16 cursor-pointer rounded-full md:h-24 md:w-24',
        // isSelected && 'border-2 border-green-500',
      ])}
      onClick={handleClick}
    >
      <Image fill src={image} alt={'Avatar'} loader={DEFAULT_IMAGE_LOADER} />
      {isSelected && (
        <Image
          width={24}
          height={24}
          src="/assets/check-avatar.svg"
          alt="Selected avatar icon"
          className="absolute inset-0"
          loader={DEFAULT_IMAGE_LOADER}
        />
      )}
    </div>
  );
};

export default AvatarOption;
