import { useUpdatePlayerAccount } from '@/hooks/accounts';
import useWalletSelector from '@/hooks/use-wallet-selector';
import { FormattedAccount } from '@/types/accounts';
import { getIconByCurrency } from '@/utils/helpers';
import { Radio } from '@purposeinplay/core-v2';
import React from 'react';

const CurrencyRadios = () => {
  const { formattedAccounts, account: activeAccount } = useWalletSelector();

  const { mutateAsync: updateAccount } = useUpdatePlayerAccount();

  return (
    <ul className="flex flex-wrap gap-4">
      {formattedAccounts.map((account: FormattedAccount) => {
        const { currency, balance } = account;
        const isActive = activeAccount?.currency === currency;
        return (
          <li
            onClick={() => updateAccount(account?.currency)}
            key={account?.key}
          >
            <Radio
              checked={isActive}
              //@ts-ignore TO DO: fix this
              label={
                <div className="flex flex-row items-center space-x-1">
                  <span>{account?.currency}</span>
                  <span>
                    {getIconByCurrency(account.currency.toLowerCase())}
                  </span>
                </div>
              }
              id={account?.key}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default CurrencyRadios;
