'use client';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useGetLootboxes, useSpinWheel } from '@/hooks/lootboxes';
import { filterLootboxesByGroupKey, filterPrizes } from '../utils';
import { cn } from '@/utils/style';
import RibbonImage from './ribbon-image';
import WofButton from './wof-button';
import WheelCountdown from './wheel-countdown';
import Image from 'next/image';
import { useHandleTransitionEnd } from '../hooks/use-transition-end';
import {
  Enum_Componentwheelwheelstyles_Type,
  Maybe,
  WheelOfFortune,
} from '@/generated';
import { CONTENT_API_URL } from '@/utils/constants';
import SupportGraphics from './support-graphics';

const Wheel = ({ wheel }: { wheel: Maybe<WheelOfFortune> | undefined }) => {
  const [style, setStyle] = useState({});
  const [isSpinning, setIsSpinning] = useState(false);

  const { mutateAsync: spinWof }: any = useSpinWheel();

  const [prizeTitle, setPrizeTitle] = useState<string | undefined>();

  const { data: lootboxes, isLoading: lootboxesLoading } = useGetLootboxes();

  const lootboxesFiltered = useMemo(
    () =>
      filterLootboxesByGroupKey(
        lootboxes,
        wheel?.lootbox_group_key,
        wheel?.exchange_group_key,
      ),
    [lootboxes, wheel?.exchange_group_key, wheel?.lootbox_group_key],
  );

  const hasLootbox = lootboxesFiltered?.length > 0;

  const wheelRef = useRef(null);
  const active = useRef<any>(null);

  const spinWheel = (finalAngle: number) => {
    setIsSpinning(true);
    setStyle({ animation: 'none' });

    setTimeout(() => {
      const additionalSpins = 5 * 360;
      const totalDegrees = additionalSpins + finalAngle;
      const calculatedDuration = 10;

      setStyle({
        transform: `rotate(${totalDegrees}deg)`,
        transition: `transform ${calculatedDuration}s ease-in-out`,
      });
    }, 10);
  };

  useHandleTransitionEnd(
    prizeTitle,
    wheel?.slug as string,
    wheelRef,
    setIsSpinning,
  );

  const spinTheWheel = async (lootboxId: string | number): Promise<any> => {
    setIsSpinning(true);
    const result = await spinWof({ id: lootboxId });
    return result;
  };

  const processPrizes = useCallback(
    (wofData: any) => {
      const filteredPrizes = filterPrizes(wheel?.prizes, wofData) || [];
      setPrizeTitle(filteredPrizes[0]?.identifier);
      return filteredPrizes;
    },
    [wheel?.prizes],
  );

  const handleClick = async () => {
    if (!hasLootbox || isSpinning) return;

    const lootboxId = lootboxesFiltered[0]?.id;
    if (!lootboxId) return;

    const result = await spinTheWheel(lootboxId);
    const filteredPrizes = processPrizes(result);
    const degree = filteredPrizes[0]?.deg;

    if (degree) {
      // TO DO UPDATE ANIMATION FOR INDICATOR
      // startIndicatorAnimation(active);
      spinWheel(degree);
    }
  };
  const wheelStyles = useMemo(() => {
    const container = wheel?.custom_styles?.find(
      (style) =>
        style?.type === Enum_Componentwheelwheelstyles_Type?.WheelContainer,
    );
    const mainWheel = wheel?.custom_styles?.find(
      (style) => style?.type === Enum_Componentwheelwheelstyles_Type?.Wheel,
    );
    const spinBtn = wheel?.custom_styles?.find(
      (style) =>
        style?.type === Enum_Componentwheelwheelstyles_Type?.SpinButton,
    );

    return {
      container,
      mainWheel,
      spinBtn,
    };
  }, [wheel]);

  return (
    <div
      className={cn([
        'relative mx-auto flex flex-col items-center',
        wheelStyles?.container,
      ])}
    >
      <div className="relative">
        <div
          style={style}
          className={cn([
            'relative z-10 h-[337px] w-[337px] md:h-[447px] md:w-[447px]',
            wheelStyles?.mainWheel,
          ])}
          ref={wheelRef}
        >
          <Image
            layout="fill"
            src={`${CONTENT_API_URL}${wheel?.wheel_image?.data?.attributes?.url}`}
            loading="eager"
            alt="Wheel Image"
          />
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            onClick={() => handleClick()}
            className={cn([
              'relative  z-40 flex h-full w-[18%] cursor-pointer  items-center justify-center ',
              (!hasLootbox || isSpinning) &&
                'pointer-events-none cursor-not-allowed',
              wheelStyles?.spinBtn,
            ])}
          >
            <Image
              width={100}
              height={100}
              alt="Wheel of fortune spin button"
              src={`${CONTENT_API_URL}${wheel?.spin_icon?.data?.attributes?.url}`}
            />
          </div>
        </div>
        <div
          ref={active}
          className={cn([
            'absolute right-1 top-16 z-30  h-12 w-12 md:top-20 md:h-14 md:w-14',
          ])}
        >
          <Image
            alt="Wheel of fortune indicator"
            fill
            src="/assets/wheel/regular/indicator-v2.svg"
          />
        </div>
        <SupportGraphics supportGraphics={wheel?.support_graphics} />
        {wheel?.ribbon_image?.data && (
          <RibbonImage
            src={`${CONTENT_API_URL}${wheel?.ribbon_image?.data?.attributes?.url}`}
          />
        )}
      </div>
      <WofButton
        lootboxesLoading={lootboxesLoading}
        lootboxesFiltered={lootboxesFiltered}
        hasLootbox={hasLootbox}
        isWheelAnimating={isSpinning}
        setMakeCall={handleClick}
      />
      <WheelCountdown
        lootboxes={lootboxesFiltered}
        lootboxesLoading={lootboxesLoading}
      />
    </div>
  );
};

export default Wheel;
