import { Avatar, Field, Input, Modal, Text } from '@purposeinplay/core-v2';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import useWildChatContent from '../hooks/use-wild-chat-content';
import useUpdateNickname from '../hooks/use-update-nickname';
import { useToast } from '@purposeinplay/utils';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { FooterButtons } from './footer-buttons';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import UserAvatar from '@/modules/layout/navbar/components/user-avatar';
import useModal from '@/hooks/modal';
import { useAppSelector } from '@/hooks/redux';
import { useAvatar } from '@/hooks/avatar';
import Image from 'next/image';
import { cn } from '@/utils/style';

interface ValidationError {
  type:
    | 'min_length'
    | 'max_length'
    | 'pattern'
    | 'no_underscores'
    | 'already_taken';
  message: string;
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const NicknameModal = ({ open, setOpen }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    mutateAsync,
    isPending,
    isError,
    error: apiError,
  } = useUpdateNickname();
  const createNicknameContent = useWildChatContent().create_nickname_modal;
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { open: openModal } = useModal();

  const forbiddenNicknamesSet = useMemo(() => {
    return new Set(
      (createNicknameContent?.forbidden_nicknames || []).map((name) =>
        name.toLowerCase(),
      ),
    );
  }, [createNicknameContent?.forbidden_nicknames]);

  const validateNickname = useCallback(
    (value: string): ValidationError | null => {
      // Normalize and trim the value
      const trimmedValue = value.trim().replace(/\s+/g, ' ');

      // Check for minimum length (includes empty check)
      if (trimmedValue.length < 5) {
        return {
          type: 'min_length',
          message: createNicknameContent?.rules?.min_length,
        };
      }

      // Check for maximum length
      if (trimmedValue.length > 20) {
        return {
          type: 'max_length',
          message: createNicknameContent?.rules?.max_length,
        };
      }

      // Check against forbidden nicknames (case insensitive)
      const normalizedValue = trimmedValue.toLowerCase();
      if (forbiddenNicknamesSet.has(normalizedValue)) {
        return {
          type: 'already_taken',
          message: createNicknameContent?.rules?.already_taken,
        };
      }

      // Check for valid characters (letters, numbers, and underscores)
      const validPattern = /^[a-zA-Z0-9_]+$/;
      if (!validPattern.test(trimmedValue)) {
        return {
          type: 'pattern',
          message: createNicknameContent?.rules?.pattern,
        };
      }

      // Check for underscore at start or end
      if (trimmedValue.startsWith('_') || trimmedValue.endsWith('_')) {
        return {
          type: 'no_underscores',
          message: createNicknameContent?.rules?.no_underscores,
        };
      }

      return null;
    },
    [createNicknameContent?.rules, forbiddenNicknamesSet],
  );

  // Debounced validation
  const debouncedValidation = useMemo(
    () =>
      debounce((value: string) => {
        const validationError = validateNickname(value);
        setError(!!validationError);
        setErrorMessage(validationError?.message || '');
      }, 300),
    [validateNickname],
  );

  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // If there was a previous submission error, clear it immediately
    if (isSubmitted) {
      setIsSubmitted(false);
      setError(false);
      setErrorMessage('');
    }

    if (value) {
      debouncedValidation(value);
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);

    const validationError = validateNickname(inputValue);
    if (validationError) {
      setError(true);
      setErrorMessage(validationError.message);
      return;
    }

    try {
      await mutateAsync(inputValue);
      queryClient.invalidateQueries({ queryKey: ['playerApiSettings'] });
      toast({
        state: 'success',
        title: toastMessages?.updated_profile,
      });
      setOpen(false);
    } catch (error: unknown) {
      if (
        error &&
        typeof error === 'object' &&
        'errors' in error &&
        Array.isArray((error as any).errors) &&
        (error as any).errors[0]?.custom_chat_name
      ) {
        setError(true);
        setErrorMessage(createNicknameContent?.rules?.already_taken);
      } else {
        console.error('An unexpected error occurred:', error);
        toast({
          state: 'error',
          title: toastMessages?.general_error,
        });
      }
    }
  };

  return (
    <Modal isOpen={open} setIsOpen={setOpen}>
      <form
        id="nickname-form"
        onSubmit={handleSubmit}
        className="flex flex-col space-y-7"
        aria-labelledby="nickname-modal-title"
      >
        <span>
          <Text
            size="lg"
            className="mb-1 font-semibold leading-6 text-text-subdued"
          >
            {createNicknameContent?.title}
          </Text>
          <Text className="font-normal leading-6 text-text-subdued">
            {createNicknameContent?.description}
          </Text>
        </span>
        <div className="flex flex-col items-center justify-center">
          <UserAvatar
            iconSize={96}
            size="xl"
            hasArrow={false}
            onClick={() => {
              openModal('avatar');
            }}
            styleAvatarLoading={`w-[96px] h-[96px] lg:w-[30px]`}
          />
          <Text size="xl" className="font-bold leading-7">
            {`@${inputValue}`}
          </Text>
        </div>
        <Field>
          <Input
            label="Username"
            error={error}
            errorMessage={errorMessage}
            maxLength={20}
            value={inputValue}
            onChange={handleChange}
            placeholder={createNicknameContent?.input_placeholder}
            variant="static"
            aria-invalid={error}
            aria-describedby={
              error ? 'nickname-error' : 'nickname-modal-description'
            }
            id="nickname-input"
          />
          {/* Helper text  */}
          {!error && (
            <Text className="mt-1" size="xs">
              {createNicknameContent?.rules?.no_change}
            </Text>
          )}
        </Field>
        <FooterButtons
          isLoading={isPending}
          onClose={() => setOpen(false)}
          isDisabled={inputValue.length === 0 || isPending || error}
        />
      </form>
    </Modal>
  );
};

export default NicknameModal;
