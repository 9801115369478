import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import useWildChatContent from '../hooks/use-wild-chat-content';

const EmptyChat = () => {
  const emptyChatContent = useWildChatContent().empty_state;
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center">
      <Text size="lg" className="font-semibold">
        {emptyChatContent?.title}
      </Text>
      <Text size="sm" className="text-text-subdued">
        {emptyChatContent?.description}
      </Text>
    </div>
  );
};

export default EmptyChat;
