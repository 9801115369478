import { TextLoader } from '@/components/Loaders';
import React from 'react';

const SidemenuLinksLoader = () => {
  return (
    <>
      <TextLoader className="h-12 w-12 rounded-lg lg:h-12 lg:w-12" />
      <TextLoader className="h-12 w-12 rounded-lg lg:h-12 lg:w-12" />
    </>
  );
};

export default SidemenuLinksLoader;
