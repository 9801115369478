'use client';
import { Button, Table, TableCell, TableRow } from '@purposeinplay/core-v2';
import React, { useState, useMemo } from 'react';
import CryptoValue from '@/components/CryptoValue';
import useMediaQuery, { LG_BREAKPOINT } from '@/hooks/use-media-query';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { useTournamentData } from '../hooks/use-tournament-data';
import '../styles/leaderboard.css';
import { cn } from '@/utils/style';
import useDarkMode from '@/hooks/dark';
import {
  TournamentTableProps,
  TournamentDataResult,
} from '@/modules/tournaments/types';
import { RankIcon } from './rank-icon';
import { PlayerNameCell } from './player-name-cell';
import { PrizeDisplay } from './prize-display';

const TournamentTable = ({
  tournament,
  isForSidebar = false,
}: TournamentTableProps) => {
  const { matchesQuery, isLoading } = useMediaQuery(LG_BREAKPOINT);
  const { darkMode } = useDarkMode();
  const [visiblePlayers, setVisiblePlayers] = useState(10);
  const { formatCurrency } = useCurrencyFormatter();

  const isDynamic = tournament?.frontend_identifier?.includes('dynamic');
  const isTournamentRate = tournament?.strategy === 'rate';
  const isTournamentCompPoint =
    tournament?.frontend_identifier?.includes('compoint');
  const buttonShow = tournament?.prizes?.length <= visiblePlayers;

  const {
    players,
    sortedPrizes,
    currency,
    playerTournamentStatus,
    tournamentContent,
  } = useTournamentData(tournament, visiblePlayers) as TournamentDataResult;

  const sortedByRate = useMemo(
    () => [...(players || [])].sort((a, b) => (b.rate || 0) - (a.rate || 0)),
    [players],
  );

  const tableHeadersContent = tournamentContent?.tournament_item?.table_header;

  const headersArray = useMemo(
    () => [
      '#',
      tableHeadersContent?.th1,
      tableHeadersContent?.th2,
      tableHeadersContent?.th3,
      tableHeadersContent?.th4,
    ],
    [tableHeadersContent],
  );

  const tableHeadersUpdated = useMemo(() => {
    let headers = [...headersArray];

    // For sidebar, only show Rank, Player, Prize
    if (isForSidebar) {
      return [headers[0], headers[1], headers[4]];
    }

    // For regular view, apply the original filtering logic
    if (!isTournamentRate) headers = headers.filter((_, index) => index !== 3);
    if (!matchesQuery) headers = headers.filter((_, index) => index !== 2);
    return headers;
  }, [headersArray, isTournamentRate, matchesQuery, isForSidebar]);

  const tableRows = useMemo(() => {
    const getRowClassName = (index: number) => {
      return cn([
        'h-[38px] border-0',
        index < 3 &&
          {
            0: darkMode ? 'first-place-dark' : 'first-place',
            1: darkMode ? 'second-place-dark' : 'second-place',
            2: darkMode ? 'third-place-dark' : 'third-place',
          }[index],
      ]);
    };

    return Array.from({ length: visiblePlayers }, (_, index) => {
      const isYou = playerTournamentStatus?.award_place === index + 1;
      const player = players?.[index];

      // Create cells based on whether it's for sidebar or not
      let cells = [
        // Rank column (always shown)
        <TableCell className="py-0" key={`cell1-${index}`}>
          <span className="flex items-center">
            <RankIcon index={index} />
          </span>
        </TableCell>,

        // Player name column (always shown)
        <TableCell className="py-0" key={`cell2-${index}`}>
          <PlayerNameCell
            player={player}
            isYou={isYou}
            foundPlayerText={tournamentContent?.tournament_item?.found_player}
          />
        </TableCell>,
      ];

      // For regular view, add the conditional columns
      if (!isForSidebar) {
        // Bet column (shown on large screens)
        if (matchesQuery && !isLoading) {
          cells.push(
            <TableCell className="py-0 xl:min-w-[8rem]" key={`cell3-${index}`}>
              {player?.bet_cents ? (
                <CryptoValue
                  className="whitespace-nowrap"
                  value={player.bet_cents}
                  currency={tournament?.currency}
                />
              ) : (
                'N/A'
              )}
            </TableCell>,
          );
        }

        // Rate column (shown for rate tournaments)
        if (isTournamentRate) {
          cells.push(
            <TableCell className="py-0" key={`cell4-${index}`}>
              <span className="whitespace-nowrap">
                {isTournamentRate && sortedByRate?.[index]?.rate
                  ? `${sortedByRate[index].rate.toFixed(2)}x`
                  : player?.bet_cents
                    ? formatCurrency(Number(player.bet_cents), currency?.code)
                    : 'N/A'}
              </span>
            </TableCell>,
          );
        }
      }

      // Prize column (always shown)
      cells.push(
        <TableCell
          className="whitespace-nowrap py-0 xl:min-w-[5rem] [&>span]:justify-end"
          key={`cell5-${index}`}
        >
          <PrizeDisplay
            isTournamentCompPoint={isTournamentCompPoint}
            isDynamic={isDynamic}
            sortedPrizes={sortedPrizes}
            index={index}
            tournament={tournament}
            currency={currency}
          />
        </TableCell>,
      );

      return (
        <TableRow className={getRowClassName(index)} key={`row-${index}`}>
          {cells}
        </TableRow>
      );
    });
  }, [
    visiblePlayers,
    playerTournamentStatus?.award_place,
    players,
    tournamentContent?.tournament_item?.found_player,
    matchesQuery,
    isLoading,
    tournament,
    isTournamentRate,
    sortedByRate,
    formatCurrency,
    currency,
    isTournamentCompPoint,
    isDynamic,
    sortedPrizes,
    darkMode,
    isForSidebar,
  ]);

  const handleLoadMore = () => setVisiblePlayers((prev) => prev + 5);

  return (
    <div className="relative">
      <Table
        stylingOptions={{
          containerStyles: 'p-0 hide-scroll-wrapper',
          headerStyles: 'relative z-[50] uppercase',
          tableHeadCellStyles: 'text-xs text-text-subdued',
        }}
        headers={tableHeadersUpdated}
      >
        {tableRows}
      </Table>

      {!buttonShow && !isForSidebar && (
        <Button
          size="sm"
          onClick={handleLoadMore}
          color="tertiary"
          className="mx-auto w-full"
        >
          {tournamentContent?.actions?.load}
        </Button>
      )}
    </div>
  );
};

export default TournamentTable;
