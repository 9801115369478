import { useMutation } from '@tanstack/react-query';
import { useAnalytics } from './use-analytics';

const useVerifyAgent = () => {
  const { track } = useAnalytics();
  return useMutation({
    mutationFn: (payload: { user_id: string; platform: string }) => {
      try {
        return fetch('/api/verify-agent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            return Promise.resolve(parsed);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
    onSuccess: (res) => {
      track('agent_verification', {
        category: 'fraud',
        label: res?.response?.message,
      });
    },
    onError: (err: any) => {
      track('agent_verification', {
        category: 'fraud',
        label: err?.errors?.message,
      });
    },
  });
};

export default useVerifyAgent;
