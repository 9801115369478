import React from 'react';
import SunIcon from 'public/assets/sun.svg';
import MoonIcon from 'public/assets/moon.svg';
import useDarkMode from '@/hooks/dark';
import { Switch } from '@purposeinplay/core-v2';
import { useRouter } from 'next/navigation';
import { cn } from '@/utils/style';
import { useCookies } from 'react-cookie';

const ThemeSwitch = ({
  isMobile,
}: {
  isMobile?: boolean | null | undefined;
}) => {
  const { darkMode, setDarkMode } = useDarkMode();
  const router = useRouter();
  const [cookies, setCookie] = useCookies(['x-theme']);

  return (
    <div
      className={cn([
        'flex w-full justify-start [&>div]:space-x-2',
        isMobile && 'items-center justify-center py-4 [&>div]:space-x-0',
      ])}
    >
      <Switch
        size="large"
        leftIcon={<SunIcon />}
        rightIcon={<MoonIcon />}
        checked={darkMode}
        labelStyle="cursor-pointer"
        onChange={(value: boolean) => {
          setDarkMode(value ? 'dark' : 'light');
          setCookie('x-theme', value ? 'dark' : 'light');
          router.refresh();
        }}
        labelRight={'PM'}
        labelLeft={'AM'}
      />
    </div>
  );
};

export default ThemeSwitch;
