import Image from 'next/image';
import { RankIconProps } from '@/modules/tournaments/types';

export const RankIcon = ({ index }: RankIconProps) => {
  if (index < 3) {
    return (
      <span className="flex shrink-0 items-center">
        <Image
          width={24}
          height={24}
          src={`assets/tournament-prizes/cup${index + 1}.svg`}
          alt={`Prize cup ${index + 1}`}
        />
      </span>
    );
  }

  return (
    <span className="flex h-7 w-7 items-center justify-center">
      {index + 1}
    </span>
  );
};
