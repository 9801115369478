import { useTranslation } from '@/app/i18n/client';
import {
  Tournament,
  TournamentTopPlayer,
  TournamentsPageProps,
} from '../types';
import { usePlayerTournamentStatus } from '@/hooks/tournaments/tournaments';
import { useCurrencies } from '@/hooks/currencies';
import { useMemo } from 'react';

export const useTournamentData = (
  tournament: Tournament,
  visiblePlayers: number,
) => {
  const { t } = useTranslation();
  const { data: playerTournamentStatus } = usePlayerTournamentStatus(
    tournament?.id,
  );
  const { data: currencies } = useCurrencies();
  const tournamentContent = t('tournaments') as TournamentsPageProps;

  const players = useMemo(() => {
    return tournament?.top_players
      ?.slice(0, visiblePlayers)
      .map((player: TournamentTopPlayer) => ({
        nickname: player?.nickname,
        wins: player?.win_cents,
        rate: player?.rate,
        bet_cents: player?.bet_cents,
      }));
  }, [tournament?.top_players, visiblePlayers]);

  const sortedPrizes = useMemo(() => {
    if (!tournament?.prizes) return [];
    return tournament.prizes.sort(
      (a: any, b: any) => a.award_place - b.award_place,
    );
  }, [tournament?.prizes]);

  const currency = useMemo(() => {
    return currencies.find(
      (currencyItem: any) => currencyItem.code === tournament?.currency,
    );
  }, [currencies, tournament?.currency]);

  return {
    players,
    sortedPrizes,
    currency,
    playerTournamentStatus,
    tournamentContent,
  };
};
