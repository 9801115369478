import { useEffect, useState } from 'react';

const useIsTelegram = () => {
  const [isTelegram, setIsTelegram] = useState(false);

  useEffect(() => {
    const detectTelegram = () => {
      if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        return false;
      }

      if (window?.Telegram || window?.Telegram?.WebApp) {
        return true;
      }

      const userAgent = navigator.userAgent || '';

      return (
        'TelegramWebview' in window ||
        'TelegramWebviewProxy' in window ||
        'TelegramWebviewProxyProto' in window ||
        /Telegram/i.test(userAgent)
      );
    };

    setIsTelegram(detectTelegram());

    const handleEnvironmentChange = () => {
      setIsTelegram(detectTelegram());
    };

    window.addEventListener('resize', handleEnvironmentChange);

    return () => {
      window.removeEventListener('resize', handleEnvironmentChange);
    };
  }, []);

  return { isTelegram };
};

export default useIsTelegram;
