'use client';
import React, { memo, useCallback, useMemo } from 'react';
import { Button } from '@purposeinplay/core-v2';
import { FormDataProps } from '../types';

import { useGetExchangeRatesPoints } from '@/hooks/use-get-exchange-rates-points';
import { useGetExchangeRatesFS } from '@/hooks/comp-points-exchange-rates';
import { RATES_FS_FIXED } from '../constants';
import { useTranslation } from '@/app/i18n/client';
import { WheelContentType } from '@/types/json-content/wheel';
import { WildPointsContentType } from '@/types/json-content/wild-points';

const ExchangeButton: React.FC<FormDataProps> = ({
  exchangeLoading,
  pointsRemaining,
  exchangeToFS,
  exchangeToWoF,
  points,
}) => {
  const isFreeSpinOrWof =
    exchangeToFS ||
    (exchangeToWoF && Object?.values(exchangeToWoF).some(Boolean));

  const { data: ratesFS } = useGetExchangeRatesFS();

  const exchangeRatesPoints = useGetExchangeRatesPoints();

  const minPointsForCrypto = 5;
  let minPointsForFreeSpin = 0;

  if (ratesFS && ratesFS.length > 0) {
    minPointsForFreeSpin = ratesFS[0]?.rate?.points;
  } else {
    minPointsForFreeSpin = RATES_FS_FIXED.points;
  }

  const isMultipleOf = (number: number, multiple: number): boolean =>
    number % multiple === 0;
  const isMultipleOfSpin = useCallback(
    (points: number, spinRate: number): boolean =>
      isMultipleOf(points, spinRate),
    [],
  );

  const getMinPointsForWof = useCallback(() => {
    if (exchangeToWoF?.WildWheelSpins) {
      return exchangeRatesPoints.wofWheel;
    }
    if (exchangeToWoF?.JungleWheelSpins) {
      return exchangeRatesPoints.jungleWheel;
    }
    if (exchangeToWoF?.VIPWheelSpins) {
      return exchangeRatesPoints.vipWheel;
    }
    return 0;
  }, [
    exchangeToWoF?.WildWheelSpins,
    exchangeToWoF?.JungleWheelSpins,
    exchangeToWoF?.VIPWheelSpins,
    exchangeRatesPoints?.wofWheel,
    exchangeRatesPoints?.jungleWheel,
    exchangeRatesPoints?.vipWheel,
  ]);

  const hasNoPoints = !pointsRemaining || pointsRemaining === 0;

  const isDisabledForFreeSpinOrWof = useCallback(() => {
    if (exchangeToFS) {
      return points < minPointsForFreeSpin;
    }
    return points < getMinPointsForWof();
  }, [exchangeToFS, minPointsForFreeSpin, getMinPointsForWof, points]);

  const isDisabledForCrypto = !isFreeSpinOrWof && points < minPointsForCrypto;

  const isButtonDisabled = useMemo(() => {
    return (
      points === 0 ||
      exchangeLoading ||
      hasNoPoints ||
      (isFreeSpinOrWof ? isDisabledForFreeSpinOrWof() : isDisabledForCrypto) ||
      points > pointsRemaining ||
      (exchangeToWoF &&
        Object?.values(exchangeToWoF).some(Boolean) &&
        !isMultipleOfSpin(points, getMinPointsForWof()))
    );
  }, [
    points,
    exchangeLoading,
    hasNoPoints,
    isFreeSpinOrWof,
    isDisabledForFreeSpinOrWof,
    isDisabledForCrypto,
    pointsRemaining,
    exchangeToWoF,
    isMultipleOfSpin,
    getMinPointsForWof,
  ]);

  const { t } = useTranslation();
  const wildPoints = t('wild_points') as WildPointsContentType;

  const buttonText = hasNoPoints
    ? wildPoints?.modal?.action?.btn_label
    : `${wildPoints?.modal?.action?.btn_label} ${points?.toLocaleString()} ${wildPoints?.modal?.action?.wild_points}`;

  return (
    <Button
      type="submit"
      isLoading={exchangeLoading}
      disabled={isButtonDisabled}
      className="w-full"
    >
      {buttonText}
    </Button>
  );
};

export default memo(ExchangeButton);
