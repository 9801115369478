import { useAllNotifications } from '@/app/providers/notifications';
import { useAuth } from '@/hooks/auth';
import { Button } from '@purposeinplay/core-v2';
import React from 'react';

const FeedActions = ({ loading }: any) => {
  const { user } = useAuth();
  const { setUnreadNotifications, notifications, unreadNotifications } =
    useAllNotifications();

  return (
    <>
      {user?.isAuthenticated && (
        <Button
          color="tertiary"
          size="xs"
          ghost
          disabled={
            notifications.length == 0 ||
            loading ||
            // @ts-ignore
            unreadNotifications[user?.currentUser?.id]?.length === 0
          }
          onClick={(e) => {
            e.preventDefault();
            setUnreadNotifications((prev: any) => {
              return {
                ...prev,
                // @ts-ignore
                [user?.currentUser?.id]: [],
              };
            });
          }}
        >
          Mark all as read
        </Button>
      )}
    </>
  );
};

export default FeedActions;
