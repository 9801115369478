export const getLabelByPeriod = (period: string) => {
  switch (period) {
    case 'day':
      return '1 Day';
    case 'day_3':
      return '3 Days';
    case 'week':
      return '1 Week';
    case 'month':
      return '1 Month';
    case 'month_3':
      return '3 Months';
    case 'month_6':
      return '6 Months';
  }
};
