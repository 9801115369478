'use client';

import React, { useCallback } from 'react';
import { useAppDispatch } from '@/hooks/redux';
import { IconButton } from '@purposeinplay/core-v2';
import {
  setExpandFilter,
  setOpenSearch,
  setSelectedCollection,
  setSelectedProviders,
} from '@/app/reducers/search';
import useMediaQuery, {
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  XL_BREAKPOINT,
} from '@/hooks/use-media-query';
import MagnifySVG from 'public/assets/magnify.svg';
import { usePathname, useSearchParams } from 'next/navigation';
import { SearchTrigger } from '@/modules/search';

const SearchField = ({ className }: { className: string }) => {
  const { matchesQuery: isLaptop } = useMediaQuery(LAPTOP_BREAKPOINT);
  const { matchesQuery: isXL } = useMediaQuery(XL_BREAKPOINT);
  const pathname = usePathname();
  const onSearchPage = pathname?.includes('/search');
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  const removePageHref = useCallback(
    (triggerNav?: boolean) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());
      urlSearchParams.delete('page');
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      if (triggerNav) {
        window.history.replaceState(null, '', `${pathname}${query}`);
        return;
      }
      return `${pathname}${query}`;
    },
    [pathname, searchParams],
  );

  if (isLaptop && onSearchPage) return null;

  return (
    <>
      {isXL ? (
        <div className={className}>
          <SearchTrigger
            onBeforeSearchOpen={() => {
              dispatch(setSelectedCollection([]));
              dispatch(setSelectedProviders([]));
              dispatch(setExpandFilter(false));
              if (searchParams?.get('page')) {
                removePageHref(true);
              }
            }}
          />
        </div>
      ) : (
        <IconButton
          color="tertiary"
          onClick={() => {
            dispatch(setSelectedCollection([]));
            dispatch(setSelectedProviders([]));
            dispatch(setExpandFilter(false));
            if (searchParams?.get('page')) {
              removePageHref(true);
            }
            dispatch(setOpenSearch(true));
          }}
          size="md"
          ghost
          iconOnly
          iconLeft={<MagnifySVG className="!fill-none stroke-text-subdued" />}
          className="flex items-center justify-center"
        />
      )}
    </>
  );
};

export default SearchField;
