'use client';
import React from 'react';
import { cn } from '@/utils/style';
import MobileHeader from './mobile-header';
import FeaturedSidebarList from './featured-sidebar-list';
import FeaturedSidebarBanner from './featured-sidebar-banner';
import SidebarList from './sidebar-list';

interface DefaultSidebarProps {
  sidebarRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  isMounted: boolean;
}

const DefaultSidebar: React.FC<DefaultSidebarProps> = ({
  sidebarRef,
  isOpen,
  isMounted,
}) => {
  return (
    <aside
      className={cn([
        'transition-[width, transform] safe-top-padding safe-bottom-padding fixed left-0 top-0 z-[999] h-screen w-[3.75rem] -translate-x-full bg-bgr-lighter duration-150 ease-in-out md:z-[91] md:translate-x-0 xl:w-[16.25rem]',
        !isOpen &&
          isMounted &&
          'hide-scroll-wrapper w-[3.75rem] xl:w-[3.75rem]',
        'overflow-y-auto',
        isOpen && isMounted && 'w-full translate-x-0 md:w-[16.25rem]',
      ])}
    >
      <div
        ref={sidebarRef}
        className={cn([
          'h-full bg-bgr-lighter pb-20 md:pb-0',
          'md:pt-14 lg:pt-16',
          isOpen && 'scrollbar overflow-y-auto',
        ])}
      >
        <MobileHeader />
        <FeaturedSidebarList />
        <FeaturedSidebarBanner />
        <SidebarList />
      </div>
    </aside>
  );
};

export default DefaultSidebar;
