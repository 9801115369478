import { useAuth } from './auth';

export const usePreviewBonuses = () => {
  const { user } = useAuth();

  const found = user?.currentUser?.statuses?.find(
    (elem: any) => elem.id === 'livec:option' || elem.id === 'vipc:option',
  );
  return !found ? true : false;
};
