import useCountdown from '@/hooks/use-countdown';
import React from 'react';

const CountDownButton = ({ endTime }: { endTime?: any }) => {
  const { state, hasNoState } = useCountdown({
    short: false,
    date: endTime,
  });
  return (
    <div tw="flex">
      <span>
        Available in{' '}
        {hasNoState
          ? ''
          : state?.days +
            ':' +
            state?.hours +
            ':' +
            state?.minutes +
            ':' +
            state?.seconds}
      </span>
    </div>
  );
};

export default CountDownButton;
