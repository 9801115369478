import { Modal } from '@purposeinplay/core-v2';
import React from 'react';
import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { processMarkdown } from '@/utils/helpers';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const RuleModal = ({ isOpen, setIsOpen }: Props) => {
  const { data: WildChatContent } = usePagesQuery(
    { path: WILD_CHAT_PATH },
    {
      queryKey: ['pages', WILD_CHAT_PATH],
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );
  const content = WildChatContent?.pages?.data[0]?.attributes?.content?.find(
    (content) => content?.__typename === 'ComponentContentMarkdown',
  );

  return (
    <Modal isOpen={isOpen} size="lg" setIsOpen={setIsOpen}>
      <div className="markdown-body">
        <div
          dangerouslySetInnerHTML={{
            __html: processMarkdown(content?.Markdown as string),
          }}
        />
      </div>
    </Modal>
  );
};

export default RuleModal;

const WILD_CHAT_PATH = `?m=wild_chat_placeholder_path`;
