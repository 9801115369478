import React, { useEffect } from 'react';
import Image from 'next/image';
import { WheelOfFortune } from '@/generated';
import { cn } from '@/utils/style';
import { CONTENT_API_URL } from '@/utils/constants';

const SupportGraphics = ({
  supportGraphics,
}: {
  supportGraphics: WheelOfFortune['support_graphics'];
}) => {
  return (
    <div>
      {supportGraphics?.map((graphic, index) => {
        return (
          <div
            key={`support-graphic-${graphic?.__typename}-${index}`}
            className={cn([graphic?.styles])}
          >
            <Image
              src={`${CONTENT_API_URL}${graphic?.image?.data?.attributes?.url}`}
              fill={graphic?.width && graphic?.height ? false : true}
              width={graphic?.width || undefined}
              height={graphic?.height || undefined}
              alt={`Support Image ${graphic?.__typename}`}
            />
          </div>
        );
      })}
      {/* all the styles from Strapi custom graphis should be added here:
       *tailwind can't generate classes that are coming from the cms :( since it generates does at build time
       *so we have to add them manually here unfortunately.
       */}
      <span className="pointer-events-none absolute -top-[5rem] w-full opacity-0 xs:-top-[7rem]" />
    </div>
  );
};

export default SupportGraphics;
