import React from 'react';
import useTournamentsState from '@/modules/tournaments/hooks/use-tournaments-state';
import ContainerBodyLayout from './container-body-layout';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';
import { useGamesContent } from '@/hooks/use-games-content';
import useTournamentSelection from '../hooks/use-tournament-selection';
import TournamentListView from './tournament-list-view';
import TournamentDetailView from './tournament-detail-view';
import LoadingView from './loading-view';
import { Tournament } from '@/types/tournaments';

const SidebarTournaments: React.FC = () => {
  const { areTournamentsFetching, activeTournaments } = useTournamentsState();
  const { setShouldDisplayBackButton } = useGamePageSidebar();
  const { gamesContent } = useGamesContent();
  const sections = gamesContent.game_sidebar.sections;

  const {
    selectedTournament,
    selectTournament,
    deselectTournament,
    getContainerClassNames,
  } = useTournamentSelection();

  const handleTournamentClick = (tournament: Tournament) => {
    setShouldDisplayBackButton(true);
    selectTournament(tournament);
  };

  const handleBack = () => {
    setShouldDisplayBackButton(false);
    deselectTournament();
  };

  // Render loading state
  if (areTournamentsFetching) {
    return (
      <ContainerBodyLayout title={sections.tournaments} onBack={handleBack}>
        <LoadingView />
      </ContainerBodyLayout>
    );
  }

  return (
    <ContainerBodyLayout title={sections.tournaments} onBack={handleBack}>
      <div className="relative h-full max-h-[calc(100vh-120px)] min-h-[200px] overflow-hidden">
        <div className={getContainerClassNames()}>
          <TournamentListView
            tournaments={activeTournaments}
            onSelectTournament={handleTournamentClick}
          />
          <TournamentDetailView tournament={selectedTournament} />
        </div>
      </div>
    </ContainerBodyLayout>
  );
};

export default SidebarTournaments;
