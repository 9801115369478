import { ComponentNavigationLinkItem, useNavigationQuery } from '@/generated';
import { CONTENT_API_URL, DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { MapNavigationItem } from '../types';
import { useMemo } from 'react';

export const useMapContent = () => {
  const {
    data: navContent,
    isLoading,
    error,
  } = useNavigationQuery(
    {
      areaType: 'sidemenu',
    },
    {
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );

  const sidemenuMapItems = useMemo(() => {
    const areas = navContent?.navigation?.data?.attributes?.areas;
    if (!areas) return undefined;
    // This filtering based on index is pretty brittle
    // But for now, until cms is updated, it'll have to do
    return areas.length > 2 ? areas[2]?.navigation_items : undefined;
  }, [navContent?.navigation?.data?.attributes?.areas]);

  const mapItems = useMemo<MapNavigationItem[] | null>(() => {
    if (!sidemenuMapItems?.length) return null;

    return sidemenuMapItems
      .filter(
        (item): item is NonNullable<typeof item> =>
          !!item && !!item.navigation_item?.data?.attributes,
      )
      .map((item) => {
        const content = item.navigation_item?.data?.attributes;

        if (!content || content.type !== 'link' || !content.element?.[0]) {
          return null;
        }

        const element = content.element[0] as ComponentNavigationLinkItem;
        const identifier = element.href?.replace('/', '') || '';

        return {
          type: content.type,
          displayFor: element.display_for,
          opens_in: element.opens_in,
          identifier,
          title: element.label,
          items: [
            {
              id: `item-${element.label}-${element.id}`,
              url: element.href,
              title: element.label,
              icon: getContentUrl(element.icon?.data?.attributes?.url),
              mapBannerMobile: getContentUrl(
                element.map_banner_mobile?.data?.[0]?.attributes?.url,
              ),
              mapBannerDesktop: getContentUrl(
                element.map_banner_desktop?.data?.[0]?.attributes?.url,
              ),
            },
          ],
        };
      })
      .filter(Boolean) as MapNavigationItem[];
  }, [sidemenuMapItems]);
  return {
    mapItems,
    isLoading,
    error,
  };
};

const getContentUrl = (url?: string): string | undefined => {
  if (!url) return undefined;
  return `${CONTENT_API_URL}${url}`;
};
