import React from 'react';
import { Badge } from '@purposeinplay/core-v2';
import { TournamentCountdownBadgeProps } from '@/modules/tournaments/types';
import useCountdown from '@/hooks/use-countdown';
import { useTournamentStatus } from '../utils/use-tournament-status';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { TournamentsContentType } from '@/types/json-content/tournaments';

export const TournamentCountdownBadge = ({
  tournament,
  className,
}: TournamentCountdownBadgeProps) => {
  const { tournamentEnded, inTheFuture } = useTournamentStatus(tournament);
  const { state } = useCountdown({
    short: false,
    date: tournament?.end_at,
  });

  const { t } = useTranslation();
  const tournamentContent = t('tournaments') as TournamentsContentType;

  if (
    tournamentEnded ||
    !tournament?.in_progress ||
    !state?.days ||
    !state?.hours ||
    !state?.minutes ||
    !state?.seconds
  ) {
    return null;
  }

  return (
    <Badge
      type="success"
      ghost
      size="lg"
      radius="rounded"
      className={cn(['whitespace-nowrap sm:hidden', className])}
    >
      {`${tournamentContent.actions.ends} ${state.days}d ${state.hours}h ${state.minutes}m ${state.seconds}s`}
    </Badge>
  );
};

export default TournamentCountdownBadge;
