import Timeleft from '@/components/Timeleft';
import useCountdown from '@/hooks/use-countdown';
import { stat } from 'fs';
import React from 'react';

const WheelCounter = ({ endTime, text }: { endTime: any; text: string }) => {
  const { state } = useCountdown({
    short: false,
    date: endTime,
  });

  return (
    <>
      <div className="text-center text-sm text-text-default md:mb-2">
        {text}
      </div>
      <Timeleft
        invert
        units={{
          days: state?.days,
          hours: state?.hours,
          minutes: state?.minutes,
          seconds: state?.seconds,
        }}
      />
    </>
  );
};

export default WheelCounter;
