import React from 'react';
import InfoStatus from '../info-status';
import { getLabelByPeriod } from '../../utils';
import Timeleft from '@/components/Timeleft';
import { cn } from '@/utils/style';
import useCountdown from '@/hooks/use-countdown';
import { Field, FieldGroup, Label } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { SettingsContentType } from '@/types/json-content/settings';

interface Props {
  filteredLimit?: any;
  className?: string;
}

const UnconfirmedCoolingOff = ({ filteredLimit, className }: Props) => {
  const { state, hasNoState } = useCountdown({
    short: false,
    date: filteredLimit?.disable_at,
  });

  const { t } = useTranslation();
  const settingsContent = t('account_settings') as SettingsContentType;
  const content = settingsContent?.sessions;

  return (
    <div
      className={cn([
        'flex flex-col items-start justify-between',
        className && className,
      ])}
    >
      <InfoStatus
        tagLabel={content?.info_status_label?.pending}
        description={getLabelByPeriod(filteredLimit?.period)}
        tagStatus="warning"
        className="mb-2"
      />

      <Field>
        <Label>{content?.countdown_label}</Label>
        <Timeleft
          units={{
            days: state?.days,
            minutes: state?.minutes,
            hours: state?.hours,
            seconds: state?.seconds,
          }}
        />
      </Field>
    </div>
  );
};

export default UnconfirmedCoolingOff;
