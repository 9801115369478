import React, { useMemo, useState } from 'react';
import { Tabs } from '@purposeinplay/core-v2';

import CoolingOff from '../cooling-off';
import SelfExcusion from '../self-exclusion';
import SessionLimit from '../session';

const ProfileLimits = () => {
  const [tabs, setTabs] = useState([
    {
      id: 'cooling_off',
      label: 'Cooling Off',
      active: true,
    },
    {
      id: 'self_exclusion',
      label: 'Self-Exclusion',
      active: false,
    },
    {
      id: 'session_limit',
      label: 'Session Limit',
      active: false,
    },
  ]);

  const [activeTab, setActiveTab] = useState('cooling_off');

  const getContent = useMemo(() => {
    switch (activeTab) {
      case 'cooling_off':
        return <CoolingOff />;
      case 'self_exclusion':
        return <SelfExcusion />;
      case 'session_limit':
        return <SessionLimit />;
      default:
        <CoolingOff />;
    }
  }, [activeTab]);

  return (
    <div>
      <Tabs
        tabStyleOptions={{
          navItemStyles: 'whitespace-nowrap',
        }}
        className="scrollbar mb-3 md:mb-7"
        variant="segmented-rounded"
        items={tabs}
        onClick={(e, item) => {
          setTabs((prevItems: any) => {
            return prevItems.map((element: any) => {
              return item.id === element.id
                ? {
                    ...element,
                    active: true,
                  }
                : { ...element, active: false };
            });
          });
          setActiveTab(item.id);
        }}
      />
      <div>{getContent}</div>
    </div>
  );
};

export default ProfileLimits;
