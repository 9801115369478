import { ParagraphLoader } from '@/components/Loaders';
import { useGetLotteryContent } from '@/components/Lottery/hooks/use-lottery-content';
import { useLotteriesQuery } from '@/generated';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { processMarkdown } from '@/utils/helpers';
import { Modal } from '@purposeinplay/core-v2';
import { useSearchParams } from 'next/navigation';
import React from 'react';

const LotteryTermsModal = () => {
  const { closeOnly } = useModal();
  const currentModal = useCurrentModal();

  const lotData = useGetLotteryContent();

  const { data: lotteriesCMS, isLoading: lotteriesCMSLoading } =
    useLotteriesQuery();

  const searchParams = useSearchParams();
  const lotteryId = searchParams.get('lot_id');

  let disclosureContent: string | undefined | null;

  if (lotteriesCMS?.lotteries?.data) {
    const foundLottery = lotteriesCMS.lotteries.data.find(
      (item) => item.attributes?.uid === lotteryId,
    );

    disclosureContent =
      foundLottery?.attributes?.disclosure ??
      lotteriesCMS.lotteries.data.find(
        (item) => item.attributes?.uid === 'wild-lottery',
      )?.attributes?.disclosure;
  }

  return (
    <Modal
      title={lotData?.terms_modal?.title || 'Terms & Conditions'}
      isOpen={currentModal === 'lottery-terms'}
      setIsOpen={() => {
        // removes the modal from the URL and removes lot_id query param
        closeOnly(['lot_id', 'm']);
      }}
      size="3xl"
    >
      {lotteriesCMSLoading ? (
        <ParagraphLoader />
      ) : (
        disclosureContent && (
          <div className="markdown-body">
            <div
              dangerouslySetInnerHTML={{
                __html: processMarkdown(disclosureContent),
              }}
            />
          </div>
        )
      )}
    </Modal>
  );
};

export default LotteryTermsModal;
