import React from 'react';
import SectionSettingsItem from '../../section-settings/section-settings-item';
import { TextLoader } from '@/components/Loaders';

const LoadingLimit = () => {
  return (
    <SectionSettingsItem>
      <TextLoader className="mb-4 h-7" />
      <TextLoader className="mb-4 h-6" />
      <TextLoader className="mb-4" />
      <TextLoader />
    </SectionSettingsItem>
  );
};

export default LoadingLimit;
