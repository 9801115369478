'use client';
import useIsStandalone from '@/hooks/use-is-standalone';
import useIsTelegram from '@/hooks/use-is-telegram';
import { cn } from '@/utils/style';
import React, { useMemo } from 'react';

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const { isStandalone } = useIsStandalone();
  const { isTelegram } = useIsTelegram();

  const isTgOrStandalone = useMemo(() => {
    return isTelegram || isStandalone;
  }, [isStandalone, isTelegram]);

  return (
    <div
      className={cn([
        'flex w-full flex-col',
        !isTgOrStandalone && 'pt-14 lg:pt-16',
      ])}
    >
      <div className="flex flex-col justify-between">{children}</div>
    </div>
  );
};

export default AppWrapper;
