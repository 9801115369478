import { useGetExchangeRatesLootbox } from './comp-points-exchange-rates';

export const useGetExchangeRatesPoints = () => {
  const { data: rates } = useGetExchangeRatesLootbox();

  let result: any = {};

  if (rates && rates.length > 0) {
    for (const item of rates) {
      if (item?.group === 'wof_regular_exchange') {
        result['wofWheel'] = item.rate.points;
      }
      if (item?.group === 'wof_jungle_exchange') {
        result['jungleWheel'] = item.rate.points;
      }
      if (item?.group === 'wof_vip_exchange') {
        result['vipWheel'] = item.rate.points;
      }
    }
  } else {
    result = {
      wofWheel: 5000,
      jungleWheel: 25000,
      vipWheel: 100000,
    };
  }

  return result;
};
