import React, { memo, useCallback, useState } from 'react';
import { ExchangeInputsProps } from '../types';
import {
  Button,
  ErrorMessage,
  Field,
  IconButton,
  Input,
  Label,
} from '@purposeinplay/core-v2';
import CurrencySelector from './currency-selector';
import ArrowsSvg from 'public/assets/arrows.svg';
import FsTabs from '@/components/FsTabs';
import { FS_TABS, WOF_TABS } from '@/components/FsTabs/components/helpers';
import { usePointsToUpdate } from '../hooks/use-points-to-update';

const ExchangeInputs = memo(
  ({
    error,
    formData,
    setFormData,
    selectedAction,
    pointsRemaining,
    setTabPoints,
  }: ExchangeInputsProps) => {
    const exchangeMoney = selectedAction?.label === 'Crypto';

    const { getPointsToUpdateWith, fsRate } = usePointsToUpdate(formData);

    const handleSetPoints = (amount: string) => {
      setFormData({ ...formData, points: Number(amount) });
    };

    const handleSetFull = () => {
      setFormData({
        ...formData,
        points: pointsRemaining,
      });
    };

    const handleSetTabPoints = useCallback(
      (points: number) => {
        setTabPoints && setTabPoints(points);
        if (selectedAction?.id === 'wof') {
          setFormData({ ...formData, points: getPointsToUpdateWith(points) });
        }
        if (selectedAction?.id === 'fs') {
          setFormData({ ...formData, points: points * fsRate });
        }
      },
      [
        formData,
        fsRate,
        getPointsToUpdateWith,
        selectedAction?.id,
        setFormData,
        setTabPoints,
      ],
    );

    if (selectedAction?.id === 'fs') {
      return (
        <div>
          <FsTabs key="fs" items={FS_TABS} onClick={handleSetTabPoints} />
          {error && (
            <span className="block text-xs text-text-critical data-[disabled]:pointer-events-none data-[disabled]:text-text-critical-disabled sm:text-sm/6">
              {error}
            </span>
          )}
        </div>
      );
    }

    if (selectedAction?.id === 'wof') {
      return (
        <div>
          <FsTabs key="wof" items={WOF_TABS} onClick={handleSetTabPoints} />
          {error && (
            <span className="block text-xs text-text-critical data-[disabled]:pointer-events-none data-[disabled]:text-text-critical-disabled sm:text-sm/6">
              {error}
            </span>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center space-x-0 space-y-2 lg:flex-row lg:justify-between lg:space-x-3 lg:space-y-0">
        <div className="input-number-container relative w-full flex-1">
          <Input
            error={!!error}
            errorMessage={error}
            type="number"
            maxLength={20}
            value={formData?.points?.toString()}
            onChange={(e) => handleSetPoints(e.target.value)}
            id="exchange-input"
            variant="static"
          />
          <Button
            className="absolute right-2 top-2"
            type="button"
            onClick={(e) => handleSetFull()}
            size="xs"
            color="tertiary"
          >
            100%
          </Button>
        </div>
        <IconButton
          className="pointer-events-none"
          color="tertiary"
          ghost
          size="sm"
          iconLeft={<ArrowsSvg className="rotate-90 transform md:rotate-0" />}
          iconOnly
        />
        <div className="input-number-container relative w-full flex-1">
          {exchangeMoney && (
            <CurrencySelector
              points={formData?.points}
              currency={formData?.currency}
              setFormData={setFormData}
            />
          )}
        </div>
      </div>
    );
  },
);

ExchangeInputs.displayName = 'ExchangeInputs';

export default ExchangeInputs;
