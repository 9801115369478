import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { Heading, IconButton } from '@purposeinplay/core-v2';
import Image from 'next/image';
import React, { useState } from 'react';
import useChatState from '../hooks/use-chat-state';
import CloseSVG from 'public/assets/close.svg';
import InfoSVG from 'public/assets/info.svg';
import RuleModal from './rule-modal';
import useWildChatContent from '../hooks/use-wild-chat-content';

const ChatHeader = () => {
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const { toggleChat, closeChat } = useChatState();
  const chatContent = useWildChatContent();

  const handleClick = () => {
    setRuleModalOpen(true);
    closeChat();
  };

  return (
    <div className="-mx-2 -mt-2 flex h-14 justify-between bg-bgr-lightest p-3 md:h-16">
      <div className="flex items-center space-x-1">
        <span className="text-base font-semibold text-text-default">
          {chatContent?.title}
        </span>
      </div>
      <div className="flex items-center">
        <IconButton
          className="[&>span]:flex [&>span]:items-center [&>span]:justify-center "
          iconOnly
          size="xs"
          iconRight={
            <InfoSVG className="h-3.5 w-3.5 fill-current text-text-default" />
          }
          color="tertiary"
          ghost
          onClick={handleClick}
        />
        <IconButton
          type="button"
          size="xs"
          onClick={toggleChat}
          ghost
          color="tertiary"
          iconLeft={<CloseSVG />}
          iconOnly
        />
      </div>
      <RuleModal isOpen={ruleModalOpen} setIsOpen={setRuleModalOpen} />
    </div>
  );
};

export default ChatHeader;
