import { Button } from '@purposeinplay/core-v2';
import { LoadMoreButtonProps } from '../types';

export const LoadMoreButton = ({
  onClick,
  isVisible,
  isLoading = false,
  disabled = false,
}: LoadMoreButtonProps) => {
  if (!isVisible) return null;

  return (
    <Button
      className="w-full"
      color="tertiary"
      onClick={onClick}
      disabled={disabled || isLoading}
      isLoading={isLoading}
    >
      {isLoading ? 'Loading...' : 'Load More'}
    </Button>
  );
};
