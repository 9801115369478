import { useMutation } from '@tanstack/react-query';
import { useWildChatInfo } from '@/modules/wild-chat/hooks/new-hooks';
import { useAuth } from '@/hooks/auth';
import { useToast } from '@purposeinplay/utils';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const useUpdateNickname = () => {
  const { data: wildChatInfo } = useWildChatInfo();
  const { toast } = useToast();
  const toastMessages = useOtherToastMessages();

  return useMutation({
    mutationKey: ['updateChatNickname'],
    mutationFn: async (nickname: string) => {
      if (!wildChatInfo?.access_token || !wildChatInfo?.host) {
        throw new Error('Missing required auth info');
      }

      const response = await fetch(`${wildChatInfo.host}/player_api/settings`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${wildChatInfo.access_token}`,
        },
        body: JSON.stringify({
          custom_chat_name: nickname,
        }),
      });

      try {
        const data = await response.text();
        if (response.ok) {
          return data ? JSON.parse(data) : {};
        } else {
          return Promise.reject(data ? JSON.parse(data) : {});
        }
      } catch (error) {
        return Promise.reject({ message: 'Internal system error', code: 500 });
      }
    },
  });
};

export default useUpdateNickname;
