import React from 'react';
import SidebarActionLink from './sidebar-action-link';
import { ActionLink } from '../types';
import { cn } from '@/utils/style';
import { useAppSelector } from '@/hooks/redux';
import { useIsMounted } from '@purposeinplay/utils';

interface Props {
  links: ActionLink[];
}

const ActionLinks = React.memo(({ links }: Props) => {
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();
  return (
    <div
      className={cn([
        'grid grid-cols-1 gap-1 xl:grid-cols-2 xl:p-0',
        isSidemenuOpen && isMounted && 'grid grid-cols-2 gap-1 md:grid-cols-2',
        !isSidemenuOpen && isMounted && 'xl:grid-cols-1',
      ])}
    >
      {links &&
        links.map((link) => (
          <SidebarActionLink
            key={`sidebar-action-link-${link.heading}-${link?.text}`}
            link={link}
          />
        ))}
    </div>
  );
});

ActionLinks.displayName = 'SidemenuActionLinks';

export default ActionLinks;
