import { Chip, Select } from '@purposeinplay/core-v2';
import React, { useMemo } from 'react';
import { SELECT_ITEMS } from '../constants';
import { ExchangeInputsProps } from '../types';
import { useFSRate } from '../hooks/use-fs-rate';

const WofDropdown = ({
  formData,
  setFormData,
  tabPoints,
}: {
  formData: ExchangeInputsProps['formData'];
  setFormData: ExchangeInputsProps['setFormData'];
  tabPoints: number;
}) => {
  const { exchangeRatesPoints } = useFSRate();
  const getChipLabel = useMemo(() => {
    if (formData?.exchangeToWoF.WildWheelSpins) {
      return 'Common Wheel Spins';
    }
    if (formData?.exchangeToWoF.JungleWheelSpins) {
      return 'Rare Wheel Spins';
    }
    if (formData?.exchangeToWoF.VIPWheelSpins) {
      return 'Epic Wheel Spins';
    }
  }, [formData?.exchangeToWoF]);

  const getPointsFromId = (id: string) => {
    if (id === 'wof') {
      return exchangeRatesPoints.wofWheel;
    }
    if (id === 'jungle') {
      return exchangeRatesPoints.jungleWheel;
    }
    if (id === 'vip') {
      return exchangeRatesPoints.vipWheel;
    }
    return 0;
  };

  return (
    <Select
      multi={false}
      dropdownStylingOptions={{
        contentStyles: 'relative',
      }}
      onSelect={(item) => {
        setFormData({
          ...formData,
          exchangeToWoF: {
            WildWheelSpins: item.id === 'wof' ? true : false,
            JungleWheelSpins: item.id === 'jungle' ? true : false,
            VIPWheelSpins: item.id === 'vip' ? true : false,
          },
          points: tabPoints * getPointsFromId(item.id),
        });
      }}
      items={SELECT_ITEMS}
      customTrigger={
        <Chip customChipStyles="truncate" withArrow label={getChipLabel} />
      }
    />
  );
};

export default WofDropdown;
