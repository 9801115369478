import { IconButton } from '@purposeinplay/core-v2';
import React from 'react';
import ChatIcon from 'public/assets/chat-icon.svg';
import useChatState from '../hooks/use-chat-state';
import useFeatureVisibility from '@/hooks/use-feature-visibility';
import { cn } from '@/utils/style';

interface Props {
  stylingOptions?: {
    className?: string;
    iconStyles?: string;
    buttonSize?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
  };
}

const WildChatButton = ({ stylingOptions }: Props) => {
  const { toggleChat } = useChatState();
  const { buttonSize, iconStyles, className } = stylingOptions ?? {};

  const buttonClassName =
    className ??
    'flex h-7 w-7 flex-row items-center justify-center truncate rounded-lg lg:h-8  lg:w-8 lg:rounded-radius-minimal  [&>span:nth-of-type(1)]:!text-xs [&>span:nth-of-type(1)]:!font-semibold [&>span:nth-of-type(2)]:!h-auto [&>span:nth-of-type(2)]:!w-auto';

  return (
    <IconButton
      onClick={toggleChat}
      size={buttonSize ?? 'md'}
      iconOnly
      className={buttonClassName}
      ghost
      color="tertiary"
      iconStyles={cn(['-mt-0.5', iconStyles])}
      iconRight={<ChatIcon />}
    />
  );
};

export default WildChatButton;
