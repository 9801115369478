import { useAuth } from '@/hooks/auth';
import { useCryptoDepositInfo } from '@/hooks/paymentsv2';
import { useMemo } from 'react';
import { DepositLoader, TextLoader } from '../Loaders';
import QRCode from 'qrcode.react';
import CopySVG from 'public/assets/wallet/copy.svg';
import copy from 'copy-to-clipboard';
import { IconButton, Input, Text } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { SettingsContentType } from '@/types/json-content/settings';

interface Props {
  closeModal?: () => void;
  deposit?:
    | {
        currency: string;
        amount: number;
      }
    | undefined;
  disableDark?: boolean;
}

const DepositForm: React.FC<Props> = ({ deposit, closeModal }) => {
  const {
    user: { currentUser },
  }: any = useAuth();
  const { depositInfo, isLoading } = useCryptoDepositInfo(
    currentUser?.currency,
  );
  const depositData = useMemo(
    () => deposit || { currency: currentUser?.currency, amount: '0.005' },
    [currentUser, deposit],
  );

  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const depositFormContent = contentSettings?.update_profile?.form;

  if (!depositInfo || isLoading) return <DepositLoader />;

  const isDepositUSDT =
    depositInfo?.type === 'multi_protocol_crypto_address' &&
    depositInfo.proposedValue === 'ERC20';

  const depositInfoHref = isDepositUSDT
    ? depositInfo?.addressByProtocol?.ERC20.href
    : depositInfo?.href;

  const depositInfoAddress = isDepositUSDT
    ? depositInfo?.addressByProtocol?.ERC20.address
    : depositInfo?.address;

  return (
    <div className="md:pb-3">
      <div className="mb-3 flex flex-col items-center justify-center">
        <div className="rounded-lg bg-white p-3 shadow-md">
          <QRCode value={depositInfoHref} size={100} />
        </div>
        <Text>{`${depositFormContent?.qr?.part1} ${depositData?.currency} ${depositFormContent?.qr?.part2}`}</Text>
      </div>
      <div className="relative mb-3">
        <Input
          className="truncate !pr-16"
          variant="static"
          id="depositAddressInput"
          type="text"
          readOnly
          label={`${depositFormContent?.input?.label?.part1} ${depositData.currency} ${depositFormContent?.input?.label?.part2}`}
          value={depositInfoAddress}
        />
        <IconButton
          onClick={() => copy(depositInfoAddress)}
          className="group absolute right-1 top-[31px] h-8 w-8"
          ghost
          color="primary"
        >
          <CopySVG className="stroke-current text-text-default hover:stroke-current hover:text-base-primary" />
        </IconButton>
      </div>

      {closeModal && (
        <div
          className="hover:text-success cursor-pointer text-text-default underline"
          onClick={() => {
            localStorage.removeItem('awaitingDeposit');
            closeModal();
          }}
        >
          {depositFormContent?.footer_text}
        </div>
      )}
    </div>
  );
};

export default DepositForm;
