'use client';
import React from 'react';
import { useTournamentStatus } from '../utils/use-tournament-status';
import { Badge } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { cn } from '@/utils/style';
import { TournamentsContentType } from '@/types/json-content/tournaments';
import useDynamicTournamentState from '../hooks/use-dynamic-tournament-state';
import { TextLoader } from '@/components/Loaders';
import { StatusTagProps } from '../types';
import TournamentCountdownBadge from './tournament-countdown-badge';
import { usePathname } from 'next/navigation';

const TournamentStatusTag = ({
  tournament,
  className,
  size = 'lg',
}: StatusTagProps) => {
  const { tournament: dynamicTournament, matchingTournament } =
    useDynamicTournamentState();
  const pathname = usePathname();
  const isForDynamicPage = pathname.includes('/tournaments/');

  const tournamentToUse = tournament || dynamicTournament;

  const { tournamentEnded, inTheFuture } = useTournamentStatus(tournamentToUse);

  const isDynamicTournament =
    tournamentToUse?.frontend_identifier?.includes('dynamic');

  const { t } = useTranslation();
  const tournamentContent = t('tournaments') as TournamentsContentType;

  const tournamentStatus = tournamentEnded
    ? tournamentContent['statuses'].finished
    : tournamentToUse?.in_progress
      ? tournamentContent['statuses'].active
      : inTheFuture
        ? tournamentContent['statuses'].soon
        : '';

  const getBadgeType = () => {
    switch (tournamentStatus) {
      case tournamentContent['statuses'].finished:
        return 'error';
      case tournamentContent['statuses'].active:
        return 'purple';
      case tournamentContent['statuses'].soon:
        return 'warning';
      default:
        return 'warning';
    }
  };

  if (!tournamentToUse) return <TextLoader className="h-6 w-10" />;

  const isActive = tournamentStatus === tournamentContent['statuses'].active;

  return (
    <div
      className={cn([
        'absolute left-3 top-3 z-10 flex flex-col gap-1 [&>div>span>span]:text-xxs',
        className,
      ])}
    >
      <div className="flex flex-wrap items-center gap-1">
        <Badge
          className={!isForDynamicPage ? 'text-white' : ''}
          type={getBadgeType()}
          ghost
          size={size}
          radius="rounded"
        >
          <div className="capitalize">{tournamentStatus}</div>
        </Badge>
        {isActive && isForDynamicPage && (
          <TournamentCountdownBadge tournament={tournamentToUse} />
        )}

        {isDynamicTournament && (
          <Badge size={size} radius="rounded">
            <div className="capitalize">
              {tournamentContent?.statuses?.dynamic}
            </div>
          </Badge>
        )}
      </div>
    </div>
  );
};

export default TournamentStatusTag;
