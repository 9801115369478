import React, { useCallback, useMemo } from 'react';
import { Badge, Button, Heading } from '@purposeinplay/core-v2';
import Image from 'next/image';
import { useToast } from '@purposeinplay/utils';
import WalletPrizeSVG from 'public/assets/wallet-prize.svg';
import {
  useClearPreviewBonusCoupon,
  useSetPreviewBonusCoupon,
} from '@/hooks/bonuses';
import { useAuth } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import { getImageUrlSelectBonus } from '../utils';
import { useTranslation } from '@/app/i18n/client';
import { useAppDispatch } from '@/hooks/redux';
import {
  setBonusDetailsnDynamicContent,
  setBonusSelectionDynamicContent,
} from '@/app/reducers/bootstrap';
import {
  PreviewBonus,
  PreviewBonusesContentType,
} from '@/types/json-content/preview_bonuses';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { useAnalytics } from '@/hooks/use-analytics';

export const SelectBonusCard = React.memo(
  ({
    bonus,
    index,
    totalItems,
    isInBonusesPage,
    className,
    widthFull = false,
  }: {
    index: number;
    bonus: PreviewBonus;
    totalItems: number;
    isInBonusesPage?: boolean;
    className?: string;
    widthFull?: boolean;
  }) => {
    const { toast } = useToast();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const content = t('preview_bonuses') as PreviewBonusesContentType;
    const { open } = useModal();
    const { mutateAsync: activateBonus, isPending: setBonusLoading } =
      useSetPreviewBonusCoupon();

    const { mutateAsync: clearBonus, isPending: clearBonusLoading } =
      useClearPreviewBonusCoupon();

    const { user } = useAuth();

    const promoCode = useMemo(
      () => user?.currentUser?.deposit_bonus_code,
      [user],
    );
    const isCardLoading = useMemo(() => {
      return setBonusLoading || clearBonusLoading;
    }, [setBonusLoading, clearBonusLoading]);

    const { track } = useAnalytics();

    const handleClaimBonus = useCallback(
      async (code: string) => {
        if (code === promoCode) {
          open('wallet', { a: 'deposit', c: 'BTC' });
          return;
        }
        try {
          if (promoCode) {
            await clearBonus({});
          }
          await activateBonus(code);

          toast({
            state: 'success',
            title: content?.bonus_selection_modal?.notification,
          });

          track('claim_post_signup_bonus', {
            category: 'bonuses',
            label: code,
            user_id: user?.currentUser?.id,
          });
        } catch (error) {
          console.error('Failed to claim bonus:', error);
        }
      },
      [
        activateBonus,
        clearBonus,
        content?.bonus_selection_modal?.notification,
        open,
        promoCode,
        toast,
        track,
        user?.currentUser?.id,
      ],
    );

    const handleClickDetails = () => {
      if (isInBonusesPage) {
        dispatch(
          setBonusDetailsnDynamicContent({
            data: bonus,
          }),
        );
        open('details', { bonus: bonus?.id });
        return;
      }
      dispatch(
        setBonusSelectionDynamicContent({
          data: bonus,
          isOpen: 'details',
        }),
      );
    };

    return (
      <div
        className={cn([
          'relative flex h-full w-full max-w-[16.688rem] flex-col justify-between gap-spacing-md rounded-2xl bg-bgr-lightest p-3 lg:max-w-[inherit]',
          !isInBonusesPage &&
            'w-[16.688rem] max-w-[16.688rem] md:max-w-[16.875rem]',
          widthFull === true &&
            'w-full max-w-none md:max-w-[16.875rem] lg:max-w-[inherit]',
          className && className,
        ])}
      >
        <div className="absolute -top-6 left-1/2 h-12 w-12 -translate-x-1/2 transform">
          <Image
            src={getImageUrlSelectBonus(bonus)}
            objectFit="cover"
            alt={`Bonus ${bonus?.title} Image`}
            width={48}
            height={48}
            loader={DEFAULT_IMAGE_LOADER}
          />
        </div>

        <div className="flex w-full flex-row justify-end">
          <Badge className="" type="success" radius="rounded" size="md" ghost>
            {`${bonus?.wager_req} Wager`}
          </Badge>
        </div>

        <div>
          <Heading
            as="h2"
            className="text-lg font-semibold leading-6 text-text-default"
          >
            {bonus?.title}
          </Heading>
          <p className="text-xs leading-4 text-text-subdued">
            {bonus?.description}
          </p>
        </div>
        <div className="flex items-center justify-between rounded-lg border border-dotted border-border-general-default bg-surface-default p-2 ">
          <div className="flex flex-col">
            <span className="text-sm leading-none text-text-subdued">
              {content?.bonus_selection_modal?.use_bonus}
            </span>
            <span className="text-sm  font-semibold text-base-primary">
              {bonus?.coupon_code}
            </span>
          </div>
          <WalletPrizeSVG className="fill-current text-text-subdued" />
        </div>
        <div className="flex flex-row space-x-1">
          <Button
            onClick={handleClickDetails}
            color="tertiary"
            className="w-full"
          >
            {content?.bonus_selection_modal?.actions?.details}
          </Button>
          <Button
            isLoading={isCardLoading}
            disabled={isCardLoading}
            onClick={() => handleClaimBonus(bonus?.coupon_code as string)}
            className="w-full whitespace-nowrap"
          >
            {bonus?.coupon_code === promoCode
              ? content?.bonus_selection_modal?.actions?.deposit
              : content?.bonus_selection_modal?.actions?.claim}
          </Button>
        </div>
        {index !== totalItems - 1 && (
          <div className="absolute -right-6 top-24 z-30 flex h-8 w-8 items-center justify-center rounded-full bg-bgr-lighter p-2.5 text-xs font-medium leading-none text-text-default">
            {content?.bonus_selection_modal?.card_dots['or']}
          </div>
        )}
      </div>
    );
  },
);

SelectBonusCard.displayName = 'SelectBonusCard';

export default SelectBonusCard;
