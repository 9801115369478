import { RewardResult } from '../types';

export const CARD_COUNT = 9;
export const CARD_COUNT_MODAL = 10;

export const REWARD_PLACEHOLDERS: RewardResult[] = [
  {
    id: '1',
    title: 'Hell Hot 100',
    challenge_id: 'challenge_1',
    challenge_title: 'Placeholder Challenge 1',
    challenge_type: 'Type 1',
    user_id: 'user_1',
    game_title: 'Placeholder Game 1',
    bet_message_id: 'bet_message_1',
    win_message_id: 'win_message_1',
    bet_message_reference_id: 'bet_reference_1',
    win_message_reference_id: 'win_reference_1',
    bet_amount: '10',
    win_amount: '20',
    reward_type: 'Cash Bonus',
    amount: '50',
    created_at: '2023-01-01',
    multiplier_custom_data: {
      target_multiplier: 1000,
    },

    min_bets: {
      USDT: 0.5,
      BTC: 0.000013,
      BCH: 0.00217,
      ETH: 0.0002,
      LTC: 0.0071,
      DOGE: 6.17,
      XRP: 0.79,
      ADA: 1.26,
      TRX: 4.76,
      BNB: 0.002007,
    },
    cash_bonus: {
      amount: {
        usdt: 370,
        btc: 0.009908,
        bch: 1.607787,
        eth: 0.1817,
        ltc: 5.223,
        doge: 4569.5937,
        xrp: 591.2878,
        ada: 934.5351,
        trx: 3516.6283,
        bnb: 1.486106,
      },
    },
    free_spins_bonus: {},
    game_identifier: 'endorphina/endorphina2_HellHot100',
  },
  {
    id: '2',
    challenge_id: 'challenge_2',
    challenge_title: 'Placeholder Challenge 2',
    challenge_type: 'Type 2',
    user_id: 'user_2',
    game_title: 'Placeholder Game 2',
    bet_message_id: 'bet_message_2',
    win_message_id: 'win_message_2',
    bet_message_reference_id: 'bet_reference_2',
    win_message_reference_id: 'win_reference_2',
    bet_amount: '15',
    win_amount: '25',
    reward_type: 'Free Spins Bonus',
    amount: '30',
    created_at: '2023-02-01',
    multiplier_custom_data: {
      target_multiplier: 700,
    },
    min_bets: {
      USDT: 1,
      BTC: 0.000027,
      BCH: 0.004345,
      ETH: 0.0005,
      LTC: 0.0141,
      DOGE: 12.3503,
      XRP: 1.5972,
      ADA: 2.5229,
      TRX: 9.5037,
      BNB: 0.004018,
    },
    cash_bonus: {
      amount: {
        usdt: 200,
        btc: 0.005356,
        bch: 0.869074,
        eth: 0.0982,
        ltc: 2.8233,
        doge: 2470.05,
        xrp: 319.8061,
        ada: 504.9633,
        trx: 1902.2578,
        bnb: 0.803488,
      },
    },
    free_spins_bonus: {},
    game_identifier: 'softswiss/WildCash',
    title: 'Wild Cash',
  },

  {
    id: '3',
    challenge_id: 'challenge_3',
    challenge_title: 'Placeholder Challenge 3',
    challenge_type: 'Type 3',
    user_id: 'user_3',
    game_title: 'Placeholder Game 3',
    bet_message_id: 'bet_message_3',
    win_message_id: 'win_message_3',
    bet_message_reference_id: 'bet_reference_3',
    win_message_reference_id: 'win_reference_3',
    bet_amount: '20',
    win_amount: '35',
    reward_type: 'Multiplier Bonus',
    amount: '40',
    created_at: '2023-03-01',
    multiplier_custom_data: {
      target_multiplier: 2300,
    },
    min_bets: {
      USDT: 1,
      BTC: 0.000027,
      BCH: 0.004345,
      ETH: 0.0005,
      LTC: 0.0141,
      DOGE: 12.3503,
      XRP: 1.5972,
      ADA: 2.5229,
      TRX: 9.5037,
      BNB: 0.004018,
    },

    cash_bonus: {
      amount: {
        usdt: 940,
        btc: 0.025198,
        bch: 4.086779,
        eth: 0.462,
        ltc: 13.2806,
        doge: 11609.238,
        xrp: 1504.8909,
        ada: 2374.0743,
        trx: 8942.6203,
        bnb: 3.775575,
      },
    },
    free_spins_bonus: {},
    title: '3 Buzzing Wilds',
    game_identifier: 'pragmaticexternal/3BuzzingWilds',
  },
  {
    id: '4',
    challenge_id: 'challenge_3',
    challenge_title: 'Placeholder Challenge 3',
    challenge_type: 'Type 3',
    user_id: 'user_3',
    game_title: 'Placeholder Game 3',
    bet_message_id: 'bet_message_3',
    win_message_id: 'win_message_3',
    bet_message_reference_id: 'bet_reference_3',
    win_message_reference_id: 'win_reference_3',
    bet_amount: '20',
    win_amount: '35',
    reward_type: 'Multiplier Bonus',
    amount: '40',
    created_at: '2023-03-01',
    multiplier_custom_data: {
      target_multiplier: 4000,
    },
    min_bets: {
      USDT: 0.25,
      BTC: 0.000007,
      BCH: 0.001088,
      ETH: 0.0001,
      LTC: 0.0035,
      DOGE: 3.0876,
      XRP: 0.4001,
      ADA: 0.6322,
      TRX: 2.3774,
      BNB: 0.001004,
    },

    cash_bonus: {
      amount: {
        usdt: 1050,
        btc: 0.02816,
        bch: 4.569191,
        eth: 0.5165,
        ltc: 14.8515,
        doge: 12967.7658,
        xrp: 1680.4859,
        ada: 2655.4376,
        trx: 9985.0112,
        bnb: 4.218199,
      },
    },
    free_spins_bonus: {},
    title: 'Big Wild Buffalo',
    game_identifier: 'belatra/BigWildBuffalo',
  },
];
