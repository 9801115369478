import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ActivityCardProps, ChallengesContentProps } from '../types';
import useShouldFillProfile from '@/hooks/use-should-fill-profile';
import useModal from '@/hooks/modal';
import { useTranslation } from 'react-i18next';
import { Text } from '@purposeinplay/core-v2';
import Multiplier from './multiplier';
import WinAmount from './win-amount';

const renderValue = (value: any) => (value !== undefined ? value : 'N/A');

const ActivityCard = React.memo(
  ({
    winAmount,
    multiplier,
    item,
    isForChallenge,
    linkSrc,
  }: ActivityCardProps) => {
    const { t } = useTranslation();
    const challengesContent = t('challenges') as ChallengesContentProps;
    const cardContent = challengesContent?.card_data;

    const shouldFillProfile = useShouldFillProfile(
      isForChallenge ? item?.game_provider : item?.game_provider,
    );
    const { open } = useModal();

    const basePath = `https://cdn.wild.io/thumbnail`;
    const fallbackSrc = `${basePath}/${
      (item?.game_identifier || '').split('/')[0]
    }/${(item?.game_identifier || '').split('/')[1]}.webp`;

    const [src, setSrc] = useState(`${basePath}/${item?.game_identifier}.png`);

    return (
      <Link prefetch={false} passHref href={`/games/${linkSrc}`}>
        <div
          className="group"
          onClick={(e) => {
            if (shouldFillProfile) {
              e.preventDefault();
              open('fill-profile');
              return;
            }
          }}
        >
          <div className="wild-transition flex flex-row space-x-2.5 rounded-3xl bg-bgr-lightest p-2 group-hover:-translate-y-1">
            <div>
              <div className="relative h-[96px] w-[96px] shrink-0 overflow-hidden rounded-2xl">
                <Image
                  alt={`Game thumbnail for ${item?.game_identifier}`}
                  src={src}
                  onError={() => setSrc(fallbackSrc)}
                  fill
                  className="rounded-lg object-cover"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex h-full max-w-[17rem] flex-1 flex-col justify-between text-sm leading-4">
                <div>
                  <Text className="font-semibold leading-6">
                    {item?.title}{' '}
                  </Text>

                  <div className="overflow-hidden text-sm leading-5 text-text-default">
                    {cardContent?.text?.first_to_hit ?? 'First to hit'}&nbsp;
                    <span className="text-base-primary">
                      {renderValue(multiplier)}x
                    </span>{' '}
                    {cardContent?.text?.with_min ?? 'with min'}&nbsp;
                    <span className="text-sm text-text-default">
                      {renderValue(<Multiplier goal={item} />)}{' '}
                      <span className="text-sm text-text-default">
                        {cardContent?.text?.bet ?? 'bet'}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="flex flex-row space-x-1 [&>svg]:fill-current [&>svg]:text-text-subdued [&>svg]:hover:text-green-500">
                  <div className="text-sm leading-5 text-text-subdued">
                    {cardContent?.prize_title ?? 'Prize'}:
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="text-sm leading-5 text-text-default">
                      {item?.reward_type !== 'freespins'
                        ? renderValue(<WinAmount amount={winAmount} />)
                        : `${item?.free_spins_bonus?.freespins_count} FS`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  },
);

ActivityCard.displayName = 'ActivityCard';

export default ActivityCard;
