import React from 'react';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';
import ContainerBodyLayout from './container-body-layout';
import Notification from '@/modules/feed/index.tsx';
import GameContainer from './game-container';
import SidebarTournaments from './sidebar-tournaments';
import { useGamesContent } from '@/hooks/use-games-content';
import GameSection from './game-section';

const AnimatedContainerBody = () => {
  const { activeSection } = useGamePageSidebar();
  const { gamesContent } = useGamesContent();
  const sections = gamesContent.game_sidebar.sections;

  if (activeSection === 'notification') {
    return (
      <ContainerBodyLayout title={sections.notification}>
        <Notification forSidebar />
      </ContainerBodyLayout>
    );
  }
  if (activeSection === 'favorites') {
    return (
      <ContainerBodyLayout title={sections.favorites}>
        <GameContainer />
      </ContainerBodyLayout>
    );
  }
  if (activeSection === 'tournaments') {
    return <SidebarTournaments />;
  }
  if (activeSection === 'new') {
    return (
      <ContainerBodyLayout title="New Games">
        <div className="flex flex-col gap-7">
          <GameSection hasTitle={false} gameCat="fresh-releases" />
        </div>
      </ContainerBodyLayout>
    );
  }
  if (activeSection === 'popular') {
    return (
      <ContainerBodyLayout title="Popular Games">
        <div className="flex flex-col gap-7">
          <GameSection hasTitle={false} gameCat="top-games" />
        </div>
      </ContainerBodyLayout>
    );
  }

  return (
    <ContainerBodyLayout
      title={
        activeSection
          ? sections[activeSection as keyof typeof sections] || ''
          : ''
      }
    >
      {activeSection}
    </ContainerBodyLayout>
  );
};

export default AnimatedContainerBody;
