import { useTranslation } from '@/app/i18n/client';
import { useDepositPreviewBonuses } from '@/hooks/bonuses';
import { PreviewBonusesContentType } from '@/types/json-content/preview_bonuses';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import React from 'react';
import { getTitleByDepositCount } from '../utils';

const PopUpContent = React.memo(
  ({
    content,
    depositCount,
  }: {
    content: any;
    depositCount: number | undefined;
  }) => {
    const { t } = useTranslation();
    const contentPreviewBonuses = t(
      'preview_bonuses',
    ) as PreviewBonusesContentType;
    const contentPreview =
      contentPreviewBonuses?.bonus_selection_modal?.preview_bonuses;
    return (
      <div className="flex flex-row items-center gap-spacing-xs">
        <Image
          src="/assets/deposit-pop-up.svg"
          width={48}
          height={48}
          alt="Deposit pop up icon"
          loader={DEFAULT_IMAGE_LOADER}
        />
        <div className="flex w-3/4 flex-col">
          <Text
            size="xl"
            as="span"
            className="mb-1 font-bold leading-none text-text-default"
          >
            {getTitleByDepositCount(depositCount || 0, contentPreview)}
          </Text>
          <Text size="lg" as="span" className="text-text-subdued">
            {content?.description}
          </Text>
        </div>
      </div>
    );
  },
);

PopUpContent.displayName = 'PopUpContent';

export default PopUpContent;
