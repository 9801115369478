import { Badge, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import React, { useState } from 'react';
import ArrowLeftSVG from 'public/assets/arrow-left.svg';
import { cn } from '@/utils/style';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { useAppSelector } from '@/hooks/redux';
import useSearchTerms from '@/modules/search/hooks/use-search-terms';
import Link from 'next/link';
import { useGamePageSidebar } from '@/modules/layout/sidebar/context/game-page-sidebar-context';

interface Props {
  /** The image component*/
  image?: React.ReactNode;
  /** If no image component has been passed, an imageUrl can be used*/
  imageUrl?: string;
  /** Fallback image url*/
  fallBackUrl?: string;
  /** The title displayed on the right side of the image */
  title: string;
  /** The provider of the game */
  provider: string;
  /** Is the game popular */
  isPopular?: boolean;
  slug?: string;
}

const MinimalGameCard: React.FC<Props> = ({
  image,
  imageUrl,
  title,
  fallBackUrl,
  provider,
  isPopular,
  slug,
}) => {
  const [showArrow, setShowArrow] = useState(false);
  const [src, setSrc] = React.useState(imageUrl);
  const { isRestricted, isPending } = useIsRestricted(provider);

  // Add hook to access sidebar drawer state
  const { setIsOpen: setSidebarIsOpen } = useGamePageSidebar();

  const { t } = useTranslation();
  const generalContent = t('general') as GeneralContentType;
  const restrictedPage = generalContent?.restricted_page;

  const { addSearchTerm } = useSearchTerms();

  const { query } = useAppSelector((state) => state.search);

  const handleGameClick = () => {
    setSidebarIsOpen(false);

    if (!isPopular) {
      addSearchTerm(query);
    }
  };

  return (
    <Link href={`/games/${slug}`}>
      <div
        onClick={handleGameClick}
        onMouseEnter={() => setShowArrow(true)}
        onMouseLeave={() => setShowArrow(false)}
        className={cn([
          'wild-transition relative flex cursor-pointer flex-row items-center justify-between space-x-2 rounded-lg p-1 hover:bg-surface-hovered',
          showArrow && 'pr-3',
          isRestricted && 'bg-surface-hovered grayscale',
        ])}
      >
        <div className="flex flex-row items-center space-x-2">
          {imageUrl && !image ? (
            <div
              className={cn([
                'relative h-10 w-10 rounded-lg [&>div]:first-of-type:rounded-lg',
              ])}
            >
              <Image
                alt={`Game thumbnail for ${title}`}
                src={src || imageUrl}
                onError={() => setSrc(fallBackUrl)}
                fill
                className="rounded-lg object-cover"
                sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 15vw"
              />
            </div>
          ) : (
            image
          )}
          <div className="flex flex-col space-y-1">
            <Text
              size="sm"
              className="max-w-32 truncate font-medium leading-none"
            >
              {title}
            </Text>
            <Text
              size="xs"
              className="font-normal capitalize leading-none text-text-subdued"
            >
              {provider}
            </Text>
          </div>
        </div>
        {/* {showArrow && !isRestricted && (
        <div className="flex h-4 w-4 items-center justify-center">
          <ArrowLeftSVG className="h-3 w-3 rotate-180 fill-current text-icons-primary" />
        </div>
      )} */}
        {isRestricted && (
          <Badge className="text-xs" radius="rounded" ghost type="neutral">
            {restrictedPage?.gamecard?.title}
          </Badge>
        )}
      </div>
    </Link>
  );
};

export default MinimalGameCard;
