import { useEffect, useRef, useCallback } from 'react';
import { scrollLockManager } from './utils/scroll-lock-manager';
import { ScrollOptions } from './types/scroll-lock';
import { useEventListener } from 'usehooks-ts';
import useMediaQuery, { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';

const useBodyLock = (
  isLocked: boolean,
  options: ScrollOptions = {},
  lockableElements?: Element | Element[],
) => {
  const { matchesQuery: isMobileLg } = useMediaQuery(MOBILE_LG_BREAKPOINT);
  const lockId = useRef(`lock-${Math.random().toString(36).substr(2, 9)}`);
  const previousIsLocked = useRef(isLocked);

  // Handle resize events
  const handleResize = useCallback(() => {
    if (isLocked && isMobileLg) {
      // Re-apply lock with updated window dimensions
      scrollLockManager.unlock(lockId.current);
      scrollLockManager.lock(lockId.current, options);
    }
  }, [isLocked, isMobileLg, options]);

  useEventListener('resize', handleResize);

  useEffect(() => {
    if (!isMobileLg) return;

    // Handle single element or array of elements
    const elements = lockableElements
      ? Array.isArray(lockableElements)
        ? lockableElements
        : [lockableElements]
      : [];

    // Register lockable elements
    elements.forEach((element) => {
      if (element) {
        scrollLockManager.addLockableTarget(element);
      }
    });

    return () => {
      // Cleanup lockable elements
      elements.forEach((element) => {
        if (element) {
          scrollLockManager.removeLockableTarget(element);
        }
      });
    };
  }, [lockableElements, isMobileLg]);

  useEffect(() => {
    if (!isMobileLg) return;

    // Only apply changes if the lock state has changed
    if (isLocked !== previousIsLocked.current) {
      if (isLocked) {
        scrollLockManager.lock(lockId.current, options);
      } else {
        scrollLockManager.unlock(lockId.current);
      }
      previousIsLocked.current = isLocked;
    }

    // Cleanup on unmount
    return () => {
      if (isLocked) {
        scrollLockManager.unlock(lockId.current);
      }
    };
  }, [isLocked, options, isMobileLg]);

  // Return functions to pause and resume this specific lock
  return {
    pauseLock: () => {
      if (isLocked && isMobileLg) {
        scrollLockManager.unlock(lockId.current);
      }
    },
    resumeLock: () => {
      if (isLocked && isMobileLg) {
        scrollLockManager.lock(lockId.current, options);
      }
    },
  };
};

export default useBodyLock;
