import React from 'react';
import { GameCard } from '@/components/GameCard';
import Link from 'next/link';
import { useAuth } from '@/hooks/auth';
import useShouldFillProfile from '@/hooks/use-should-fill-profile';
import useModal from '@/hooks/modal';
import PlaySVG from 'public/assets/play-new.svg';
import {
  useAddToFavorites,
  useFavoriteGamesData,
  useRemoveFromFavorites,
} from '@/hooks/games/favorites';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import { cn } from '@/utils/style';
import { FALLBACK_SRC_BASE } from '@/components/GameCard/constants';

interface GridGameItemProps {
  game: any;
  forBottomDrawer?: boolean;
}

const GridGameItem: React.FC<GridGameItemProps> = ({
  game,
  forBottomDrawer = false,
}) => {
  const shouldFillProfile = useShouldFillProfile(game?.provider);
  const { mutateAsync: addToFavorites, isPending: isAddingToFavorites } =
    useAddToFavorites();
  const {
    mutateAsync: removeFromFavorites,
    isPending: isRemovingFromFavorites,
  } = useRemoveFromFavorites();
  const { favoriteGameIdentifiers } = useFavoriteGamesData();

  const { isRestricted, isPending } = useIsRestricted(game?.provider);

  const { open } = useModal();
  const {
    user: { isAuthenticated },
  } = useAuth();
  const isMutating = isAddingToFavorites || isRemovingFromFavorites;

  const handleOnFavoriteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!favoriteGameIdentifiers?.includes(game.identifier)) {
      addToFavorites({
        gameID: game.id,
        completeGame: game,
      });
    } else {
      removeFromFavorites({
        gameID: game.id,
        completeGame: game,
      });
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof window !== 'undefined') {
      const bottomDrawerElement = document.getElementById(
        'game-page-bottom-drawer',
      );
      if (bottomDrawerElement) {
        bottomDrawerElement.style.display = 'none';
      }

      const closeEvent = new CustomEvent('closeBottomDrawer');
      document.dispatchEvent(closeEvent);
    }

    if (shouldFillProfile) {
      e.preventDefault();
      open('fill-profile');
      return;
    }
    if (isRestricted) {
      e.preventDefault();
    }
  };

  // Class to control the container size based on context
  const containerClass = forBottomDrawer
    ? 'h-full w-full' // Full size for bottom drawer
    : 'h-full w-full'; // Full size for sidebar

  return (
    <div className={containerClass}>
      <Link
        href={`/games/${game?.slug}`}
        className={cn([
          'block h-full w-full',
          isRestricted && 'cursor-default',
        ])}
        onClick={handleClick}
      >
        <div className="h-full w-full">
          <GameCard
            identifier={game?.identifier}
            tag={game?.payout}
            provider={game?.provider}
            isFavorited={favoriteGameIdentifiers?.includes(game.identifier)}
            hideFavoriteButton={!isAuthenticated}
            onFavoriteClick={handleOnFavoriteClick}
            isDisabled={isMutating}
            imageUrl={`https://cdn.wild.io/thumbnail/${game?.identifier}.png`}
            fallBackUrl={`${FALLBACK_SRC_BASE}/thumbnail/${
              game?.identifier.split('/')[0]
            }/${game?.identifier.split('/')[1]}.webp`}
            title={game.title}
            playIcon={<PlaySVG />}
          />
        </div>
      </Link>
    </div>
  );
};

export default GridGameItem;
