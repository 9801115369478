// GameIframeElement.tsx
import React, { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import { useAppSelector } from '@/hooks/redux';
import { useScreenLayout } from '../../context/screen-layout-context';

const GameIframeElement = () => {
  const bootstrap = useAppSelector((state) => state.bootstrap);
  const { gameViews } = bootstrap;
  const gameView = gameViews?.[0] || null;
  const pathname = usePathname();
  const iframeRef = useRef<any>();
  const { layout } = useScreenLayout();

  const shouldRenderIFrame =
    !gameView?.status ||
    layout === 'single' ||
    (gameView.status !== 'no-currency' &&
      gameView.status !== 'closed' &&
      gameView.status !== 'real-only');

  useEffect(() => {
    const iframe = iframeRef?.current;
    const onIframeLoad = () => {
      try {
        const iframeURL = new URL(iframe?.contentWindow.location.href);
        if (
          iframeURL?.pathname?.includes('/settings') &&
          !pathname?.includes('/settings')
        ) {
          window.location.href = '/settings?required=true'; // Redirect parent window
        }
      } catch (error) {
        console.error('Error accessing iframe content:', error);
      }
    };

    iframe?.addEventListener('load', onIframeLoad);

    return () => {
      iframe?.removeEventListener('load', onIframeLoad);
    };
  }, [pathname]);

  if (!shouldRenderIFrame) return null;

  if (layout !== 'single') return null;

  return (
    <iframe
      id="gameframe"
      ref={iframeRef}
      src={gameView?.url}
      className="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover"
    />
  );
};

export default GameIframeElement;
