import { Text } from '@purposeinplay/core-v2';
import DiscordSvg from 'public/assets/discord.svg';
import TelegramSvg from 'public/assets/telegram.svg';

export const SELECT_ITEMS = [
  {
    id: 'telegram',
    component: (
      <div className="flex flex-row items-center gap-x-2">
        <TelegramSvg />
        <Text size="sm" as="span" className="font-normal">
          Telegram
        </Text>
      </div>
    ),
  },
  {
    id: 'discord',
    component: (
      <div className="flex flex-row items-center gap-x-2">
        <DiscordSvg />
        <Text size="sm" as="span" className="font-normal">
          Discord
        </Text>
      </div>
    ),
  },
];
