import {
  useAddPlayerLimit,
  usePlayerLimits,
  useRemoveUserLimit,
} from '@/hooks/limits';
import { SessionLimit as SessionLimitType, UserLimit } from '@/types/user';
import React, { useEffect, useMemo, useState } from 'react';
import LoadingLimit from '../loading-limit';
import UnconfirmedDisabled from './unconfirmed_disable';
import ActiveLimit from './active-limit';
import { Button, Input, Text } from '@purposeinplay/core-v2';
import { useTranslation } from '@/app/i18n/client';
import { SettingsContentType } from '@/types/json-content/settings';
import ConfirmationSession from './confirmation-session';

const SessionLimit = ({ isOnSettings = false }) => {
  const [minutes, setMinutes] = useState('0');
  const [minutesEdite, setMinutesEdite] = useState('0');

  const [editLimit, setEditLimit] = useState(false);
  const {
    mutateAsync: addSessionLimit,
    isPending: addLimitLoading,
    error,
    isError,
    reset,
  } = useAddPlayerLimit();

  const { mutateAsync: removeSessionLimit, isPending: removeLimitLoading } =
    useRemoveUserLimit();

  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const content = contentSettings?.responsible_gambling?.limits?.modal?.session;

  const { data: limits = [], isLoading: limitsLoading } = usePlayerLimits();

  const filteredLimit = useMemo(() => {
    const limit = limits?.find(
      (limit: SessionLimitType | UserLimit) =>
        limit.type === 'session' &&
        (limit.status === 'active' || limit.status === 'unconfirmed_disable'),
    );
    return limit;
  }, [limits]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (filteredLimit?.period) setMinutes(filteredLimit?.period.toString());
  }, [filteredLimit]);

  if (limitsLoading) {
    return <LoadingLimit />;
  }

  if (filteredLimit && filteredLimit.status === 'unconfirmed_disable') {
    return <UnconfirmedDisabled filteredLimit={filteredLimit} />;
  }

  return (
    <>
      <div>
        {filteredLimit && filteredLimit.status === 'active' ? (
          <>
            <ActiveLimit filteredLimit={filteredLimit} className="mb-4" />
            {editLimit && (
              <Input
                id="session-limit-edit"
                label="Time in Minutes"
                type="number"
                placeholder="Minutes"
                variant="static"
                // @ts-ignore
                min="6"
                // @ts-ignore
                error={isError && error?.errors?.period?.range}
                // @ts-ignore
                errorMessage={error?.errors?.period?.range}
                value={minutesEdite}
                onChange={(e) => {
                  if (isError) {
                    reset();
                  }
                  setMinutesEdite(e.target.value);
                }}
                className="mb-4 w-4/5 md:w-2/4"
              />
            )}
          </>
        ) : (
          <>
            <Text size="sm" className="mb-4 text-text-subdued md:max-w-xl">
              {content?.description}
            </Text>
            <Input
              id="session-limit"
              label={content?.input_label}
              type="number"
              variant="static"
              placeholder="Minutes"
              // @ts-ignore
              min="6"
              disabled={filteredLimit && filteredLimit?.status === 'active'}
              // @ts-ignore
              error={isError && error?.errors?.period?.range}
              value={minutes}
              onChange={(e) => {
                if (isError) {
                  reset();
                }
                setMinutes(e.target.value);
              }}
              className="mb-4 w-4/5 md:w-2/4"
            />
          </>
        )}

        {!filteredLimit && (
          <Button
            size={isOnSettings ? 'xs' : 'sm'}
            color="primary"
            className="w-full md:w-auto"
            onClick={() => setIsOpen(true)}
            disabled={addLimitLoading}
            isLoading={addLimitLoading}
          >
            {content?.action?.primary}
          </Button>
        )}
        {filteredLimit && filteredLimit.status !== 'unconfirmed_disable' && (
          <div className="flex flex-row">
            <Button
              size={isOnSettings ? 'md' : 'sm'}
              color="tertiary"
              className="mr-1"
              onClick={async () => {
                if (!editLimit) {
                  setEditLimit(true);
                } else {
                  try {
                    await addSessionLimit({
                      user_limit: {
                        period: Number(minutesEdite),
                        type: 'session',
                      },
                    });
                  } catch (e) {
                    setIsOpen(false);
                    throw e;
                  }
                }
              }}
              disabled={addLimitLoading}
              isLoading={addLimitLoading}
            >
              {editLimit ? content?.action?.save : content?.action?.edit}
            </Button>
            <Button
              color="tertiary"
              size={isOnSettings ? 'md' : 'sm'}
              disabled={removeLimitLoading}
              isLoading={removeLimitLoading}
              onClick={() => removeSessionLimit(filteredLimit.id)}
            >
              {content?.action?.secondary}
            </Button>
          </div>
        )}
      </div>
      <ConfirmationSession
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        addSessionLimit={addSessionLimit}
        addLimitLoading={addLimitLoading}
        minutes={minutes}
      />
    </>
  );
};

export default SessionLimit;
