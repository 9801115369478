import { useTranslation } from '@/app/i18n/client';
import { AgentVerificationContentType } from '@/types/json-content/agent_verification';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import Image from 'next/image';
import React from 'react';
import { VerifyAgentForm } from '../types';
import { getContentByState, getImageByState } from '../utils';
import { Heading, Text } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';

type Props = {
  state: VerifyAgentForm;
};

const VerifyAgentContent: React.FC<Props> = React.memo(({ state }) => {
  const { t } = useTranslation();
  const content = t('agent_verification') as AgentVerificationContentType;
  const modalContent = getContentByState(state?.state, content?.modal_states);
  const description = modalContent?.description?.includes('{{user}}')
    ? modalContent?.description.replace('{{user}}', state?.user_id)
    : modalContent?.description;

  return (
    <div className="flex flex-col items-center justify-center gap-spacing-xs">
      <Image
        width={68}
        height={68}
        alt="Default"
        src={getImageByState(state?.state)}
      />
      <Heading className="text-xl font-semibold">{modalContent?.title}</Heading>
      <Text
        size="sm"
        className={cn([
          'text-center font-normal text-text-subdued',
          state?.state === 'success' && 'text-text-success',
          state?.state === 'invalid' && 'text-text-critical',
        ])}
      >
        {description}
      </Text>
    </div>
  );
});

VerifyAgentContent.displayName = 'VerifyAgentContent';

export default VerifyAgentContent;
