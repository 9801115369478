import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MoreTabContextProps {
  shouldDisplayBackButton: boolean;
  setShouldDisplayBackButton: (value: boolean) => void;
}

const MoreTabContext = createContext<MoreTabContextProps | undefined>(
  undefined,
);

export const MoreTabProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [shouldDisplayBackButton, setShouldDisplayBackButton] = useState(false);

  return (
    <MoreTabContext.Provider
      value={{
        shouldDisplayBackButton,
        setShouldDisplayBackButton,
      }}
    >
      {children}
    </MoreTabContext.Provider>
  );
};

export const useMoreTab = (): MoreTabContextProps => {
  const context = useContext(MoreTabContext);
  if (context === undefined) {
    throw new Error('useMoreTab must be used within a MoreTabProvider');
  }
  return context;
};
