import React from 'react';

const ChatBodyContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      id="chat-body-container"
      className="scrollbar relative -mx-5 mb-2 flex-1 pl-5 pr-2"
    >
      {children}
    </div>
  );
};

export default ChatBodyContainer;
