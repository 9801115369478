import { useTranslation } from '@/app/i18n/client';
import { AgentVerificationContentType } from '@/types/json-content/agent_verification';
import React, { useCallback, useState } from 'react';
import { VerifyAgentForm } from '../types';
import { getContentByState } from '../utils';
import {
  Button,
  Field,
  FieldGroup,
  Input,
  Select,
} from '@purposeinplay/core-v2';
import useVerifyAgent from '@/hooks/agent-verification';
import { SELECT_ITEMS } from '../constants';

type Props = {
  state: VerifyAgentForm;
  setState: React.Dispatch<React.SetStateAction<VerifyAgentForm>>;
};
const ModalFooterVerify: React.FC<Props> = ({ state, setState }) => {
  const { t } = useTranslation();
  const content = t('agent_verification') as AgentVerificationContentType;
  const modalContent = getContentByState(state?.state, content?.modal_states);
  const { mutateAsync: verifyAgent, isPending } = useVerifyAgent();
  const [platform, setPlatform] = useState('telegram');

  const handleSubmit = useCallback(
    async (e: any) => {
      e?.preventDefault();
      try {
        await verifyAgent({ user_id: state?.user_id, platform: platform });
        setState((prev) => ({ ...prev, state: 'success' }));
      } catch (e) {
        setState((prev) => ({
          ...prev,
          state: 'invalid',
        }));
      }
    },
    [setState, state, verifyAgent, platform],
  );

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <FieldGroup className="space-y-2 lg:space-y-2">
        <Field className="relative mb-0 lg:mb-0">
          <Input
            id="agent_id"
            type="text"
            placeholder={modalContent?.input?.placeholder}
            value={state?.user_id}
            onChange={(e) =>
              setState((prev) => ({ ...prev, user_id: e?.target?.value }))
            }
            variant="static"
          />
          <div className="absolute right-0 top-0 h-full [&>div>button]:h-full [&>div]:h-full">
            <Select
              dropdownStylingOptions={{
                triggerStyles: '[&_.item-wrapper_svg]:hidden',
                containerStyles: 'min-w-40 !right-0 !left-auto',
              }}
              defaultSelectedIndex={SELECT_ITEMS.findIndex(
                (item) => item?.id === platform,
              )}
              items={SELECT_ITEMS}
              stylingOptions={{
                triggerStyles: 'bg-transparent !h-full ring-0',
              }}
              onSelect={(item: any) => setPlatform(item?.id)}
            />
          </div>
        </Field>
        <Field>
          <Button
            size="md"
            type="submit"
            isLoading={isPending}
            disabled={isPending}
            className="w-full max-w-full"
          >
            {modalContent?.action?.label}
          </Button>
        </Field>
      </FieldGroup>
    </form>
  );
};

export default ModalFooterVerify;
