import { AvatarLoader } from '@/components/Loaders';
import React, { useMemo } from 'react';
import { useAvatar } from '@/hooks/avatar';
import { useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { Avatar } from '@purposeinplay/core-v2';
import Image from 'next/image';
import { useLoyalty } from '@/hooks/loyalty';

const UserAvatar = ({
  size = 'sm',
  onClick,
  isOpen,
  hasArrow = true,
  containerStyles,
  styleAvatarLoading,
  iconSize = 38,
  ...rest
}: {
  size: 'sm' | 'md' | 'lg' | 'xl';
  isOpen?: boolean;
  onClick?: () => void;
  hasArrow?: boolean;
  containerStyles?: string;
  styleAvatarLoading?: string;
  iconSize?: number;
}) => {
  const { currentLevel } = useLoyalty();
  const { data } = useAvatar();
  const savedAvatar = useAppSelector((state) => state.bootstrap.savedAvatar);
  const userAvatar = useMemo(
    () => (savedAvatar ? savedAvatar : data?.avatar),
    [savedAvatar, data],
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      onClick && onClick();
    }
  };

  return (
    <span
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyPress}
      role="button"
      className={cn([
        'relative flex items-center focus-visible:rounded-full focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-base-primary',
        containerStyles,
      ])}
    >
      {!userAvatar ? (
        <AvatarLoader className={styleAvatarLoading} />
      ) : (
        <Avatar
          circularProgressProps={{
            animationSpeed: 10,
            incrementSpeed: 1,
            progress: currentLevel?.progress || 0,
          }}
          stylingOptions={{
            strokeBgColor: 'text-surface-subdued',
          }}
          icon={
            <Image
              src={`assets/avatars/${userAvatar}.png`}
              width={iconSize}
              height={iconSize}
              alt="User Avatar"
            />
          }
          levelBadgeContent={currentLevel?.level}
          size={size}
        />
      )}
      {hasArrow && (
        <div className="absolute right-0 z-0 flex h-5 w-12 items-center justify-end rounded-full bg-bgr-default">
          <svg
            className={cn([
              `text-success mr-0.5 h-4 w-4 transform fill-current transition duration-200 ease-out`,
              isOpen && `rotate-180`,
            ])}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </span>
  );
};

export default UserAvatar;
