import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useModal from '@/hooks/modal';

export const useHandleTransitionEnd = (
  prizeTitle: string | undefined,
  slug: string,
  wheelRef: React.RefObject<HTMLElement>,
  setIsSpinning: (value: boolean) => void,
) => {
  const { open } = useModal();
  const queryClient = useQueryClient();

  useEffect(() => {
    const wheelElement = wheelRef.current;
    const handleTransitionEnd = () => {
      queryClient.invalidateQueries({ queryKey: ['playerLootboxes'] });
      if (prizeTitle) {
        open('prize', { p: prizeTitle, t: slug });
      }
      setIsSpinning(false);
    };

    if (wheelElement) {
      wheelElement.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (wheelElement) {
        wheelElement.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [open, prizeTitle, queryClient, setIsSpinning, slug, wheelRef]);
};
