import React from 'react';
import AvatarOption from './avatar-option';

const avatarPresets = [
  { id: 'preset-default', image: '/assets/avatars/preset-default.png' },
  { id: 'preset-1', image: '/assets/avatars/preset-1.png' },
  { id: 'preset-2', image: '/assets/avatars/preset-2.png' },
  { id: 'preset-3', image: '/assets/avatars/preset-3.png' },
  { id: 'preset-4', image: '/assets/avatars/preset-4.png' },
  { id: 'preset-5', image: '/assets/avatars/preset-5.png' },
  { id: 'preset-6', image: '/assets/avatars/preset-6.png' },
  { id: 'preset-7', image: '/assets/avatars/preset-7.png' },
  { id: 'preset-8', image: '/assets/avatars/preset-8.png' },
];

const AvatarOptions = () => {
  return (
    <div className="grid grid-cols-3 gap-spacing-lg sm:grid-cols-4">
      {avatarPresets.map((preset) => (
        <AvatarOption key={preset.id} id={preset.id} image={preset.image} />
      ))}
    </div>
  );
};

export default AvatarOptions;
