import { CONTENT_API_URL } from './constants';
import { QueryClient } from '@tanstack/react-query';
import { cache } from 'react';

export const CONTENT_ENDPOINT = `${CONTENT_API_URL}/graphql`;

const prefetchContent = cache(
  async (
    queryName: string,
    gqlQuery: any,
    reactQueryClient: QueryClient,
    variables?: any,
    options?: any,
  ): Promise<any> => {
    const data = await fetch(CONTENT_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        query: gqlQuery,
        variables,
      }),
      next: { revalidate: false },
    })
      .then((res) => res.json())
      .then((res) => res?.data);
    const queryClient = reactQueryClient;
    await queryClient?.prefetchQuery({
      queryKey: variables !== undefined ? [queryName, variables] : [queryName],
      queryFn: async () => {
        if (options?.onResolved) {
          return options?.onResolved(data);
        }
        return data;
      },
      ...options,
    });
    return data;
  },
);

export const fetchContent = cache(
  async (gqlQuery: any, variables?: any, options?: any): Promise<any> => {
    const data = await fetch(CONTENT_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        query: gqlQuery,
        variables,
      }),
      next: { revalidate: false },
      ...options,
    })
      .then((res) => res.json())
      .then((res) => res?.data);
    return data;
  },
);

export default prefetchContent;
