'use client';
import React from 'react';
import MinimizedHeader from './minimized-header';
import useMediaQuery, { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';
import IFrameContainer from './iframe-container';
import RestrictedWrapper from './restricted-wrapper';
import RestrictedGameOverlay from './currency-selector-overlay';
import CurrencySelectorOverlay from './game-view-overlay';
import GameViewWrapper from './gameview-wrapper';
import GameIframeElement from './game-iframe-element';
import GameViewLayout from './gameview-layout';
import { useScreenLayout } from '../../context/screen-layout-context';

const GameIframe = () => {
  const { matchesQuery: isMobileLG, isLoading } =
    useMediaQuery(MOBILE_LG_BREAKPOINT);

  const { layout } = useScreenLayout();

  if ((isMobileLG && !isLoading) || layout !== 'single') {
    return null;
  }

  return (
    <GameViewWrapper>
      <MinimizedHeader />
      <IFrameContainer>
        <GameViewLayout>
          <CurrencySelectorOverlay />
          <RestrictedGameOverlay />
          <RestrictedWrapper />
          <GameIframeElement />
        </GameViewLayout>
      </IFrameContainer>
    </GameViewWrapper>
  );
};

export default GameIframe;
