import TournamentStatusTag from '@/modules/tournaments/components/tournament-status-tag';
import useTournamentsState from '@/modules/tournaments/hooks/use-tournaments-state';
import { CONTENT_API_URL } from '@/utils/constants';
import { Tournament } from '@/types/tournaments';
import { cn } from '@/utils/style';
import Link from 'next/link';
import React, { useMemo } from 'react';
import Image from 'next/image';
import useCountdown from '@/hooks/use-countdown';
import { Badge } from '@purposeinplay/core-v2';

const MiniTournamentCard = ({ tournament }: { tournament: Tournament }) => {
  const { tournamentsCMS } = useTournamentsState();
  const { state, ended, hasNoState } = useCountdown({
    short: false,
    date: tournament?.end_at,
    enabled: !!tournament,
  });

  const formattedCountdown = useMemo(() => {
    return `${state.days}d ${state.hours}h ${state.minutes}m ${state.seconds}s`;
  }, [state, hasNoState, ended]);

  const matchingTournament = useMemo(() => {
    return tournamentsCMS?.tournaments?.data.find(
      (content: any) =>
        content.attributes.uid === tournament?.frontend_identifier,
    )?.attributes;
  }, [tournamentsCMS?.tournaments?.data, tournament?.frontend_identifier]);

  const imageUrl = useMemo(() => {
    return (
      matchingTournament?.thumbnail?.tournament_image?.data?.[0]?.attributes
        ?.url || ''
    );
  }, [matchingTournament?.thumbnail?.tournament_image?.data]);

  const isTournamentActive = !hasNoState;

  return (
    // <Link
    //   href={`/tournaments/${tournament?.frontend_identifier}-${tournament?.id}`}
    //   className="aspect-[2/1] overflow-hidden rounded-xl"
    // >
    <div className={cn(['relative aspect-[2/1] overflow-hidden  rounded-xl'])}>
      <TournamentStatusTag size="sm" tournament={tournament} />
      <div className="relative aspect-[2/1] overflow-hidden ">
        <Image
          fill
          alt="Tournament Image"
          src={`${CONTENT_API_URL}${imageUrl}`}
          className="object-cover [mask-image:linear-gradient(to_top,transparent,black_50%)]"
        />
      </div>
      <div className="absolute bottom-3 left-3">
        {isTournamentActive && (
          <Badge radius="rounded" size="sm">
            {formattedCountdown}
          </Badge>
        )}
      </div>
    </div>
    // </Link>
  );
};

export default MiniTournamentCard;
