export const getBaseIconStyles = (text: string) => {
  switch (text) {
    case 'Menu':
      return `text-text-default [&>svg]:fill-current group-hover:text-text-default`;
    case 'Search':
      return `text-text-default [&>svg]:fill-current [&>svg]:stroke-current group-hover:text-text-default`;
    case 'Casino':
      return `text-text-default [&>svg]:fill-current group-hover:text-text-default`;
  }
};
