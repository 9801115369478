import { useMemo } from 'react';
import { useAuth } from '@/hooks/auth';
import { useAllNotifications } from '@/app/providers/notifications';

export const useNotificationStatus = () => {
  const {
    user: { currentUser },
  } = useAuth();

  const { unreadNotifications } = useAllNotifications();

  const userNotifications = useMemo(() => {
    if (!currentUser?.id) return [];

    return (unreadNotifications[currentUser.id] || []).filter(
      (notification: any) => !notification?.includes('undefined'),
    );
  }, [unreadNotifications, currentUser?.id]);

  const hasUnreadNotifications = userNotifications.length > 0;

  return { hasUnreadNotifications, userNotifications };
};
