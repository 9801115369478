import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useWildChatInfo } from '@/modules/wild-chat/hooks/new-hooks';
import { useAuth } from '@/hooks/auth';

export const usePlayerApiSettings =
  (): UseQueryResult<PlayerApiSettingsResponse> => {
    const { data: wildChatInfo, isLoading: isInfoLoading } = useWildChatInfo();
    const { user } = useAuth();

    return useQuery<PlayerApiSettingsResponse>({
      queryKey: ['playerApiSettings'],
      queryFn: async () => {
        if (!wildChatInfo || !wildChatInfo.host || !wildChatInfo.access_token) {
          throw new Error('Missing wildChatInfo or required fields');
        }

        const url = `${wildChatInfo.host}/player_api/settings`;

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${wildChatInfo.access_token}`,
            },
          });

          const data = await response.text();

          if (response.ok) {
            return (data && JSON.parse(data)) as PlayerApiSettingsResponse;
          } else {
            return Promise.reject(JSON.parse(data));
          }
        } catch (e) {
          return Promise.reject({
            message: 'Internal system error',
            code: 500,
          });
        }
      },
      enabled: !!user?.isAuthenticated || !!wildChatInfo,
    });
  };

// Define the type for the user
type CurrentUser = {
  id: string;
  avatar_path: string;
  avatar_url: string;
  chat_name: string;
};

// Define the type for a room
type Room = {
  id: string;
  centrifugo_channel: string;
  display_users_online: boolean;
  name: string;
};

// Define the type for the entire response
type PlayerApiSettingsResponse = {
  current_user: CurrentUser;
  rooms: Room[];
  access_status: string;
};
