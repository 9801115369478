import { useMemo } from 'react';
import React from 'react';
import WheelIcon from '@/modules/wheel-of-fortune/components/wheel-icon';
import {
  useNavigationQuery,
  ComponentNavigationFeaturedNavSection,
  ComponentNavigationSubmenu,
  ComponentNavigationLinkItem,
  NavigationItem,
} from '@/generated';
import { CONTENT_API_URL, DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { extractTranslations } from '@/utils/content';
import { useTranslation } from '@/app/i18n/client';

const useSidemenuItems = ({
  forGamesPage = false,
}: {
  forGamesPage?: boolean;
} = {}) => {
  const { data: navContent, isLoading } = useNavigationQuery(
    {
      areaType: 'sidemenu',
    },
    {
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );

  const sidemenuNav =
    navContent?.navigation?.data?.attributes?.areas &&
    navContent?.navigation?.data?.attributes?.areas[forGamesPage ? 1 : 0];

  const { i18n } = useTranslation();

  const allSidemenuItems = useMemo(() => {
    return sidemenuNav?.navigation_items?.map((navItem) => {
      const content = extractTranslations(
        i18n.language,
        navItem?.navigation_item?.data?.attributes,
      ) as NavigationItem;
      if (!content?.element) {
        return navItem;
      }
      if (content?.type === 'submenu') {
        const element = content?.element[0] as ComponentNavigationSubmenu;
        const submenuItems = element?.menu_items?.map((item) => {
          return {
            id: `item-${item?.label}-${item?.id}`,
            url: item?.href,
            title: item?.label,
            opens_in: item?.opens_in,
            icon: item?.icon?.data
              ? `${CONTENT_API_URL}${item?.icon?.data?.attributes?.url}`
              : null,
          };
        });
        return {
          isExpanded: element?.is_expanded,
          actionBtn: element?.action_btn,
          title: element?.label,
          type: content?.type,
          items: submenuItems,
          displayFor: element?.display_for?.auth_status,
        };
      }
      if (content?.type === 'link') {
        const element = content?.element[0] as ComponentNavigationLinkItem;
        return {
          type: content?.type,
          displayFor: element?.display_for?.auth_status,
          opens_in: element?.opens_in,
          identifier: element?.identifier,
          title: element?.label,
          items: [
            {
              id: `item-${element?.label}-${element?.id}`,
              url: element?.href,
              title: element?.label,
              icon: `${CONTENT_API_URL}${element?.icon?.data?.attributes?.url}`,
            },
          ],
        };
      }
      if (content?.type === 'featured_section') {
        const element = content
          ?.element[0] as ComponentNavigationFeaturedNavSection;
        const featuredNavItems = element?.featured_action_link?.map((item) => {
          return {
            icon: `${CONTENT_API_URL}${item?.icon?.data?.attributes?.url}`,
            title: `${item?.heading} ${item?.label}`,
            heading: item?.heading,
            text: item?.label,
            textColor: item?.heading_color,
            opensModal: item?.opens_modal,
            hoverColor: item?.bg_hover_color,
            backgroundColor: item?.bg_color,
          };
        });
        return {
          displayFor: element?.display_for?.auth_status,
          type: content?.type,
          items: featuredNavItems,
        };
      }

      return {
        ...navItem,
      };
    });
  }, [i18n.language, sidemenuNav?.navigation_items]);

  return {
    isLoading,
    items: allSidemenuItems,
  };
};

export default useSidemenuItems;
