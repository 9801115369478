import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useConfirmation } from '@/hooks/auth';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';

interface Props {
  confirmation_token: string;
  closeModal: () => void;
}

const UserConfirmationModal: React.FC<Props> = ({
  confirmation_token,
  closeModal,
}) => {
  const [didResolve, setDidResolve] = useState(false);
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const pageTriggerContent = general?.page_triggers;

  const { isPending, error, isSuccess, isError }: any =
    useConfirmation(confirmation_token);

  const { toast } = useToast();
  useEffect(() => {
    if (isPending || didResolve) return;
    if (isError && error && error?.confirmation_token) {
      toast({
        state: 'error',
        title: pageTriggerContent?.toast?.token_expired,
      });
      setDidResolve(true);
      closeModal();
      return;
    }
    if (isError && error && error?.email) {
      toast({
        state: 'error',
        title: pageTriggerContent?.toast?.already_confirmed,
      });
      setDidResolve(true);
      closeModal();
      return;
    }
    if (!isPending && isSuccess) {
      toast({
        state: 'success',
        title: pageTriggerContent?.toast?.confirmed,
      });
      setDidResolve(true);
      closeModal();
      return;
    }
  }, [
    closeModal,
    didResolve,
    error,
    isError,
    isPending,
    isSuccess,
    pageTriggerContent?.toast?.already_confirmed,
    pageTriggerContent?.toast?.confirmed,
    pageTriggerContent?.toast?.token_expired,
    toast,
  ]);

  return null;
};

export default UserConfirmationModal;
