'use client';
import { useAccounts } from '@/hooks/accounts';
import { useAuth } from '@/hooks/auth';
import { useCurrencies } from '@/hooks/currencies';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import { getPrecision, subunitsToUnit } from '@/utils/money';
import CryptoTooltip from '@/components/CryptoTooltip';
import React, { useMemo, memo } from 'react';
import { setViewFiat } from '@/app/reducers/bootstrap';
import CryptoAmount from '@/components/CryptoAmount';
import FiatAmount from '@/components/FiatAmount';

const WinAmount = memo(({ amount }: any) => {
  const { account: currentAccount } = useAccounts();

  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

  const { user } = useAuth();

  const amountCrypto = useMemo(() => {
    return !user?.isAuthenticated
      ? amount && amount['BTC']
      : amount &&
          currentAccount &&
          amount[currentAccount?.currency?.toLowerCase()];
  }, [amount, currentAccount, user?.isAuthenticated]);

  const fiatAmount = useFiatFromCrypto(
    currentAccount?.currency || 'BTC',
    amountCrypto,
  );

  return (
    <div className="flex items-center space-x-1">
      {user.isAuthenticated ? (
        <CryptoTooltip
          cryptoAmount={
            viewFiat ? (
              <FiatAmount
                removeIcon
                fiatAmount={fiatAmount}
                currency={currentAccount?.currency || 'BTC'}
              />
            ) : (
              <CryptoAmount
                removeIcon
                balance={amountCrypto}
                currency={currentAccount?.currency || 'BTC'}
              />
            )
          }
          cryptoCode={currentAccount?.currency || 'BTC'}
          className="flex items-center space-x-1"
        >
          {viewFiat ? null : (
            <CryptoAmount
              removeIcon
              balance={amountCrypto}
              currency={currentAccount?.currency || 'BTC'}
            />
          )}
        </CryptoTooltip>
      ) : (
        <CryptoAmount balance={amountCrypto} currency="BTC" />
      )}
    </div>
  );
});

WinAmount.displayName = 'WinAmount';

export default WinAmount;
