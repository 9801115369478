import React, { useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { useAddPlayerLimit } from '@/hooks/limits';
import { SettingsContentType } from '@/types/json-content/settings';
import { Alert, Button, Select, Text } from '@purposeinplay/core-v2';
import { useQueryClient } from '@tanstack/react-query';

const SelfExcusion = () => {
  const { t } = useTranslation();
  const contentSettings = t('account_settings') as SettingsContentType;
  const content = contentSettings?.responsible_gambling?.self_exclusion_modal;
  const selfExclussionTypes =
    contentSettings?.responsible_gambling?.self_exclusion_types;

  const [exclusion, setExclusion] = useState(selfExclussionTypes[0]);
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: addLimit, isPending: addLimitLoading } =
    useAddPlayerLimit();

  const queryClient = useQueryClient();

  return (
    <>
      <div>
        <Text size="sm" className="mb-4 text-text-subdued">
          {content?.description}
        </Text>
        <Select
          items={selfExclussionTypes}
          stylingOptions={{ triggerStyles: 'mb-4' }}
          defaultSelectedIndex={selfExclussionTypes.findIndex(
            (item) => item?.id === exclusion?.id,
          )}
          onSelect={(item: any) => {
            if (item.id === 'none') return;
            setExclusion(item);
          }}
        />
        <Text size="sm" className="mb-4 text-text-subdued">
          {content?.description_2}
        </Text>
        <Text size="sm" className="mb-4 text-text-subdued">
          {content?.description_3}
        </Text>
        <Button
          size="sm"
          tw="w-full md:w-auto"
          color="primary"
          disabled={exclusion == null}
          onClick={() => setIsOpen(true)}
        >
          {content?.action?.primary}
        </Button>
      </div>
      <Alert
        title={content?.alert_title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryButtonOnClick={() => {
          addLimit({
            user_limit: {
              period: exclusion?.id,
              type: 'selfexclusion',
            },
          }).then(() => {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] });
          });
        }}
        content={`${content?.alert_description} ${exclusion?.component}?`}
        isLoading={addLimitLoading}
        primaryButtonLabel={content?.alert_action?.primary}
        secondaryButtonLabel={content?.alert_action?.secondary}
      />
    </>
  );
};

export default SelfExcusion;
