'use client';

import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useChat } from '@/services/live-chat/use-chat';
import { setOpenFeed, setOpenSidemenu } from '@/app/reducers/bootstrap';
import { setOpenSearch } from '@/app/reducers/search';
import { cn } from '@/utils/style';
import { usePathname, useRouter } from 'next/navigation';
import SidebarItemTitle from './item-title';
import SidebarItemIcon from './item-icon';
import { SidemenuItem } from '../types';
import { Tooltip } from '@purposeinplay/core-v2';
import { useIsMounted } from '@purposeinplay/utils';
import useMediaQuery, {
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  MOBILE_LG_BREAKPOINT,
} from '@/hooks/use-media-query';
import { sidebarBaseItemStyles } from '../styles';
import { useLogout } from '@/hooks/auth';
import { useAnalytics } from '@/hooks/use-analytics';
import {
  useGamePageSidebar,
  SidebarSection,
} from '../context/game-page-sidebar-context';
import useChatState from '@/modules/wild-chat/hooks/use-chat-state';
import { useAllNotifications } from '@/app/providers/notifications';
import { useAuth } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import { useGamesContent } from '@/hooks/use-games-content';

type Props = {
  item: SidemenuItem;
  className?: string;
  forGamesPage?: boolean;
};

const SectionItem: React.FC<Props> = React.memo(
  ({ item, className, forGamesPage }) => {
    const { gamesContent } = useGamesContent();
    const {
      isOpen,
      setIsOpen,
      activeSection,
      setActiveSection,
      setShouldDisplayBackButton,
    } = useGamePageSidebar();
    const { toggleChat } = useChatState();
    const { open: openModal } = useModal();
    const openFeed = useAppSelector((state) => state.bootstrap.openFeed);
    const isSidemenuOpen = useAppSelector(
      (state) => state.bootstrap.isSidemenuOpen,
    );
    const { matchesQuery: isMobileLg } = useMediaQuery(LAPTOP_BREAKPOINT);

    const dispatch = useAppDispatch();
    const [state, loadChat] = useChat({ loadWhenIdle: true });
    const pathname = usePathname();
    const isActive = pathname === item.url;
    const isMounted = useIsMounted();
    const { mutateAsync: logoutUser } = useLogout();
    const router = useRouter();

    const { track } = useAnalytics();

    const { user } = useAuth();
    const { unreadNotifications } = useAllNotifications();

    const userNotifications = useMemo(() => {
      if (!user?.currentUser?.id) return [];
      return (unreadNotifications[user.currentUser.id] || []).filter(
        (notification: any) => !notification?.includes('undefined'),
      );
    }, [unreadNotifications, user?.currentUser?.id]);

    const hasUnreadNotifications = userNotifications.length > 0;

    // Simplified identification helpers using item.identifier
    const isWalletItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'wallet';
    }, []);

    const isSigninItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'signin' || item.url === '/?m=signin';
    }, []);

    const isChatItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'chat';
    }, []);

    const isLiveSupportItem = useCallback((item: SidemenuItem): boolean => {
      return item.id === 'sidemenu-live-support';
    }, []);

    const isNavigationItem = useCallback(
      (item: SidemenuItem): boolean => {
        return (
          !!forGamesPage &&
          !!item.url &&
          (item.identifier === 'new' || item.identifier === 'popular')
        );
      },
      [forGamesPage],
    );

    const isSearchItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'search';
    }, []);

    const isLogoutItem = useCallback((item: SidemenuItem): boolean => {
      return item.id === 'sidemenu-logout';
    }, []);

    const isCommunityItem = useCallback((item: SidemenuItem): boolean => {
      return item.id === 'sidemenu-community-support';
    }, []);

    const isFavoritesItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'favorites' || item.identifier === 'favourite';
    }, []);

    const isNotificationItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'notifications';
    }, []);

    const isTournamentsItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'tournaments';
    }, []);

    const isNewGamesItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'new';
    }, []);

    const isPopularGamesItem = useCallback((item: SidemenuItem): boolean => {
      return item.identifier === 'popular';
    }, []);

    // Simplified section mapping using item.identifier
    const mapItemToSection = useCallback(
      (item: SidemenuItem): SidebarSection => {
        if (isNotificationItem(item)) return 'notification';
        if (isFavoritesItem(item)) return 'favorites';
        if (isTournamentsItem(item)) return 'tournaments';
        if (isNewGamesItem(item)) return 'new';
        if (isPopularGamesItem(item)) return 'popular';
        return null;
      },
      [
        isFavoritesItem,
        isNotificationItem,
        isTournamentsItem,
        isNewGamesItem,
        isPopularGamesItem,
      ],
    );

    const getSpecialUrl = useCallback(() => {
      // Handle wallet item
      if (isWalletItem(item) && item.url) {
        // If we're on a game page, append the wallet URL to the current path
        if (forGamesPage && pathname) {
          return `${pathname}${item.url}`;
        }
        return item.url;
      }

      // Handle signin item
      if (item.identifier === 'signin') {
        // If we're on a game page, append the signin URL to the current path
        if (forGamesPage && pathname) {
          return `${pathname}/?m=signin`;
        }
        return item.url;
      }

      return item.url || '';
    }, [forGamesPage, isWalletItem, item, pathname]);

    const specialUrl = getSpecialUrl();

    const handleLogout = useCallback(async () => {
      await Promise.all([
        track('signout_succeeded', {
          category: 'auth',
        }),
        logoutUser(),
      ]);
      dispatch(setOpenSidemenu(false));
      router.replace('/');
    }, [dispatch, logoutUser, router, track]);

    const handleCoreItemClick = useCallback(
      async (e: any) => {
        // Original sidebar functionality
        if (isLogoutItem(item)) {
          await handleLogout();
          return true; // Signal that we handled the event
        }

        if (isCommunityItem(item)) {
          e.stopPropagation();
          dispatch(setOpenFeed(!openFeed));
          localStorage.setItem(
            'openFeed',
            openFeed === true ? 'false' : 'true',
          );
          return true;
        }

        if (isLiveSupportItem(item)) {
          loadChat({ open: true });
          return true;
        }

        // Close sidemenu on mobile
        if (isMobileLg) {
          dispatch(setOpenSidemenu(false));
        }

        return false; // Signal that we didn't handle the event completely
      },
      [
        dispatch,
        handleLogout,
        isCommunityItem,
        isLiveSupportItem,
        isLogoutItem,
        isMobileLg,
        item,
        loadChat,
        openFeed,
      ],
    );

    const handleSectionToggle = useCallback(
      (section: SidebarSection) => {
        if (isOpen && section === activeSection) {
          // If clicking the same item that's already open, close the drawer
          setIsOpen(false);
          setActiveSection(null);
        } else {
          // If clicking a different item or the drawer is closed, open/update it
          setIsOpen(true);
          setActiveSection(section);
        }
      },
      [activeSection, isOpen, setActiveSection, setIsOpen],
    );

    const handleGamesPageItemClick = useCallback(
      async (e: any) => {
        const identifier = item.identifier;

        // Handle wallet item
        if (isWalletItem(item)) {
          if (isMobileLg) {
            dispatch(setOpenSidemenu(false));
          }
          if (pathname && item.url) {
            e.preventDefault();
            router.push(`${pathname}${item.url}`);
          }
          return true;
        }

        // Handle signin item
        if (isSigninItem(item)) {
          if (isMobileLg) {
            dispatch(setOpenSidemenu(false));
          }
          if (item.url === '/?m=signin') {
            e.preventDefault();
            openModal('signin');
            return true;
          }
          if (pathname && item.url) {
            e.preventDefault();
            const modalParam = item.url.includes('?m=')
              ? item.url.split('?m=')[1]
              : 'signin';
            const targetUrl = `${pathname}?m=${modalParam}`;
            router.push(targetUrl);
          }
          return true;
        }

        // Handle chat item
        if (isChatItem(item)) {
          toggleChat();
          return true;
        }

        // Handle navigation items (new, popular)
        if (isNavigationItem(item)) {
          if (isMobileLg) {
            dispatch(setOpenSidemenu(false));
          }

          e.preventDefault();

          // For new and popular items, toggle the sidebar section
          if (isNewGamesItem(item)) {
            setShouldDisplayBackButton(false);
            handleSectionToggle('new');
            return true;
          }

          if (isPopularGamesItem(item)) {
            setShouldDisplayBackButton(false);
            handleSectionToggle('popular');
            return true;
          }

          // For other navigation items, navigate to the URL
          if (item.url) {
            router.push(item.url);
          }
          return true;
        }

        // Handle search item
        if (isSearchItem(item)) {
          e.preventDefault();
          setShouldDisplayBackButton(false);
          dispatch(setOpenSearch(true));
          setIsOpen(false);
          return true;
        }

        // Map item to section and toggle it
        const section = mapItemToSection(item);
        if (section) {
          e.preventDefault();
          setShouldDisplayBackButton(false);
          handleSectionToggle(section);
          return true;
        }

        return false;
      },
      [
        dispatch,
        handleSectionToggle,
        isChatItem,
        isMobileLg,
        isNavigationItem,
        isSearchItem,
        isSigninItem,
        isWalletItem,
        item,
        mapItemToSection,
        openModal,
        pathname,
        router,
        setIsOpen,
        setShouldDisplayBackButton,
        toggleChat,
        isNewGamesItem,
        isPopularGamesItem,
      ],
    );

    const handleItemClick = useCallback(
      async (e: any) => {
        // First, check if this is a special favorites case
        if (isFavoritesItem(item)) {
          e.preventDefault();
          handleSectionToggle('favorites');
          return;
        }

        // For game pages, try handling with games page logic first
        if (forGamesPage) {
          const handled = await handleGamesPageItemClick(e);
          if (handled) return;
        }

        // Fall back to core (original) sidebar logic if not handled by games page
        await handleCoreItemClick(e);
      },
      [
        forGamesPage,
        handleCoreItemClick,
        handleGamesPageItemClick,
        handleSectionToggle,
        item,
        isFavoritesItem,
      ],
    );

    const itemProps = useMemo(
      () => ({
        className: cn([
          sidebarBaseItemStyles,
          isActive && 'bg-surface-hovered',
          !isSidemenuOpen &&
            isMounted &&
            'xl:flex xl:justify-center xl:w-full xl:gap-0 xl:items-center xl:p-0',
          isSidemenuOpen &&
            isMounted &&
            'justify-start flex gap-2 p-spacing-xs',
          forGamesPage &&
            'xl:flex xl:items-center xl:justify-center [&>div]:xl:w-full [&>div]:xl:h-full',
          className,
        ]),
        onClick: (e: any) => {
          // Special case for favorites button which is handled at this level
          if (isFavoritesItem(item)) {
            e.preventDefault();
            handleSectionToggle('favorites');
            return;
          }

          // For non-games pages or wallet items, handle directly
          if (!forGamesPage || isWalletItem(item)) {
            handleItemClick(e);
            return;
          }

          // For game pages, prevent default and handle navigation
          e.preventDefault();
          handleItemClick(e);
        },
      }),
      [
        className,
        forGamesPage,
        handleItemClick,
        handleSectionToggle,
        isActive,
        isMounted,
        isSidemenuOpen,
        item,
        isFavoritesItem,
        isWalletItem,
      ],
    );

    const renderItemContent = useCallback(
      () => (
        <>
          <SidebarItemIcon icon={item?.icon || ''} />
          {item.id === 'item-Notification-19' && hasUnreadNotifications && (
            <span className="absolute right-0 top-0.5 h-1 w-1 rounded-full bg-base-critical" />
          )}
        </>
      ),
      [hasUnreadNotifications, item?.icon, item.id],
    );

    const triggerContent = useCallback(() => {
      if (item?.url) {
        return (
          <Link
            scroll={true}
            prefetch={false}
            href={
              isWalletItem(item) || item.identifier === 'signin'
                ? specialUrl || ''
                : item.url || ''
            }
            key={`item-${item.id}`}
            {...itemProps}
          >
            {renderItemContent()}
            {!forGamesPage && <SidebarItemTitle title={item?.title} />}
          </Link>
        );
      }

      return (
        <button {...itemProps}>
          {renderItemContent()}
          {!forGamesPage && <SidebarItemTitle title={item?.title} />}
        </button>
      );
    }, [
      forGamesPage,
      isWalletItem,
      item,
      itemProps,
      renderItemContent,
      specialUrl,
    ]);

    const tooltipStyles = {
      popperStyles: '!z-[99999] p-2 text-xs',
      arrowStyles: '!z-[99999]',
    };

    if (forGamesPage && isMounted) {
      return (
        <Tooltip
          styleOptions={tooltipStyles}
          type="tooltip"
          displayOn="hover"
          trigger={triggerContent()}
          placement="right"
        >
          {item.title}
        </Tooltip>
      );
    }

    if (!isSidemenuOpen && isMounted) {
      return (
        <Tooltip
          styleOptions={tooltipStyles}
          type="tooltip"
          displayOn="hover"
          trigger={triggerContent()}
          placement="right"
        >
          {item.title}
        </Tooltip>
      );
    }

    return triggerContent();
  },
);

SectionItem.displayName = 'SectionItem';

export default SectionItem;
