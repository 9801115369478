import { Button, IconButton } from '@purposeinplay/core-v2';
import React from 'react';
import useWildChatContent from '../hooks/use-wild-chat-content';
import { useAuth } from '@/hooks/auth';
import SendSVG from 'public/assets/send.svg';

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
}: {
  onClick: (e: any) => void;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  const { user } = useAuth();
  const chatContent = useWildChatContent();

  const buttonContent = user?.isAuthenticated
    ? chatContent?.input?.cta
    : chatContent?.popup?.cta;

  return (
    <IconButton
      iconOnly
      iconLeft={<SendSVG />}
      disabled={isLoading || isDisabled}
      isLoading={isLoading}
      onClick={onClick}
    >
      {buttonContent}
    </IconButton>
  );
};

export default SubmitButton;
