import React from 'react';
import { PercentageBarProps } from './types';

const PercentageBar: React.FC<PercentageBarProps> = ({
  percentage,
  seenCount,
  totalCount,
  percentagebarContent,
}) => {
  return (
    <div className="pt-10 text-center">
      <div className="mx-auto">
        <div className="relative mx-auto mb-2 h-1  w-full rounded-full bg-surface-default sm:w-52 xl:w-[22.625rem]">
          <span
            style={{ width: `${percentage}%` }}
            className="absolute left-0 top-0 h-1 w-full rounded-full bg-base-primary"
          />
        </div>

        <p className="overflow-hidden text-sm font-medium text-text-default">
          {percentagebarContent?.you_viewed ?? ' You viewed'}: {seenCount}{' '}
          {percentagebarContent?.out_of ?? 'out of'} {totalCount}{' '}
          {percentagebarContent?.challenges ?? 'challenges'}
        </p>
      </div>
    </div>
  );
};

export default PercentageBar;
