import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import RafArrowSVG from 'public/assets/raf-arrow.svg';
import { useTranslation } from '@/app/i18n/client';
import { ReferAFriendContentType } from '@/types/json-content/refer_a_friend';

const Steps = () => {
  const { t } = useTranslation();
  const content = t('refer_a_friend') as ReferAFriendContentType;
  return (
    <div>
      <Text
        size="lg"
        className="mb-2 font-semibold text-text-subdued md:font-medium"
      >
        {content?.steps_title}
      </Text>
      <div className="relative z-10 grid items-center gap-2 rounded-2xl bg-transparent p-0 md:grid-cols-3 md:justify-center md:gap-16 md:bg-bgr-lighter md:px-10 md:py-7">
        {content?.steps.map((step, index: number) => {
          return (
            <div
              key={step.id}
              className="group relative rounded-[0.625rem] bg-bgr-lighter p-3 md:rounded-none md:bg-transparent md:p-0"
            >
              <RafArrowSVG className="text-tbgr-lightest absolute -right-10 top-1/2 hidden -translate-y-1/2 transform fill-current opacity-10 group-last:hidden md:block" />
              <div className="flex flex-row md:flex-col">
                <Text
                  size="xl"
                  as="span"
                  className="mr-1 font-semibold text-base-primary md:mr-0 md:text-2xl"
                >
                  {step.number}.
                </Text>
                <Text
                  size="xl"
                  className="md:2xl font-semibold text-text-default md:font-bold"
                >
                  {step.title}
                </Text>
              </div>
              <Text
                size="sm"
                className="leading-6 text-text-subdued md:leading-5"
              >
                {step.description}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Steps;
