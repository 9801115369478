import React, { useMemo } from 'react';
import { useAuth } from '@/hooks/auth';
import { SidemenuItem } from '../types';
import SidebarSection from './section';

interface Props {
  isMobile?: boolean;
  isOpen?: boolean;
  title?: string;
  items?: SidemenuItem[];
  isAccordion?: boolean;
  isExpanded?: boolean;
  authOnly?: boolean;
  forGamesPage?: boolean;
}

const AccordionSection: React.FC<Props> = React.memo(
  ({
    items,
    isAccordion,
    title,
    authOnly,
    isExpanded,
    isMobile = false,
    forGamesPage,
  }) => {
    const {
      user: { isAuthenticated, isLoading },
    } = useAuth();

    if (authOnly && !isAuthenticated && !isLoading) return null;
    return (
      <SidebarSection
        forGamesPage={forGamesPage}
        isAccordion={isAccordion}
        items={items}
        isMobile={isMobile}
      />
    );
  },
);

AccordionSection.displayName = 'Accordion Menu Item';

export default AccordionSection;
