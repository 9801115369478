import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useAuth } from './auth';
import { useAppDispatch } from './redux';
import { setSavedAvatar } from '@/app/reducers/bootstrap';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const useAvatar = (): UseQueryResult<{ userId: number; avatar: string }> => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: ['userAvatar'],
    queryFn: () => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NEXT_PUBLIC_IS_QA === 'true'
      ) {
        dispatch(setSavedAvatar(null));
        const avatar = JSON.parse(
          localStorage.getItem(`avatar-${user?.currentUser?.id}`) as string,
        );

        if (avatar) {
          dispatch(setSavedAvatar(avatar.avatar));
          return Promise.resolve({ avatar: avatar.avatar });
        } else {
          const randomAvatar = `preset-${Math.floor(Math.random() * 8) + 1}`;
          localStorage.setItem(
            `avatar-${user?.currentUser?.id}`,
            JSON.stringify({
              avatar: randomAvatar,
              id: user?.currentUser?.id,
            }),
          );
          dispatch(setSavedAvatar(randomAvatar));
          return Promise.resolve({ avatar: randomAvatar });
        }
      }
      return fetch(`/api/avatar?id=${user?.currentUser?.id}`).then(
        async (response) => {
          const data = await response.text();
          if (response.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            dispatch(setSavedAvatar(parsed?.avatar));
            return parsed;
          } else {
            return Promise.reject(JSON.parse(data));
          }
        },
      );
    },
    refetchOnMount: true,
    enabled:
      user?.isAuthenticated &&
      !user?.isLoading &&
      user?.currentUser?.id !== undefined,
  });
};

const useUpdateAvatar = (shouldToast = true): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { user }: any = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    // @ts-ignore
    mutationFn: (avatar: any) => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NEXT_PUBLIC_IS_QA === 'true'
      ) {
        localStorage.setItem(
          `avatar-${user?.currentUser?.id}`,
          JSON.stringify({
            ...avatar,
            id: user?.currentUser?.id,
          }),
        );
        dispatch(setSavedAvatar(avatar?.avatar));
        return Promise.resolve({
          ...avatar,
          id: user?.currentUser?.id,
        });
      }
      try {
        return fetch('/api/avatar', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...avatar,
            id: user?.currentUser?.id,
          }),
        }).then(async (res) => {
          const data = await res.text();
          if (res.ok) {
            const parsed = (data && JSON.parse(data)) || {};
            dispatch(setSavedAvatar(parsed?.avatar));
            return Promise.resolve(parsed);
          } else {
            return Promise.reject(JSON.parse(data));
          }
        });
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      if (shouldToast)
        toast({
          state: 'success',
          title: toastMessages?.updated_avatar,
          autoClose: 1500,
        });
      queryClient.invalidateQueries({ queryKey: ['userAvatar'] });
    },
  });
};

export { useAvatar, useUpdateAvatar };
