import { useTranslation } from '@/app/i18n/client';
import useModal, { useCurrentModal } from '@/hooks/modal';
import { AgentVerificationContentType } from '@/types/json-content/agent_verification';
import { Modal } from '@purposeinplay/core-v2';
import React, { useState } from 'react';
import VerifyAgentContent from './components/modal-content';
import VerifyAgentFooter from './components/modal-footer';
import { VerifyAgentForm } from './types';

const VerifyAgentModal = () => {
  const { close } = useModal();
  const currentModal = useCurrentModal();
  const { t } = useTranslation();
  const content = t('agent_verification') as AgentVerificationContentType;
  const [modalState, setModalState] = useState<VerifyAgentForm>({
    state: 'default',
    user_id: '',
  });

  return (
    <Modal
      title={content?.modal_title || 'Agent Verification'}
      isOpen={currentModal === 'verify-agent'}
      modalStylingOptions={{
        containerStyles: 'overflow-visible',
      }}
      setIsOpen={() => {
        close();
        setModalState({ user_id: '', state: 'default' });
      }}
      size="md"
    >
      <div className="flex flex-col gap-7">
        <VerifyAgentContent state={modalState} />
        <VerifyAgentFooter setState={setModalState} state={modalState} />
      </div>
    </Modal>
  );
};

export default VerifyAgentModal;
