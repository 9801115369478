import React, { useMemo } from 'react';
import { Modal } from '@purposeinplay/core-v2';
import useModal, { useCurrentModal } from '@/hooks/modal';
import Wheel from './wheel';
import {
  Enum_Componentwheelwheelstyles_Type,
  Maybe,
  PublicationState,
  useWofQuery,
  WheelOfFortune,
} from '@/generated';
import { cn } from '@/utils/style';
import { getModalWheelStyles } from '../utils';

const WheelModal = () => {
  const { close } = useModal();
  const currentModal = useCurrentModal();
  const pathSlug = currentModal?.split('-wheel')[0];
  const { data: wofData } = useWofQuery(
    {
      publicationState: PublicationState.Preview,
      slug: pathSlug as string,
    },
    { enabled: currentModal?.includes('wheel') && pathSlug !== undefined },
  );

  const foundWheel = wofData?.wheelOfFortunes?.data?.find((wheel) =>
    currentModal?.includes(wheel?.attributes?.slug as string),
  )?.attributes as Maybe<WheelOfFortune>;

  const isOpen = foundWheel !== undefined;

  const containerStyle = useMemo(() => {
    return foundWheel?.custom_styles?.find(
      (style) =>
        style?.type === Enum_Componentwheelwheelstyles_Type.MainContainer,
    )?.styles as string | undefined;
  }, [foundWheel?.custom_styles]);

  return (
    <Modal
      modalStylingOptions={{
        containerStyles: cn([
          containerStyle && containerStyle,
          getModalWheelStyles(foundWheel?.slug as string),
        ]),
        closeBtnContainerStyles:
          '[&>span>svg]:fill-current [&>span>svg]:text-white [&>span>svg]:hover:text-gray-300',
      }}
      size="xl"
      isOpen={isOpen}
      setIsOpen={() => close()}
    >
      <div className="relative">
        <Wheel wheel={foundWheel} />
      </div>
    </Modal>
  );
};

export default WheelModal;
