import { cn } from '@/utils/style';
import { Tabs } from '@purposeinplay/core-v2';
import React, { useCallback, useState } from 'react';

// TO DO - we have this component in bonus-shop as well
// maybe reuse it once branches are merged

const FsTabs = ({
  className,
  onClick,
  items,
}: {
  className?: string;
  onClick: (points: number) => void;
  items: { active: boolean; id: string; label: React.ReactNode }[];
}) => {
  const [tabs, setTabs] = useState(items);

  const handleClick = useCallback(
    (e: any, item: any) => {
      setTabs((prev) => {
        return prev.map((el) =>
          el?.id === item?.id
            ? { ...el, active: true }
            : { ...el, active: false },
        );
      });
      onClick && onClick(parseInt(item?.id, 10));
    },
    [onClick],
  );

  return (
    <div className={cn(['w-full  [&>div]:w-full', className])}>
      <Tabs
        tabStyleOptions={{
          containerStyles: 'w-full',
          navStyles: 'w-full justify-between',
          navItemStyles:
            'flex-1 flex justify-center items-center [&>span>div>img]:grayscale  text-text-subdued !py-[6.5px] !px-0',
          activeNavItemStyles:
            '[&>span>div>img]:grayscale-0 !text-text-default',
        }}
        variant="segmented"
        items={tabs}
        onClick={handleClick}
      />
    </div>
  );
};

export default FsTabs;
