import CryptoValue from '@/components/CryptoValue';

export const mapValueToPrize = (
  sortedPrizes: any,
  index: any,
  tournament: any,
  currency: any,
) => {
  const prize = sortedPrizes[index];

  if (!prize) return 'N/A';
  if (prize.money_award_cents) {
    const formattedMoney = (
      <CryptoValue
        forPrize
        value={prize?.money_award_cents}
        currency={currency?.code}
      />
    );
    return tournament.currency === 'BTC' ? formattedMoney : formattedMoney;
  }

  if (prize.freespins_count && !prize.money_award_cents) {
    return `${prize.freespins_count} FS`;
  }

  if (prize.stuff.includes('VIP Upgrade')) {
    return prize.stuff;
  }

  return 'N/A';
};

export const mapValueToPrizeUpdated = (prize: any) => {
  if (!prize) return 'N/A';

  if (prize.money_award && prize.money_award_cents) {
    return `$${prize.money_award.split('.')[0]}`;
  }

  if (prize.freespins_count) {
    return `${prize.freespins_count}FS`;
  }

  if (prize.stuff.includes('VIP Upgrade')) {
    return prize.stuff;
  }

  return 'N/A';
};

export const getTournamentPlace = (place: number) => {
  switch (place) {
    case 1:
      return '1st PLACE';
    case 2:
      return '2nd PLACE';
    case 3:
      return '3rd PLACE';
  }
};
export const getTournamentPlaceDE = (place: number) => {
  switch (place) {
    case 1:
      return 'PLATZ 1';
    case 2:
      return 'PLATZ 2';
    case 3:
      return 'PLATZ 3';
  }
};

export const shouldRenderIcon = (prize: any) => {
  return !(
    prize.freespins_count &&
    !prize.money_award_cents &&
    !prize.stuff.includes('VIP Upgrade')
  );
};
