import React from 'react';
import { Text } from '@purposeinplay/core-v2';
import { GameSectionType } from '../types';
import { useTranslation } from '@/app/i18n/client';
import { FavoritesContentType } from '@/types/json-content/favorites';
import { RecentlyPlayedContentType } from '@/types/json-content/recently-played';

interface GameListEmptyStateProps {
  type: GameSectionType;
}

export const GameListEmptyState: React.FC<GameListEmptyStateProps> = ({
  type,
}) => {
  const { t } = useTranslation();

  let title = '';
  let description = '';

  if (type === 'favorites') {
    const content = t('favorites') as FavoritesContentType;
    title = content?.no_results?.title || 'No Favorites Yet';
    description =
      content?.no_results?.subtitle ||
      "You haven't added any games to your favorite list";
  } else if (type === 'recents') {
    const content = t('recently_played') as RecentlyPlayedContentType;
    title = content?.no_results?.title || 'No Recent Games';
    description =
      content?.no_results?.subtitle || "You haven't played any games recently";
  } else if (type === 'suggested') {
    title = 'No Popular Games';
    description = "We couldn't find any suggested games for you";
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 rounded-lg border border-dashed border-border-general-subdued px-16 py-4">
      <Text className="text-base font-semibold leading-none">{title}</Text>
      <Text size="sm" className="text-center leading-5 text-text-subdued">
        {description}
      </Text>
    </div>
  );
};

export default GameListEmptyState;
