import React, { useCallback, useMemo, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import BottomNavItem from './bottom-nav-item';
import MenuSVG from 'public/assets/bottom-nav/menu.svg';
import SearchSVG from 'public/assets/bottom-nav/search.svg';
import CasinoSVG from 'public/assets/bottom-nav/casino.svg';
import SportsSVG from 'public/assets/bottom-nav/sports.svg';
import BonusesSvg from 'public/assets/bottom-nav/bonus.svg';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setOpenSearch, setSearchType } from '@/app/reducers/search';
import useModal from '@/hooks/modal';
import { useAuth } from '@/hooks/auth';
import { SearchType } from '@/modules/search/types';
import { MOBILE_LG_BREAKPOINT } from '@/hooks/use-media-query';
import useMediaQuery from '@/hooks/use-media-query';
import useBodyLock from '@/hooks/use-body-lock';
import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import GamePageBottomNavWrapper from './components/game-page-bottom-nav-wrapper';

const BottomNav = () => {
  const { matchesQuery: isMobileLG } = useMediaQuery(MOBILE_LG_BREAKPOINT);
  const openFeed = useAppSelector((state) => state.bootstrap.openFeed);
  const openSearch = useAppSelector((state) => state.search.open);
  const isOpen = useAppSelector((state) => state.bootstrap.isSidemenuOpen);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { open } = useModal();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const modalContent = searchParams.get('m');

  useBodyLock(isOpen);

  const {
    user: { isAuthenticated },
  } = useAuth();

  const BOTTOM_NAV_ITEMS = useMemo(
    () => [
      {
        text: 'Menu',
        onClick: () => dispatch(setOpenSidemenu(!isOpen)),
        icon: <MenuSVG />,
        isActive: isOpen,
      },
      {
        text: 'Casino',
        onClick: () => {
          router.push('/casino');
          dispatch(setOpenSidemenu(false));
        },
        icon: <CasinoSVG />,
        isActive: !openFeed && pathname === '/casino',
      },

      {
        text: 'Bonuses',
        onClick: () => {
          dispatch(setOpenSidemenu(false));
          if (isAuthenticated) {
            router.push('/bonuses');
          } else {
            open('signin');
          }
        },
        isActive: pathname === '/bonuses',
        icon: <BonusesSvg />,
        // isHighlighted: true,
      },
      {
        text: 'Sports',
        onClick: () => router.push('/sports'),
        icon: <SportsSVG />,
      },
      {
        text: 'Search',
        onClick: () => {
          dispatch(setOpenSidemenu(false));
          if (pathname !== '/search') {
            dispatch(setOpenSearch(true));
            dispatch(setSearchType(SearchType.GAMES));
          }
        },
        icon: <SearchSVG />,
        isActive: openSearch,
      },
    ],
    [
      isOpen,
      openFeed,
      pathname,
      openSearch,
      dispatch,
      router,
      isAuthenticated,
      open,
    ],
  );

  const [activeItem, setActiveItem] = useState<any>(BOTTOM_NAV_ITEMS[1].text);

  const handleItemClick = useCallback(
    (item: any) => {
      if (item === activeItem) {
        setActiveItem(null);
      } else {
        setActiveItem(item);
      }
    },
    [activeItem],
  );

  if (!isMobileLG) return null;

  return (
    <div
      style={{ zIndex: 999 }}
      className="safe-bottom-padding fixed inset-x-0 bottom-0 flex w-full justify-between rounded-t-xl bg-bgr-lightest px-3 py-1 shadow"
    >
      {BOTTOM_NAV_ITEMS.map((item) => {
        return (
          <BottomNavItem
            onItemClick={() => {
              handleItemClick(item.text);
            }}
            item={item}
            isItemActive={item.text === activeItem && item.isActive}
            key={item.text}
          />
        );
      })}
    </div>
  );
};

export default BottomNav;

export { GamePageBottomNavWrapper };
