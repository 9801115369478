import React, { useMemo } from 'react';
import Image from 'next/image';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setGameViewOpen, setIsFun } from '@/app/reducers/bootstrap';
import { useAuth } from '@/hooks/auth';
import { Switch, Text } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { GamesContentType } from '@/types/json-content/games';
import FavoriteButton from '@/components/FavoriteButton';
import useHandleFavoriteGame from '@/modules/games/hooks/use-handle-favorite-game';
import useSeoGameByTitle from '@/modules/games/hooks/use-seo-game-by-title';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import useGamesSidebarItems from '../../../layout/sidebar/hooks/use-games-sidebar-items';

interface MoreItemProps {
  label: string;
  id: string;
  onShowJackpotDetail?: () => void;
  onItemClick?: () => void;
}

const MoreItem: React.FC<MoreItemProps> = ({
  label,
  id,
  onShowJackpotDetail,
  onItemClick,
}) => {
  const dispatch = useAppDispatch();
  const isFun = useAppSelector((state) => state.bootstrap.isFun);
  const {
    user: { isAuthenticated },
  } = useAuth();
  const { t } = useTranslation();
  const gameContent = t('games') as GamesContentType;
  const { game } = useSeoGameByTitle();
  const { isFavorited, handleFavorites, isMutating } =
    useHandleFavoriteGame(game);
  const { rawLinks } = useGamesSidebarItems();

  // Find icons from rawLinks
  const chatIcon = useMemo(() => {
    const chatLink = rawLinks?.find((link) => link.identifier === 'chat');
    return chatLink?.items?.[0]?.icon || '/assets/chat_icon.svg';
  }, [rawLinks]);

  const supportIcon = useMemo(() => {
    const intercomLink = rawLinks?.find(
      (link) => link.identifier === 'intercom',
    );
    return (
      intercomLink?.items?.[0]?.icon || '/assets/sidemenu_live_support_v2.svg'
    );
  }, [rawLinks]);

  const hasJackpot = useMemo(() => {
    if (!game?.categories || !Array.isArray(game.categories)) {
      return false;
    }

    return game.categories.some((category: string) =>
      category.toLowerCase().includes('jackpot'),
    );
  }, [game?.categories]);

  const handleItemClick = () => {
    if (onItemClick) {
      onItemClick();
      return;
    }

    // Otherwise, handle standard behavior
    switch (id) {
      case 'favorites':
        handleFavorites();
        break;
      case 'mode':
        if (isAuthenticated) {
          dispatch(setGameViewOpen(false));
          dispatch(setIsFun(!isFun));
        }
        break;
      case 'jackpot':
        if (hasJackpot && onShowJackpotDetail) {
          onShowJackpotDetail();
        }
        break;
      default:
        break;
    }
  };

  const renderComponent = () => {
    switch (id) {
      case 'favorites':
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="pointer-events-auto flex h-6 w-6 items-center justify-center"
          >
            <FavoriteButton
              isSimpleVariant
              className="relative right-0 top-0"
              isFavorited={isFavorited}
              onFavoriteClick={handleFavorites}
              isDisabled={isMutating}
            />
          </div>
        );
      case 'mode':
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="pointer-events-auto"
          >
            <Switch
              labelLeft={
                <Text
                  size="sm"
                  className={cn(['uppercase', !isFun && `text-text-subdued`])}
                >
                  {gameContent?.game?.game_toggle?.fun}
                </Text>
              }
              labelRight={
                <Text
                  size="sm"
                  className={cn(['uppercase', isFun && `text-text-subdued`])}
                >
                  {gameContent?.game?.game_toggle?.real}
                </Text>
              }
              checked={!isFun}
              onChange={() => {
                dispatch(setGameViewOpen(false));
                if (isAuthenticated) {
                  dispatch(setIsFun(!isFun));
                }
              }}
              disabled={!isAuthenticated}
            />
          </div>
        );
      case 'jackpot':
        return hasJackpot ? (
          <Image
            alt="Bonus Prize"
            height={20}
            width={20}
            src="/assets/jackpot-icon.svg"
            loader={DEFAULT_IMAGE_LOADER}
          />
        ) : (
          <div className="h-5 w-5" />
        );
      case 'chat':
        return (
          <Image
            alt="Community Chat"
            height={20}
            width={20}
            src={chatIcon}
            loader={DEFAULT_IMAGE_LOADER}
          />
        );
      case 'support':
        return (
          <Image
            alt="Support"
            height={20}
            width={20}
            src={supportIcon}
            loader={DEFAULT_IMAGE_LOADER}
          />
        );
    }
  };

  if (id === 'jackpot' && !hasJackpot) {
    return null;
  }

  if (id === 'mode' && !isAuthenticated) {
    return null;
  }

  return (
    <div
      className="mb-1 flex cursor-pointer flex-row items-center justify-between rounded-[4px] p-2 last:mb-0 hover:bg-surface-hovered"
      onClick={handleItemClick}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleItemClick();
        }
      }}
    >
      <span className="text-sm leading-5 text-text-default">{label}</span>
      {renderComponent()}
    </div>
  );
};

export default MoreItem;
