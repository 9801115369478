'use client';

import React, { useMemo } from 'react';
import Link from 'next/link';
import SocialButtons from './social-buttons';
import { usePathname } from 'next/navigation';
import { Footer as FooterType, useFooterQuery } from '@/generated';
import { useTranslation } from '@/app/i18n/client';
import { extractTranslations } from '@/utils/content';
import ContentAreaWrapper from '../ContentAreaWrapper';
import { cn } from '@/utils/style';
import { Heading, Text } from '@purposeinplay/core-v2';
import AdaptiveImage from '../AdaptiveImage';
import {
  DEFAULT_CONTENT_STALE_TIME,
  DEFAULT_IMAGE_LOADER,
} from '@/utils/constants';
import AwardIcon from './award-icon';
import { GeneralContentType } from '@/types/json-content/general';
import Image from 'next/image';
import config from '@/config';
import ThemeSwitch from '../ThemeSwitch';
import { useSupportChatToggle } from '@/hooks/use-support-chat-toggle';

const LinkText = ({ text }: { text: string }) => (
  <Text
    size="sm"
    className="text-sm text-text-subdued transition duration-200 ease-out hover:text-base-primary hover:text-opacity-100"
  >
    {text}
  </Text>
);

const FooterSection = React.memo(
  ({
    title,
    links,
  }: {
    title: string;
    links: { text: string; href: string; target?: string; rel?: string }[];
  }) => (
    <div className="footer__column">
      <Heading
        as="h4"
        className="mb-3 text-sm font-semibold uppercase tracking-wider"
      >
        {title}
      </Heading>
      <ul className="space-y-2">
        {links.map((link) => (
          <li key={link.href}>
            {link.text === 'Blog' ? (
              <a
                href={link.href}
                target={link.target || '_self'}
                rel={link.rel}
              >
                <LinkText text={link.text} />
              </a>
            ) : (
              <Link
                href={link.href}
                passHref
                prefetch={false}
                target={link.target || '_self'}
                rel={link.rel}
              >
                <LinkText text={link.text} />
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  ),
);

FooterSection.displayName = 'FooterSection';

const Footer = () => {
  const { i18n, t } = useTranslation();
  const pathname = usePathname();
  const generalContent = t('general') as GeneralContentType;
  const { handleClick } = useSupportChatToggle();

  const { data: footerContent } = useFooterQuery(undefined, {
    queryKey: ['footer'],
    staleTime: DEFAULT_CONTENT_STALE_TIME,
  });

  const content = extractTranslations(
    i18n.language,
    footerContent?.footer?.data?.attributes,
  ) as FooterType;

  const footerSections = useMemo(() => {
    return (
      // Remove support because that is a special case
      content?.menu?.slice(0, -1).map((section) => {
        const title = section?.heading || '';

        const links =
          section?.menu_item?.map((item) => ({
            href: item?.href || '',
            text: item?.title || '',
          })) || [];

        return <FooterSection key={title} title={title} links={links} />;
      }) || []
    );
  }, [content?.menu]);

  return (
    <ContentAreaWrapper
      className={cn([
        'px-3 lg:px-8',
        pathname === '/games/[id]' && `max-w-lg2 lg:max-w-lg2`,
      ])}
    >
      <footer aria-labelledby="footerHeading">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8">
          <div className="mb-7 flex flex-col">
            <div className="mb-1">
              <AdaptiveImage
                alt="logo"
                width={110}
                priority
                height={35}
                fill={false}
                loader={DEFAULT_IMAGE_LOADER}
                sources={{
                  desktop: {
                    dark: '/assets/logo-dark-v2.svg',
                    light: '/assets/logo-v2.svg',
                  },
                }}
              />
            </div>
            <Text size="sm" className="mb-spacing-xs text-text-subdued">
              {content?.copyright}
            </Text>
            <SocialButtons />
            <ThemeSwitch />
            {/* Uncomment this for language toggle */}
            {/* <LocaleChanger /> */}
          </div>
          <div className="mb-4 grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            {footerSections}
            <div>
              <Heading
                as="h4"
                className="mb-3 text-sm font-semibold uppercase tracking-wider"
              >
                {content?.menu?.[4]?.heading}
              </Heading>
              <ul className="space-y-2">
                <li className="cursor-pointer" onClick={handleClick}>
                  <Text
                    size="sm"
                    className="text-text-subdued transition duration-200 ease-out hover:text-text-default"
                  >
                    {content?.menu?.[4]?.menu_item?.[0]?.title}
                  </Text>
                </li>
                {/* Map over remaining items besides Live Support one, if there are any */}
                {content?.menu?.[4]?.menu_item?.slice(1).map((item) => (
                  <li key={item?.title}>
                    <Link href={item?.href ?? '#'} passHref>
                      <LinkText text={item?.title ?? ''} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-4  lg:border-border-general-default lg:py-4">
          <div className="rounded-xl border border-border-general-default p-4 lg:grid lg:grid-cols-2">
            <div className="flex items-center justify-center">
              <Text
                size="xs"
                className="mb-4 text-text-subdued lg:mb-0 lg:max-w-2xl"
              >
                {content?.disclaimer}
              </Text>
            </div>
            <div className="flex flex-col border-border-general-subdued  lg:flex-row">
              <div className="w-full lg:mb-0 lg:flex lg:items-center lg:justify-end lg:space-x-6">
                <div>
                  <div className="flex flex-wrap items-center justify-center gap-5 lg:justify-end">
                    <Image
                      width={36}
                      height={36}
                      src="/assets/footer-badge.svg"
                      alt="Footer Badge"
                    />
                    <Image
                      width={30}
                      height={33}
                      src="/assets/gamcare.svg"
                      alt="GamCare"
                    />
                    <Image
                      width={117}
                      height={33}
                      src="/assets/responsiblegambling.svg"
                      alt="Responsible Gambling"
                    />
                    <Image
                      width={95}
                      height={33}
                      src="/assets/gamblersanonymous.svg"
                      alt="Anonymous"
                    />
                    <a
                      className="wild-transition hover:opacity-80"
                      href={`https://cert.gcb.cw/certificate?id=${config.CURACAO_TOKEN}`}
                    >
                      <Image
                        height={32}
                        width={56}
                        alt="Gcb seal"
                        src="/assets/GCBSeal.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Awards section */}
        <div className="flex flex-col gap-2">
          <Text size="sm" className="text-text-subdued">
            {`${generalContent?.footer?.awards?.title ?? 'AWARDS'}`}
          </Text>
          <div className="flex flex-row flex-wrap gap-7">
            {[
              'best-new-casino',
              'best-casino',
              'players-choice',
              'intercom',
            ].map((award) => (
              <AwardIcon key={award} svgName={award} alt="Award Icon" />
            ))}
          </div>
        </div>
      </footer>
    </ContentAreaWrapper>
  );
};

export default Footer;
