import React from 'react';
import NotificationHeader from './notification-header';
import { TextLoader } from '@/components/Loaders';
import { cn } from '@/utils/style';

const NotificationsLoading = ({
  forSidebar = false,
}: {
  forSidebar?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      {!forSidebar && <NotificationHeader loading={true} />}
      {Array.from(Array(5).keys()).map((key) => (
        <div
          className={cn([
            'flex flex-row items-center p-2',
            !forSidebar && 'border-b border-border-general-default p-3',
          ])}
          key={key}
        >
          <TextLoader className="mr-2 h-8 !w-8" />
          <TextLoader className="lg:w-[w-2/3]" />
        </div>
      ))}
    </div>
  );
};

export default NotificationsLoading;
