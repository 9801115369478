import { TextLoader } from '@/components/Loaders';
import { cn } from '@/utils/style';
import React from 'react';

const MinimalGameCardLoader = () => {
  return (
    <div className={cn(['flex flex-row items-center p-2'])}>
      <TextLoader className="mr-2 h-8 !w-8" />
      <TextLoader className="lg:w-[w-2/3]" />
    </div>
  );
};

export default MinimalGameCardLoader;
