import React, { memo, useCallback, useMemo } from 'react';
import { PointTabProps } from '../types';
import { useCurrencies } from '@/hooks/currencies';
import { subunitsToUnit } from '@/utils/money';
import CryptoIconWrapper from '@/components/CryptoIconWrapper';
import { getColorByCurrency, getIconByCurrency } from '@/utils/helpers';
import { useAccounts } from '@/hooks/accounts';
import { Select } from '@purposeinplay/core-v2';
import useExchangeRatesCash from '../hooks/use-exchange-rates-cash';
import CurrencyValue from './currency-value';

const CurrencySelector = ({
  currency,
  points: propPoints,
  setFormData,
}: {
  currency: string;
  points: number;
  setFormData: PointTabProps['setFormData'];
}) => {
  const { data: currencies = [] } = useCurrencies();
  const { accounts = [] }: any = useAccounts();

  const exchangeRates = useExchangeRatesCash();

  const availableCurrencies = useMemo(
    () =>
      currencies.map((currency) => ({
        id: currency.code,
        component: (
          <div className="flex items-center space-x-2">
            <span>
              {' '}
              {currency.code.toLowerCase() === 'eur' || 'usd' ? (
                <CryptoIconWrapper
                  className="flex  h-6  w-6 items-center  justify-center [&>svg]:h-6 [&>svg]:w-6"
                  color={getColorByCurrency(currency.code.toLowerCase())}
                >
                  {getIconByCurrency(currency.code.toLowerCase())}
                </CryptoIconWrapper>
              ) : (
                <CryptoIconWrapper
                  className="flex h-6 w-6 items-center justify-center"
                  color={getColorByCurrency(currency.code.toLowerCase())}
                >
                  {getIconByCurrency(currency.code.toLowerCase(), {
                    small: true,
                  })}
                </CryptoIconWrapper>
              )}
            </span>
            <CurrencyValue
              currency={currency}
              exchangeRates={exchangeRates}
              propPoints={propPoints}
              subunitsToUnit={subunitsToUnit}
            />
          </div>
        ),
      })),
    [currencies, exchangeRates, propPoints],
  );

  const currenciesFiltered = useMemo(() => {
    return availableCurrencies.filter((currency1) => {
      return accounts.some(
        (currency2: any) => currency2.currency === currency1.id,
      );
    });
  }, [accounts, availableCurrencies]);

  const handleOnSelect = useCallback((item: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      currency: item.id,
    }));
  }, []);

  return (
    <div>
      <Select
        customValue={
          currenciesFiltered.find((item) => item.id === currency)?.component
        }
        onSelect={handleOnSelect}
        multi={false}
        items={currenciesFiltered}
      />
    </div>
  );
};

export default memo(CurrencySelector);
