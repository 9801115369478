'use client';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { Button, IconButton, Text } from '@purposeinplay/core-v2';
import useModal from '@/hooks/modal';
import CloseSvg from 'public/assets/deposit-pop-up-close.svg';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import useBonusTime from '@/hooks/use-bonus-time';
import PopUpContent from './components/pop-up-content';
import PopUpCountdown from './components/pop-up-coundown';
import PopUpButton from './components/pop-up-button';
import { usePlayerStats } from '@/hooks/others';
import { useAuth } from '@/hooks/auth';
import { usePreviewBonuses } from '@/hooks/preview-bonuses';
import { cn } from '@/utils/style';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import useIsOnGamePage from '@/hooks/use-is-on-game-page';

const DepositBonusPopUp = () => {
  const { open } = useModal();
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useAuth();
  const { data: playerStats, isLoading: playerStatsLoading } = usePlayerStats();
  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);
  const isOnGamePage = useIsOnGamePage();

  const showPopup = useMemo(() => {
    if (isMobile && isOnGamePage) return false;
    if (playerStatsLoading || !playerStats) return false;
    return playerStats?.deposits_count >= 3 ? false : true;
  }, [isMobile, isOnGamePage, playerStats, playerStatsLoading]);

  const showPreviewBonuses = usePreviewBonuses();

  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;
  const content = general?.deposit_bonus_pop_up;

  const { time, progress } = useBonusTime();
  if (playerStatsLoading || (!user?.isLoading && !user?.isAuthenticated))
    return null;
  if (!showPopup || !showPreviewBonuses) {
    return null;
  }

  return (
    <>
      <div
        data-theme="dark"
        className={cn([
          'safe-bottom-margin fixed bottom-20 left-3 right-3 z-[100] flex w-auto flex-col gap-3 rounded-2xl bg-[linear-gradient(164deg,#8365FF_9.64%,#503A95_88.77%)] p-padding-3xl md:bottom-6 md:left-auto md:right-7 md:w-96 md:gap-7 lg:bottom-20',
          !isOpen && 'hidden',
        ])}
      >
        <div className="flex flex-row items-start justify-between">
          <PopUpContent
            content={content}
            depositCount={playerStats?.deposits_count}
          />
          <IconButton
            iconLeft={<CloseSvg />}
            iconOnly
            onClick={() => setIsOpen(!isOpen)}
            ghost
            color="tertiary"
            size="xs"
          />
        </div>
        <div className="flex flex-row items-end justify-between">
          <div className="flex flex-col">
            <div className="mb-1 flex flex-row items-center">
              <Image
                alt="Bonus Prize pop up"
                height={16}
                width={16}
                src="/assets/lightning-limit.svg"
                loader={DEFAULT_IMAGE_LOADER}
              />
              <Text
                size="xs"
                className="font-medium text-text-default"
                as="span"
              >
                {content?.countdown_label}
              </Text>
            </div>
            <PopUpCountdown time={time} />
          </div>

          <Button size="sm" onClick={() => open('bonus-selection')}>
            {content?.btn_label}
          </Button>
        </div>
      </div>
      <PopUpButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        progress={progress}
        time={time}
      />
    </>
  );
};

export default DepositBonusPopUp;
