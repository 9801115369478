import { useTranslation } from '@/app/i18n/client';
import { useAppDispatch } from '@/hooks/redux';
import { AuthContentType } from '@/types/json-content/auth';
import { GeneralContentType } from '@/types/json-content/general';
import { handleError } from '@/utils/helpers';
import { Button, Field, FieldGroup, Label } from '@purposeinplay/core-v2';
import { setOpenTriggerModal } from '@/app/reducers/bootstrap';
import React, { useCallback, useState } from 'react';
import useUpdateDetailsV2 from '@/hooks/use-update-details-v2';
import EmailInput from '@/components/Auth/components/signup/email-input';
import PrivacyTermsLabel from '@/components/Auth/components/signup/privacy-terms-label';
import { useToast } from '@purposeinplay/utils';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const UpdateInfoContent = () => {
  const { t, i18n } = useTranslation();
  const authContent = t('auth') as AuthContentType;
  const generalContent = t('general') as GeneralContentType;
  const content = authContent.signup_modal;
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();

  const dispatch = useAppDispatch();

  const { updateDetails, isError, isPending, error } = useUpdateDetailsV2();

  const [state, setState] = useState({
    email: '',
    terms_acceptance: false,
  });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      const target = e.target;
      setState({ ...state, [type]: target.value });
    },
    [state],
  );

  const isButtonDisabled = isPending || !state.email || !state.terms_acceptance;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateDetails({
        email: state.email,
        terms_acceptance: state.terms_acceptance,
        currency: 'BTC',
        // receive_promos: true,
      });

      dispatch(setOpenTriggerModal(undefined));
    } catch (err) {
      console.error(err);
      dispatch(setOpenTriggerModal(undefined));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FieldGroup>
        <Field>
          <span className="text-sm text-text-subdued">
            {generalContent?.page_triggers?.modal?.update_info?.description}
          </span>
        </Field>
        <Field>
          <EmailInput
            quick={false}
            content={content}
            isError={isError}
            email={state?.email}
            errors={error?.errors}
            handleChange={handleChange}
          />
        </Field>
        <Field>
          <PrivacyTermsLabel
            state={state}
            setState={setState}
            inOverlay={null}
            quick={false}
          >
            {isError && handleError(error?.errors, 'profile') && (
              <Label htmlFor="terms_acceptance" className="text-text-critical">
                {handleError(error?.errors, 'profile')?.accepted}
              </Label>
            )}
          </PrivacyTermsLabel>
        </Field>
        <Field>
          <Button
            size="md"
            type="submit"
            className="w-full"
            isLoading={isPending}
            disabled={isButtonDisabled}
          >
            {generalContent?.page_triggers?.modal?.update_info?.btn_text}
          </Button>
        </Field>
      </FieldGroup>
    </form>
  );
};

export default UpdateInfoContent;
