import React from 'react';

interface NotificationIndicatorProps {
  icon: React.ReactNode;
}

/**
 * Renders an icon with a notification indicator dot
 */
const NotificationIndicator: React.FC<NotificationIndicatorProps> = ({
  icon,
}) => {
  return (
    <div className="relative">
      {icon}
      <span className="absolute right-0 top-0.5 h-1 w-1 rounded-full bg-base-critical" />
    </div>
  );
};

export default NotificationIndicator;
