import React from 'react';
import { useMemo } from 'react';
import { useAuth } from '@/hooks/auth';
import SectionItem from './section-item';
import { SectionProps, SidemenuItem } from '../types';

const SidebarSection: React.FC<SectionProps> = React.memo(
  ({
    items = [],
    isMobile,
    authOnly,
    isAccordion = false,
    closeSide,
    forGamesPage,
    ...props
  }) => {
    const {
      user: { currentUser, isAuthenticated, isLoading },
    } = useAuth();

    const filteredItems = useMemo(
      () =>
        items.filter((item: SidemenuItem) => {
          // If the item doesn't require authentication or user is authenticated, show it
          if (!item.authOnly || isAuthenticated) {
            return true;
          }
          return false;
        }),
      [items, isAuthenticated],
    );

    // Don't render anything if section requires auth and user is not authenticated
    if (authOnly && !isAuthenticated) return null;
    // Don't render anything if there are no items after filtering
    if (filteredItems.length === 0) return null;

    return filteredItems.map((menuItem: SidemenuItem) => {
      return (
        <SectionItem
          forGamesPage={forGamesPage}
          key={`sidemenu-item-${menuItem.id}`}
          item={menuItem}
        />
      );
    });
  },
);

SidebarSection.displayName = 'SidebarSection';

export default SidebarSection;
