'use client';
import React, { useEffect, useState } from 'react';

import { Button, Modal } from '@purposeinplay/core-v2';
import Image from 'next/image';

import { useTranslation } from '@/app/i18n/client';
import { ComponentContentMarkdown, usePagesQuery } from '@/generated';
import {
  DEFAULT_CONTENT_STALE_TIME,
  DEFAULT_IMAGE_LOADER,
} from '@/utils/constants';
import { extractTranslations } from '@/utils/content';
import { processMarkdown } from '@/utils/helpers';

const CookiesNotice = React.memo(() => {
  const { i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [cookieNotAccepted, setCookieNotAccepted] = useState(false);
  const { data: privacyPage } = usePagesQuery(
    { path: '/privacy' },
    {
      queryKey: ['pages', '/privacy'],
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );
  const privacyContent = privacyPage?.pages?.data[0]?.attributes;
  const privacy = extractTranslations(i18n.language, privacyContent);
  const privacyMkContent = privacy?.content?.find(
    (content: any) => content?.__typename === 'ComponentContentMarkdown',
  ) as unknown as ComponentContentMarkdown;

  const handleClick = () => {
    localStorage.setItem('wild_cookies_accepted', JSON.stringify(true));
    setCookieNotAccepted(false);

    // Dispatch storage event for other components to detect the change
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: 'wild_cookies_accepted',
        newValue: JSON.stringify(true),
        storageArea: localStorage,
      }),
    );
  };

  useEffect(() => {
    const termsAccepted = localStorage.getItem('wild_cookies_accepted');
    if (termsAccepted === 'true') {
      setCookieNotAccepted(false);
    } else {
      setCookieNotAccepted(true);
    }
  }, []);

  return (
    cookieNotAccepted && (
      <>
        <div
          style={{ zIndex: 100 }}
          className="safe-bottom-margin fixed bottom-16 flex w-full justify-center text-sm text-text-default md:bottom-24 lg:bottom-8 lg:left-1/2 lg:max-w-lg lg:-translate-x-1/2"
        >
          <div className="flex w-full items-center justify-start gap-spacing-xs bg-bgr-lightest p-3 md:max-w-lg md:rounded-xl">
            <Image
              width={20}
              height={20}
              src="/assets/cookies.svg"
              alt="Cookies image"
              loader={DEFAULT_IMAGE_LOADER}
            />
            <div className="text-white">
              <span className="font-medium ">Wild.io uses cookies.</span> See{' '}
              <span
                onClick={() => setModalOpen(true)}
                className="cursor-pointer underline"
              >
                Cookie Notice
              </span>{' '}
              for details.
            </div>
            <Button
              size="sm"
              className="ml-auto shrink-0 whitespace-nowrap"
              stroke
              onClick={handleClick}
              ghost
              color="tertiary"
            >
              Accept all
            </Button>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          title="Privacy Policy"
          size="3xl"
        >
          {privacy && privacyMkContent && (
            <div className="markdown-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: processMarkdown(privacyMkContent?.Markdown as string),
                }}
              />
            </div>
          )}
        </Modal>
      </>
    )
  );
});

CookiesNotice.displayName = 'CookiesNotice';

export default CookiesNotice;
