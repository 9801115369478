import { useQuery } from '@tanstack/react-query';
import client from '../utils/api';
import { useAuth } from './auth';

export const useGetExchangeRatesMoney = () => {
  return useQuery({
    queryKey: ['playerCompPointsRateMoney'],
    queryFn: () => client('comp_points/rates/money'),
  });
};

export const useGetExchangeRatesFS = () => {
  return useQuery({
    queryKey: ['playerCompPointsRateFreespins'],
    queryFn: () => client('comp_points/rates/freespins'),
  });
};

export const useGetExchangeRatesLootbox = () => {
  return useQuery({
    queryKey: ['playerCompPointsRateLootbox'],
    queryFn: () => client('comp_points/rates/lootboxes'),
  });
};

export const useGetExchangeRatesLotteries = () => {
  const {
    user: { isAuthenticated },
  } = useAuth();
  return useQuery({
    queryKey: ['playerCompPointsRateLotteries'],
    queryFn: () => client('comp_points/rates/lotteries'),
    enabled: isAuthenticated,
  });
};
