import { usePagesQuery } from '@/generated';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import dayjs from 'dayjs';
import { usePathname } from 'next/navigation';
import { useMapContent } from './use-map-content';
import { useMemo } from 'react';

const useIsCurrentMap = () => {
  const pathname = usePathname();
  const { data: allPages } = usePagesQuery(undefined, {
    staleTime: DEFAULT_CONTENT_STALE_TIME,
    queryKey: ['available-maps'],
  });

  const availableMaps = allPages?.pages?.data.filter(
    (page) => page.attributes?.available_for,
  );

  const currentDate = dayjs().utc();

  // ADD {test: true} to the available_for object to test the map before date
  const testMap = availableMaps?.find(
    (map) => map.attributes?.available_for?.test,
  );

  const currentMap = testMap
    ? testMap
    : availableMaps?.find(
        (map) =>
          Number(map.attributes?.available_for?.year) === currentDate.year() &&
          Number(map.attributes?.available_for?.month) ===
            currentDate.month() + 1,
      );

  const mapName = currentMap?.attributes?.path?.replace('/', '');
  const { mapItems } = useMapContent();
  const activeMapDetails = useMemo(() => {
    return mapItems?.find((item) => item.identifier === mapName)?.items[0];
  }, [mapItems, mapName]);

  return {
    isCurrentMap: currentMap?.attributes?.path.includes(pathname),
    currentMap,
    activeMapDetails,
  };
};

export default useIsCurrentMap;
