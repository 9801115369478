import EmptyState from '@/components/EmptyState';

export const ChallengesNoResults: React.FC<{ description: string }> = ({
  description,
}) => (
  <EmptyState
    iconName={'target'}
    title={'No results'}
    description={description}
  />
);
