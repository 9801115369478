'use client';
import { useEffect, useRef, useState, useMemo } from 'react';
import { scrollToChatBottom } from '../utils';
import type { ChatMessage } from '@/app/providers/chat';
import { usePlayerApiSettings } from './use-get-chat-settings';

export function useChatScroll(messages: ChatMessage[]) {
  const { data: chatPlayerSettings, isLoading: areSettingsLoading } =
    usePlayerApiSettings();

  const currentUserChatName = useMemo(() => {
    const chatName = chatPlayerSettings?.current_user?.chat_name;
    if (!chatName) return '';
    return chatName;
  }, [chatPlayerSettings?.current_user?.chat_name]);

  // Tracks if the user is near the bottom
  const wasNearBottom = useRef(true);

  // Tracks the number of new messages that arrived while the user wasn't at bottom
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  // Stores the previous total messages count to detect how many new messages arrived
  const prevMessagesCount = useRef(messages.length);

  // 1) Attach scroll listener & check scroll position
  useEffect(() => {
    const container = document.getElementById('chat-body-container');
    if (!container) return;

    const threshold = 50;

    const checkPosition = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const nearBottom = scrollTop + clientHeight >= scrollHeight - threshold;

      if (nearBottom) {
        wasNearBottom.current = true;
        setNewMessagesCount(0); // Reset new messages if user scrolls to bottom
      } else {
        wasNearBottom.current = false;
      }
    };

    container.addEventListener('scroll', checkPosition);

    // Check initial position on mount
    checkPosition();

    return () => container.removeEventListener('scroll', checkPosition);
  }, []);

  // Compute if the last message was sent by the current user.
  // This uses useMemo so that the value is recalculated only when its dependencies change.
  const lastMessageFromCurrentUser = useMemo(() => {
    if (areSettingsLoading || !currentUserChatName || messages?.length === 0) {
      return false;
    }
    const lastMessage = messages[messages.length - 1];
    return lastMessage.author.chat_name === currentUserChatName;
  }, [areSettingsLoading, currentUserChatName, messages]);

  // 2) Detect new messages
  useEffect(() => {
    const currentCount = messages.length;
    const previousCount = prevMessagesCount.current;
    const newMessagesArrived = currentCount - previousCount;

    if (newMessagesArrived > 0) {
      // If the last message is from the current user, do not scroll or increment counter.
      if (lastMessageFromCurrentUser) {
        prevMessagesCount.current = currentCount;
        return;
      }

      if (wasNearBottom.current) {
        // Automatically scroll if the user is near the bottom
        scrollToChatBottom();
        setNewMessagesCount(0);
        return;
      }
      // Accumulate the new messages count
      setNewMessagesCount((prev) => prev + newMessagesArrived);
    }

    // Update previous count
    prevMessagesCount.current = currentCount;
  }, [messages, lastMessageFromCurrentUser]);

  // 3) Utility to scroll & reset unseen messages counter
  const handleGoToBottom = () => {
    scrollToChatBottom();
    setNewMessagesCount(0);
  };

  return {
    newMessagesCount,
    handleGoToBottom,
  };
}
