'use client';
import React, { createContext, useContext, useState } from 'react';
import { GameSectionType } from '@/modules/layout/sidebar/types';
import { useGamesContent } from '@/hooks/use-games-content';

export type BottomDrawerSection =
  | 'notification'
  | 'favorites'
  | 'more'
  | 'new'
  | 'popular'
  | null;

interface GamePageBottomDrawerContextType {
  activeSection: BottomDrawerSection;
  setActiveSection: (section: BottomDrawerSection) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedGameCat: GameSectionType | null;
  setSelectedGameCat: (category: GameSectionType | null) => void;
  selectedGameTitle: string | null;
  setSelectedGameTitle: (title: string | null) => void;
}

const GamePageBottomDrawerContext =
  createContext<GamePageBottomDrawerContextType | null>(null);

export const useGamePageBottomDrawer = () => {
  const context = useContext(GamePageBottomDrawerContext);

  if (!context) {
    throw new Error('useGamePageBottomDrawer should be used in a provider');
  }
  return context;
};

interface GamePageBottomDrawerProviderProps {
  children: React.ReactNode;
}

export const GamePageBottomDrawerProvider: React.FC<
  GamePageBottomDrawerProviderProps
> = ({ children }) => {
  const [activeSection, setActiveSection] = useState<BottomDrawerSection>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGameCat, setSelectedGameCat] =
    useState<GameSectionType | null>(null);
  const [selectedGameTitle, setSelectedGameTitle] = useState<string | null>(
    null,
  );

  return (
    <GamePageBottomDrawerContext.Provider
      value={{
        activeSection,
        setActiveSection,
        isOpen,
        setIsOpen,
        selectedGameCat,
        setSelectedGameCat,
        selectedGameTitle,
        setSelectedGameTitle,
      }}
    >
      {children}
    </GamePageBottomDrawerContext.Provider>
  );
};
