import { useTranslation } from '@/app/i18n/client';
import { WheelContentType } from '@/types/json-content/wheel';
import { Button, Input, Text } from '@purposeinplay/core-v2';
import React from 'react';
import { WofButtonProps } from '../types';

export const WofControls = ({
  lootboxesFiltered,
  hasLootbox,
  isWheelAnimating,
  setMakeCall,
  hasAvailableIn,
}: WofButtonProps) => {
  const { t } = useTranslation();
  const wheelContent = t('wheel') as WheelContentType;
  return (
    <div className="mx-auto flex w-2/3 flex-col items-center justify-center space-x-1">
      <Text size="sm" className="mb-spacing-xs font-medium text-text-default">
        {wheelContent?.action?.available}
      </Text>
      <div
        data-theme="dark"
        className="flex flex-row items-center space-x-1 !bg-transparent [&>div>div>div>div>input]:!ring-gray-600/50"
      >
        {lootboxesFiltered?.length !== 0 && (
          <Input
            variant="static"
            inputSize="md"
            disabled
            value={lootboxesFiltered?.length}
          />
        )}

        <Button
          color="primary"
          size="md"
          disabled={
            !hasLootbox || isWheelAnimating || (hasAvailableIn && !hasLootbox)
          }
          isLoading={isWheelAnimating}
          onClick={() => setMakeCall()}
          className="min-w-max"
        >
          {wheelContent?.action?.spin}
        </Button>
      </div>
    </div>
  );
};
