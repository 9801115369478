'use client';
import React, { Suspense } from 'react';
import Link from 'next/link';
import { useAuth } from '@/hooks/auth';
import GameOptionLinks from './components/game-option-links.tsx';
import RightNavGuest from './components/right-nav-guest.tsx';
import SearchField from './components/search-wrapper.tsx';
import useMediaQuery, { MD_BREAKPOINT } from '@/hooks/use-media-query';
import { DivLoader, RightNavLoader } from '@/components/Loaders';
import dynamic from 'next/dynamic';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import AdaptiveImage from '@/components/AdaptiveImage';
import { SearchModal } from '@/modules/search';
import useIsStandalone from '@/hooks/use-is-standalone';
import { cn } from '@/utils/style';
import useIsTelegram from '@/hooks/use-is-telegram';
import { ToggleButton } from '../sidebar/index.ts';
import useIsOnGamePage from '@/hooks/use-is-on-game-page.tsx';

const RightNav = dynamic(
  () => import('./components/right-nav.tsx').then((module) => module.default),
  {
    ssr: false,
  },
);

const Navbar = () => {
  const { user } = useAuth();
  const { matchesQuery: isMD, isLoading: mdLoading } =
    useMediaQuery(MD_BREAKPOINT);
  const { isStandalone } = useIsStandalone();
  const { isTelegram } = useIsTelegram();
  const isOnGamePage = useIsOnGamePage();

  if (isOnGamePage) {
    return null;
  }

  return (
    <header
      className={cn([
        'safe-top-padding z-[100] w-full bg-bgr-lightest',
        isStandalone || isTelegram
          ? 'relative md:fixed md:left-0 md:right-0 md:top-0'
          : 'fixed left-0 right-0 top-0',
      ])}
    >
      <div className="flex h-14 flex-row items-center  px-3 py-2 shadow-shadow-md lg:h-16">
        <>
          <Suspense>
            <SearchModal />
          </Suspense>
          <div className="flex items-center justify-center space-x-7">
            <div>
              <div className="flex shrink-0 items-center space-x-1">
                <Suspense>
                  <ToggleButton />
                </Suspense>

                <Link href="/">
                  <AdaptiveImage
                    alt="logo"
                    width={110}
                    priority
                    height={36}
                    fill={false}
                    loader={DEFAULT_IMAGE_LOADER}
                    sources={{
                      desktop: {
                        dark: '/assets/logo-dark-v2.svg',
                        light: '/assets/logo-v2.svg',
                      },
                    }}
                    className={cn([
                      user?.isAuthenticated ? 'hidden md:block' : 'block',
                    ])}
                  />

                  <AdaptiveImage
                    className={cn([
                      'md:hidden',
                      !user?.isAuthenticated && 'hidden',
                    ])}
                    alt="logo"
                    width={27}
                    priority
                    height={34}
                    fill={false}
                    loader={DEFAULT_IMAGE_LOADER}
                    sources={{
                      desktop: {
                        dark: '/assets/logo-symbol-v2.svg',
                        light: '/assets/logo-symbol-v2.svg',
                      },
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="hidden flex-row items-center md:flex">
              <Suspense>
                <GameOptionLinks inNavbar />
                {mdLoading ? (
                  <DivLoader className="ml-2 inline-block h-10 lg:w-[20.5rem]" />
                ) : (
                  isMD && <SearchField className="ml-2" />
                )}
              </Suspense>
            </div>
          </div>

          <Suspense fallback={<RightNavLoader />}>
            <div className="flex flex-1 items-center">
              {(!user || !user.currentUser || !user.currentUser.email) && (
                <RightNavGuest />
              )}
              {user && !user.isLoading && user?.currentUser?.email && (
                <RightNav />
              )}
            </div>
          </Suspense>
        </>
      </div>
    </header>
  );
};

export default Navbar;
