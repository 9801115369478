import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/DepositBonusPopUp/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/LayoutWrapper/app-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/LayoutWrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/components/Modals/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/PageTriggers/components/app-notice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/PageTriggers/components/cookies-notice.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/components/PageTriggers/index.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/modules/games/components/game-iframe/index.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/modules/layout/bottom-navigation/mobile-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/sidebar/components/featured-sidebar-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/sidebar/components/logout-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/sidebar/components/overlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/sidebar/components/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/modules/layout/sidebar/components/toggle-button.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/modules/wild-chat/index.tsx");
