import { useAuth } from './auth';
import { useMemo } from 'react';
import { useFliptBoolean } from './use-flipt';

type FeatureContext = {
  user_group: string;
};

type FeatureVisibilityResult = {
  shouldShow: boolean;
  isLoading: boolean;
  error: null;
};

/**
 * Hook that provides feature visibility control using Flipt
 * @param featureKey - The unique identifier of the feature flag
 * @returns Object containing shouldShow boolean indicating if feature should be visible
 */
const useFeatureVisibility = (featureKey: string): FeatureVisibilityResult => {
  const { user } = useAuth();

  // Remove this if groups are needed for access
  // const hasAccess = useMemo(() => {
  //   if (!user?.currentUser?.statuses) return false;
  //   return user.currentUser.statuses.some(
  //     (status: any) =>
  //       status.id === 'chat:feature' || status.id === 'chat-manual:feature',
  //   );
  // }, [user?.currentUser?.statuses]);

  const hasAccess = true;

  const contextAttributes = useMemo((): FeatureContext | undefined => {
    if (!hasAccess) return undefined;
    return {
      user_group: 'chat-group',
    };
  }, [hasAccess]);

  const isDataReady = Boolean(
    contextAttributes && !user?.isLoading && !user?.isFetching && hasAccess,
  );

  const isEnabled = useFliptBoolean(
    featureKey,
    false,
    'default',
    isDataReady ? contextAttributes : undefined,
  );

  return {
    shouldShow: isDataReady && isEnabled,
    isLoading: user?.isLoading || user?.isFetching || !isDataReady,
    error: null,
  };
};

export default useFeatureVisibility;
