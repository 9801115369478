import { cn } from '@/utils/style';
import { Badge, Heading } from '@purposeinplay/core-v2';
import React from 'react';

interface Props {
  tagStatus?:
    | 'neutral'
    | 'error'
    | 'warning'
    | 'success'
    | 'information'
    | 'highlight'
    | 'purple';
  tagLabel?: string;
  description?: string;
  className?: string;
}

const InfoStatus = ({ tagStatus, tagLabel, description, className }: Props) => {
  return (
    <div className={cn(['flex flex-row items-center', className && className])}>
      <Heading as="h2" className="mr-1 text-sm font-bold">
        {description}
      </Heading>
      <Badge type={tagStatus} ghost size="md">
        {tagLabel}
      </Badge>
    </div>
  );
};

export default InfoStatus;
