'use client';
import React from 'react';
import { useAuth } from '@/hooks/auth';
import { Button } from '@purposeinplay/core-v2';
import { TournamentsPageProps } from '../types';
import { CardButtonProps } from '../types';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import Link from 'next/link';
import useCardButtonsState from '../hooks/use-card-buttons-state';
import CardButtonsLoader from './card-buttons-loader';
import { usePathname } from 'next/navigation';

const TournamentCardButtons = ({
  forDynamicRoute,
  tournament,
  className,
  isForSidebar = false,
}: CardButtonProps) => {
  const {
    tournamentJoinable,
    handleEnroll,
    buttonText,
    isLoading,
    isAlreadyEnrolled,
    playerTournamentsLoading,
  } = useCardButtonsState(tournament);

  const {
    user: { isLoading: isAuthLoading, isAuthenticated },
  } = useAuth();

  const pathname = usePathname();

  const { t } = useTranslation();
  const tournamentContent = t('tournaments') as TournamentsPageProps;

  if (!tournament || !tournament?.id) {
    return null;
  }

  if (isAuthLoading || playerTournamentsLoading) {
    return <CardButtonsLoader isForSidebar={isForSidebar} />;
  }

  return (
    <>
      <div
        className={cn([
          'flex justify-between space-x-2.5',
          forDynamicRoute && 'flex w-full justify-start',
          className,
        ])}
      >
        {!isAuthenticated && (
          <Link className="w-full" href={`${pathname}/?m=signup`}>
            <Button linkHref="/signup" size="sm" className={'w-full'}>
              Sign Up
            </Button>
          </Link>
        )}
        {tournamentJoinable && (
          <Button
            size={forDynamicRoute ? 'sm' : 'md'}
            disabled={isAlreadyEnrolled}
            isLoading={isLoading}
            onClick={handleEnroll}
            className={'w-full'}
          >
            {buttonText}
          </Button>
        )}
        {!forDynamicRoute && (
          <Link
            href={`/tournaments/${tournament?.frontend_identifier}-${tournament?.id}`}
            passHref
            prefetch={true}
            className="w-full"
          >
            <Button
              linkHref={`/tournaments/${tournament?.frontend_identifier}-${tournament?.id}`}
              color="tertiary"
              className={cn([
                'whitespace-nowrap',
                !forDynamicRoute && `w-full`,
              ])}
            >
              {tournamentContent['actions'].view_leaderboard}
            </Button>
          </Link>
        )}
      </div>
    </>
  );
};

export default TournamentCardButtons;
