import { useAppSelector } from '@/hooks/redux';
import React from 'react';
import BonusSelectBody from './bonus-select-body';
import BonusSelectionDetails from './bonus-preview/bonus-selection-details';

const BonusSelectionRenderer = React.memo(
  ({ isInBonusesPage }: { isInBonusesPage?: boolean }) => {
    const bonusSelectionDynamicContent = useAppSelector(
      (state) => state.bootstrap.bonusSelctionDynamicContent,
    );

    switch (bonusSelectionDynamicContent.isOpen) {
      case 'details':
        return <BonusSelectionDetails />;
      case 'default':
        return <BonusSelectBody isInBonusesPage={isInBonusesPage} />;
      default:
        return <BonusSelectBody />;
    }
  },
);

BonusSelectionRenderer.displayName = 'BonusSelectionRenderer';

export default BonusSelectionRenderer;
