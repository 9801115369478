import React, { memo } from 'react';
import { useMemo } from 'react';
import { Challenge, RewardResult } from '../types';
import { useAccounts } from '@/hooks/accounts';
import { useCurrencies } from '@/hooks/currencies';
import { useAppSelector } from '@/hooks/redux';
import { useFiatFromCrypto } from '@/hooks/fiat';
import { getPrecision, subunitsToUnit } from '@/utils/money';
import { useAuth } from '@/hooks/auth';
import CryptoTooltip from '@/components/CryptoTooltip';
import FiatAmount from '@/components/FiatAmount';
import CryptoAmount from '@/components/CryptoAmount';

const Multiplier = memo(
  ({ goal }: { goal: Challenge | RewardResult | undefined }) => {
    const { account: currentAccount } = useAccounts();
    const { data: currencies } = useCurrencies();
    const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);

    const foundCurrency = useMemo(
      () =>
        currencies.find(
          (currencyItem) => currencyItem.code === currentAccount?.currency,
        ),
      [currencies, currentAccount?.currency],
    );

    const fiatAmount = useFiatFromCrypto(
      foundCurrency?.code,
      goal?.min_bets?.[currentAccount?.currency],
    );

    const currency = currentAccount?.currency;
    const minBets = goal?.min_bets;
    const precision = getPrecision(subunitsToUnit[currency]);

    const getSumToMultiply = useMemo(() => {
      if (minBets?.[currency]) {
        return (
          <CryptoTooltip
            cryptoAmount={
              viewFiat ? (
                <FiatAmount
                  removeIcon
                  fiatAmount={fiatAmount}
                  currency={currency}
                />
              ) : (
                <div className="flex flex-row">
                  <CryptoAmount
                    removeIcon
                    balance={minBets[currency].toFixed(precision)}
                    currency={currency}
                  />
                </div>
              )
            }
            cryptoCode={foundCurrency?.code}
            className="flex items-center space-x-1"
          >
            <CryptoAmount
              balance={minBets[currency].toFixed(precision)}
              currency={currency}
            />
          </CryptoTooltip>
        );
      }
    }, [
      minBets,
      currency,
      viewFiat,
      fiatAmount,
      precision,
      foundCurrency?.code,
    ]);

    const {
      user: { isAuthenticated },
    } = useAuth();

    return (
      <span className="text-primary">
        {getSumToMultiply ? (
          <div className="inline-flex text-base-primary">
            {getSumToMultiply}{' '}
          </div>
        ) : isAuthenticated ? (
          'N/A for this currency'
        ) : (
          <CryptoAmount
            balance={minBets['BTC']?.toFixed(
              getPrecision(subunitsToUnit['BTC']),
            )}
            currency="BTC"
          />
        )}
      </span>
    );
  },
);

Multiplier.displayName = 'Multiplier';

export default Multiplier;
