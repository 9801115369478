import React, { useCallback, useMemo } from 'react';
import { useAuth } from '@/hooks/auth';
import { useTranslation } from 'react-i18next';
import { SelectBonusCard } from './select-bonus-card';
import CarouselWithGradient from '@/components/Carousels/components/carousel-with-gradient';
import {
  PreviewBonusesContentType,
  PreviewBonus,
} from '@/types/json-content/preview_bonuses';
import { useDepositPreviewBonuses } from '@/hooks/bonuses';
import { DivLoader } from '@/components/Loaders';
import { cn } from '@/utils/style';
import { usePlayerStats } from '@/hooks/others';

const getReqDepositsToDisplay = (id: string) => {
  switch (id) {
    case 'first_deposit':
      return 0;
    case 'second_deposit':
      return 1;
    case 'third_deposit':
      return 2;
    default:
      return -1; // Return -1 for unknown IDs to indicate invalid cases
  }
};

const SelectBonusList = ({
  isInBonusesPage,
}: {
  isInBonusesPage?: boolean;
}) => {
  const { user } = useAuth();

  const { t } = useTranslation();
  const content = t('preview_bonuses') as PreviewBonusesContentType;
  const selectBonusCards = content?.bonus_selection_modal?.preview_bonuses;

  const promoCode = useMemo(
    () => user?.currentUser?.deposit_bonus_code,
    [user],
  );
  const { data: playerStats, isLoading: playerStatsLoading } = usePlayerStats();
  const showAllBonuses = useMemo(() => {
    if (playerStatsLoading || !playerStats) return false;
    return playerStats?.deposits_count > 0 ? false : true;
  }, [playerStats, playerStatsLoading]);

  const depositCount = playerStats?.deposits_count;

  const { data: bonusesPreview, isLoading: bonusesPreviewLoading } =
    useDepositPreviewBonuses();

  const currentDeposit = useMemo(() => {
    const firstDepositBonus = selectBonusCards?.find(
      (item) => item?.id === 'first_deposit',
    ) as PreviewBonus;
    if (depositCount === 0) {
      // If no deposits yet, return the first deposit bonus
      return firstDepositBonus;
    }

    // Filter deposit bonuses and find the next eligible one
    return (
      selectBonusCards
        ?.filter((item) => item?.id?.includes('deposit'))
        ?.find((item) => {
          const requiredDeposits = getReqDepositsToDisplay(item?.id);
          // Ensure requiredDeposits is valid and matches the next deposit
          return requiredDeposits !== -1 && depositCount === requiredDeposits;
        }) || firstDepositBonus
    );
  }, [selectBonusCards, depositCount]);

  const selectItems = useMemo(() => {
    if (bonusesPreviewLoading) return [];
    const currentPreviewId = bonusesPreview && bonusesPreview[0]?.id;
    if (promoCode === 'EXCLUSIVE') {
      return selectBonusCards.filter((item) => !item.id.includes('deposit'));
    }
    const welcomeBonus = selectBonusCards
      ?.filter((item) => item?.id?.includes('deposit'))
      ?.find((item) => currentPreviewId?.includes(item?.id));

    if (!showAllBonuses && welcomeBonus) {
      return [welcomeBonus];
    }

    const restBonuses = selectBonusCards?.filter(
      (item) =>
        item?.id !== welcomeBonus?.id &&
        !item?.id?.includes('deposit') &&
        !item?.id?.includes('exclusive') &&
        depositCount === 0,
    );

    if (!welcomeBonus) {
      return [currentDeposit, ...restBonuses];
    }

    return [welcomeBonus, ...restBonuses];
  }, [
    bonusesPreview,
    bonusesPreviewLoading,
    currentDeposit,
    promoCode,
    selectBonusCards,
    showAllBonuses,
    depositCount,
  ]);

  if (bonusesPreviewLoading) {
    return (
      <CarouselWithGradient
        itemsWrapperStyles={cn([isInBonusesPage && 'lg:!grid grid-cols-4'])}
        space="8"
        items={Array.from({ length: 4 }).map((_, index) => {
          return (
            <DivLoader
              key={`preview-loader-${index}`}
              className={cn([
                'relative flex h-[232px] w-[270px] flex-col justify-between gap-spacing-md rounded-2xl bg-bgr-lightest p-3 lg:w-full ',
                !isInBonusesPage &&
                  '!w-[270px] max-w-[16.688rem] md:max-w-[16.875rem] lg:!w-[270px]',
              ])}
            ></DivLoader>
          );
        })}
        slideWidth={0}
      />
    );
  }

  return (
    <div
      className={cn([
        !showAllBonuses && 'pt-7',
        isInBonusesPage && !showAllBonuses && 'grid-cols-4 lg:!grid',
        !isInBonusesPage && !showAllBonuses && 'md:max-w-max',
      ])}
    >
      {!showAllBonuses ? (
        <SelectBonusCard
          isInBonusesPage={isInBonusesPage}
          key={selectItems[0]?.id}
          bonus={selectItems[0]}
          index={0}
          totalItems={selectItems.length}
          widthFull={true}
        />
      ) : (
        <CarouselWithGradient
          itemsWrapperStyles={cn([
            'pt-8',
            isInBonusesPage && 'lg:!grid grid-cols-4',
          ])}
          space="8"
          items={selectItems.map((bonus, index) => (
            <SelectBonusCard
              isInBonusesPage={isInBonusesPage}
              key={bonus?.id}
              bonus={bonus}
              index={index}
              totalItems={selectItems.length}
            />
          ))}
          slideWidth={0}
        />
      )}
    </div>
  );
};

export default SelectBonusList;
