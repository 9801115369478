import React, { useEffect, useState } from 'react';
import { cn } from '@/utils/style';
import Image from 'next/image';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setOpenSidemenu } from '@/app/reducers/bootstrap';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { getHref } from '../utils';
import useLinkHandler from '../hooks/use-link-handler';
import WheelIcon from '@/modules/wheel-of-fortune/components/wheel-icon';
import { ActionLink } from '../types';
import Link from 'next/link';
import { useIsMounted } from '@purposeinplay/utils';

const ActionLinkTrigger: React.FC<{ link: ActionLink }> = ({ link }) => {
  const {
    icon,
    heading,
    text,
    opensModal,
    textColor,
    backgroundColor,
    hoverColor,
  } = link;

  const { matchesQuery: isDeviceMobile } = useMediaQuery(MOBILE_BREAKPOINT);
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );
  const isMounted = useIsMounted();

  const [style, setStyle] = useState({
    background: backgroundColor,
  });

  const dispatch = useAppDispatch();

  const closeSidemenuIfMobile = () => {
    if (isDeviceMobile) dispatch(setOpenSidemenu(false));
  };

  const handleClick = useLinkHandler({ link, closeSidemenuIfMobile });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="w-full">
      <Link
        // Adds href when server side
        href={getHref(opensModal)}
        tabIndex={0}
        onMouseEnter={() => setStyle({ background: hoverColor })}
        onMouseLeave={() => setStyle({ background: backgroundColor })}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        style={style}
        className={cn([
          'flex min-h-10 w-full cursor-pointer items-center rounded-radius-sm border-none p-spacing-xs outline-none ease-in-out focus:outline-none xl:gap-1.5 xl:px-padding-md xl:py-padding-lg',
          !isSidemenuOpen && isMounted && 'p-0',
          isSidemenuOpen && isMounted && 'gap-2 px-padding-md py-padding-lg',
        ])}
      >
        <span
          className={cn([
            'relative mx-auto flex h-7 min-w-7 items-center justify-center xl:mx-0',
            !isSidemenuOpen && isMounted && 'xl:mx-auto',
            isSidemenuOpen && isMounted && 'mx-0',
          ])}
        >
          {heading === 'Wheel' ? (
            <WheelIcon />
          ) : typeof icon === 'string' ? (
            <Image
              alt={`Sidebar action link ${heading} ${text}`}
              loading="eager"
              className="object-contain"
              fill
              src={icon}
            />
          ) : (
            icon
          )}
        </span>
        <span
          className={cn([
            'hidden space-x-1 text-left text-xs uppercase leading-none md:flex-col md:space-x-0 md:text-[11px] xl:flex xl:flex-col',
            !isSidemenuOpen && isMounted && 'xl:hidden xl:space-x-1',
            isSidemenuOpen &&
              isMounted &&
              'flex flex-col gap-1 space-x-0 md:gap-0',
          ])}
        >
          <span
            className="font-medium"
            style={{
              color: textColor,
            }}
          >
            {heading}
          </span>
          <span className="whitespace-nowrap font-medium text-text-default">
            {text}
          </span>
        </span>
      </Link>
    </div>
  );
};

export default ActionLinkTrigger;
