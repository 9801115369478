import { IconButton } from '@purposeinplay/core-v2';
import { Heading } from '@purposeinplay/core-v2';
import React, { memo } from 'react';
import { useGamePageBottomDrawer } from '../context/game-page-bottom-drawer-context';
import CloseSVG from 'public/assets/close.svg';
import { cn } from '@/utils/style';

// Using memo to prevent unnecessary re-renders
const DrawerContentLayout = memo(
  ({ title = '', children }: { title?: string; children: React.ReactNode }) => {
    const { setIsOpen } = useGamePageBottomDrawer();

    const handleClose = () => {
      setIsOpen(false);
    };

    return (
      <div className="scrollbar -mx-3 h-full max-h-[70vh] px-3">{children}</div>
    );
  },
);

// Add display name for better debugging
DrawerContentLayout.displayName = 'DrawerContentLayout';

export default DrawerContentLayout;
