import { useAppSelector } from '@/hooks/redux';
import { cn } from '@/utils/style';
import { useIsMounted } from '@purposeinplay/utils';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ElementsContainer: React.FC<Props> = ({ className, children }) => {
  const isOpen = useAppSelector((state) => state.bootstrap.isSidemenuOpen);

  const isMounted = useIsMounted();

  return (
    <div
      className={cn([
        'relative flex flex-col bg-bgr-lighter px-1.5 py-padding-xl xl:px-3',
        !isOpen && isMounted && 'xl:px-1.5',
        isOpen && isMounted && 'px-3',
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default ElementsContainer;
