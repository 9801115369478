import React from 'react';
import InfoStatus from '../info-status';
import { SessionLimit, UserLimit } from '@/types/user';

interface ActiveLimitProps {
  filteredLimit: SessionLimit | UserLimit;
  className?: string;
}

const ActiveLimit = ({ filteredLimit, className }: ActiveLimitProps) => {
  return (
    <InfoStatus
      tagLabel="Active"
      tagStatus="success"
      description={`${filteredLimit?.period} minutes`}
      className={className && className}
    />
  );
};

export default ActiveLimit;
