export const getTitleByDepositCount = (depositCount: number, content: any) => {
  switch (depositCount) {
    case 0:
      return content?.find((item: any) => item?.id === 'first_deposit')
        ?.description;
    case 1:
      return content?.find((item: any) => item?.id === 'second_deposit')
        ?.description;
    case 2:
      return content?.find((item: any) => item?.id === 'third_deposit')
        ?.description;
    default:
      return null;
  }
};
