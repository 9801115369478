export function formatCompoints(num: number) {
  if (num < 1000) {
    return num.toString();
  }

  const thousands = Math.floor(num / 1000);
  const remainder = num % 1000;

  if (remainder === 0) {
    return `${thousands}k`;
  }

  const decimal = Math.floor(remainder / 100) / 10;
  return `${thousands},${decimal}k`;
}
