import { useAuth } from '@/hooks/auth';
import {
  useEnrollPlayer,
  usePlayerTournaments,
} from '@/hooks/tournaments/tournaments';
import React, { useCallback, useMemo } from 'react';
import { PlayerTournament, Tournament, TournamentsPageProps } from '../types';
import { useTranslation } from '@/app/i18n/client';

const useCardButtonsState = (tournament: Tournament) => {
  const {
    user: { isAuthenticated },
  } = useAuth();
  const { mutateAsync: enrollPlayer, isPending: isLoading } = useEnrollPlayer();
  const { t } = useTranslation();
  const tournamentContent = t('tournaments') as TournamentsPageProps;

  const tournamentJoinable = useMemo(
    () =>
      tournament?.user_confirmation_required &&
      isAuthenticated &&
      tournament?.in_progress,
    [
      isAuthenticated,
      tournament?.in_progress,
      tournament?.user_confirmation_required,
    ],
  );

  const { data: playerTournaments = [], isFetching: playerTournamentsLoading } =
    usePlayerTournaments();

  const alreadyEnrolled = useMemo(() => {
    if (playerTournamentsLoading) return;
    const foundTournament = playerTournaments?.find(
      (playerTournament: PlayerTournament) =>
        playerTournament?.tournament_id === tournament?.id,
    );
    return foundTournament?.user_confirmed;
  }, [tournament, playerTournaments, playerTournamentsLoading]);

  const handleEnroll = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (alreadyEnrolled) return;
      enrollPlayer({
        tournamentId: tournament?.id,
        enroll: !alreadyEnrolled,
      });
    },
    [alreadyEnrolled, enrollPlayer, tournament?.id],
  );

  const isAlreadyEnrolled = Boolean(alreadyEnrolled);

  const buttonText = isAlreadyEnrolled
    ? tournamentContent['actions'].enrolled
    : isLoading
      ? tournamentContent['actions'].loading
      : tournamentContent['actions'].enroll;

  return {
    tournamentJoinable,
    handleEnroll,
    buttonText,
    isLoading,
    isAlreadyEnrolled,
    playerTournamentsLoading,
  };
};

export default useCardButtonsState;
