import { useAvatar } from '@/hooks/avatar';
import { useAppSelector } from '@/hooks/redux';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import Image from 'next/image';
import React, { useMemo, useState } from 'react';

const ChatBubbleAvatar = ({
  isCurrentUser = false,
  imageSrc,
  isAdmin = false,
}: {
  isCurrentUser?: boolean;
  imageSrc?: string;
  isAdmin?: boolean;
}) => {
  const [isImgError, setIsImgError] = useState(false);
  const { data } = useAvatar();
  const savedAvatar = useAppSelector((state) => state.bootstrap.savedAvatar);
  const userAvatar = useMemo(
    () => (savedAvatar ? savedAvatar : data?.avatar),
    [savedAvatar, data],
  );

  const handleImageError = () => setIsImgError(true);

  if (isAdmin) {
    return (
      <div className="relative">
        <Image
          loader={DEFAULT_IMAGE_LOADER}
          src={isImgError ? DEFAULT_IMAGE_SRC : `/assets/chat/avatar_admin.png`}
          width={32}
          height={32}
          alt="Admin Avatar"
          onError={handleImageError}
        />
        <Image
          loader={DEFAULT_IMAGE_LOADER}
          className={'absolute -bottom-1 -right-1'}
          src={`/assets/chat/badge_admin.png`}
          width={16}
          height={16}
          alt="Admin Avatar Star"
        />
      </div>
    );
  }

  if (isCurrentUser) {
    return (
      <Image
        className="shrink-0"
        src={
          isImgError ? DEFAULT_IMAGE_SRC : `assets/avatars/${userAvatar}.png`
        }
        width={32}
        height={32}
        alt="Current User Avatar"
        onError={handleImageError}
      />
    );
  }

  const avatarIndex = getAvatarNumber(imageSrc || '1') ?? 0;
  const presetNumber = avatarIndex > 8 ? 8 : avatarIndex;

  return (
    <Image
      className="shrink-0"
      src={
        isImgError
          ? DEFAULT_IMAGE_SRC
          : `assets/avatars/preset-${presetNumber}.png`
      }
      width={30}
      height={30}
      alt="User Avatar"
      onError={handleImageError}
    />
  );
};

export default ChatBubbleAvatar;

export function getAvatarNumber(avatarPath: string): number | null {
  const match = avatarPath.match(/(\d+)\.svg$/);
  return match ? parseInt(match[1], 10) : null;
}

const DEFAULT_IMAGE_SRC = 'assets/avatars/preset-1.png';
