import { useState, useCallback, useMemo, useEffect } from 'react';
import { useRecentGamesData } from '@/hooks/games';
import { useFavoriteGamesData } from '@/hooks/games/favorites';
import useGameStats from '@/modules/games/hooks/use-game-stats';
import { useAllWildCategories, useWildCategories } from '@/hooks/collections';
import { useGamesWithFilters } from '@/hooks/games/use-games-with-filters';
import { useIsMobile } from '@/modules/home/context/is-mobile';
import { GameSectionType, Collection } from '../types';

const BASE_INITIAL_GAMES_TO_SHOW = 9;
const MOBILE_EXTRA_GAMES = 5;
const BOTTOM_DRAWER_GAMES_TO_SHOW = 20; // Show at least 20 games in bottom drawer
const MAX_SUGGESTED_GAMES = 15;
const GAMES_PER_LOAD = 9;

export const useGameSection = (
  gameCat: GameSectionType,
  forBottomDrawer = false,
) => {
  const isUserAgentMobile = useIsMobile();

  // Determine initial number of games to show
  const initialGamesToShow = useMemo(() => {
    // For bottom drawer with specific categories, show at least 20 games
    if (
      forBottomDrawer &&
      (gameCat === 'recents' ||
        gameCat === 'favorites' ||
        gameCat === 'suggested')
    ) {
      return BOTTOM_DRAWER_GAMES_TO_SHOW;
    }

    // For bottom drawer with other categories, show at least BASE_INITIAL_GAMES_TO_SHOW
    if (forBottomDrawer) {
      return BASE_INITIAL_GAMES_TO_SHOW;
    }

    // Otherwise use default logic for sidebar
    return isUserAgentMobile
      ? BASE_INITIAL_GAMES_TO_SHOW + MOBILE_EXTRA_GAMES
      : BASE_INITIAL_GAMES_TO_SHOW;
  }, [isUserAgentMobile, forBottomDrawer, gameCat]);

  const [visibleGames, setVisibleGames] = useState(initialGamesToShow);
  const { data: collections } = useAllWildCategories();
  const gameCatUpdated = useMemo(() => {
    if (gameCat === 'suggested') {
      return 'top-games';
    }
    return gameCat;
  }, [gameCat]);

  // Track if we're currently fetching more games
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  // Update visibleGames if mobile status or drawer status changes
  useEffect(() => {
    setVisibleGames(initialGamesToShow);
  }, [isUserAgentMobile, forBottomDrawer, initialGamesToShow]);

  const { favoriteIdsAndIdentifiers } = useFavoriteGamesData();

  const foundCollection = useMemo(() => {
    return collections?.find((collection: Collection) =>
      collection.id.includes(gameCatUpdated),
    );
  }, [collections, gameCatUpdated]);

  const foundSuggestedGameNumber = useMemo(() => {
    return foundCollection?.games_count;
  }, [foundCollection?.games_count]);

  const isCategoryWithPagination = useMemo(() => {
    return (
      gameCat === 'suggested' ||
      gameCat === 'fresh-releases' ||
      gameCat === 'top-games' ||
      gameCat === 'popular'
    );
  }, [gameCat]);

  // Determine page size - use larger page size in bottom drawer
  const pageSize = useMemo(() => {
    if (forBottomDrawer && isCategoryWithPagination) {
      return BOTTOM_DRAWER_GAMES_TO_SHOW;
    }
    return GAMES_PER_LOAD;
  }, [forBottomDrawer, isCategoryWithPagination]);

  const {
    data: suggestedGamesData,
    isLoading: suggestedGamesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGamesWithFilters(
    {
      device: isUserAgentMobile ? 'mobile' : 'desktop',
      without_territorial_restrictions: true,
      page: 1,
      page_size: pageSize,
      filter: {
        categories: {
          identifiers: foundCollection?.id ? [foundCollection.id] : ['popular'],
        },
      },
    },
    {
      enabled: isCategoryWithPagination,
    },
  );

  const { completeGames: recentGames, recentLoading } = useRecentGamesData();

  const {
    completeGames: favoriteGames,
    completeGamesLoading: favoritesLoading,
  } = useFavoriteGamesData();

  const handleLoadMore = useCallback(() => {
    if (isCategoryWithPagination) {
      // For games fetched from API, fetch the next page
      fetchNextPage();
    } else {
      // For local games (favorites, recents), just show more from the existing array
      setIsFetchingMore(true);
      setVisibleGames((prev) => prev + GAMES_PER_LOAD);
      // Simulate loading for consistent UX
      setTimeout(() => {
        setIsFetchingMore(false);
      }, 300);
    }
  }, [isCategoryWithPagination, fetchNextPage]);

  const isLoading = useMemo(() => {
    switch (gameCat) {
      case 'recents':
        return recentLoading;
      case 'favorites':
        return favoritesLoading;
      case 'suggested':
      case 'popular':
      case 'fresh-releases':
      case 'top-games':
        return suggestedGamesLoading;
      default:
        return recentLoading || favoritesLoading || suggestedGamesLoading;
    }
  }, [gameCat, recentLoading, favoritesLoading, suggestedGamesLoading]);

  const updatedFavoriteGames = useMemo(() => {
    return favoriteGames?.map((game: any) => ({
      ...game,
      slug: favoriteIdsAndIdentifiers?.find(
        (g: any) => g.identifier === game.identifier,
      )?.slug,
    }));
  }, [favoriteGames, favoriteIdsAndIdentifiers]);

  const games = useMemo(() => {
    switch (gameCat) {
      case 'favorites':
        return updatedFavoriteGames;
      case 'recents':
        return recentGames;
      case 'suggested':
      case 'popular':
      case 'fresh-releases':
      case 'top-games': {
        // Flatten all pages of data to get the complete list
        if (!suggestedGamesData?.pages) return [];
        return suggestedGamesData.pages.flatMap((page) => page.data || []);
      }
      default:
        return [];
    }
  }, [gameCat, updatedFavoriteGames, recentGames, suggestedGamesData]);

  const totalGames = useMemo(() => {
    switch (gameCat) {
      case 'favorites':
        return favoriteGames?.length || 0;
      case 'recents':
        return recentGames?.length || 0;
      case 'suggested':
      case 'popular':
      case 'fresh-releases':
      case 'top-games':
        // Get total from the API pagination info if available
        if (suggestedGamesData?.pages && suggestedGamesData.pages.length > 0) {
          const lastPage =
            suggestedGamesData.pages[suggestedGamesData.pages.length - 1];
          if (lastPage?.pagination?.total_count) {
            return lastPage.pagination.total_count;
          }
        }
        return foundSuggestedGameNumber || 0;
      default:
        return 0;
    }
  }, [
    gameCat,
    favoriteGames,
    recentGames,
    foundSuggestedGameNumber,
    suggestedGamesData,
  ]);

  const shouldShowLoadMore = useMemo(() => {
    if (isCategoryWithPagination) {
      // For API-based categories, check if there's a next page available
      return hasNextPage === true;
    }
    // For local categories (favorites, recents), check if there are more games to show
    return totalGames > visibleGames;
  }, [isCategoryWithPagination, hasNextPage, totalGames, visibleGames]);

  const sectionTitle = useMemo(() => {
    switch (gameCat) {
      case 'favorites':
        return 'Favorites';
      case 'recents':
        return 'Recently Played';
      case 'suggested':
        return 'Popular Games';
      case 'popular':
        return 'Popular Games';
      case 'fresh-releases':
        return 'New Games';
      case 'top-games':
        return 'Top Games';
      default:
        return '';
    }
  }, [gameCat]);

  return {
    games,
    visibleGames: isCategoryWithPagination
      ? games.length
      : forBottomDrawer && (gameCat === 'recents' || gameCat === 'favorites')
        ? Math.min(games?.length || 0, BOTTOM_DRAWER_GAMES_TO_SHOW)
        : visibleGames,
    isLoading,
    totalGames,
    shouldShowLoadMore,
    handleLoadMore,
    sectionTitle,
    isLoadingMore: isCategoryWithPagination
      ? isFetchingNextPage
      : isFetchingMore,
  };
};
