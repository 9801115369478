import AdaptiveImage from '@/components/AdaptiveImage';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import Link from 'next/link';
import React, { useRef } from 'react';
import { cn } from '@/utils/style';
import { sidebarListWrapperStyles } from '../styles';
import useGamesSidebarItems from '../hooks/use-games-sidebar-items';
import SectionItem from './section-item';
import ScreenSelect from './screen-select';
import { useOnClickOutside } from '@purposeinplay/utils';
import { useGamePageSidebar } from '../context/game-page-sidebar-context';
import { useGamesContent } from '@/hooks/use-games-content';
import { useAuth } from '@/hooks/auth';
import SidemenuLinksLoader from './sidemenu-link-loaders';
import CloseSVG from 'public/assets/close.svg';
import { IconButton } from '@purposeinplay/core-v2';

const GamePageSidebarElements = () => {
  const { items: sidemenuItems, rawLinks: linkItems } = useGamesSidebarItems();
  const { setIsOpen, isOpen } = useGamePageSidebar();
  const { gamesContent } = useGamesContent();
  const navigation = gamesContent.game_sidebar.navigation;
  const containerRef = useRef(null);
  const {
    user: { isLoading, isAuthenticated },
  } = useAuth();

  // Find chat item from rawLinks
  const chatItem = linkItems?.find(
    (item) => item.identifier === 'chat' && item.displayFor === 'ALL',
  );

  // Prepare chat item data if available
  const chatItemData =
    chatItem && chatItem.items && chatItem.items[0]
      ? {
          id: chatItem.items[0].id,
          title: chatItem.items[0].title || '',
          icon:
            typeof chatItem.items[0].icon === 'string'
              ? chatItem.items[0].icon
              : '',
          authOnly: false,
          identifier: chatItem.identifier || undefined,
        }
      : null;

  return (
    <div id="gamepage-sidebar-container">
      <div className="absolute inset-0 flex flex-col justify-between gap-2">
        <div className="z-10 flex flex-col gap-2">
          <div className="flex flex-col gap-3">
            {isOpen ? (
              <div className="flex h-12 w-12 items-center justify-end">
                <IconButton
                  type="button"
                  size="sm"
                  onClick={() => setIsOpen(false)}
                  ghost
                  color="tertiary"
                  iconLeft={<CloseSVG />}
                  iconOnly
                />
              </div>
            ) : (
              <Link
                className="flex h-12 w-12 items-center justify-center"
                href="/"
              >
                <AdaptiveImage
                  alt={gamesContent.game_sidebar.logo.alt}
                  width={26}
                  priority
                  height={33}
                  fill={false}
                  loader={DEFAULT_IMAGE_LOADER}
                  sources={{
                    desktop: {
                      dark: '/assets/logo-symbol-v2.svg',
                      light: '/assets/logo-symbol-v2.svg',
                    },
                  }}
                />
              </Link>
            )}

            <ul className="flex flex-col items-center justify-center gap-2">
              {isLoading ? (
                <SidemenuLinksLoader />
              ) : (
                sidemenuItems?.links?.map((item, index) => (
                  <li
                    key={index}
                    className={cn([
                      sidebarListWrapperStyles,
                      'flex items-center justify-center [&>div]:flex [&>div]:w-full [&>div]:items-center [&>div]:justify-center',
                    ])}
                  >
                    {item}
                  </li>
                ))
              )}
              <ScreenSelect />
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {!isAuthenticated && sidemenuItems?.signinItem && (
            <ul className={cn([sidebarListWrapperStyles, '[&>div]:w-full'])}>
              <li className="flex w-full list-none items-center justify-center [&>div]:w-full">
                <SectionItem
                  forGamesPage
                  item={{
                    ...sidemenuItems.signinItem,
                    identifier:
                      sidemenuItems.signinItem.identifier || undefined,
                  }}
                />
              </li>
            </ul>
          )}

          {/* Chat Item */}
          {chatItemData && (
            <ul className={cn([sidebarListWrapperStyles, '[&>div]:w-full'])}>
              <li className="flex w-full list-none items-center justify-center [&>div]:w-full">
                <SectionItem forGamesPage item={chatItemData} />
              </li>
            </ul>
          )}

          <ul className={cn([sidebarListWrapperStyles, '[&>div]:w-full'])}>
            <li className="flex w-full list-none items-center justify-center [&>div]:w-full">
              <SectionItem
                forGamesPage
                item={{
                  id: gamesContent.game_sidebar.items.live_support.id,
                  title: gamesContent.game_sidebar.items.live_support.title,
                  icon: '/assets/sidemenu_live_support_v2.svg',
                  authOnly: false,
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GamePageSidebarElements;
