import { useFiatFromCents } from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import React from 'react';
import FiatAmount from '../FiatAmount';
import { cn } from '@/utils/style';
import CryptoAmount from '../CryptoAmount';

const CryptoValue = ({
  value,
  currency,
  forPrize = false,
  className,
}: {
  value: number;
  currency: string;
  forPrize?: boolean;
  className?: string;
}) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const fiat = useFiatFromCents(currency, value);
  const { formatCurrency } = useCurrencyFormatter();
  return viewFiat ? (
    forPrize ? (
      <FiatAmount
        className={cn(['flex items-center justify-end', className])}
        fiatAmount={typeof fiat === 'string' && (fiat as string)?.split('.')[0]}
        currency={currency?.toLowerCase()}
      />
    ) : (
      <FiatAmount
        className={className}
        fiatAmount={fiat}
        currency={currency.toLowerCase()}
      />
    )
  ) : (
    <CryptoAmount
      className={className}
      balance={formatCurrency(value, currency, { style: 'decimal' })}
      currency={currency}
    />
  );
};

export default CryptoValue;
