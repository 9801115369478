import React from 'react';
import { Modal } from '@purposeinplay/core-v2';

import ProfileLimits from '../profile-limits';
import useModal, { useCurrentModal } from '@/hooks/modal';

const LimitsModal = () => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'confirm_limit';

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      title="Profile Limits"
      setIsOpen={() => {
        close();
      }}
    >
      <ProfileLimits />
    </Modal>
  );
};

export default LimitsModal;
