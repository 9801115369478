import React from 'react';
import useShouldHideIFrame from '@/modules/games/hooks/use-should-hide-iframe';
import { cn } from '@/utils/style';
import { useAppSelector } from '@/hooks/redux';
import useIsOnGamePage from '@/hooks/use-is-on-game-page';

const IFrameContainer = ({ children }: { children: React.ReactNode }) => {
  const { isMinimized } = useShouldHideIFrame();
  const isSidemenuOpen = useAppSelector(
    (state) => state.bootstrap.isSidemenuOpen,
  );

  const isOnGamePage = useIsOnGamePage();

  return (
    <div
      className={cn([
        !isMinimized &&
          'relative  mb-7 transition-all duration-200 ease-in-out md:pl-[3.75rem]  lg:mb-12',
        !isMinimized && isSidemenuOpen && 'xl:pl-[16rem]',
        isOnGamePage && 'md:pl-[4.5rem] xl:pl-[4.5rem]',
      ])}
    >
      <div className={cn([!isMinimized && 'relative mx-auto  px-3 lg:px-8 '])}>
        {children}
      </div>
    </div>
  );
};

export default IFrameContainer;
