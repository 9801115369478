import { fetchContent } from '@/utils/content-api';
import React from 'react';
import { useTranslation } from '@/app/i18n/client';

export const useFetchData = <TData, TVariables>(
  query: string,
): ((variables?: TVariables) => Promise<TData>) => {
  // it is safe to call React Hooks here.
  const { i18n } = useTranslation();

  return async (variables?: TVariables) => {
    return await fetchContent(query, {
      ...variables,
      locale: i18n.language,
    });
  };
};

export const getData = <TData, TVariables>(
  query: string,
): (() => Promise<TData>) => {
  return async (variables?: TVariables) => {
    return await fetchContent(query, { ...variables });
  };
};
