import { cn } from '@/utils/style';
import React from 'react';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import useWildChatContent from '../hooks/use-wild-chat-content';
import { ChatMessage } from '@/app/providers/chat';
import { FsIcon } from '@/components/FsTabs/components/helpers';

const RainbotChatBubbleSimple = ({ message }: { message: ChatMessage }) => {
  const rainbotContent = useWildChatContent().rainbot;

  return (
    <div className={cn(['mb-3 flex flex-row space-x-2'])}>
      <div className="shrink-0">
        <Image
          loader={DEFAULT_IMAGE_LOADER}
          height={30}
          width={30}
          src="/assets/chat/avatar_rain_bot.png"
          alt="rainbot icon"
        />
      </div>
      <div className="flex flex-col space-y-1">
        <div
          className={cn([
            'rounded-lg  bg-base-highlight/20 p-2 text-sm text-text-subdued',
          ])}
        >
          <span>
            {`${message?.payload?.content}`}
            <span className="ml-0.5">🌧️</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default RainbotChatBubbleSimple;
